import { useApolloClient } from "@apollo/client";
import { FetchParams, KapexTable, QueryResponse } from "@kapex/tables";
import {
  Button,
  Group,
  ScrollArea,
  Text,
  TypographyStylesProvider,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { useCallback, useEffect, useState } from "react";
import { MdAdd, MdCancel, MdCheck } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { NewsletterTemplatePage } from "src/api/graphql/queries/NewsletterTemplate";
import {
  GetQuarterlyNewsletter,
  QuarterlyNewsletterPage,
} from "src/api/graphql/queries/QuarterlyNewsletter";
import {
  GetQuarterlyNewsletterQuery,
  GetQuarterlyNewsletterQueryVariables,
  NewsletterTemplatePageQuery,
  NewsletterTemplatePageQueryVariables,
  PublishQuarterlyNewsletterMutation,
  PublishQuarterlyNewsletterMutationVariables,
  QuarterlyNewsletterPageQuery,
  QuarterlyNewsletterPageQueryVariables,
} from "src/generated/graphql";
import CreateNewsletterForm from "./components/CreateNewsletterForm";
import UpdateNewsletterForm from "./components/UpdateNewsletterForm";
import { PublishQuarterlyNewsletter } from "src/api/graphql/mutations/QuarterlyNewsletter";
import { showNotification, updateNotification } from "@mantine/notifications";
import RichTextEditor from "@mantine/rte";

type NewsletterTemplateData = {
  id: string;
  updatedDate: Date;
  header: string;
  body: string;
};

type QuarterlyNewsletterData = {
  id: string;
  updatedDate: Date;
  header: string;
  body: string;
  quarter: number;
  year: number;
};

const Newsletter = () => {
  const modals = useModals();
  const { query, mutate } = useApolloClient();
  const theme = useMantineTheme();
  const scrolling = useMediaQuery("(max-height: 862px)");
  const doubleWide = useMediaQuery("(min-width: 2028px)");
  const [refetchTableData, setrefetchTableData] = useState(false);
  const [currentQuarter, setCurrentQuarter] = useState<
    QuarterlyNewsletterData | undefined
  >(undefined);

  useEffect(() => {
    if (!currentQuarter) {
      getCurrentQuarter();
    }
  }, []);

  const getCurrentQuarter = async () => {
    let response = await query<
      GetQuarterlyNewsletterQuery,
      GetQuarterlyNewsletterQueryVariables
    >({
      query: GetQuarterlyNewsletter,
      fetchPolicy: "no-cache",
    });

    if (response.data.quarterlyNewsletter) {
      setCurrentQuarter(response.data.quarterlyNewsletter);
    }
  };

  const getNewsletterTemplates =useCallback(async (
    fetchParams: FetchParams
  ): Promise<QueryResponse<NewsletterTemplateData>> => {
    let response = await query<
      NewsletterTemplatePageQuery,
      NewsletterTemplatePageQueryVariables
    >({
      query: NewsletterTemplatePage,
      variables: {
        pagination: {
          ...fetchParams,
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.newsletterTemplatePage) {
      return {
        rowCount: response.data.newsletterTemplatePage.totalRows,
        rowData: response.data.newsletterTemplatePage
          .items as NewsletterTemplateData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  },[refetchTableData]) ;

  const getQuarterlyNewsletters = async (
    fetchParams: FetchParams
  ): Promise<QueryResponse<QuarterlyNewsletterData>> => {
    let response = await query<
      QuarterlyNewsletterPageQuery,
      QuarterlyNewsletterPageQueryVariables
    >({
      query: QuarterlyNewsletterPage,
      variables: {
        pagination: {
          ...fetchParams,
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.quarterlyNewsletterPage) {
      return {
        rowCount: response.data.quarterlyNewsletterPage.totalRows,
        rowData: response.data.quarterlyNewsletterPage
          .items as QuarterlyNewsletterData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  };

  const publishTemplate = async (newsletterTemplateId: string) => {
    showNotification({
      id: "publish-template",
      loading: true,
      title: "Loading",
      message: "Publishing newsletter template...",
      autoClose: false,
      disallowClose: true,
    });
    let response = await mutate<
      PublishQuarterlyNewsletterMutation,
      PublishQuarterlyNewsletterMutationVariables
    >({
      mutation: PublishQuarterlyNewsletter,
      fetchPolicy: "no-cache",
      variables: {
        newsletterTemplateId,
      },
    });

    if (response.data) {
      updateNotification({
        id: "publish-template",
        color: "green",
        title: "Success!",
        message: "Template published!",
        icon: <MdCheck size={16} />,
        autoClose: 2000,
        onClose: () => {
          window.location.reload();
        },
      });
    } else if (response.errors) {
      updateNotification({
        id: "publish-template",
        color: "red",
        title: "Failure!",
        message: "Failed to publish the template!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };

  const GetNewsLetterTemplate = useCallback(() => {
    return (<ScrollArea.Autosize
      maxHeight={
        doubleWide
          ? "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px)) - 40px)"
          : "calc(((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px)) - 96px)/2)"
      }
      style={{
        minHeight: 300,
      }}
      type="auto"
      scrollbarSize={9}
      sx={(theme) => ({
        ".mantine-ScrollArea-root": {
          borderRadius: 4,
          paddingRight: 1,
        },
        ".mantine-ScrollArea-viewport": {
          borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
        },
        ".mantine-ScrollArea-scrollbar": {
          background: theme.colors.dark[4],
          ".mantine-ScrollArea-thumb": {
            backgroundColor: theme.colors.greenMachine[6],
          },
        },
      })}
    >
      <KapexTable<NewsletterTemplateData>
        tableSx={{
          thead: {
            th: {
              ">div": {
                color: "#fff",
              },
            },
          },
          tbody: {
            "tr:hover": {
              backgroundColor: `${theme.colors.greenMachine[1]} !important`,
            },
          },
          tfoot: {
            td: {
              borderRight: "none",
              div: {
                color: "#fff",
              },
            },
          },
        }}
        headerColor={{ r: 255, g: 255, b: 255 }}
        headerBg={theme.colors.greenMachine[6]}
        borderColor={theme.colors.greenMachine[8]}
        columnConfigs={[
          {
            field: "header",
            name: "Subject",
            filter: {
              type: "text",
              field: "header",
            },
            cellRenderer: (rowItem) => {
              return (
                <Text
                  sx={{
                    fontWeight: 500,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    modals.openModal({
                      styles: {
                        title: {
                          fontWeight: 500,
                          fontSize: 20,
                        },
                      },
                      title: "Create Newsletter Template",
                      size: "min(600px, 80vw)",
                      children: (
                        <UpdateNewsletterForm
                          id={rowItem.id}
                          onClose={() => {
                            modals.closeAll();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  {`${rowItem.header}`}
                </Text>
              );
            },
          },
          {
            field: "updatedDate",
            name: "Updated",
            filter: {
              field: "updatedDate",
              type: "dateRange",
            },
            valueParser: (rowItem) => {
              return rowItem.updatedDate
                ? new Date(rowItem.updatedDate).toDateString()
                : "N/A";
            },
          },
          {
            field: "id",
            name: "",
            cellRenderer: (rowItem) => {
              return (
                <Button
                  radius={"xl"}
                  compact
                  variant="outline"
                  style={{
                    fontSize: 16,
                  }}
                  onClick={() => {
                    modals.openModal({
                      styles: {
                        title: {
                          fontWeight: 500,
                          fontSize: 20,
                        },
                      },
                      title: "Publish this Quarter's Newsletter Template",
                      size: "min(600px, 80vw)",
                      children: (
                        <div>
                          <Text>
                            {currentQuarter
                              ? `A newsletter template has alrady been selected for this quater. Publishing will update the default template for this quarter. However, designers will not be re-notified. Do you still wish to proceed with publishing?`
                              : `Are you sure you want to assign the selected template as this quarter's newsletter? Designers will be notified to customize and send their newsletters using this template.`}
                          </Text>
                          <Group
                            position="right"
                            spacing={"xl"}
                            style={{ marginTop: 48 }}
                          >
                            <Button
                              variant="outline"
                              onClick={() => modals.closeAll()}
                              color="red"
                              style={{ fontSize: 16 }}
                            >{`Cancel`}</Button>
                            <Button
                              onClick={() => {
                                modals.closeAll();
                                publishTemplate(rowItem.id);
                              }}
                              style={{ fontSize: 16 }}
                            >{`Publish`}</Button>
                          </Group>
                        </div>
                      ),
                    });
                  }}
                  leftIcon={<IoNewspaperOutline size={18} />}
                >
                  Publish
                </Button>
              );
            },
          },
        ]}
        dataFetch={getNewsletterTemplates}
      />
    </ScrollArea.Autosize>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTableData]);

  return (
    <>
      <Group
        align={"flex-start"}
        style={{
          marginRight: scrolling ? 30 : 0,
        }}
      >
        <div style={{ width: "100%", maxWidth: doubleWide ? 900 : undefined }}>
          <Group position="apart" style={{ marginBottom: 4 }}>
            <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
              {"Templates"}
            </Text>
            <Button
              radius={"xl"}
              compact
              onClick={() => {
                modals.openModal({
                  styles: {
                    title: {
                      fontWeight: 500,
                      fontSize: 20,
                    },
                  },
                  title: "Create Newsletter Template",
                  size: "min(600px, 80vw)",
                  children: (
                    <CreateNewsletterForm
                      refresh={() => setrefetchTableData(!refetchTableData)}
                      onClose={() => {
                        modals.closeAll();
                      }}
                    />
                  ),
                });
              }}
              leftIcon={<MdAdd size={24} />}
              size="lg"
            >
              Create Template
            </Button>
          </Group>
          <GetNewsLetterTemplate />
        </div>
        <div style={{ width: "100%", maxWidth: doubleWide ? 900 : undefined }}>
          <Group position="apart" style={{ marginBottom: 4 }}>
            <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
              {"Published Newsletters"}
            </Text>
          </Group>
          <ScrollArea.Autosize
            maxHeight={
              doubleWide
                ? "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px)) - 40px)"
                : "calc(((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px)) - 96px)/2)"
            }
            style={{
              minHeight: 300,
            }}
            type="auto"
            scrollbarSize={9}
            sx={(theme) => ({
              ".mantine-ScrollArea-root": {
                borderRadius: 4,
                paddingRight: 1,
              },
              ".mantine-ScrollArea-viewport": {
                borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
              },
              ".mantine-ScrollArea-scrollbar": {
                background: theme.colors.dark[4],
                ".mantine-ScrollArea-thumb": {
                  backgroundColor: theme.colors.greenMachine[6],
                },
              },
            })}
          >
            <KapexTable<QuarterlyNewsletterData>
              tableSx={{
                thead: {
                  th: {
                    ">div": {
                      color: "#fff",
                    },
                  },
                },
                tbody: {
                  "tr:hover": {
                    backgroundColor: `${theme.colors.greenMachine[1]} !important`,
                  },
                },
                tfoot: {
                  td: {
                    div: {
                      color: "#fff",
                    },
                  },
                },
              }}
              headerColor={{ r: 255, g: 255, b: 255 }}
              headerBg={theme.colors.greenMachine[6]}
              borderColor={theme.colors.greenMachine[8]}
              columnConfigs={[
                {
                  field: "header",
                  name: "Subject",
                  filter: {
                    type: "text",
                    field: "header",
                  },
                  cellRenderer: (rowItem) => {
                    return (
                      <Text
                        sx={{
                          fontWeight: 500,
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          modals.openModal({
                            styles: {
                              title: {
                                fontWeight: 500,
                                fontSize: 20,
                              },
                              body: {
                                minHeight: 200,
                              },
                            },
                            title: "View Quarterly Newsletter Template",
                            size: "min(800px, 80vw)",
                            children: (
                              <div>
                                <Text
                                  size={18}
                                  weight={500}
                                  style={{ marginBottom: 16 }}
                                >
                                  {rowItem.header}
                                </Text>
                                <TypographyStylesProvider>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: rowItem.body,
                                    }}
                                  />
                                </TypographyStylesProvider>
                              </div>
                            ),
                          });
                        }}
                      >
                        {`${rowItem.header}`}
                      </Text>
                    );
                  },
                },
                {
                  field: "quarter",
                  name: "Quarter",
                  filter: {
                    field: "quarter",
                    type: "number",
                  },
                },
                {
                  field: "year",
                  name: "Year",
                  filter: {
                    field: "year",
                    type: "number",
                  },
                },
                {
                  field: "updatedDate",
                  name: "Updated",
                  filter: {
                    field: "updatedDate",
                    type: "dateRange",
                  },
                  valueParser: (rowItem) => {
                    return rowItem.updatedDate
                      ? new Date(rowItem.updatedDate).toDateString()
                      : "N/A";
                  },
                },
              ]}
              dataFetch={getQuarterlyNewsletters}
            />
          </ScrollArea.Autosize>
        </div>
      </Group>
    </>
  );
};

export default Newsletter;
