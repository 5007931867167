import { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  TextInput,
  Group,
  Button,
  ScrollArea,
  useMantineTheme,
  Menu,
  Indicator,
  Tooltip,
  Center,
  Loader,
  Container,
} from "@mantine/core";
import { Stack, Title, Text, ActionIcon } from "@mantine/core";
import { useMediaQuery, useToggle } from "@mantine/hooks";
import {
  MdCancel,
  MdCheck,
  MdChevronLeft,
  MdDelete,
  MdMoreHoriz,
  MdOutlineSyncProblem,
  MdSync,
} from "react-icons/md";
import {
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from "src/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { GetAccountOne } from "src/api/graphql/queries/Account";
import { useParams } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import {
  DeleteAccount,
  UpdateAccount,
} from "src/api/graphql/mutations/Accounts";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useTogglableTheme } from "src/utils/theme";
import { HiScale } from "react-icons/hi";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import CashSection from "./components/CashSection";
import PositionSection from "./components/PositionSection";
import StrategySection from "./components/StrategySection";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { useModals } from "@mantine/modals";

export type AccountData = NonNullable<GetAccountOneQuery["accountOne"]>;

const AccountDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { query, mutate } = useApolloClient();
  const { refetchUserData, setCurrentPageTitle, isDarkMode } =
    useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const modals = useModals();

  const [value, toggle] = useToggle(["edit", "save"]);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState<AccountData>();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getAccount = async (accountOneId: string) => {
    setLoading(true);
    let response = await query<GetAccountOneQuery, GetAccountOneQueryVariables>(
      {
        query: GetAccountOne,
        variables: {
          accountOneId,
        },
        fetchPolicy: "no-cache",
      }
    );
    setLoading(false);
    if (response && response.data !== null && response.data.accountOne) {
      setAccount(response.data.accountOne);
      setTitle(response.data.accountOne.name);
    }
  };

  const updateAccount = async (updateAccountId: string) => {
    try {
      showNotification({
        id: "update-account",
        loading: true,
        title: "Loading",
        message: "Updating Account...",
        autoClose: false,
        disallowClose: true,
      });

      const response = await mutate<
        UpdateAccountMutation,
        UpdateAccountMutationVariables
      >({
        mutation: UpdateAccount,
        variables: {
          updateAccountId,
          data: {
            name: title,
          },
        },
      });

      if (response.data?.updateAccount) {
        getAccount(response.data?.updateAccount?.id);
        updateNotification({
          id: "update-account",
          color: "green",
          title: "Success!",
          message: "Account updated!",
          icon: <MdCheck size={16} />,
          autoClose: 2000,
        });
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      updateNotification({
        id: "update-account",
        color: "red",
        title: "Failure!",
        message: message || "Unable to update the account!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };

  const deleteAccount = async () => {
    if (id) {
      const response = await mutate<
        DeleteAccountMutation,
        DeleteAccountMutationVariables
      >({
        mutation: DeleteAccount,
        variables: {
          id,
        },
        fetchPolicy: "no-cache",
      });

      if (response.data?.deleteAccount) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (id) {
      getAccount(id);
    }
  }, [id]);

  useEffect(() => {
    setCurrentPageTitle("Account Details");

    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [setCurrentPageTitle]);

  const editNameToggle = () => {
    if (id && value === "save") {
      updateAccount(id);
    }
    toggle();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  let sameDay =
    new Date().getDate() == new Date(account?.lastSynced).getDate() &&
    new Date().valueOf() - new Date(account?.lastSynced).valueOf() <
    1000 * 60 * 60 * 24;

  return (
    <ScrollArea
      style={{
        height:
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )",
      }}
      sx={{
        ".mantine-ScrollArea-viewport > div": {
          display: "block !important",
        },
      }}
      scrollbarSize={6}
    >
      {loading ? <Container
        style={{
          height:
            "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px + 46px)) )",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Loader />
      </Container> : <>
        <Group position="apart">
          <Button
            variant="outline"
            onClick={() => navigate("/my-accounts")}
            size="xs"
            style={{
              fontSize: 16,
              lineHeight: "16px",
              color: theme.colors.greenMachine[7],
              border: "none",
              paddingLeft: 0,
            }}
          >
            <MdChevronLeft size={22} /> {"Back"}
          </Button>

          <Menu position="bottom-end" withArrow offset={-4}>
            <Menu.Target>
              <Indicator
                size={account?.withinThreshold || sameDay ? 0 : 10}
                offset={2}
                position="top-end"
                color={"red"}
                style={{ marginRight: "3%", marginTop: 4 }}
              >
                <ActionIcon
                  size="md"
                  variant="light"
                  style={{
                    marginRight: "0%",
                    backgroundColor: toggleTheme.card_bg,
                  }}
                >
                  <MdMoreHoriz color={toggleTheme.primary} size={40} />
                </ActionIcon>
              </Indicator>
            </Menu.Target>
            <Menu.Dropdown style={{ right: "3%", left: "" }}>
              <Indicator
                size={account?.withinThreshold || sameDay ? 0 : 10}
                offset={6}
                position="top-end"
                color={"red"}
              >
                <Menu.Item
                  disabled={!account?.strategy || account.withinThreshold}
                  onClick={() => {
                    navigate(`/account-sync/${account?.id}`);
                  }}
                >
                  <Group position="left">
                    <HiScale size={18} />
                    <Text>{"View Trades"}</Text>
                  </Group>
                </Menu.Item>
              </Indicator>
              <Menu.Item
                onClick={() => {
                  modals.openModal({
                    styles: {
                      title: {
                        fontWeight: 500,
                        fontSize: 20,
                      },
                    },
                    title: "Delete Account",
                    size: "min(400px, 80vw)",
                    children: (
                      <Center>
                        <Stack>
                          <Text>
                            {"Are you certain you want to delete this Account?"}
                          </Text>
                          <Group position="right" mt="md">
                            <Button
                              type="reset"
                              onClick={() => modals.closeAll()}
                              variant="outline"
                            >{`Cancel`}</Button>
                            <Button
                              type="submit"
                              color={"red"}
                              onClick={() => {
                                modals.closeAll();
                                deleteAccount().then(() => {
                                  refetchUserData();
                                  navigate("/my-accounts");
                                });
                              }}
                            >
                              {`Delete`}
                            </Button>
                          </Group>
                        </Stack>
                      </Center>
                    ),
                    onClose: () => { },
                  });
                }}
              >
                <Group position="left">
                  <MdDelete size={18} color="red" />
                  <Text>{"Delete Account"}</Text>
                </Group>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        {!account?.withinThreshold && !sameDay && (
          <Text
            size={16}
            weight={500}
            align="center"
            color={"red"}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              pointerEvents: "none",
              marginTop: -28,
            }}
          >
            <MdOutlineSyncProblem
              size={18}
              style={{ marginRight: 4, marginTop: 4 }}
            />
            {`Account Out-of-Sync!`}
          </Text>
        )}
        {sameDay && (
          <Tooltip
            label={
              "Refetching occurs after extended trading hours end. Account data may be inaccurate during this period."
            }
          >
            <Text
              size={16}
              weight={500}
              align="center"
              color={"dimmed"}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                //pointerEvents: "none",
                marginTop: -28,
              }}
            >
              <MdSync size={18} style={{ marginRight: 4, marginTop: 4 }} />
              {`Refetch Pending...`}
            </Text>
          </Tooltip>
        )}
        <Stack
          style={{
            marginLeft: "3%",
            marginRight: "3%",
            marginTop: "1.5%",
            marginBottom: "1.5%",
          }}
          spacing={4}
        >
          <Group position="apart">
            <Group position="left" spacing={4}>
              {value === "save" ? (
                <TextInput
                  placeholder="Account name"
                  value={title}
                  style={{ marginRight: -6 }}
                  onChange={handleChange}
                  sx={{
                    input: {
                      padding: 4,
                      minHeight: 26,
                      height: 26,
                    },
                  }}
                />
              ) : (
                <Title size={24} weight={500}>
                  {account?.custodian.name +
                    (account?.mask ? " - #" + account?.mask : "")}
                </Title>
              )}
            </Group>
            <Text weight={500} size={20}>
              {formatter.format(account?.totalValue || 0)}
            </Text>
          </Group>
          <Text weight={500} size={16}>
            {account?.name}
          </Text>
        </Stack>

        {!account?.withinThreshold && !sameDay && (
          <Stack style={{ marginLeft: "3%", marginRight: "3%" }} spacing={2}>
            <Button
              disabled={!account?.strategy || account.withinThreshold}
              leftIcon={<MdSync size={18} />}
              onClick={() => {
                navigate(`/account-sync/${account?.id}`);
              }}
              style={{ fontSize: 16 }}
            >
              {"View Trades to Re-Sync"}
            </Button>
          </Stack>
        )}

        <div style={{ padding: "1.5% 3%" }}>
          {account && id && <StrategySection account={account} id={id} />}
        </div>
        <div style={{ padding: "1.5% 3%" }}>
          {account && <CashSection account={account} sameDay={sameDay} />}
        </div>
        <div style={{ padding: "1.5% 3%" }}>
          {account && (
            <PositionSection account={account} setAccount={setAccount} />
          )}
        </div>
      </>}
    </ScrollArea>
  );
};
export default AccountDetails;
