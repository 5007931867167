import { useApolloClient } from "@apollo/client";
import {
  Badge,
  Button,
  Card,
  Center,
  Container,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { GetDesigner } from "src/api/graphql/queries/Designer";
import { GetStripePaymentMethodLink } from "src/api/graphql/queries/Subscription";
import {
  GetDesignerQuery,
  GetDesignerQueryVariables,
  GetStripePaymentMethodLinkQuery,
  GetStripePaymentMethodLinkQueryVariables,
  SubscriptionRenewalFrequency,
} from "src/generated/graphql";

type DesignerData = NonNullable<GetDesignerQuery["designerOne"]>;
const SubscriptionPackage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setCurrentPageTitle, currentSubscriber } = useContext(AppContext);
  const { query } = useApolloClient();
  const { id } = useParams();
  const [designer, setDesigner] = useState<DesignerData | undefined>(undefined);
  const theme = useMantineTheme();
  const [loading, setLoading] = useState<boolean>(false);

  const getStripePaymentMethodLink = async (
    frequency: SubscriptionRenewalFrequency
  ) => {
    const response = await query<
      GetStripePaymentMethodLinkQuery,
      GetStripePaymentMethodLinkQueryVariables
    >({
      query: GetStripePaymentMethodLink,
      variables: {
        successSubRoute: `${window.location.pathname.substring(
          1
        )}?paymentSucess=1&freq=${frequency}`,
        cancelSubRoute: window.location.pathname.substring(1),
      },
    });
    if (response.data.getStripePaymentMethodLink) {
      window.location.href = response.data.getStripePaymentMethodLink;
    }
  };

  const handleCheckPaymentMethod = () => {
    return !currentSubscriber?.customerPaymentMethods ? false : true;
  };

  useEffect(() => {
    setLoading(true);
    const stripeSuccess = searchParams.get("paymentSucess");
    const frequency = searchParams.get("freq");
    if (stripeSuccess === "1" && designer !== undefined && frequency !== null) {
      setLoading(false);
      navigate("/subscription/confirmation", {
        state: {
          designer,
          frequency,
        },
      });
    }
    setLoading(false);
  }, [currentSubscriber, designer, navigate, searchParams]);

  useEffect(() => {
    setCurrentPageTitle("Subscription");
    const getDesigner = async (id: string) => {
      const response = await query<GetDesignerQuery, GetDesignerQueryVariables>(
        {
          query: GetDesigner,
          fetchPolicy: "no-cache",
          variables: {
            id,
          },
        }
      );
      if (response.data.designerOne) {
        setDesigner(response.data.designerOne);
      }
    };
    if (id && !designer) {
      getDesigner(id);
    }
    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [designer, id, query, setCurrentPageTitle]);

  return (
    <Container>
      {!loading ? (
        <ScrollArea.Autosize maxHeight="70vh">
          <Center>
            <Text
              align="center"
              size={22}
              style={{ fontFamily: "Inter-SemiBold", marginBottom: 10 }}
            >
              Choose Your Subscription
            </Text>
          </Center>
          <Stack>
            <Card
              style={{
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                padding: "5px 10px",
              }}
              shadow="sm"
              radius="md"
              withBorder
            >
              <Group position="apart" mt="md" mb="xs">
                <Text
                  size={isMobile ? 16 : 20}
                  style={{ fontFamily: "Inter-Medium" }}
                >
                  Quarterly Subscription
                </Text>
                <Text align="center" weight={500} size={20}>
                  <Badge
                    color="pink"
                    style={{ fontFamily: "Inter-Medium" }}
                    size="lg"
                    variant="light"
                  >
                    3 Months
                  </Badge>
                </Text>
              </Group>

              <Text align="center" size="md" color="dimmed">
                Subscription Fee: $
                {Number(
                  (designer?.revenueShare?.designerFee || 0) +
                    (designer?.revenueShare?.serviceFee || 0)
                ).toFixed(2)}
              </Text>

              <Button
                variant="filled"
                color={theme.colors.green[6]}
                fullWidth
                mt="md"
                radius="md"
                onClick={() => {
                  if (handleCheckPaymentMethod()) {
                    navigate("/subscription/confirmation", {
                      state: {
                        designer,
                        frequency: SubscriptionRenewalFrequency.Quarterly,
                      },
                    });
                  } else {
                    getStripePaymentMethodLink(
                      SubscriptionRenewalFrequency.Quarterly
                    );
                  }
                }}
              >
                Subscriber Quarterly
              </Button>
            </Card>
            <Card
              style={{
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                padding: "5px 10px",
              }}
              shadow="sm"
              radius="md"
              withBorder
            >
              <Group position="apart" mt="md" mb="xs">
                <Text
                  size={isMobile ? 16 : 20}
                  style={{ fontFamily: "Inter-Medium" }}
                >
                  Annual Subscription
                </Text>
                <Badge
                  style={{ fontFamily: "Inter-Medium" }}
                  size="lg"
                  color="pink"
                  variant="light"
                >
                  12 Months
                </Badge>
              </Group>

              <Text align="center" size="md" color="dimmed">
                Subscription Fee: $
                {Number(
                  (designer?.revenueShare?.designerFeeAnnual || 0) +
                    (designer?.revenueShare?.serviceFeeAnnual || 0)
                ).toFixed(2)}
              </Text>

              <Button
                variant="filled"
                color={theme.colors.green[6]}
                fullWidth
                mt="md"
                radius="md"
                onClick={() => {
                  if (handleCheckPaymentMethod()) {
                    navigate("/subscription/confirmation", {
                      state: {
                        designer,
                        frequency: SubscriptionRenewalFrequency.Annually,
                      },
                    });
                  } else {
                    getStripePaymentMethodLink(
                      SubscriptionRenewalFrequency.Quarterly
                    );
                  }
                }}
              >
                Subscribe Annually
              </Button>
            </Card>
          </Stack>
        </ScrollArea.Autosize>
      ) : (
        <>
          <Loader />
        </>
      )}
    </Container>
  );
};

export default SubscriptionPackage;
