import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Center,
  Group,
  ScrollArea,
  Stack,
  useMantineTheme,
  Text,
  ActionIcon,
} from "@mantine/core";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import {
  GetCustodianAccountsQuery,
  GetCustodianAccountsQueryVariables,
  GetPlaidLinkTokenQuery,
  GetPlaidLinkTokenQueryVariables,
  GetPlaidUpdateLinkTokenQuery,
  GetPlaidUpdateLinkTokenQueryVariables,
  MigratePlaidAccountMutation,
  MigratePlaidAccountMutationVariables,
} from "src/generated/graphql";
import { useApolloClient } from "@apollo/client";
import {
  GetCustodianAccounts,
  GetPlaidLinkToken,
  GetPlaidUpdateLinkToken,
} from "src/api/graphql/queries/Account";
import { AppContext } from "src/api/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import LaunchLink from "./LaunchLink";
import { MigratePlaidAccount } from "src/api/graphql/mutations/Accounts";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useModals } from "@mantine/modals";
import { useTogglableTheme } from "src/utils/theme";
import LoginForm, { LoginFormType } from "../MyAccounts/LoginForm";
import UpdatePlaidAccounts from "../UpdateAccounts";
import AddUpdateAccount from "./AddUpdateAccount";


export type MigrateAccountPayload = {
  publicToken: string;
  plaidInstitutionId: string;
  plaidAccountIds: string[];
  userTag?: string;
  update?: boolean;
};
const PlaidLink = () => {
  const theme = useMantineTheme();
  const { currentSubscriber, setCurrentPageTitle, isDarkMode } =
    useContext(AppContext);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { query, mutate } = useApolloClient();
  const navigate = useNavigate();

  const [linkToken, setLinkToken] = useState<string | undefined>(undefined);
  const modals = useModals();
  const [isUpdateFlow, setIsUpdateFlow] = useState<boolean>(false);
  const loginRef = useRef<LoginFormType>(null);
  const params = useParams();

  const closeAllModals = () => {
    //Note: closing modals on actions are closing the redirection
    // modals.closeAll();
  }

  const navigateToMyAccounts = () => {
    //Notes: instead of using navigate(-1) used navigation because after palid screen redirectiong it was not working
    navigate("/my-accounts");
  }

  useEffect(() => {
    setCurrentPageTitle("Portfolio Linking by Plaid");
    if (params.custodianId) {
      // for reauthorization purpose
      setIsUpdateFlow(true);
      getUpdateLinkToken(params.custodianId);
    } else if (!linkToken) {
      if (currentSubscriber?.custodians.length === 0) {
        getLinkToken();
      } else {
        modals.openModal({
          styles: {
            title: {
              fontWeight: 500,
              fontSize: 20,
            },
          },
          title: "Link an Account",
          size: "min(400px, 80vw)",
          children: (
            <AddUpdateAccount
              closeAllModals={closeAllModals}
              setIsUpdateFlow={setIsUpdateFlow}
              navigateToMyAccounts={navigateToMyAccounts}
              setLinkToken={setLinkToken}
            />
          ),
          onClose: () => {
            navigateToMyAccounts();
          },
        });
      }
    }
    return () => {
      setCurrentPageTitle(undefined);
    };
  }, []);

  const getCustodianAccounts = async (custodianId: string) => {
    let response = await query<
      GetCustodianAccountsQuery,
      GetCustodianAccountsQueryVariables
    >({
      query: GetCustodianAccounts,
      fetchPolicy: "no-cache",
      variables: {
        custodianId,
      },
    });
    return response?.data?.getCustodianAccounts;
  };

  const getLinkToken = async () => {
    let response = await query<
      GetPlaidLinkTokenQuery,
      GetPlaidLinkTokenQueryVariables
    >({
      query: GetPlaidLinkToken,
      fetchPolicy: "no-cache",
    });

    if (response?.data?.getPlaidLinkToken) {
      setLinkToken(response.data.getPlaidLinkToken);
    }
  };

  const getUpdateLinkToken = async (custodianId: string) => {
    let response = await query<
      GetPlaidUpdateLinkTokenQuery,
      GetPlaidUpdateLinkTokenQueryVariables
    >({
      query: GetPlaidUpdateLinkToken,
      fetchPolicy: "no-cache",
      variables: {
        custodianId,
      },
    });

    if (response?.data?.getPlaidUpdateLinkToken) {
      setLinkToken(response.data.getPlaidUpdateLinkToken);
    }
  };

  const handleSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    if (currentSubscriber && metadata.institution && metadata.accounts[0]) {
      if (isUpdateFlow) {
        let accountIds = metadata.accounts.map((account) => account.id);

        let accounts = await migrateAccount({
          publicToken,
          plaidInstitutionId: metadata.institution.institution_id,
          plaidAccountIds: accountIds,
          update: isUpdateFlow,
        });
        if (params.custodianId) {
          navigate("/");
        } else if (accounts) {
          navigate("/my-accounts");
        }
      } else {
        // newLogin
        modals.openModal({
          styles: {
            title: {
              fontWeight: 500,
              fontSize: 20,
            },
          },
          title: "New Institution Login",
          size: "min(400px, 80vw)",
          children: (
            <Center>
              <LoginForm
                header={`We noticed this is the first time you have used this login at this institution. Please enter a name to help keep track of which login this account is associated.`}
                onSubmit={async (name) => {
                  loginRef.current?.updateLoadingState(true);
                  if (metadata.institution && metadata.accounts.length > 0) {
                    let accountIds = metadata.accounts.map(
                      (account) => account.id
                    );
                    await migrateAccount({
                      publicToken,
                      plaidInstitutionId: metadata.institution.institution_id,
                      plaidAccountIds: accountIds,
                      userTag: name,
                    });
                  } else {
                    loginRef.current?.updateLoadingState(false);
                  }
                }}
                institutionName={metadata.institution.name}
                ref={loginRef}
              />
            </Center>
          ),
          closeOnClickOutside: false,
          closeOnEscape: false,
          withCloseButton: false,
        });
      }
    }
  };

  const migrateAccount = async (data: MigrateAccountPayload) => {
    try {
      let response = await mutate<
        MigratePlaidAccountMutation,
        MigratePlaidAccountMutationVariables
      >({
        mutation: MigratePlaidAccount,
        variables: {
          data,
        },
        fetchPolicy: "no-cache",
      });
      modals.closeAll();
      const migratedAccounts = response?.data?.migratePlaidAccount;
      // do not open activate account if it is reauth
      if (!params.custodianId) {
        modals.openModal({
          styles: {
            title: {
              fontWeight: 500,
              fontSize: 20,
            },
          },
          title: "Link an Account",
          size: "min(400px, 100vw)",
          children: <UpdatePlaidAccounts accounts={migratedAccounts} />,
          onClose: () => {
            navigate("/my-accounts");
          },
        });
      }
      return response.data?.migratePlaidAccount;
    } catch (err) {
      modals.closeAll();
      console.log(err);

      if (String(err).includes("ErrorCode: 4021")) {
        modals.openModal({
          styles: {
            title: {
              fontWeight: 500,
              fontSize: 20,
            },
          },
          title: "Link an Account: Error",
          size: "min(400px, 80vw)",
          children: (
            <Center>
              <Stack>
                <Text color="red">
                  {`An error occurred while attempting to import the selected account: `}
                </Text>
                <Text>{String(err).split("ErrorCode: 4021")[1]}</Text>
              </Stack>
            </Center>
          ),
          closeOnClickOutside: true,
          onClose: () => {
            navigate("/my-accounts");
          },
        });
      }
    } finally {
      loginRef.current?.updateLoadingState(false);
    }
  };

  return (
    <ScrollArea
      style={{
        height:
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )",
      }}
      sx={{
        ".mantine-ScrollArea-viewport > div": {
          display: "block !important",
        },
      }}
      scrollbarSize={6}
    >
      <Button
        variant="outline"
        onClick={() => navigate(-1)}
        size="xs"
        style={{
          fontSize: 16,
          lineHeight: "16px",
          color: theme.colors.greenMachine[7],
          border: "none",
          paddingLeft: 0,
        }}
      >
        <MdChevronLeft size={22} /> Back
      </Button>
      {linkToken && (
        <LaunchLink token={linkToken} handleSuccess={handleSuccess} />
      )}
    </ScrollArea>
  );
};
export default PlaidLink;
