import { gql } from "@apollo/client";

export const GetPositions = gql`
  query GetPositions($accountId: String!) {
    positionMany(accountId: $accountId) {
      id
      selected
      stockData {
        id
        symbol
        shortName
      }
      shares
      value
    }
  }
`;
