import { gql } from "@apollo/client";

export const UpdateAccount = gql`
  mutation UpdateAccount(
    $updateAccountId: String!
    $data: UpdateAccountInput!
  ) {
    updateAccount(id: $updateAccountId, data: $data) {
      id
      name
    }
  }
`;

export const MigratePlaidAccount = gql`
  mutation MigratePlaidAccount($data: MigratePlaidAccountInput!) {
    migratePlaidAccount(data: $data) {
      id
      name
      mask
      externalId
      totalValue
      isActive
    }
  }
`;

export const UpdatePlaidAccount = gql`
  mutation UpdatePlaidAccount($data: UpdatePlaidAccountInput!) {
    updatePlaidAccount(data: $data) {
      id
      name
      mask
      externalId
      totalValue
      isActive
    }
  }
`;

export const MarkAccountSynced = gql`
  mutation MarkAccountSynced($id: String!) {
    markedSynced(id: $id)
  }
`;

export const DeleteAccount = gql`
  mutation DeleteAccount($id: String!) {
    deleteAccount(id: $id)
  }
`;

export const UpdateLoginTag = gql`
  mutation UpdateLoginTag($id: String!, $userTag: String!) {
    updateCustodianTag(custodianId: $id, userTag: $userTag) {
      id
      name
      userTag
      plaidInstitutionId
    }
  }
`;
