import { gql } from "@apollo/client";

export const GetPlatformAdminPage = gql`
  query GetPlatformAdminPage($pagination: PaginationInput!) {
    platformAdminPage(pagination: $pagination) {
      totalRows
      items {
        id
        user {
          id
          cognitoId
          role
          firstName
          lastName
          email
          phoneNumber
          createdDate
        }
      }
    }
  }
`;
