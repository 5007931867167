import { useApolloClient } from "@apollo/client";
import { FetchParams, KapexTable } from "@kapex/tables";
import { Group, ScrollArea, Text, useMantineTheme } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { useContext } from "react";
import { AppContext } from "src/api/AppContext";
import { GetAccountPage } from "src/api/graphql/queries/Account";
import {
  GetAccountPageQuery,
  GetAccountPageQueryVariables,
} from "src/generated/graphql";
import { currencyFormatter } from "src/utils/formatting";
import { useTogglableTheme } from "src/utils/theme";
import AccountDetailsReadOnly from "./components/AccountDetailsReadOnly";

type AccountsData = {
  id: string;
  createdDate: any;
  name: string;
  strategy: { id: string; name: string };
  totalValue: number;
  subscriber: {
    user: {
      firstName: string;
      lastName: string;
    };
  };
};

const AccountList = () => {
  const modals = useModals();
  const { query } = useApolloClient();
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);

  const getAccounts = async (fetchParams: FetchParams) => {
    const response = await query<
      GetAccountPageQuery,
      GetAccountPageQueryVariables
    >({
      query: GetAccountPage,
      variables: {
        pagination: {
          ...fetchParams,
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.accountPage) {
      return {
        rowCount: response.data.accountPage.totalRows,
        rowData: response.data.accountPage.items as AccountsData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  };
  return (
    <div
      style={{
        marginRight: 15,
      }}
    >
      <Group position="apart">
        <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
          {"Accounts"}
        </Text>
      </Group>
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 40px)) - 48px )"
        }
        style={{
          minHeight: 300,
          marginBottom: 8,
        }}
        type="auto"
        scrollbarSize={9}
        sx={(theme) => ({
          ".mantine-ScrollArea-root": {
            borderRadius: 4,
            paddingRight: 1,
          },
          ".mantine-ScrollArea-viewport": {
            borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
          },
          ".mantine-ScrollArea-scrollbar": {
            background: theme.colors.dark[4],
            ".mantine-ScrollArea-thumb": {
              backgroundColor: theme.colors.greenMachine[6],
            },
          },
        })}
      >
        <KapexTable<AccountsData>
          tableSx={{
            thead: {
              th: {
                ">div": {
                  color: "#fff",
                },
              },
              "::after": {
                left: "-1px",
              },
            },
            tbody: {
              tr: {
                td: {
                  borderColor: toggleTheme.card_disabled_bg,
                  ">div": {
                    color: toggleTheme.text,
                  },
                },
              },
              "tr:hover": {
                backgroundColor: `${toggleTheme.card_disabled_bg} !important`,
              },
            },
            tfoot: {
              td: {
                div: {
                  color: "#fff",
                },
              },
            },
          }}
          headerColor={{ r: 255, g: 255, b: 255 }}
          headerBg={theme.colors.greenMachine[6]}
          borderColor={theme.colors.greenMachine[8]}
          columnConfigs={[
            {
              field: "name",
              name: "Name",
              filter: {
                type: "text",
                field: "name",
              },
              sortable: true,
              cellRenderer: (rowItem) => {
                return (
                  <Text
                    sx={{
                      fontWeight: 500,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      modals.openModal({
                        styles: {
                          title: {
                            fontWeight: 500,
                            fontSize: 20,
                          },
                          body: {
                            padding: 16,
                          },
                        },
                        title: `Account Details - ${rowItem.subscriber.user.firstName} ${rowItem.subscriber.user.lastName}`,
                        size: "min(650px, 80vw)",
                        overflow: "inside",
                        children: <AccountDetailsReadOnly id={rowItem.id} />,
                      });
                    }}
                  >
                    {`${rowItem.name}`}
                  </Text>
                );
              },
            },
            {
              field: "name",
              parentRelationField: "subscriber",
              name: "Subscriber Name",
              filter: {
                type: "text",
                parentRelationField: "subscriber",
                field: "name",
              },
              sortable: true,
              cellRenderer: (rowItem) => {
                return (
                  <Text
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {`${rowItem.subscriber.user?.firstName} ${rowItem.subscriber.user?.lastName}`}
                  </Text>
                );
              },
            },
            {
              field: "name",
              parentRelationField: "strategy",
              name: "Strategy Name",
              filter: {
                field: "name",
                parentRelationField: "strategy",
                type: "text",
              },
              sortable: true,
              valueParser: (rowItem) => {
                return rowItem.strategy ? rowItem.strategy.name : "N/A";
              },
            },
            {
              field: "totalValue",
              name: "Total Value",
              filter: {
                field: "totalValue",
                type: "text",
              },
              sortable: true,
              valueParser: (rowItem) => {
                return currencyFormatter.format(rowItem.totalValue || 0);
              },
            },
            {
              field: "createdDate",
              name: "Created Date",
              filter: {
                field: "createdDate",
                type: "text",
              },
              sortable: true,
              valueParser: (rowItem) => {
                return rowItem
                  ? new Date(rowItem.createdDate).toDateString()
                  : "N/A";
              },
            },
          ]}
          dataFetch={getAccounts}
        />
      </ScrollArea.Autosize>
    </div>
  );
};

export default AccountList;
