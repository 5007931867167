import { Container, Loader } from "@mantine/core";
import { FC, useContext } from "react";
import { AppContext } from "src/api/AppContext";
import { UserRole } from "src/generated/graphql";
import DesignerOnboarding from "./screens/DesignerOnboarding";
import SubscriberOnboarding from "./screens/SubscriberOnboarding";

const Onboarding: FC = ({ children }) => {
  const { currentUser } = useContext(AppContext);

  if (currentUser?.role === UserRole.Designer) {
    return <DesignerOnboarding />;
  } else if (currentUser?.role === UserRole.Subscriber) {
    return <SubscriberOnboarding />;
  }
  return <Container style={{
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }}>
    <Loader size={"lg"}/>
  </Container>;
};

export default Onboarding;
