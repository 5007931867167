import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "src/modules/MobileScreens/Login";
import UnsubscribedDesignerDetails from "src/modules/MobileScreens/UnsubscribedDesignerDetails";

const NewUserRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/designer/:id" element={<UnsubscribedDesignerDetails />} />
    </Routes>
  );
};

export default NewUserRoutes;
