import { useApolloClient } from "@apollo/client";
import { ScrollArea, Text, useMantineTheme } from "@mantine/core";
import { GetUserPage } from "src/api/graphql/queries/User";
import {
  GetUserPageQuery,
  GetUserPageQueryVariables,
  UserRole,
} from "src/generated/graphql";
import { KapexTable, FetchParams, QueryResponse } from "@kapex/tables";
import { ArrayElement } from "src/utils/helperTypes";
import { useTogglableTheme } from "src/utils/theme";
import { useContext } from "react";
import { AppContext } from "src/api/AppContext";

export type UserData = ArrayElement<GetUserPageQuery["userPage"]["items"]>;

const User = () => {
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { query } = useApolloClient();

  const getUsers = async (
    fetchParams: FetchParams
  ): Promise<QueryResponse<UserData>> => {
    let response = await query<GetUserPageQuery, GetUserPageQueryVariables>({
      query: GetUserPage,
      variables: {
        pagination: {
          count: fetchParams.count,
          page: fetchParams.page,
          orderBy: fetchParams.orderBy,
          filters: fetchParams.filters,
        },
      },
    });

    if (response.data.userPage) {
      return {
        rowCount: response.data.userPage.totalRows,
        rowData: response.data.userPage.items as UserData[],
      };
    }
    return {
      rowData: [],
      rowCount: 0,
    };
  };

  return (
    <div>
      <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
        {"Users"}
      </Text>
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 40px)) - 48px )"
        }
        style={{
          minHeight: 300,
          marginBottom: 8,
        }}
        type="auto"
        scrollbarSize={9}
        sx={(theme) => ({
          ".mantine-ScrollArea-root": {
            borderRadius: 4,
            paddingRight: 1,
          },
          ".mantine-ScrollArea-viewport": {
            borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
          },
          ".mantine-ScrollArea-scrollbar": {
            background: theme.colors.dark[4],
            ".mantine-ScrollArea-thumb": {
              backgroundColor: theme.colors.greenMachine[6],
            },
          },
        })}
      >
        <KapexTable<UserData>
          tableSx={{
            thead: {
              th: {
                ">div": {
                  color: "#fff",
                },
              },
              "::after": {
                left: "-1px",
              },
            },
            tbody: {
              tr: {
                td: {
                  borderColor: toggleTheme.card_disabled_bg,
                  ">div": {
                    color: toggleTheme.text,
                  },
                },
              },
              "tr:hover": {
                backgroundColor: `${toggleTheme.card_disabled_bg} !important`,
              },
            },
            tfoot: {
              td: {
                div: {
                  color: "#fff",
                },
              },
            },
          }}
          headerColor={{ r: 255, g: 255, b: 255 }}
          headerBg={theme.colors.greenMachine[6]}
          borderColor={theme.colors.greenMachine[8]}
          columnConfigs={[
            {
              field: "name",
              name: "Name",
              filter: {
                field: "name",
                type: "text",
              },
              sortable: true,
              valueParser: (rowItem) =>
                `${rowItem.firstName} ${rowItem.lastName}`,
            },
            {
              field: "role",
              name: "Role",
              filter: {
                field: "role",
                type: "select",
                options: [
                  UserRole.Subscriber,
                  UserRole.Designer,
                  UserRole.PlatformAdmin,
                ],
              },
              sortable: true,
            },
            {
              field: "email",
              name: "Email",
              filter: {
                field: "email",
                type: "text",
              },
              sortable: true,
            },
            {
              field: "phoneNumber",
              name: "Phone",
              filter: {
                field: "phoneNumber",
                type: "text",
              },
              sortable: true,
              valueParser: (rowItem) => {
                let num = rowItem.phoneNumber;
                if (!num) {
                  return "N/A";
                }
                let len = num.length;
                if (len >= 10) {
                  return `(${num.slice(len - 10, len - 7)}) ${num.slice(
                    len - 7,
                    len - 4
                  )}-${num.slice(len - 4)}`;
                } else {
                  return "N/A";
                }
              },
            },
            {
              field: "createdDate",
              name: "Created",
              filter: {
                field: "createdDate",
                type: "dateRange",
              },
              sortable: true,
              valueParser: (rowItem) => {
                return new Date(rowItem.createdDate).toDateString();
              },
            },
          ]}
          dataFetch={getUsers}
        />
      </ScrollArea.Autosize>
    </div>
  );
};

export default User;
