import { useApolloClient } from "@apollo/client";
import {
  Button,
  Group,
  Text,
  Card,
  useMantineTheme,
  Stack,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/api/AppContext";
import { UpdatePlaidAccount } from "src/api/graphql/mutations/Accounts";
import {
  UpdatePlaidAccountMutation,
  UpdatePlaidAccountMutationVariables,
} from "src/generated/graphql";
import { useTogglableTheme } from "src/utils/theme";
import { useNavigate } from "react-router-dom";
import { useModals } from "@mantine/modals";
import { currencyFormatter } from "src/utils/formatting";

type AccountsMigration = {
  id: string;
  name: string;
  externalId: string;
  totalValue?: number | null;
  isActive?: boolean | null;
  mask?: string | null;
};
type AccountListItemProps = {
  accounts: AccountsMigration[] | undefined;
  account: AccountsMigration;
  index: number;
};

const AccountListItem = ({
  accounts,
  account,
  index,
}: AccountListItemProps) => {
  const theme = useMantineTheme();
  const { isDarkMode } = useContext(AppContext);
  const [isAccountActive, setIsAccountActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { mutate } = useApolloClient();
  const navigate = useNavigate();
  const modals = useModals();

  useEffect(() => {
    setIsAccountActive(!!account.isActive);
  }, [account]);

  const handleUpdateAccountStatus = async (data: {
    accountId: string;
    activate: boolean;
  }) => {
    try {
      setLoading(true);
      let response = await mutate<
        UpdatePlaidAccountMutation,
        UpdatePlaidAccountMutationVariables
      >({
        mutation: UpdatePlaidAccount,
        variables: {
          data,
        },
        fetchPolicy: "no-cache",
      });
      setIsAccountActive(!!response.data?.updatePlaidAccount?.isActive);
      if (response.data?.updatePlaidAccount?.isActive) {
        modals.closeAll();
        navigate(`/plaid-success/${response.data?.updatePlaidAccount?.id}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card
      style={{
        marginTop: -1,
        backgroundColor: toggleTheme.card_bg,
        borderBottomRightRadius:
          index === (accounts && accounts?.length - 1) ? 8 : 0,
        borderBottomLeftRadius:
          index === (accounts && accounts.length - 1) ? 8 : 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        color: toggleTheme.text,
        borderTop: "none",
        cursor: "pointer",
      }}
    >
      <Group position="apart">
        {account.mask && account.totalValue ? (
          <Stack spacing={0} style={{ flex: 1 }}>
            <Group spacing={40} position="apart" align={"center"}>
              <Text weight={500} size={18} color={toggleTheme.primaryText}>
                {"#" + account.mask}
              </Text>
              <Text weight={500} size={14} color={toggleTheme.text}>
                {currencyFormatter.format(account.totalValue)}
              </Text>
            </Group>
            <Text weight={500} size={14} color={toggleTheme.card_disabled_text}>
              {account.name}
            </Text>
          </Stack>
        ) : (
          <Text weight={500} size={16} color={toggleTheme.primaryText}>
            {account.name}
          </Text>
        )}
        <Button
          type="submit"
          loading={loading}
          color={theme.colors.greenMachine[6]}
          disabled={isAccountActive}
          onClick={(e) => {
            e.stopPropagation();
            handleUpdateAccountStatus({
              accountId: account.id,
              activate: !isAccountActive,
            });
          }}
          size="xs"
          style={{
            fontSize: 14,
            lineHeight: "16px",
            border: "none",
            paddingLeft: 0,
            paddingRight: 0,
            padding: 6,
            minWidth: 56.6,
          }}
        >
          {isAccountActive ? "Linked" : loading ? "Linking" : "Link"}
        </Button>
      </Group>
    </Card>
  );
};

export default AccountListItem;
