import {
  Authenticator,
  Button,
  Theme,
  ThemeProvider,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { Center, Loader, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";
import { useContext } from "react";
import { ApolloClientContext } from "src/api/ApolloClientContext";
import { AmplifyUser } from "@aws-amplify/ui";
import "./style.css";
import { EnvConfig, GetEnv } from "src/utils/EnvConfig";

const env = GetEnv() as EnvConfig;
const Login = () => {
  const mantineTheme = useMantineTheme();

  const theme: Theme = {
    name: "Freedom Folios Auth Theme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: mantineTheme.colors.gray[0],
          },
          secondary: {
            value: mantineTheme.white,
          },
        },
        font: {
          interactive: {
            value: mantineTheme.black,
          },
        },
        brand: {
          primary: {
            "10": mantineTheme.colors.greenMachine[6],
            "80": mantineTheme.colors.greenMachine[5],
            "90": mantineTheme.colors.greenMachine[3],
            "100": mantineTheme.colors.greenMachine[6],
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: mantineTheme.colors.greenMachine[6],
              },
            },
            _hover: {
              color: {
                value: mantineTheme.colors.greenMachine[2],
              },
            },
            _active: {
              color: {
                value: mantineTheme.colors.greenMachine[6],
              },
              backgroundColor: {
                value: mantineTheme.colors.gray[0],
              },
              borderColor: {
                value: mantineTheme.colors.greenMachine[6],
              },
            },
          },
        },
      },
    },
  };

  return (
    <Center
      style={{
        height: "100%",
        background: mantineTheme.colors.gray[8],
      }}
    >
      <ThemeProvider theme={theme}>
        <Authenticator
          formFields={formFields}
          components={components}
          loginMechanisms={["email"]}
        >
          {({ signOut, user }) => <Result user={user} />}
        </Authenticator>
      </ThemeProvider>
    </Center>
  );
};
export default Login;

type Props = {
  user: AmplifyUser | undefined;
};
const Result = ({ user }: Props) => {
  const { amplifyUser, setAmplifyUser } = useContext(ApolloClientContext);

  useEffect(() => {
    if (user && !amplifyUser) {
      setAmplifyUser(user);
    }
  }, [user]);

  return <Loader />;
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
    },
  },
  signUp: {
    email: {
      label: "Email:",
      placeholder: "john@doe.com",
      isRequired: true,
    },
    ...(env.env == "prod"
      ? {
          given_name: {
            label: "Firstname:",
            placeholder: "John",
            isRequired: true,
            order: 1,
          },
          family_name: {
            label: "Lastname:",
            placeholder: "Doe",
            isRequired: true,
            order: 2,
          },
        }
      : {
          name: {
            label: "Fullname",
            placeholder: "John Doe",
            isRequired: true,
            order: 1,
          },
        }),
    password: {
      label: "Password:",
      placeholder: "********",
      isRequired: true,
    },
    confirm_password: {
      label: "Confirm Password:",
      placeholder: "********",
      isRequired: true,
    },
  },
  forceNewPassword: {
    ...(env.env == "prod"
      ? {
          given_name: {
            label: "Firstname:",
            placeholder: "John",
            order: 1,
          },
          family_name: {
            label: "Lastname:",
            placeholder: "Doe",
            order: 2,
          },
        }
      : {
          name: {
            label: "Fullname",
            placeholder: "John Doe",
            order: 1,
          },
        }),
    password: {
      placeholder: "Enter your Password:",
    },
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      label: "New Label",
      isRequired: false,
    },
    confirm_password: {
      placeholder: "Enter your Password Please:",
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

const components = {
  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {},
  SetupTOTP: {},
  ConfirmSignIn: {},
  ResetPassword: {},
  ConfirmResetPassword: {},
};
