import { useApolloClient } from "@apollo/client";
import {
  Badge,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Group,
  Indicator,
  Skeleton,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import React, { useContext, useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router";
import { AppContext } from "src/api/AppContext";
import { GetAccountMany } from "src/api/graphql/queries/Account";
import {
  GetAccountManyQuery,
  GetAccountManyQueryVariables,
  GetSubscriberQuery,
} from "src/generated/graphql";
import CustodianReauth from "src/modules/MobileScreens/CustodianReauth";
import { NestedLogin } from "src/modules/MobileScreens/MyAccounts";
import { ArrayElement } from "src/utils/helperTypes";
import { useTogglableTheme } from "src/utils/theme";

type AccountData = ArrayElement<GetAccountManyQuery["accountMany"]>;
type SubscriberData = NonNullable<GetSubscriberQuery["subscriberOne"]>;

export type CustodianAccount = {
  id: string;
  name: string;
  userTag?: string | null;
  plaidInstitutionId: string;
  reAuthRequired: boolean;
};

const SubscriberDashboard = () => {
  const desktopView = useMediaQuery("(min-width: 550px)");
  const { query } = useApolloClient();
  const { currentSubscriber, isDarkMode } = useContext(AppContext);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const modals = useModals();
  const [nestedLoginData, setNestedLoginData] = useState<NestedLogin[]>([]);
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    //load data once subcriber is loaded
    if (currentSubscriber) {
      getAccounts();
    }
  }, [currentSubscriber]);

  const getUniqueCustodians = (custodians: CustodianAccount[]) => {
    const uniqueCustodians = [];
    const custodianIds: string[] = [];
    for (let i = 0; i < custodians.length; i++) {
      if (!custodianIds.includes(custodians[i].id)) {
        custodianIds.push(custodians[i].id);
        uniqueCustodians.push(custodians[i]);
      }
    }
    return uniqueCustodians;
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const showReauthLogin = localStorage.getItem("showReauthLogin");
      if (showReauthLogin === "true") {
        let reauthRequiredAccounts = accounts.filter(
          (account) => account.custodian.reAuthRequired
        );
        let reauthRequiredCustodians = getUniqueCustodians(
          reauthRequiredAccounts.map((account) => account.custodian)
        );
        if (reauthRequiredCustodians && reauthRequiredCustodians.length > 0) {
          modals.openModal({
            styles: {
              title: {
                fontWeight: 500,
                fontSize: 20,
              },
            },
            title: "Re-Authorize",
            size: "min(400px, 100vw)",
            children: <CustodianReauth custodians={reauthRequiredCustodians} />,
            onClose: () => {
              //  modals.closeAll();
            },
          });
        } else {
          localStorage.setItem("showReauthLogin", "false");
        }
      } else {
        localStorage.setItem("showReauthLogin", "false");
      }
    }
  }, [accounts]);

  const getAccounts = async () => {
    setLoading(true);
    const response = await query<
      GetAccountManyQuery,
      GetAccountManyQueryVariables
    >({
      query: GetAccountMany,
      variables: {
        subscriberId: currentSubscriber?.id,
      },
    });
    if (response.data.accountMany.length > 0) {
      setAccounts(response.data.accountMany);
      if (currentSubscriber) {
        parseAccounts(currentSubscriber, response.data.accountMany);
      }
    }
    setLoading(false);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const parseAccounts = (sub: SubscriberData, accs: AccountData[]) => {
    let activeCustodians = sub.custodians.filter(
      (custodian) => custodian.isActive
    );
    let data = activeCustodians.map((login) => {
      let accountData = accs.filter(
        (account) => account.custodian.id === login.id
      );
      return {
        accounts: accountData,
        ...login,
      };
    });
    setNestedLoginData(data);
  };

  return (
    <Group style={{ alignItems: "flex-start" }}>
      <Card
        style={{
          background: toggleTheme.card_bg,
          color: toggleTheme.text,
          borderRadius: 8,
          padding: "8px 16px",
          width: desktopView ? "calc(50% - 16px)" : "100%",
          //minHeight: "30vh",
        }}
      >
        <Group position="apart">
          <Text size={20} weight={500}>{`Accounts`}</Text>
          <Button
            variant="outline"
            onClick={() => navigate("/my-accounts")}
            size="xs"
            style={{
              fontSize: 17,
              lineHeight: "18px",
              color: theme.colors.greenMachine[6],
              border: "none",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            Manage
            <MdChevronRight size={22} style={{ marginLeft: 2, marginTop: 3 }} />
          </Button>
        </Group>
        <Divider />

        {loading ? (
          <Container>
            <Skeleton height={20} width="50%" style={{ marginTop: "8px" }} />
            <Skeleton height={20} width="70%" style={{ marginTop: "8px" }} />
            <Skeleton height={20} width="70%" style={{ marginTop: "8px" }} />
            <Skeleton height={20} width="70%" style={{ marginTop: "8px" }} />
          </Container>
        ) : (
          <>
            {" "}
            {nestedLoginData.length ? (
              nestedLoginData.map((loginData) => {
                return (
                  <Stack
                    style={{
                      padding: "10px 12px",
                      borderRadius: 4,
                      marginTop: 10,
                      marginBottom: 6,
                      backgroundColor: "rgba(255,255,255,0.5)",
                    }}
                    key={loginData?.id}
                    spacing={10}
                  >
                    <Group position="apart">
                      <Text size={18} weight={500}>
                        {loginData.userTag || "Unnamed"} - {loginData.name}
                      </Text>
                      {loginData.reAuthRequired && (
                        <Badge
                          variant="filled"
                          size="sm"
                          color={"red"}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            modals.closeAll();
                            modals.openConfirmModal({
                              title: "Confirmation",
                              children: (
                                <Text size="sm">
                                  {`Please, be careful to select all the accounts you want to use in Freedom Folios during the login step. You will link them one at a time after the auth step. Failure to select a previously linked account during the re-auth step will result in losing real time updates to that account.This strategy has reached 100% position opinion allocation. Would you like to enable it to be discovered by your subscribers?`}
                                </Text>
                              ),
                              labels: { confirm: "Continue", cancel: "" },
                              cancelProps: {
                                display: "none",
                              },
                              confirmProps: {
                                color: theme.colors.greenMachine[7],
                              },
                              onCancel: () => console.log("Cancel"),
                              onConfirm: () => {
                                modals.closeAll();
                                navigate(`/plaid-link/${loginData.id}`);
                              },
                            });
                          }}
                        >
                          Needs Re-Authorization
                        </Badge>
                      )}
                    </Group>
                    <Divider />
                    {loginData.accounts &&
                      loginData.accounts.map((account) => {
                        let sameDay =
                          new Date().getDate() ===
                            new Date(account?.lastSynced).getDate() &&
                          new Date().valueOf() -
                            new Date(account?.lastSynced).valueOf() <
                            1000 * 60 * 60 * 24;
                        return (
                          <Stack
                            key={account.id}
                            onClick={() => {
                              navigate(`/my-accounts/${account.id}`);
                            }}
                            sx={{
                              cursor: "pointer",
                              borderRadius: 4,
                              padding: 10,
                              "&:hover": {
                                backgroundColor: toggleTheme.card_disabled_bg,
                              },
                            }}
                            spacing={0}
                          >
                            <Group position="apart">
                              <Text
                                size={18}
                                weight={500}
                                color={toggleTheme.primaryText}
                              >
                                {`${account.custodian.name} ${
                                  account.mask ? " - #" + account.mask : ""
                                }`}
                              </Text>
                              {(sameDay ||
                                !account.strategy ||
                                (!sameDay && !account.withinThreshold)) && (
                                <Badge
                                  variant="filled"
                                  size="sm"
                                  color={
                                    sameDay
                                      ? "blue"
                                      : !account.strategy
                                      ? "red"
                                      : "orange"
                                  }
                                >
                                  {sameDay
                                    ? "Pending . . ."
                                    : !account.strategy
                                    ? "No Strategy"
                                    : !sameDay
                                    ? "Out-of-Sync"
                                    : ""}
                                </Badge>
                              )}
                            </Group>
                            <Group position="apart">
                              <Text weight={400}>{account.name}</Text>
                              <Text>
                                {formatter.format(account.totalValue || 0)}
                              </Text>
                            </Group>
                          </Stack>
                        );
                      })}
                  </Stack>
                );
              })
            ) : (
              <div style={{ padding: "40px 0px" }}>
                <Center>
                  <Text>{"You do not have any accounts linked yet."}</Text>
                </Center>
                <Group position="center" style={{ marginTop: 16 }}>
                  <Button
                    onClick={() => navigate("/plaid-link")}
                    size="xs"
                    style={{
                      fontSize: 16,
                      lineHeight: "16px",
                      border: "none",
                    }}
                  >
                    {"Link an Account"}
                  </Button>
                </Group>
              </div>
            )}
          </>
        )}
      </Card>
      <Card
        style={{
          background: toggleTheme.card_bg,
          color: toggleTheme.text,
          borderRadius: 8,
          padding: "8px 16px",
          width: desktopView ? "calc(50% - 16px)" : "100%",
        }}
      >
        <Group position="apart">
          <Text size={20} weight={500}>{`Subscription`}</Text>
          <Group position="right">
            {currentSubscriber?.subscription?.isExpired && (
              <Text size={22} weight={700} color="red">{`Expired!`}</Text>
            )}
            <Button
              variant="outline"
              onClick={() => navigate("/subscription")}
              size="xs"
              style={{
                fontSize: 17,
                lineHeight: "18px",
                color: theme.colors.greenMachine[6],
                border: "none",
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              Manage
              <MdChevronRight
                size={22}
                style={{ marginLeft: 2, marginTop: 3 }}
              />
            </Button>
          </Group>
        </Group>
        <Divider />
        {currentSubscriber?.subscription ? (
          <Stack style={{ padding: "10px 12px" }}>
            <Group position="apart">
              <div>
                <Title size={13} weight={500}>
                  {"Renewal Plan"}
                </Title>
                <Text>
                  {currentSubscriber.subscription.trial
                    ? "Free Trial"
                    : currentSubscriber.subscription.renewalFrequency}
                </Text>
              </div>
              <div>
                <Title size={13} weight={500} align="right">
                  {currentSubscriber.subscription.cancelled ||
                  currentSubscriber.subscription.trial
                    ? "Expires On"
                    : "Renewal Date"}
                </Title>
                <Text align="right">
                  {new Date(
                    currentSubscriber.subscription.renewalDate
                  ).toDateString()}
                </Text>
              </div>
            </Group>
            <Group position="apart">
              <div>
                <Title size={13} weight={500}>
                  {"Cost"}
                </Title>
                <Text>
                  {currentSubscriber.subscription.trial
                    ? "N/A"
                    : formatter.format(currentSubscriber.subscription.cost)}
                </Text>
              </div>
              <div style={{ justifyContent: "flex-end" }}>
                <Title size={13} weight={500} align="right">
                  {"Enrollment Date"}
                </Title>
                <Text align="right">
                  {new Date(
                    currentSubscriber.subscription.createdDate
                  ).toDateString()}
                </Text>
              </div>
            </Group>
          </Stack>
        ) : (
          <Text>{`You are currently Unsubscribed!`}</Text>
        )}
      </Card>
    </Group>
  );
};

export default SubscriberDashboard;
