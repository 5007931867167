import { Group, Text, useMantineTheme, Image } from "@mantine/core";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { MdUpload, MdCancel } from "react-icons/md";
import { IoMdImages } from "react-icons/io";
import { useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";

type ImageUploadProps = {
  onReadSuccess: (b64: string) => void;
  initialImage?: string;
  imageAlt?: string;
  targetSize?: number;
  dropZoneProps?: Partial<DropzoneProps>;
};

const ImageUpload = (props: ImageUploadProps) => {
  const theme = useMantineTheme();

  const [imgSrc, setImgSrc] = useState<string | undefined>(props.initialImage);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setImgSrc(props.initialImage);
  }, [props.initialImage]);

  const getBase64 = (
    img: File,
    callback: (b64: string | undefined) => void
  ) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result?.toString()));
    reader.readAsDataURL(img);
  };

  return (
    <Dropzone
      loading={loading}
      style={{ height: props.targetSize }}
      onDrop={(files) => {
        setLoading(true);
        getBase64(files[0], (b64) => {
          if (b64) {
            setImgSrc(b64);
            props.onReadSuccess(b64);
          }
          setLoading(false);
        });
      }}
      onReject={(files) => {
        showNotification({
          id: "upload-image",
          color: "red",
          title: "Failure!",
          message: "An error occured while reading the file...",
          icon: <MdCancel size={16} />,
          autoClose: 2000,
        });
      }}
      maxSize={3 * 1024 ** 2}
      accept={IMAGE_MIME_TYPE}
      {...props.dropZoneProps}
    >
      {imgSrc ? (
        <Image
          style={{ aspectRatio: "1" }}
          src={imgSrc}
          withPlaceholder
          width={props.targetSize ? props.targetSize - 36 : undefined}
          height={props.targetSize ? props.targetSize - 36 : undefined}
          alt={props.imageAlt || "Picture Upload"}
        />
      ) : (
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: 220, pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <MdUpload
              size={50}
              color={
                theme.colors[theme.primaryColor][
                  theme.colorScheme === "dark" ? 4 : 6
                ]
              }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <MdCancel
              size={50}
              color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IoMdImages size={50} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drag image here or click to select file
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              File should not exceed 5mb
            </Text>
          </div>
        </Group>
      )}
    </Dropzone>
  );
};

export default ImageUpload;
