import {
  Button,
  Group,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { MdAdd } from "react-icons/md";
import { FetchParams, KapexTable } from "@kapex/tables";

import {
  GetStrategyPageQuery,
  GetStrategyPageQueryVariables,
  RiskLevel,
  TermDuration,
} from "src/generated/graphql";

import { useModals } from "@mantine/modals";
import { useContext, useEffect } from "react";
import { AppContext } from "src/api/AppContext";
import { useApolloClient } from "@apollo/client";
import { GetStrategyPage } from "src/api/graphql/queries/Strategy";
import { useNavigate } from "react-router-dom";
import CreateStrategyForm from "./components/CreateStrategyForm";
import { useTogglableTheme } from "src/utils/theme";
import { DesignerData } from "../Designer/components/DesignerDetails";

type StrategyData = {
  id: string;
  createdDate: string;
  updatedDate: string;
  seedId: string;
  designerId: string;
  name: string;
  cashMin: number;
  riskLevel: RiskLevel;
  termDuration: TermDuration;
  designer?: DesignerData
};

const Strategy = () => {
  const { query } = useApolloClient();
  const theme = useMantineTheme();
  const { currentDesigner, isDarkMode } = useContext(AppContext);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const modals = useModals();
  const navigate = useNavigate();

  const getStrategies = async (fetchParams: FetchParams) => {
    const response = await query<
      GetStrategyPageQuery,
      GetStrategyPageQueryVariables
    >({
      query: GetStrategyPage,
      variables: {
        pagination: {
          ...fetchParams,
          filters: currentDesigner
            ? [
              ...fetchParams.filters!,
              {
                field: "designerId",
                value: currentDesigner.id,
                type: "text",
              },
            ]
            : fetchParams.filters,
        },
      },
      fetchPolicy: "no-cache",
    });
    if (response.data.strategyPage) {
      return {
        rowCount: response.data.strategyPage.totalRows,
        rowData: response.data.strategyPage.items as StrategyData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  };

  return (
    <div
      style={{
        marginRight: 15,
      }}
    >
      <Group position="apart">
        <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
          {"Strategy"}
        </Text>
        {currentDesigner && (
          <Button
            leftIcon={<MdAdd size={24} />}
            radius={"xl"}
            compact
            style={{ fontSize: 16 }}
            onClick={() => {
              modals.openModal({
                styles: {
                  title: {
                    fontWeight: 500,
                    fontSize: 20,
                  },
                },
                title: "Create Strategy",
                size: "min(400px, 80vw)",
                children: (
                  <CreateStrategyForm
                    onClose={() => {
                      modals.closeAll();
                      window.location.reload();
                    }}
                  />
                ),
              });
            }}
          >
            {`New Strategy`}
          </Button>
        )}
      </Group>
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 40px)) - 48px )"
        }
        style={{
          minHeight: 300,
          marginBottom: 8,
        }}
        type="auto"
        scrollbarSize={9}
        sx={(theme) => ({
          ".mantine-ScrollArea-root": {
            borderRadius: 4,
            paddingRight: 1,
          },
          ".mantine-ScrollArea-viewport": {
            borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
          },
          ".mantine-ScrollArea-scrollbar": {
            background: theme.colors.dark[4],
            ".mantine-ScrollArea-thumb": {
              backgroundColor: theme.colors.greenMachine[6],
            },
          },
        })}
      >
        <KapexTable<StrategyData>
          tableSx={{
            thead: {
              th: {
                ">div": {
                  color: "#fff",
                },
              },
              "::after": {
                left: "-1px",
              },
            },
            tbody: {
              tr: {
                td: {
                  borderColor: toggleTheme.card_disabled_bg,
                  ">div": {
                    color: toggleTheme.text,
                  },
                },
              },
              "tr:hover": {
                backgroundColor: `${toggleTheme.card_disabled_bg} !important`,
              },
            },
            tfoot: {
              td: {
                div: {
                  color: "#fff",
                },
              },
            },
          }}
          headerColor={{ r: 255, g: 255, b: 255 }}
          headerBg={theme.colors.greenMachine[6]}
          borderColor={theme.colors.greenMachine[8]}
          columnConfigs={[
            {
              field: "name",
              name: "Name",
              filter: {
                type: "text",
                field: "name",
              },
              sortable: true,
              cellRenderer: (rowItem) => {
                return (
                  <Text
                    sx={{
                      fontWeight: 500,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate(`/strategy/${rowItem.id}`);
                    }}
                  >
                    {rowItem.name}
                  </Text>
                );
              },
            },
            {
              field: "cashMin",
              name: "Minimum Cash",
              filter: {
                field: "cashMin",
                type: "text",
              },
              sortable: true,
              cellRenderer: (rowItem) => (
                <Text align="right">{`$${rowItem.cashMin?.toFixed(2)}`}</Text>
              ),
            },
            {
              field: "createdDate",
              name: "Created",
              filter: {
                field: "createdDate",
                type: "dateRange",
              },
              sortable: true,
              valueParser: (rowItem) =>
                new Date(rowItem.createdDate).toDateString(),
            },
            {
              field: "name",
              parentRelationField: "designer",
              name: "Designer",
              sortable: false,
              valueParser: (rowItem) => {
                if (rowItem.designer) {
                  return `${rowItem.designer?.user?.firstName} ${rowItem.designer?.user?.lastName}`
                } else {
                  return 'N/A';
                }
              },
            },
          ]}
          dataFetch={getStrategies}
        />
      </ScrollArea.Autosize>
    </div>
  );
};

export default Strategy;
