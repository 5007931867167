import React, { FC, useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useCookies } from "react-cookie";
import { CognitoUser } from "@aws-amplify/auth";
import awsConfig from "./aws-config/aws-config.json";
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  Button,
  useAuthenticator,
  Theme,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import {
  Center,
  MantineProvider,
  MantineThemeOverride,
  useMantineTheme,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";

import { EnvConfig, GetEnv } from "./utils/EnvConfig";
import AppProvider from "./api/AppContext";
import ApolloClientProvider, {
  ApolloClientContext,
} from "./api/ApolloClientContext";
import Router from "./navigation/Router";
import AppShell, { UnsubAppShell } from "./modules/AppShell";
import { Amplify, Auth, Hub } from "aws-amplify";
import { NotificationsProvider } from "@mantine/notifications";
import NewUserRoutes from "./navigation/NewUserRoutes";
import { ffLogoThin } from "./assets";

const env = GetEnv() as EnvConfig;
let awsConfigObj = awsConfig[env.env];
Amplify.configure(awsConfigObj);

const MantineProviders: FC = (
  { children },
  theme: MantineThemeOverride | undefined
) => {
  return (
    <MantineProvider
      theme={{
        ...theme,
        colors: {
          greenMachine: [
            "#EEFCF2",
            "#C4F1D2",
            "#A2E4B6",
            "#85D79E",
            "#6EC989",
            "#5ABB77",
            "#4DAB69",
            "#409B5B",
            "#348C4E",
            "#2A7F43",
          ],
          blueOxford: [
            "#00104F",
            "#000E47",
            "#000C3F",
            "#000B38",
            "#000A32",
            "#00092D",
            "#000926",
            "#000723",
            "#00061F",
            "#00051B",
          ],
          charcoal: [
            "#363636",
            "#303030",
            "#2A2A2A",
            "#262626",
            "#222222",
            "#1E1E1E",
            "#1B1B1B",
            "#181818",
            "#151515",
            "#121212",
          ],
          white: [
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
          ],
        },
        primaryColor: "greenMachine",
        primaryShade: 6,
        black: "#1b1b1b",
      }}
    >
      <NotificationsProvider>
        <ModalsProvider>{children}</ModalsProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
};

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ApolloClientProvider
          uri={env.uri}
          wsUri={env.wsUri}
          authChildren={
            <AppProvider env={env}>
              <MantineProviders>
                <AppShell>
                  <Router />
                </AppShell>
              </MantineProviders>
            </AppProvider>
          }
          noAuthChildren={
            <MantineProviders>
              <UnsubAppShell>
                <NewUserRoutes />
              </UnsubAppShell>
            </MantineProviders>
          }
        />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
