import { useContext, useState } from "react";
import {
  Button,
  Card,
  Center,
  Group,
  Stack,
  useMantineTheme,
  Text,
  ActionIcon,
  Loader,
  Box,
} from "@mantine/core";
import { MdChevronRight } from "react-icons/md";
import { useApolloClient } from "@apollo/client";
import {
  GetCustodianAccounts,
  GetPlaidLinkToken,
  GetPlaidUpdateLinkToken,
} from "src/api/graphql/queries/Account";
import { AppContext } from "src/api/AppContext";
import { useModals } from "@mantine/modals";
import { useTogglableTheme } from "src/utils/theme";
import UpdatePlaidAccounts from "../UpdateAccounts";
import {
  GetCustodianAccountsQuery,
  GetCustodianAccountsQueryVariables,
  GetPlaidLinkTokenQuery,
  GetPlaidLinkTokenQueryVariables,
  GetPlaidUpdateLinkTokenQuery,
  GetPlaidUpdateLinkTokenQueryVariables,
} from "src/generated/graphql";

interface Props {
  closeAllModals: () => void;
  setIsUpdateFlow: (isUpdateFlow: boolean) => void;
  navigateToMyAccounts: () => void;
  setLinkToken: (linkToken: string) => void;
}

type LoadingStates = {
  [key: string]: boolean;
};

export default function AddUpdateAccount(props: Props) {
  const { closeAllModals, setIsUpdateFlow, setLinkToken } = props;
  const theme = useMantineTheme();
  const { currentSubscriber, isDarkMode } =
    useContext(AppContext);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { query } = useApolloClient();
  const modals = useModals();
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

  const getLinkToken = async (key: string) => {
    setLoadingStates({ ...loadingStates, [key]: true });
    try {
      let response = await query<
        GetPlaidLinkTokenQuery,
        GetPlaidLinkTokenQueryVariables
      >({
        query: GetPlaidLinkToken,
        fetchPolicy: "no-cache",
      });

      if (response?.data?.getPlaidLinkToken) {
        setLinkToken(response.data.getPlaidLinkToken);
      }
    } catch (e) {
      console.log("Unable to get plaid link token", e)
    } finally {
      setLoadingStates({ ...loadingStates, [key]: false });
    }
  };


  const getUpdateLinkToken = async (custodianId: string) => {
    setLoadingStates({ ...loadingStates, [custodianId]: true });
    try {
      let response = await query<
        GetPlaidUpdateLinkTokenQuery,
        GetPlaidUpdateLinkTokenQueryVariables
      >({
        query: GetPlaidUpdateLinkToken,
        fetchPolicy: "no-cache",
        variables: {
          custodianId,
        },
      });

      if (response?.data?.getPlaidUpdateLinkToken) {
        setLinkToken(response.data.getPlaidUpdateLinkToken);
      }
    } catch (e) {
      console.log("Error getting update link token", e);
    } finally {
      setLoadingStates({ ...loadingStates, [custodianId]: false });
    }
  };

  const getCustodianAccounts = async (custodianId: string) => {
    setLoadingStates({ ...loadingStates, [custodianId]: true });
    try {
      let response = await query<
        GetCustodianAccountsQuery,
        GetCustodianAccountsQueryVariables
      >({
        query: GetCustodianAccounts,
        fetchPolicy: "no-cache",
        variables: {
          custodianId,
        },
      });
      return response?.data?.getCustodianAccounts;
    } catch (e) {
      console.log("Unable to get custodian accounts", e)
    } finally {
      setLoadingStates({ ...loadingStates, [custodianId]: false });
    }
  };

  return (
    <Center>
      <Stack>
        <Text>
          {
            "Select an existing institution login to link another account from, or add a new institution login."
          }
        </Text>
        <div style={{ padding: 10, }}>
          <Card
            style={{
              padding: "10px 12px",
              marginTop: -1,
              backgroundColor: toggleTheme.card_bg,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              color: toggleTheme.text,
              borderTop: "none",
              cursor: "pointer",
            }}
            key={currentSubscriber?.id + "_new_institution"}
            onClick={() => {
              getLinkToken("new_account");
              setIsUpdateFlow(false);
              closeAllModals();
            }}
          >
            <Group position="apart">
              <Text
                weight={500}
                size={16}
                color={toggleTheme.primaryText}
              >
                {"Add a new Login"}
              </Text>
              {loadingStates['new_account'] ? <Loader size={28} /> :
                <ActionIcon
                  variant="outline"
                  color={toggleTheme.primary}
                  style={{ borderRadius: 20 }}
                >
                  <MdChevronRight color={toggleTheme.primary} size={26} />
                </ActionIcon>}
            </Group>
          </Card>
          {currentSubscriber?.custodians &&
            currentSubscriber.custodians.map((custodian, index) => {
              return (
                <Card
                  style={{
                    padding: "10px 12px",
                    marginTop: -1,
                    backgroundColor: toggleTheme.card_bg,
                    borderBottomRightRadius:
                      index === currentSubscriber.custodians.length - 1
                        ? 8
                        : 0,
                    borderBottomLeftRadius:
                      index === currentSubscriber.custodians.length - 1
                        ? 8
                        : 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    color: toggleTheme.text,
                    borderTop: "none",
                    cursor: "pointer",
                  }}
                  key={custodian.id + "_update_institution"}
                  onClick={async () => {
                    setIsUpdateFlow(true);
                    closeAllModals();
                    const custodianAccounts =
                      await getCustodianAccounts(custodian.id);
                    const haveActiveAccount = custodianAccounts?.find(
                      (_custodian) => _custodian.isActive
                    );
                    if (haveActiveAccount) {
                      modals.openModal({
                        styles: {
                          title: {
                            fontWeight: 500,
                            fontSize: 20,
                          },
                        },
                        title: "Link an account",
                        size: "min(400px, 100vw)",
                        children: (
                          <Center>
                            <Stack>
                              <UpdatePlaidAccounts
                                accounts={custodianAccounts}
                              />
                              <Button
                                variant="outline"
                                onClick={() => {
                                  closeAllModals();
                                  modals.openConfirmModal({
                                    title: "Confirmation",
                                    children: (
                                      <Text size="sm">
                                        {`Please, be careful to select all the accounts you want to use in Freedom Folios during the login step. You will link them one at a time after the auth step. Failure to select a previously linked account during the re-auth step will result in losing real time updates to that account.This strategy has reached 100% position opinion allocation. Would you like to enable it to be discovered by your subscribers?`}
                                      </Text>
                                    ),
                                    labels: {
                                      confirm: "Continue",
                                      cancel: "",
                                    },
                                    cancelProps: {
                                      display: "none",
                                    },
                                    confirmProps: {
                                      color:
                                        theme.colors.greenMachine[7],
                                    },
                                    onCancel: () =>
                                      console.log("Cancel"),
                                    onConfirm: () => {
                                      closeAllModals();
                                      getUpdateLinkToken(custodian.id);
                                      setIsUpdateFlow(true);
                                    },
                                  });
                                }}
                                size="xs"
                                style={{
                                  fontSize: 13,
                                  lineHeight: "16px",
                                  color: theme.colors.greenMachine[7],
                                  border: "none",
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  marginTop: 5,
                                  padding: 6,
                                  width: "100%",
                                }}
                              >
                                Don’t see you account? Try
                                re-authorizing with Plaid{" "}
                                <MdChevronRight size={18} />
                              </Button>
                            </Stack>
                          </Center>
                        ),
                      });
                    } else {
                      getLinkToken(custodian.id);
                      closeAllModals();
                    }
                  }}
                >
                  <Group position="apart">
                    <Text
                      weight={500}
                      size={16}
                      color={toggleTheme.primaryText}
                    >
                      {`${custodian.name}: ${custodian.userTag || "Unnamed"
                        }`}
                    </Text>
                    {loadingStates[custodian.id] ? <Loader size={28}/> : <ActionIcon
                      variant="outline"
                      color={toggleTheme.primary}
                      style={{ borderRadius: 20 }}
                    >
                      <MdChevronRight
                        color={toggleTheme.primary}
                        size={26}
                      />
                    </ActionIcon>}
                  </Group>
                </Card>
              );
            })}
        </div>
      </Stack>
    </Center>
  );
}
