import { gql } from "@apollo/client";

export const CreateSubscription = gql`
  mutation CreateSubscription($data: CreateSubscriptionInput!) {
    createSubscription(data: $data) {
      id
      designerId
      renewalDate
      createdDate
      renewalFrequency
      cost
      cancelled
      trial
      swapRequest {
        id
        designerId
      }
      status
    }
  }
`;

export const BeginFreeTrial = gql`
  mutation BeginFreeTrial($data: BeginFreeTrialInput!) {
    beginFreeTrial(data: $data) {
      id
      trial
      renewalDate
      renewalFrequency
      designer {
        user {
          id
          firstName
          lastName
        }
      }
      cost
    }
  }
`;

export const CancelSubscriptionRenewal = gql`
  mutation CancelSubscriptionRenewal {
    cancelCurrentSubscription
  }
`;
