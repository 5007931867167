import { useApolloClient } from "@apollo/client";
import {
  Center,
  Container,
  useMantineTheme,
  Text,
  Button,
  Stack,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { MdCheckCircle, MdLink } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { GetAccountMany } from "src/api/graphql/queries/Account";
import {
  Account,
  GetAccountManyQuery,
  GetAccountManyQueryResult,
  GetAccountManyQueryVariables,
} from "src/generated/graphql";
import { ArrayElement } from "src/utils/helperTypes";

type AccountData = ArrayElement<GetAccountManyQuery["accountMany"]>;

const PaymentSuccess = () => {
  const { refetchUserData, currentSubscriber } = useContext(AppContext);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const desktopView = useMediaQuery("(min-width: 550px)");
  const { query } = useApolloClient();
  const [accounts, setAccounts] = useState<AccountData[]>([]);

  useEffect(() => {
    refetchUserData();
    getAccounts();
  }, []);

  const getAccounts = async () => {
    let response = await query<
      GetAccountManyQuery,
      GetAccountManyQueryVariables
    >({
      query: GetAccountMany,
      fetchPolicy: "no-cache",
      variables: {
        subscriberId: currentSubscriber?.id,
      },
    });

    if (response?.data?.accountMany) {
      setAccounts(response.data.accountMany || []);
    }
  };

  return (
    <Container>
      <Stack>
        <Center>
          <MdCheckCircle size={80} color={theme.colors.greenMachine[6]} />
        </Center>
        <Text weight={500} size={30} align="center">
          Payment Successful
        </Text>
        <Text weight={500} size={20} align="center">
          Congratulations! Your Subscription is now active.
        </Text>
        {accounts.length > 0 ? (
          <div>
            <Button
              style={{ marginRight: "3%" }}
              radius={"xl"}
              size={"lg"}
              onClick={() => {
                navigate("/dashboard");
              }}
              compact
            >
              {`Back to Dashboard`}
            </Button>
          </div>
        ) : (
          <Stack align={"center"}>
            <Text weight={400} size={16} align="center">
              {`The next step in your Freedom Folios investment journey is linking an external account. Linking an account will let you monitor it's progress and apply investment strategies to help you better allocate your funds.`}
            </Text>
            <Button
              style={{ marginRight: "3%" }}
              radius={"xl"}
              size={"lg"}
              onClick={() => {
                navigate("/plaid-link");
              }}
              compact
              leftIcon={<MdLink />}
            >
              {`Link an Account`}
            </Button>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};
export default PaymentSuccess;
