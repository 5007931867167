import { gql } from "@apollo/client";

export const DesignerNewsletterPage = gql`
  query DesignerNewsletterPage($pagination: PaginationInput!) {
    designerNewsletterPage(pagination: $pagination) {
      items {
        id
        updatedDate
        header
        body
        quarter
        year
        designerId
      }
      totalRows
    }
  }
`;

export const GetDesignerNewsletter = gql`
  query GetDesignerNewsletter {
    designerNewsletter {
      id
      updatedDate
      header
      body
      quarter
      year
      designerId
    }
  }
`;
