import { useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  Center,
  Container,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  MdArrowForward,
  MdAttachMoney,
  MdChevronLeft,
  MdChevronRight,
  MdLock,
} from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { AiOutlineSwap, AiOutlineNumber } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import {
  GenerateTradeToSync,
  GetAccountOne,
} from "src/api/graphql/queries/Account";
import { GetPositions } from "src/api/graphql/queries/Positions";
import {
  GenerateTradeToSyncQuery,
  GenerateTradeToSyncQueryVariables,
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
  GetPositionsQuery,
  GetPositionsQueryVariables,
  MarkAccountSyncedMutation,
  MarkAccountSyncedMutationVariables,
  TradesOperation,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from "src/generated/graphql";
import DownloadCsvButton from "../DownCsvButton";
import { ArrayElement } from "src/utils/helperTypes";
import { AppContext } from "src/api/AppContext";
import { useTogglableTheme } from "src/utils/theme";
import { useModals } from "@mantine/modals";
import AllocateCashForm from "../AllocateCash/AllocateCashForm";
import {
  MarkAccountSynced,
  UpdateAccount,
} from "src/api/graphql/mutations/Accounts";
import { currencyFormatter } from "src/utils/formatting";
import { useMediaQuery } from "@mantine/hooks";

type PositionData = ArrayElement<GetPositionsQuery["positionMany"]>;
type AccountData = NonNullable<GetAccountOneQuery["accountOne"]>;
type TradeSyncData = ArrayElement<
  GenerateTradeToSyncQuery["generateTradesToSync"]
>;
type TradeItems = {
  currentShares: number | null | undefined;
  currentValue: number | undefined;
  futureShares: number | null | undefined;
  futureValue: number | undefined;
  operation: TradesOperation | null | undefined | string;
  stockSymbol: string | undefined;
  stockName: string | undefined;
  selected: boolean | null | undefined;
  actionShares: number | null | undefined;
  pricePerShare: number | undefined;
};
const AccountSync = () => {
  const theme = useMantineTheme();
  const { isDarkMode } = useContext(AppContext);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { id, includeReserveAdjust } = useParams();
  const [positions, setPositions] = useState<PositionData[]>([]);
  const { query, mutate } = useApolloClient();
  const modals = useModals();

  const [account, setAccount] = useState<AccountData>();
  const [tradeItems, setTradeItems] = useState<TradeItems[]>([]);
  const [tradeSync, setTradeSync] = useState<TradeSyncData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showShares, setShowShares] = useState<boolean>(true);
  const [syncingAccount, setSyncingAccount] = useState<boolean>(false);
  const navigate = useNavigate();

  const [allocableFunds, setAllocableFunds] = useState<number>(1);
  const [currentAllocation, setCurrentAllocation] = useState<number>(1);

  const [reserveBefore, setReserveBefore] = useState<number>(0);
  const [reserveAfter, setReserveAfter] = useState<number>(0);

  const desktopView = useMediaQuery("(min-width: 550px)");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const markAccountAsSynced = async () => {
    if (!id) {
      return;
    }
    setSyncingAccount(true);

    let response = await mutate<
      MarkAccountSyncedMutation,
      MarkAccountSyncedMutationVariables
    >({
      mutation: MarkAccountSynced,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    });
    setSyncingAccount(false);
    return response.data?.markedSynced;
  };

  const getTradeToSync = async () => {
    if (id) {
      let response = await query<
        GenerateTradeToSyncQuery,
        GenerateTradeToSyncQueryVariables
      >({
        query: GenerateTradeToSync,
        variables: {
          generateTradesToSyncId: id,
        },
        fetchPolicy: "no-cache",
      });
      if (
        response &&
        response.data !== null &&
        response.data.generateTradesToSync
      ) {
        setTradeSync(response.data.generateTradesToSync);
      }
    }
  };

  const getPositions = async () => {
    if (id) {
      let response = await query<GetPositionsQuery, GetPositionsQueryVariables>(
        {
          query: GetPositions,
          variables: {
            accountId: id,
          },
          fetchPolicy: "no-cache",
        }
      );
      if (response && response.data !== null && response.data.positionMany) {
        setPositions(response.data.positionMany);
      }
    }
  };

  const getAccount = async () => {
    if (id) {
      let response = await query<
        GetAccountOneQuery,
        GetAccountOneQueryVariables
      >({
        query: GetAccountOne,
        variables: {
          accountOneId: id,
        },
        fetchPolicy: "no-cache",
      });

      if (response && response.data !== null && response.data.accountOne) {
        setAccount(response.data.accountOne);
      }
    }
  };

  const updateAccountReserve = async (amount: number) => {
    if (id) {
      let response = await mutate<
        UpdateAccountMutation,
        UpdateAccountMutationVariables
      >({
        mutation: UpdateAccount,
        fetchPolicy: "no-cache",
        variables: {
          updateAccountId: id,
          data: {
            cashReserve: amount,
          },
        },
      });

      if (response.data?.updateAccount) {
        return true;
      }
    }
    return false;
  };

  const mapTradeObjects = async () => {
    const items: TradeItems[] = [];
    let currentPositions = positions
      .filter((pos) => pos.stockData)
      .map((position) => ({
        ...position,
        skip: false,
      }));

    tradeSync?.forEach((trade) => {
      const position = positions.find((pos) => {
        if (pos.stockData) {
          return pos.stockData.id == trade.stockData?.id;
        }
        return false;
      });
      if (position) {
        let futureShares =
          trade.operation === "Buy"
            ? Number(position.shares) + Number(trade.shares)
            : Number(position.shares) - Number(trade.shares);
        let pricePerShare = trade.stockData?.price || 0;
        items.push({
          currentShares: position.shares,
          currentValue: position.shares * pricePerShare,
          futureShares,
          futureValue: futureShares * pricePerShare,
          stockName: trade.stockData?.shortName,
          stockSymbol: trade.stockData?.symbol,
          operation: trade.operation,
          selected: position.selected,
          actionShares: trade.shares,
          pricePerShare,
        });
        currentPositions = currentPositions.filter(
          (pos) => pos.id != position.id
        );
      } else {
        let pricePerShare = trade.stockData?.price || 0;
        items.push({
          stockName: trade.stockData?.shortName,
          stockSymbol: trade.stockData?.symbol,
          currentShares: 0,
          currentValue: 0,
          futureShares: trade.shares,
          futureValue: (trade.shares || 0) * pricePerShare,
          operation: trade.operation,
          selected: true,
          actionShares: trade.shares,
          pricePerShare,
        });
      }
    });

    currentPositions.forEach((position) => {
      items.push({
        stockName: position.stockData?.shortName,
        stockSymbol: position.stockData?.symbol,
        currentShares: position.shares,
        currentValue: position.value || 0,
        futureShares: position.shares,
        futureValue: position.value || 0,
        selected: position.selected,
        operation: "NoAction",
        actionShares: 0,
        pricePerShare: (position.value || 0) / position.shares,
      });
    });

    setTradeItems(items);
    setLoading(false);
  };

  const getDistTotal = (account: AccountData): number => {
    return (
      account.positions
        ?.filter((pos) => pos.selected && !pos.unsupported)
        .map((pos) => pos.value || pos.shares * (pos.stockData?.price || 0))
        .reduce((a, b) => a + b, account?.unallocatedFunds || 0) || 0
    );
  };

  useEffect(() => {
    if (id) {
      getAccount();
      getPositions();
    }
  }, [id]);

  useEffect(() => {
    if (account) {
      let totalDist = getDistTotal(account);
      setCurrentAllocation(totalDist - account.cashReserve);
      setAllocableFunds(totalDist - account.cashReserve);
      setReserveBefore(account.cashReserve);
      setReserveAfter(account.cashReserve);

      if (
        account.strategy &&
        totalDist > (account.strategy.cashMin || 0) &&
        includeReserveAdjust
      ) {
        //ask how much reserve cash and get trades
        modals.openModal({
          styles: {
            title: {
              fontWeight: 500,
              fontSize: 20,
            },
          },
          title: "Adjust Reserved Cash?",
          size: "min(400px, 80vw)",
          children: (
            <AllocateCashForm
              description={`You have ${currencyFormatter.format(
                account.cashReserve
              )} currently reserved. Before generating sync trades, you may raise or lower this value by adjusting the slider or input box below. anywhere from $0.00 to ${currencyFormatter.format(
                Math.floor(totalDist - (account.strategy?.cashMin || 0))
              )}. The reserved cash amount will be set aside and the remaining amount will be used to calculate your trades.`}
              onClose={() => {
                getTradeToSync();
                //setTotalDistAfter(totalDist);
                modals.closeAll();
              }}
              allocableCash={Math.floor(
                Math.max(totalDist - (account.strategy?.cashMin || 0))
              )}
              initial={account.cashReserve}
              onSubmit={(amount) => {
                setAllocableFunds(totalDist - amount);
                setReserveAfter(amount);
                updateAccountReserve(amount).then((resp) => {
                  if (resp) {
                    getTradeToSync();
                  }
                });
                //setTotalDistAfter(totalDist-amount);
                //need to also update the reserve cash value on the account
                modals.closeAll();
              }}
            />
          ),
          onClose: () => {
            getTradeToSync();
          },
        });
      } else {
        getTradeToSync();
      }
    }
  }, [account]);

  useEffect(() => {
    mapTradeObjects();
  }, [tradeSync, positions]);

  if (loading) {
    return <Loader size={20} />;
  }

  const syncingAccountLoader = () => {
    return <Container
      style={{
        height: "100%",
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <Loader size={20} />
      <Text size={18} weight={500} style={{
        lineHeight: "16px",
        color: theme.colors.greenMachine[7],
        marginLeft: 5,
      }}>Syncing Account</Text>
    </Container>
  }
  return (
    <>
      {syncingAccount && syncingAccountLoader()}
      {!loading && tradeItems.length > 0 ? (
        <React.Fragment>
          <Group mb={6} position="apart">
            <Button
              variant="outline"
              onClick={() => navigate(-1)}
              size="xs"
              style={{
                fontSize: 16,
                lineHeight: "16px",
                color: theme.colors.greenMachine[7],
                border: "none",
                paddingLeft: 0,
              }}
            >
              <MdChevronLeft size={22} /> Back
            </Button>
            <DownloadCsvButton account={account}
              positions={positions}
              trades={tradeSync} /**CSV_Trade_sync and moved calculation inside the component */ />
          </Group>
          <Container
            style={{
              maxHeight: "100%",
              maxWidth: isMobile ? 600 : undefined,
              paddingLeft: "3%",
              paddingRight: "3%",
            }}
          >
            <Stack spacing={0}>
              <Text size={18} weight={500}>
                {account?.name}
              </Text>
              <Group spacing={0}>
                <Text
                  style={{ width: "18%" }}
                  size={14}
                  weight={700}
                  align="right"
                >
                  {"Symbol"}
                </Text>
                <Group
                  position="apart"
                  style={{
                    padding: "2px 1% 4px 15%",
                    width: "82%",
                  }}
                >
                  <Text size={14} weight={700}>
                    {`Before`}
                  </Text>
                  <Switch
                    style={{ display: "contents" }}
                    sx={{
                      ".mantine-Switch-body": {
                        marginLeft: -60,
                      },
                    }}
                    checked={showShares}
                    onChange={() => setShowShares(!showShares)}
                    onLabel={
                      <MdAttachMoney size={20} style={{ marginRight: 8 }} />
                    }
                    offLabel={
                      <AiOutlineNumber size={20} style={{ marginLeft: 8 }} />
                    }
                  />
                  <Text size={14} weight={700}>
                    {`After`}
                  </Text>
                </Group>
              </Group>
            </Stack>
            <ScrollArea.Autosize
              maxHeight={
                "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px + 75px + 36px + 30px)) )"
              }
              scrollbarSize={6}
            >
              <Card
                key={account?.id + "_reserve_cash"}
                style={{
                  padding: "5px 5px",
                  marginBottom: 4,
                  background: toggleTheme.card_bg,
                  color: toggleTheme.text,
                  borderRadius: 8,
                }}
              >
                <Group spacing={0}>
                  <div style={{ width: "18%" }}>
                    <Text
                      weight={500}
                      size={desktopView ? 16 : 12}
                      align="left"
                    >
                      {`Reserved Cash`}
                    </Text>
                  </div>

                  <Group position="apart" style={{ width: "82%" }}>
                    <Stack
                      spacing={0}
                      align={"flex-end"}
                      style={{ width: "34%" }}
                    >
                      <Text weight={600} size={16}>
                        {formatter.format(reserveBefore)}
                      </Text>
                      <Text weight={600} size={12}></Text>
                    </Stack>
                    <Stack
                      align="center"
                      spacing={0}
                      style={{
                        color: undefined,
                      }}
                    >
                      <React.Fragment>
                        <HiOutlineArrowNarrowRight size={26} />
                        <Text weight={700} size={12}></Text>
                      </React.Fragment>
                    </Stack>
                    <Stack
                      spacing={0}
                      align={"flex-end"}
                      style={{ width: "32%" }}
                    >
                      <Text weight={600} size={16}>
                        {formatter.format(reserveAfter)}
                      </Text>
                      <Text weight={600} size={12}></Text>
                    </Stack>
                  </Group>
                </Group>
              </Card>
              <Divider style={{ margin: 4 }} />
              <Card
                key={account?.id + "_trade_cash"}
                style={{
                  padding: "5px 5px",
                  marginBottom: 4,
                  background: toggleTheme.card_bg,
                  color: toggleTheme.text,
                  borderRadius: 8,
                }}
              >
                <Group spacing={0}>
                  <div style={{ width: "18%" }}>
                    <Text
                      weight={500}
                      size={desktopView ? 16 : 12}
                      align="left"
                    >
                      {`Unallocated Cash`}
                    </Text>
                  </div>

                  <Group position="apart" style={{ width: "82%" }}>
                    <Stack
                      spacing={0}
                      align={"flex-end"}
                      style={{ width: "34%" }}
                    >
                      <Text weight={600} size={16}>
                        {formatter.format(
                          (account?.unallocatedFunds || 0) - reserveBefore
                        )}
                      </Text>
                      <Text weight={600} size={12}>
                        {`${Math.floor(
                          (((account?.unallocatedFunds || 0) -
                            reserveBefore) /
                            currentAllocation) *
                          1000
                        ) / 10
                          }%`}
                      </Text>
                    </Stack>
                    <Stack
                      align="center"
                      spacing={0}
                      style={{
                        color: undefined,
                      }}
                    >
                      <React.Fragment>
                        <HiOutlineArrowNarrowRight size={26} />
                        <Text weight={700} size={12}></Text>
                      </React.Fragment>
                    </Stack>
                    <Stack
                      spacing={0}
                      align={"flex-end"}
                      style={{ width: "32%" }}
                    >
                      <Text weight={600} size={16}>
                        {formatter.format(
                          Math.max(
                            ((account?.unallocatedFunds || 0) +
                              (tradeSync || [])
                                .map(
                                  (trade) =>
                                    (trade.operation == TradesOperation.Sell
                                      ? 1
                                      : -1) *
                                    (trade.shares || 0) *
                                    (trade.stockData?.price || 0)
                                )
                                .reduce((a, b) => a + b, 0) || 0) -
                            reserveAfter,
                            0
                          )
                        )}
                      </Text>
                      <Text weight={600} size={12}>
                        {`${Math.floor(
                          (Math.max(
                            ((account?.unallocatedFunds || 0) +
                              (tradeSync || [])
                                .map(
                                  (trade) =>
                                    (trade.operation == TradesOperation.Sell
                                      ? 1
                                      : -1) *
                                    (trade.shares || 0) *
                                    (trade.stockData?.price || 0)
                                )
                                .reduce((a, b) => a + b, 0) || 0) -
                            reserveAfter,
                            0
                          ) /
                            allocableFunds) *
                          1000
                        ) / 10
                          }%`}
                      </Text>
                    </Stack>
                  </Group>
                </Group>
              </Card>
              {tradeItems.map((item, index) => {
                return (
                  <Card
                    key={item.stockSymbol || "trade" + index}
                    style={{
                      padding: "5px 5px",
                      marginBottom: 4,
                      background: item.selected
                        ? toggleTheme.card_bg
                        : toggleTheme.card_disabled_bg,
                      color: item.selected
                        ? toggleTheme.text
                        : toggleTheme.card_disabled_text,
                      borderRadius: 8,
                    }}
                  >
                    <Group spacing={0}>
                      <div style={{ width: "18%" }}>
                        <Text weight={500} size={14} align="left">
                          {`${item.stockSymbol}`}
                        </Text>
                      </div>

                      <Group position="apart" style={{ width: "82%" }}>
                        <Stack
                          spacing={0}
                          align={"flex-end"}
                          style={{ width: "34%" }}
                        >
                          <Text weight={600} size={14}>
                            {showShares
                              ? formatter.format(item.currentValue || 0)
                              : item.currentShares}
                          </Text>
                          <Text weight={600} size={12}>
                            {showShares
                              ? `${Math.floor(
                                ((item?.currentValue || 0) /
                                  currentAllocation) *
                                1000
                              ) / 10
                              }%`
                              : `${formatter.format(
                                item.pricePerShare || 0
                              )}/s`}
                          </Text>
                        </Stack>
                        <Stack
                          align="center"
                          spacing={0}
                          style={{
                            color:
                              item.operation === "Buy"
                                ? theme.colors.red[6]
                                : item.operation === "Sell"
                                  ? theme.colors.greenMachine[6]
                                  : undefined,
                          }}
                        >
                          {item.operation == "NoAction" ? (
                            item.selected ? (
                              <Text>{`--`}</Text>
                            ) : (
                              <MdLock />
                            )
                          ) : (
                            <React.Fragment>
                              <HiOutlineArrowNarrowRight size={26} />
                              <Text weight={700} size={12}>
                                {`${item.operation?.toUpperCase()} ${item.actionShares == item.currentShares
                                  ? "ALL"
                                  : item.actionShares
                                  }`}
                              </Text>
                            </React.Fragment>
                          )}
                        </Stack>
                        <Stack
                          spacing={0}
                          align={"flex-end"}
                          style={{ width: "32%", paddingRight: 2 }}
                        >
                          <Text weight={600} size={14}>
                            {showShares
                              ? formatter.format(item.futureValue || 0)
                              : item.futureShares}
                          </Text>
                          <Text weight={600} size={12}>
                            {showShares
                              ? `${Math.floor(
                                ((item?.futureValue || 0) /
                                  allocableFunds) *
                                1000
                              ) / 10
                              }%`
                              : `${formatter.format(
                                item.pricePerShare || 0
                              )}/s`}
                          </Text>
                        </Stack>
                      </Group>
                    </Group>
                  </Card>
                );
              })}
            </ScrollArea.Autosize>
            <Button
              size="lg"
              compact
              style={{ width: "100%", marginTop: 16 }}
              disabled={syncingAccount}
              onClick={() =>
                modals.openModal({
                  styles: {
                    title: {
                      fontWeight: 500,
                      fontSize: 20,
                    },
                  },
                  title: "Confirm Complete Sync",
                  size: "min(400px, 80vw)",
                  children: (
                    <Stack>
                      <Text>
                        {
                          "Freedom Folios does not perform trades on your behalf. Trades must be performed via your account's associated brokerage dashboard. Position values may be inaccurate while external data is being processed. Press continue to dismiss the current out-of-sync status on your account."
                        }
                      </Text>
                      <Button
                        compact
                        size="lg"
                        onClick={() => {
                          modals.closeAll();
                          markAccountAsSynced().then(() =>
                            navigate(`/my-accounts/${id}`)
                          );
                        }}
                      >
                        {"Continue"}
                      </Button>
                    </Stack>
                  ),
                  onClose: () => {
                    //navigate(-1);
                  },
                })
              }
            >
              {"Continue"}
            </Button>
          </Container>
        </React.Fragment>
      ) : (
        <Text>No Trade Sync Found For This Account</Text>
      )}
    </>
  );
};

export default AccountSync;
