import { useApolloClient } from "@apollo/client";
import { CognitoUser } from "@aws-amplify/auth";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import {
  GetDesignerQuery,
  GetDesignerQueryVariables,
  GetSubscriberQuery,
  GetSubscriberQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UserRole,
} from "src/generated/graphql";
import { EnvConfig } from "src/utils/EnvConfig";
import { GetDesigner } from "./graphql/queries/Designer";
import { GetSubscriber } from "./graphql/queries/Subscriber";
import { GetUser } from "./graphql/queries/User";
import awsConfig from "../aws-config/aws-config.json";
import { ApolloClientContext } from "./ApolloClientContext";
import { useCookies } from "react-cookie";

type CurrentUser = GetUserQuery["userOne"];
type SubscriberData = NonNullable<GetSubscriberQuery["subscriberOne"]>;
type DesignerData = NonNullable<GetDesignerQuery["designerOne"]>;

export type AppContextData = {
  env: EnvConfig | undefined;
  currentUser: CurrentUser;
  activeTabKey: string;
  currentSubscriber: SubscriberData | undefined;
  currentDesigner: DesignerData | undefined;
  currentPageTitle: string | undefined;
  isDarkMode: boolean;
  changeTab: (key: string, id?: string) => void;
  refetchUserData: () => void;
  setCurrentUser: Dispatch<SetStateAction<AppContextData["currentUser"]>>;
  setCurrentPageTitle: Dispatch<
    SetStateAction<AppContextData["currentPageTitle"]>
  >;
  setIsDarkMode: Dispatch<SetStateAction<AppContextData["isDarkMode"]>>;
};
export const AppContext = createContext<AppContextData>({} as AppContextData);

interface Props {
  env: EnvConfig | undefined;
}

const AppProvider: FC<Props> = ({ children, env }) => {
  const navigate = useNavigate();
  const { query } = useApolloClient();
  const { logout } = useContext(ApolloClientContext);

  const [isDarkMode, setIsDarkMode] =
    useState<AppContextData["isDarkMode"]>(false);
  const [currentUser, setCurrentUser] =
    useState<AppContextData["currentUser"]>(undefined);
  const [currentSubscriber, setCurrentSubscriber] =
    useState<AppContextData["currentSubscriber"]>(undefined);
  const [currentDesigner, setCurrentDesigner] =
    useState<AppContextData["currentDesigner"]>(undefined);
  const [activeTabKey, setActiveTabKey] =
    useState<AppContextData["activeTabKey"]>("Organization");
  const [currentPageTitle, setCurrentPageTitle] =
    useState<AppContextData["currentPageTitle"]>(undefined);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      let response = await query<GetUserQuery, GetUserQueryVariables>({
        query: GetUser,
        fetchPolicy: "no-cache",
      });
      if (response.data.userOne) {
        setCurrentUser(response.data.userOne);
        if (response.data.userOne.role === UserRole.Subscriber) {
          getSubscriber();
        }
        if (response.data.userOne.role === UserRole.Designer) {
          getDesigner();
        }
      }
    } catch (e) {
      // if we catch here then we cant find a user with the current token so invalidate token and logout
      logout();
    }
  };

  const getSubscriber = async () => {
    let response = await query<GetSubscriberQuery, GetSubscriberQueryVariables>(
      {
        query: GetSubscriber,
        fetchPolicy: "no-cache",
      }
    );
    if (response.data.subscriberOne) {
      setCurrentSubscriber(response.data.subscriberOne);
    }
  };

  const getDesigner = async () => {
    let response = await query<GetDesignerQuery, GetDesignerQueryVariables>({
      query: GetDesigner,
      fetchPolicy: "no-cache",
    });
    if (response.data.designerOne) {
      setCurrentDesigner(response.data.designerOne);
    }
  };

  const refetchUserData = () => {
    getUser();
  };

  const changeTab = (key: string, id?: string) => {
    setActiveTabKey(key);
    switch (key) {
      case "Home":
        navigate("/home");
        break;
      case "Designer":
        navigate("/designer");
        break;
      case "MyProfile":
        navigate("/profile");
        break;
      case "PlatformAdmin":
        navigate("/platformAdmin");
        break;
      case "User":
        navigate("/user");
        break;
      case "Subscriber":
        navigate("/subscriber");
        break;
      case "Strategy":
        navigate("/strategy");
        break;
      case "MySubscription":
        navigate("/subscription");
        break;
      case "Account":
        navigate("/account");
        break;
      case "MyAccounts":
        navigate("/my-accounts");
        break;
      case "HelpAndSupport":
        navigate("/help-and-support");
        break;
      case "NewsletterAdmin":
        navigate("/newsletter-admin");
        break;
      case "NewsletterDesigner":
        navigate("/newsletter-designer");
        break;
      case "ReportsAdmin":
        navigate("/admin-reports");
        break;
      case "ReportsSubscriber":
        navigate("/subscriber-reports");
        break;
      case "DesignerDashboard":
        navigate("/dashboard-designer");
        break;
      case "Dashboard":
        navigate("/dashboard");
        break;
      case "DesignerReport":
        navigate("/designer-reports");
        break;
      default:
        console.log("Changed to unhandled tab");
        currentSubscriber ? navigate("/dashboard") : navigate("/");
        break;
    }
  };

  return (
    <AppContext.Provider
      value={{
        env,
        activeTabKey,
        currentDesigner,
        currentPageTitle,
        currentSubscriber,
        currentUser,
        isDarkMode,
        changeTab,
        refetchUserData,
        setCurrentPageTitle,
        setCurrentUser,
        setIsDarkMode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
