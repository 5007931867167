import React, { useContext } from "react";
import {
  Header as MantineHeader,
  MediaQuery,
  Burger,
  Avatar,
  Menu,
  Text,
  Group,
  useMantineTheme,
  ActionIcon,
} from "@mantine/core";
import { FaBell } from "react-icons/fa";
import {
  ffIcon,
  ffLogo,
  ffLogoAlt,
  ffLogoExtraThin,
  ffLogoThin,
} from "src/assets";
import { BsSunFill, BsMoonStarsFill } from "react-icons/bs";
import { useStyles } from "./styles";
import { Auth } from "aws-amplify";
import { AppContext } from "src/api/AppContext";

import { UserRole } from "src/generated/graphql";
import { ApolloClientContext } from "src/api/ApolloClientContext";
interface HeaderProps {
  setNavbarOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isNavbarOpen: boolean;
  showUserMenu?: boolean;
  showBellIcon?: boolean;
  showThemeIcon?: boolean;
  title?: string;
}

const MobileHeader = ({
  setNavbarOpened,
  isNavbarOpen,
  showBellIcon,
  showUserMenu,
  showThemeIcon,
  title,
}: HeaderProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { isDarkMode, setIsDarkMode, currentSubscriber, currentUser } =
    useContext(AppContext);
  const { logout } = useContext(ApolloClientContext);

  return (
    <MantineHeader
      style={{
        backgroundColor: theme.colors.greenMachine[6],
        borderBottom: "none",
      }}
      height={75}
      p="sm"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          flexDirection: "row",
          justifyContent: title ? "" : "space-between",
        }}
      >
        {(currentUser?.role !== UserRole.Subscriber ||
          (currentSubscriber?.subscription &&
            (currentSubscriber?.subscription?.status === "active" ||
              currentSubscriber?.subscription?.status === "trialing"))) && (
          <MediaQuery largerThan="md" styles={{ display: "none" }}>
            <Burger
              color="white"
              mr="xl"
              onClick={() => setNavbarOpened((isOpen) => !isOpen)}
              opened={isNavbarOpen}
              size="sm"
            />
          </MediaQuery>
        )}

        <MediaQuery smallerThan="md" styles={{ display: "none" }}>
          <div style={{ height: "100%", overflow: "hidden", borderRadius: 5 }}>
            <img src={ffIcon} alt="Logo" style={{ height: "100%" }} />
          </div>
        </MediaQuery>

        {title ? (
          <Group
            style={{ width: "min(80%, 100% - 134px)", overflow: "hidden" }}
            position="left"
          >
            <Text
              color="white"
              weight={500}
              size="xl"
              style={{
                marginLeft: 16,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {title}
            </Text>
          </Group>
        ) : (
          <div style={{ height: "100%" }}>
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <img src={ffLogoThin} alt="Logo" style={{ height: "100%" }} />
            </MediaQuery>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <img
                src={ffLogoExtraThin}
                alt="Logo"
                style={{ height: "100%" }}
              />
            </MediaQuery>
          </div>
        )}
        <Group style={{ width: "max(20%, 82px)" }} position="right">
          <div
            style={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "end",
            }}
          >
            {showBellIcon && (
              <FaBell color="white" size={20} className={classes.headerItem} />
            )}
            {showThemeIcon && (
              <ActionIcon
                onClick={() => setIsDarkMode(!isDarkMode)}
                style={{ marginRight: 16 }}
              >
                {isDarkMode ? (
                  <BsSunFill
                    color="white"
                    size={24}
                    //className={classes.headerItem}
                  />
                ) : (
                  <BsMoonStarsFill
                    color="white"
                    size={24}
                    //className={classes.headerItem}
                  />
                )}
              </ActionIcon>
            )}
            {showUserMenu && (
              <Menu>
                <Menu.Target>
                  <Avatar radius="xl" />
                </Menu.Target>
                <Menu.Dropdown>
                  {currentUser?.role == UserRole.Subscriber && (
                    <Menu.Label>{"Account"}</Menu.Label>
                  )}
                  <Menu.Item color="red" onClick={() => logout()}>
                    {"Sign out"}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </div>
        </Group>
      </div>
    </MantineHeader>
  );
};

export default MobileHeader;
