import {
  ActionIcon,
  Button,
  Card,
  Divider,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext, useState } from "react";
import {
  MdCancel,
  MdCheck,
  MdChevronRight,
  MdLock,
  MdLockOpen,
} from "react-icons/md";
import { AppContext } from "src/api/AppContext";
import { currencyFormatter } from "src/utils/formatting";
import { useTogglableTheme } from "src/utils/theme";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  UpdatePositionMutation,
  UpdatePositionMutationVariables,
} from "src/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { UpdatePosition } from "src/api/graphql/mutations/Position";
import { AccountData } from "..";

type Props = {
  account: AccountData;
  setAccount: React.Dispatch<React.SetStateAction<AccountData | undefined>>;
};

const PositionSection = ({ account, setAccount }: Props) => {
  const { mutate } = useApolloClient();
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);

  const [editPositions, setEditPositions] = useState<boolean>(false);

  const editCurrentPositions = () => {
    setEditPositions(!editPositions);
  };

  const updatePosition = async (
    positionId: string,
    selected: boolean,
    successCB: (selected: boolean) => void
  ) => {
    try {
      showNotification({
        id: "update-position",
        loading: true,
        title: "Loading",
        message: "Updating Position...",
        autoClose: false,
        disallowClose: true,
      });

      const response = await mutate<
        UpdatePositionMutation,
        UpdatePositionMutationVariables
      >({
        mutation: UpdatePosition,
        variables: {
          updatePositionId: positionId,
          data: {
            selected,
          },
        },
      });

      if (response.data?.updatePosition) {
        successCB(selected);
        updateNotification({
          id: "update-position",
          color: "green",
          title: "Success!",
          message: `Position ${selected ? "Unlocked" : "Locked"}!`,
          icon: <MdCheck size={16} />,
          autoClose: 1000,
        });
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      updateNotification({
        id: "update-position",
        color: "red",
        title: "Failure!",
        message: message || "Unable to update the position!",
        icon: <MdCancel size={16} />,
        autoClose: 1000,
      });
    }
  };

  return (
    <div>
      <Group position="apart">
        <Text weight={500} size={18}>
          {"Current Positions"}
        </Text>
        {editPositions ? (
          <Button
            style={{
              marginTop: 2,
              border: "none",
              padding: 0,
              marginBottom: -8,
            }}
            rightIcon={<MdChevronRight size={20} style={{ marginLeft: -10 }} />}
            variant="outline"
            compact
            radius="xl"
            size="sm"
            onClick={editCurrentPositions}
          >
            {`Condensed View`}
          </Button>
        ) : (
          <Button
            style={{
              marginTop: 2,
              border: "none",
              padding: 0,
              marginBottom: -8,
            }}
            rightIcon={<MdChevronRight size={20} style={{ marginLeft: -10 }} />}
            variant="outline"
            compact
            radius="xl"
            size="sm"
            onClick={editCurrentPositions}
          >
            {`Change Selection`}
          </Button>
        )}
      </Group>
      <Divider style={{ marginBottom: 5, marginTop: 2 }} />
      {editPositions && (
        <Text
          size={12}
          weight={500}
          style={{ padding: 4 }}
          color={"dimmed"}
          align="center"
        >
          {
            "If a position is locked. It's allocated funds will not be considered when syncing to the applied strategy."
          }
        </Text>
      )}
      {account?.positions && account?.positions?.length > 0 ? (
        <React.Fragment>
          {account?.positions
            .sort((a, b) => {
              if (!a.stockData) {
                return 1;
              }
              if (!b.stockData) {
                return -1;
              }
              return a.stockData.symbol.localeCompare(b.stockData.symbol);
            })
            .map((position, index) => {
              return (
                <Card
                  key={account.id + index}
                  style={{
                    padding: "5px 10px",
                    marginBottom: "-1px",
                    backgroundColor: position.stockData
                      ? position.selected
                        ? toggleTheme.card_bg
                        : toggleTheme.card_disabled_bg
                      : //   `rgba(0,0,0,${
                        //   position.selected ? 0.03 : 0.16
                        // })`
                        "rgba(180,0,0,0.44)",

                    borderBottomRightRadius:
                      index < (account?.positions?.length || 0) - 1 ? 0 : 8,
                    borderBottomLeftRadius:
                      index < (account?.positions?.length || 0) - 1 ? 0 : 8,
                    borderTopRightRadius: index == 0 ? 8 : 0,
                    borderTopLeftRadius: index == 0 ? 8 : 0,
                    color: position.stockData
                      ? position.selected
                        ? toggleTheme.text
                        : toggleTheme.card_disabled_text
                      : theme.white,
                  }}
                  radius="md"
                >
                  <Group spacing={0} position="apart">
                    <Stack
                      spacing={5}
                      style={{
                        width:
                          editPositions && !position.unsupported
                            ? "70%"
                            : "100%",
                      }}
                    >
                      <Group position="apart" spacing={"xs"}>
                        <Group
                          position="left"
                          spacing={"xs"}
                          style={{ width: "50%" }}
                        >
                          <Text weight={700} style={{ width: "40%" }}>
                            {position.stockData
                              ? position.stockData.symbol
                              : "Unsupported"}
                          </Text>
                          {position.stockData && (
                            <Text weight={500} size={13}>
                              {`x ${position.shares}`}
                            </Text>
                          )}
                        </Group>
                        <Text size="md" weight={600}>
                          {position.stockData
                            ? currencyFormatter.format(position.value || 0)
                            : "N/A"}
                        </Text>
                      </Group>
                      <Group position="apart" spacing={"xs"}>
                        <Text
                          size="xs"
                          style={{
                            textOverflow: "ellipsis",
                            maxWidth: "75%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {position.stockData
                            ? position.stockData.longName
                            : position.description}
                        </Text>
                        <Text size="xs">
                          {currencyFormatter.format(
                            position.stockData
                              ? position.stockData.price
                              : position.institutionValue || 0
                          )}
                        </Text>
                      </Group>
                    </Stack>
                    {editPositions && !position.unsupported && (
                      <ActionIcon
                        onClick={() => {
                          updatePosition(
                            position.id,
                            !position.selected,
                            (selected) => {
                              position.selected = selected;
                              if (account.positions) {
                                account.positions[index] = position;
                                setAccount({ ...account });
                              }
                            }
                          );
                        }}
                        size="lg"
                      >
                        {position.selected ? (
                          <MdLockOpen
                            size={24}
                            color={theme.colors.greenMachine[6]}
                          />
                        ) : (
                          <MdLock
                            size={24}
                            color={theme.colors.greenMachine[6]}
                          />
                        )}
                      </ActionIcon>
                    )}
                  </Group>
                </Card>
              );
            })}
          <Divider variant="dashed" style={{ margin: 4 }} />
          <Card
            style={{
              padding: "5px 10px",
              marginBottom: -1,
              backgroundColor: toggleTheme.card_bg,
              borderRadius: 8,
              color: toggleTheme.text,
              borderTop: "none",
            }}
            key={account.id + "_cash_total"}
          >
            <Group position="apart" spacing={"xs"}>
              <Text weight={600} style={{ width: "40%" }}>
                {"Total"}
              </Text>
              <Text size="md" weight={600}>
                {currencyFormatter.format(
                  account.positions
                    .map((pos) =>
                      pos.stockData
                        ? pos.stockData.price * pos.shares
                        : pos.institutionValue || 0
                    )
                    .reduce((a, b) => a + b, 0)
                )}
              </Text>
            </Group>
          </Card>
        </React.Fragment>
      ) : (
        <Text style={{ marginLeft: "8px" }}>{`No position(s) found`}</Text>
      )}
    </div>
  );
};

export default PositionSection;
