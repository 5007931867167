import { RichTextEditor } from "@mantine/rte";

type Props = {
  value: string | undefined;
  setValue: (value: string | undefined) => void;
};
const TextEditor = ({ value, setValue }: Props) => {
  return (
    <RichTextEditor
      style={{ minHeight: "300px" }}
      onChange={setValue}
      value={value}
      id="rte"
    />
  );
};

export default TextEditor;
