import { gql } from "@apollo/client";

export const GetDesigners = gql`
  query GetDesigners {
    designerMany {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
        createdDate
      }
    }
  }
`;

export const GetDesigner = gql`
  query GetDesigner($id: String) {
    designerOne(id: $id) {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
        createdDate
      }
      profilePicURL
      bio
      enabled
      subscribers {
        id
        user {
          id
          firstName
          lastName
        }
      }
      revenueShare {
        id
        designerFee
        serviceFee
        designerFeeAnnual
        serviceFeeAnnual
      }
      paymentInfo {
        id
      }
      paymentInfo {
        id
        stripeAccountId
        stripeOnboardingComplete
      }
      strategies {
        id
        createdDate
        name
        description
        enabled
        riskLevel
        termDuration
        cashMin
      }
      supportPhone
      supportEmail
      preferredSupportContact
    }
  }
`;

export const GetDesignerShort = gql`
  query DesignerShort($designerShortId: String) {
    designerShort(id: $designerShortId) {
      firstName
      lastName
      profilePicURL
      bio
    }
  }
`;

export const GetDesignerPage = gql`
  query GetDesignerPage($pagination: PaginationInput!) {
    designerPage(pagination: $pagination) {
      items {
        id
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          createdDate
        }
        subscribers {
          id
        }
        enabled
      }
      totalRows
    }
  }
`;

export const GetStripeDashboardLink = gql`
  query GetStripeDashboardLink {
    getStripeDashboardLink
  }
`;

export const GetStripeAccount = gql`
  query GetStripeAccount {
    getStripeAccount {
      id
      objectType
      transferTotal
      last4
      status
      bankName
      routingNumber
      brand
      expMonth
      expYear
    }
  }
`;

export const GetStripeOnboardingLink = gql`
  query GetStripeAccountLink {
    getAccountOnboardingLink
  }
`;
