import { Button, Card, Group, Text, useMantineTheme } from "@mantine/core";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { useTogglableTheme } from "src/utils/theme";

export default function TrialSuccess() {
  const { isDarkMode } = useContext(AppContext);
  const navigate = useNavigate();

  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);

  return (
    <>
      <Card style={{
        borderRadius: 15, padding: "8px 16px", background: toggleTheme.card_bg,
        color: toggleTheme.text,
      }}>
        <Text
          align="center"
          weight={500}
          size={20}
          style={{ color: theme.colors.greenMachine[6] }}
        >
          Trial Successfully Started!
        </Text>
        <Group position="center" style={{ marginTop: 16 }}>
          <Text style={{ marginTop: "4%" }}>
            The next step is to link an account from your external investment
            service
          </Text>
        </Group>
        <Group position="center" style={{ marginTop: 16 }}>
          <Button
            onClick={() => navigate("/plaid-link")}
            size="xs"
            style={{
              fontSize: 16,
              lineHeight: "16px",
              border: "none",
              marginBottom: "5%",
            }}
          >
            {"Link an Account"}
          </Button>
        </Group>
      </Card>
    </>
  );
}