import { useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  Center,
  Container,
  Divider,
  Group,
  Input,
  ScrollArea,
  Stack,
  Text,
  ActionIcon,
  useMantineTheme,
  Checkbox,
} from "@mantine/core";
import InputMask from "react-input-mask";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  MdArrowRight,
  MdChevronRight,
  MdEdit,
  MdEmail,
  MdPerson,
  MdPhone,
  MdSave,
  MdUndo,
} from "react-icons/md";
import { AppContext } from "src/api/AppContext";
import { UpdateUser } from "src/api/graphql/mutations/User";
import { GetStripePaymentMethodLink } from "src/api/graphql/queries/Subscription";
import {
  GetStripePaymentMethodLinkQuery,
  GetStripePaymentMethodLinkQueryVariables,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "src/generated/graphql";
import { formatPhoneNumber } from "src/utils/formatting";
import { useTogglableTheme } from "src/utils/theme";

const SubscriberProfile = () => {
  const theme = useMantineTheme();
  const {
    currentUser,
    currentSubscriber,
    setCurrentPageTitle,
    refetchUserData,
    isDarkMode,
  } = useContext(AppContext);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { query, mutate } = useApolloClient();
  const [editing, setEditing] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      phoneNumber: currentSubscriber?.user?.phoneNumber,
      smsConsent: currentSubscriber?.user?.smsConsent,
    },

    validate: {
      phoneNumber: (value) =>
        value == undefined
          ? null
          : ("" + value).replace(/\D/g, "").length === 11
          ? null
          : "Phone number is not valid!",
    },
  });

  const getStripePaymentMethodLink = async () => {
    const response = await query<
      GetStripePaymentMethodLinkQuery,
      GetStripePaymentMethodLinkQueryVariables
    >({
      query: GetStripePaymentMethodLink,
      variables: {
        successSubRoute: window.location.pathname.split("/")[1],
        cancelSubRoute: window.location.pathname.split("/")[1],
      },
    });
    if (response.data.getStripePaymentMethodLink) {
      window.location.href = response.data.getStripePaymentMethodLink;
    }
  };

  const updatePhone = async (values: UpdateUserInput) => {
    if (currentSubscriber?.user?.id) {
      let response = await mutate<
        UpdateUserMutation,
        UpdateUserMutationVariables
      >({
        mutation: UpdateUser,
        variables: {
          id: currentSubscriber.user.id,
          data: values,
        },
      });

      if (response.data) {
        refetchUserData();
      }
    }
  };

  useEffect(() => {
    setCurrentPageTitle("Profile");
    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [setCurrentPageTitle]);
  return (
    <Container
      style={{ maxHeight: "100%", maxWidth: isMobile ? 600 : undefined }}
    >
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )"
        }
        scrollbarSize={6}
      >
        <Text
          style={{ fontFamily: "Inter-SemiBold", marginBottom: 15 }}
          size={22}
        >
          Information
        </Text>
        <Stack
          spacing={2}
          align="left"
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Group align="center" position="left">
            <MdPerson size={15} />
            <Text
              size={15}
              style={{ fontFamily: "Inter-Medium" }}
            >{`${currentUser?.firstName} ${currentUser?.lastName}`}</Text>
          </Group>
          <Divider my="sm" variant="dotted" />
          <Group align="center" position="left">
            <MdEmail size={18} />
            <Text
              size={15}
              style={{ fontFamily: "Inter-Medium" }}
            >{`${currentUser?.email}`}</Text>
          </Group>
          <Divider my="sm" variant="dotted" />
          {editing ? (
            <form
              onSubmit={form.onSubmit((values) => {
                setEditing(false);
                updatePhone({
                  phoneNumber:
                    "+" + ("" + values.phoneNumber).replace(/\D/g, ""),
                  smsConsent: values.smsConsent,
                });
              })}
            >
              <Group position="apart">
                <Group align="center" position="left">
                  <MdPhone size={18} />
                  <Input
                    component={InputMask}
                    mask="+1 (999) 999-9999"
                    placeholder="Your phone"
                    {...form.getInputProps("phoneNumber")}
                  />
                </Group>
                <Group align="center" position="right">
                  <ActionIcon onClick={() => setEditing(false)}>
                    <MdUndo size={24} />
                  </ActionIcon>
                  <ActionIcon type="submit">
                    <MdSave size={24} />
                  </ActionIcon>
                </Group>
              </Group>
              <Text
                size={12}
                weight={500}
                color="red"
                style={{ marginLeft: 36 }}
              >
                {form.errors.phone}
              </Text>
              <Checkbox
                checked={form.values.smsConsent}
                style={{ marginTop: 4, marginLeft: 34 }}
                label="I would like to recieve updates from my designer via text"
                {...form.getInputProps("smsConsent")}
              />
            </form>
          ) : (
            <Group position="apart">
              <Group align="center" position="left">
                <MdPhone size={18} />
                <Text size={15} style={{ fontFamily: "Inter-Medium" }}>{`${
                  currentUser?.phoneNumber
                    ? formatPhoneNumber(currentUser?.phoneNumber)
                    : "None Provided"
                }`}</Text>
              </Group>
              <ActionIcon
                onClick={() => {
                  setEditing(true);
                  form.setValues({
                    phoneNumber: currentUser?.phoneNumber || undefined,
                    smsConsent: currentUser?.smsConsent,
                  });
                }}
              >
                <MdEdit size={19} />
              </ActionIcon>
            </Group>
          )}
          <Divider my="sm" variant="dotted" />
        </Stack>
        <Text
          style={{ fontFamily: "Inter-SemiBold", marginBottom: 15 }}
          size={22}
        >
          Payment Method
        </Text>
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          {currentSubscriber?.customerPaymentMethods ? (
            <Stack>
              <Card
                style={{
                  width: "320px",
                  marginTop: "5px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                  padding: "15px",
                  background: theme.fn.linearGradient(
                    -120,
                    "#CC0000",
                    "#FF9900",
                    "#000066"
                  ),
                  color: "white",
                }}
              >
                {currentSubscriber?.customerPaymentMethods?.card ? (
                  <Stack spacing={10}>
                    <Group spacing={0} position="right" align="center">
                      <Text
                        size={15}
                        style={{ fontFamily: "Inter-SemiBold" }}
                        align="right"
                      >
                        {currentSubscriber?.customerPaymentMethods?.card.brand?.toUpperCase()}{" "}
                      </Text>
                      <MdArrowRight size={20} />
                    </Group>
                    <Text size={20} style={{ fontFamily: "Inter-Medium" }}>
                      {currentUser?.firstName} {currentUser?.lastName}
                    </Text>
                    <Group spacing={4}>
                      <Text size={25} style={{ fontFamily: "Inter-Medium" }}>
                        **** **** ****
                      </Text>
                      <Text size={25} style={{ fontFamily: "Inter-Medium" }}>
                        {currentSubscriber?.customerPaymentMethods?.card.last4}
                      </Text>
                    </Group>
                    <Text size={10} style={{ fontFamily: "Inter-Medium" }}>
                      Expires on
                      {` ${currentSubscriber?.customerPaymentMethods?.card.exp_month}/${currentSubscriber?.customerPaymentMethods?.card.exp_year}`}
                    </Text>
                  </Stack>
                ) : (
                  <Stack>
                    <Text>No Payment Method Found</Text>
                    <Button
                      onClick={getStripePaymentMethodLink}
                      style={{
                        marginTop: "16px",
                        background: "transparent",
                        textDecoration: "underline",
                        color: "black",
                        padding: 0,
                      }}
                      compact={isMobile ? true : false}
                      size="sm"
                    >
                      Add Payment Method <MdArrowRight />
                    </Button>
                  </Stack>
                )}
              </Card>
              <Button
                onClick={getStripePaymentMethodLink}
                style={{
                  marginTop: "16px",
                  background: "transparent",
                  color: theme.colors.greenMachine[6],
                  padding: 0,
                  fontSize: 18,
                }}
                compact={isMobile ? true : false}
                size="sm"
              >
                Change Payment Method <MdChevronRight size={22} />
              </Button>
            </Stack>
          ) : (
            <Stack>
              <Text>Payment Method Not Found</Text>
              <Button
                onClick={getStripePaymentMethodLink}
                style={{
                  background: "transparent",
                  textDecoration: "underline",
                  color: "black",
                  padding: 0,
                }}
                compact={isMobile ? true : false}
                size="sm"
              >
                Add Payment Method <MdArrowRight />
              </Button>
            </Stack>
          )}
        </div>
      </ScrollArea.Autosize>
    </Container>
  );
};

export default SubscriberProfile;
