import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Text, useMantineTheme } from "@mantine/core";
import { MdDownload } from "react-icons/md";
import { AccountData } from "../AccountDetails";
import "./style.css";
import { ArrayElement } from "src/utils/helperTypes";
import { GetPositionsQuery } from "src/generated/graphql";

const headers = [
  { label: "Symbol", key: "symbol" },
  { label: "Shares", key: "shares" },
  { label: "Operation", key: "operation" },
  { label: "After Trade", key: "afterTrades" },

];
type PositionData = ArrayElement<GetPositionsQuery["positionMany"]>;

type DownloadCsvButtonProps = {
  account: AccountData | undefined;
  trades: any;
  suffix?: string;
  positions: PositionData[];
};

const DownloadCsvButton = (props: DownloadCsvButtonProps) => {
  const theme = useMantineTheme();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.trades) {

      let data = props.trades.map((trade: any) => {
        //START: moved futureShares calculation inside the component
        let futureShares = 0;
        const position = props.positions.find((pos) => {
          if (pos.stockData) {
            return pos.stockData.id === trade.stockData?.id;
          }
          return false;
        });
        let pricePerShare = trade.stockData?.price || 0;
        if (position) {
          futureShares =
            trade.operation === "Buy"
              ? Number(position.shares) + Number(trade.shares)
              : Number(position.shares) - Number(trade.shares);
        }
        //END: moved futureShares calculation inside the component

        let dataObj: any = {
          symbol: trade.stockData?.symbol || trade?.stockSymbol,
          shares: trade.shares || trade.currentShares,
          operation: trade.operation,
          afterTrades: futureShares * pricePerShare,
        };
        return dataObj;
      });
      setData(data);
    }
  }, [props.trades, props.positions]);

  const getConvertedDate = (date: string) => {
    const d = Date.parse(date);
    const date_obj = new Date(d);
    return `${date_obj.toLocaleString("default", {
      day: "2-digit",
    })}_${date_obj.toLocaleString("default", {
      month: "2-digit",
    })}_${date_obj.getFullYear()}`;
  };

  return (
    <CSVLink
      data={data}
      headers={headers}
      style={{
        background: "none",
        padding: "none",
        textDecoration: "none",
      }}
      filename={`${props.account?.name}_${getConvertedDate(
        new Date().toString()
      )}${props.suffix ? `_${props.suffix}` : ""}`}
    >
      <Text
        color={theme.colors.greenMachine[7]}
        weight={600}
        style={{ display: "flex", alignItems: "center" }}
      >
        {"Export as CSV"}
        <MdDownload size={22} style={{ marginLeft: 4 }} />
      </Text>
    </CSVLink>
  );
};
export default DownloadCsvButton;