import { gql } from "@apollo/client";

export const UpdatePosition = gql`
  mutation UpdatePosition(
    $updatePositionId: String!
    $data: UpdatePositionInput!
  ) {
    updatePosition(id: $updatePositionId, data: $data) {
      id
      accountId
      stockData {
        id
        symbol
        longName
        price
      }
      shares
    }
  }
`;
