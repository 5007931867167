import { useApolloClient } from "@apollo/client";
import {
  Card,
  Divider,
  Group,
  ScrollArea,
  Title,
  Text,
  Select,
  useMantineTheme,
  Stack,
  SegmentedControl,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AppContext } from "src/api/AppContext";
import { GetDesignerReport } from "src/api/graphql/queries/DesignerReport";
import {
  GetDesignerReportQuery,
  GetDesignerReportQueryVariables,
} from "src/generated/graphql";
import { useTogglableTheme } from "src/utils/theme";

export type ReportData = NonNullable<
  GetDesignerReportQuery["getDesignerReport"]
>;

const DesignerReport = () => {
  const { query } = useApolloClient();
  const theme = useMantineTheme();
  const { currentDesigner, isDarkMode } = useContext(AppContext);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const [reportData, setReportData] = useState<ReportData>();
  const [selectedStrategyId, setSelectedStrategyId] = useState<string | null>(
    null
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState<string | null>(
    null
  );
  const desktopView = useMediaQuery("(min-width: 550px)");
  const sideBar = useMediaQuery("(min-width: 990px)");

  const getDesignerReport = async (
    designerId: string,
    strategyId: string,
    timeRange: string
  ) => {
    let response = await query<
      GetDesignerReportQuery,
      GetDesignerReportQueryVariables
    >({
      query: GetDesignerReport,
      variables: {
        designerId,
        strategyId,
        timeRange,
      },
      fetchPolicy: "no-cache",
    });
    if (response && response.data !== null && response.data.getDesignerReport) {
      setReportData(response.data.getDesignerReport);
    }
  };

  useEffect(() => {
    if (currentDesigner && currentDesigner.id) {
      getDesignerReport(
        currentDesigner.id,
        selectedStrategyId
          ? selectedStrategyId
          : currentDesigner.strategies[0].id,
        selectedTimeRange ? selectedTimeRange : "CurrentQuarter"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDesigner, selectedStrategyId, selectedTimeRange]);
  return (
    <ScrollArea.Autosize
      maxHeight={
        "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px)) - 40px)"
      }
      style={{
        minHeight: 300,
      }}
      type="auto"
      scrollbarSize={9}
      sx={(theme) => ({
        ".mantine-ScrollArea-root": {
          borderRadius: 4,
          paddingRight: 1,
        },
        ".mantine-ScrollArea-viewport": {
          borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
        },
        ".mantine-ScrollArea-scrollbar": {
          background: theme.colors.dark[4],
          ".mantine-ScrollArea-thumb": {
            backgroundColor: theme.colors.greenMachine[6],
          },
        },
      })}
    >
      <div className="section2">
        <Group position="apart">
          <Text weight={500} size={18} style={{ marginBottom: -3 }}>
            {"Overview"}
          </Text>
        </Group>

        <Divider style={{ marginBottom: 5, marginTop: 2 }} />

        <React.Fragment>
          <Card
            style={{
              margin: 4,
              backgroundColor: toggleTheme.card_bg,
              color: toggleTheme.text,
              borderRadius: 8,
            }}
          >
            <Group position="apart">
              <div>
                <Title size={13} weight={500}>
                  {"Number Of Accounts"}
                </Title>
                <Text>{reportData?.totalAccounts}</Text>
              </div>
            </Group>

            <Group position="apart" style={{ marginTop: 10 }}>
              <div>
                <Title size={13} weight={500}>
                  {"Total Subscribers"}
                </Title>
                <Text>{`${reportData?.totalSubscribers}`}</Text>
              </div>
            </Group>
          </Card>
        </React.Fragment>
      </div>
      <div style={{ padding: "1.5% 3%" }}>
        <Group position="apart">
          <Text weight={500} size={18} style={{ maxWidth: "50%" }}>
            {"Strategy Analysis"}
          </Text>
          <Select
            style={{ maxWidth: "50%" }}
            sx={{
              ".mantine-Input-input": {
                fontWeight: 500,
                fontSize: 16,
                padding: 4,
                minHeight: 0,
                height: 24,
                color: theme.colors.greenMachine[6],
                background: "none",
                border: "1px solid",
              },
              ".mantine-Input-rightSection": {
                color: theme.colors.greenMachine[6],
              },
            }}
            variant="filled"
            placeholder="Select Strategy"
            onChange={(strategyId) => {
              setSelectedStrategyId(strategyId);
            }}
            value={selectedStrategyId}
            data={
              currentDesigner?.strategies
                ? currentDesigner.strategies.map((strategy) => ({
                    value: strategy.id,
                    label: strategy.name,
                  }))
                : []
            }
          />
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />
        <SegmentedControl
          style={{
            marginLeft: desktopView ? 63 : 0,
            background: "none",
            color: toggleTheme.text,
          }}
          sx={{
            ".mantine-SegmentedControl-active": {
              background: toggleTheme.card_disabled_bg,
            },
            ".mantine-SegmentedControl-label": {
              color: toggleTheme.text,
            },
          }}
          onChange={setSelectedTimeRange}
          value={selectedTimeRange || undefined}
          fullWidth
          data={timeRanges}
        />
        <Card
          style={{
            margin: 4,
            backgroundColor: toggleTheme.card_bg,
            color: toggleTheme.text,
            borderRadius: 8,
          }}
        >
          <Stack spacing={desktopView ? 24 : 8}>
            <div
              style={{
                position: "relative",
                paddingBottom: "350px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 32,
                }}
              >
                <ResponsiveContainer height="100%">
                  <BarChart
                    height={500}
                    data={
                      reportData?.strategyData || [
                        { date: "12/12/2022", performance: 100 },
                        { date: "12/12/2023", performance: -200 },
                      ]
                    }
                    margin={{ top: 5, right: 0, left: 5, bottom: 5 }}
                  >
                    <XAxis
                      height={60}
                      textAnchor="middle"
                      label="Date"
                      dataKey={"date"}
                    ></XAxis>
                    <YAxis
                      dataKey="performance"
                      width={desktopView ? undefined : 0}
                      domain={["dataMin - 100", "dataMax + 100"]}
                    />
                    <Tooltip
                      cursor={{ fill: "transparent" }}
                      contentStyle={{
                        borderRadius: 4,
                        background: "rgba(255,255,255,0.7)",
                        fontWeight: 500,
                        border: "none",
                        padding: "4px 10px",
                      }}
                      labelFormatter={(label, payload) => {
                        return (
                          <Stack spacing={0} style={{ marginBottom: -8 }}>
                            <Text>{payload[0]?.payload.performance}</Text>
                            <Text>{payload[0]?.payload.date}</Text>
                          </Stack>
                        );
                      }}
                      formatter={(value, name, item, index, payload) => {
                        return [];
                      }}
                    />
                    <ReferenceLine y={0} stroke="#000" />

                    <Bar
                      type="monotone"
                      dataKey="performance"
                      stroke="#82ca9d"
                      fillOpacity={0.4}
                      fill="#82ca9d"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                paddingBottom: "350px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 32,
                }}
              >
                <ResponsiveContainer height="100%">
                  <LineChart
                    height={500}
                    data={
                      reportData?.strategyData || [
                        { date: "12/12/2022", totalAccounts: 100 },
                        { date: "12/12/2023", totalAccounts: 200 },
                        { date: "12/12/2024", totalAccounts: 50 },
                      ]
                    }
                    margin={{ top: 5, right: 0, left: 5, bottom: 5 }}
                  >
                    <XAxis
                      height={60}
                      textAnchor="middle"
                      label="Date"
                      dataKey={"date"}
                    ></XAxis>
                    <YAxis />
                    <Tooltip
                      cursor={{ fill: "transparent" }}
                      contentStyle={{
                        borderRadius: 4,
                        background: "rgba(255,255,255,0.7)",
                        fontWeight: 500,
                        border: "none",
                        padding: "4px 10px",
                      }}
                      labelFormatter={(label, payload) => {
                        return (
                          <Stack spacing={0} style={{ marginBottom: -8 }}>
                            <Text>{payload[0]?.payload.totalAccounts}</Text>
                            <Text>{payload[0]?.payload.date}</Text>
                          </Stack>
                        );
                      }}
                      formatter={(value, name, item, index, payload) => {
                        return [];
                      }}
                    />
                    <ReferenceLine y={0} stroke="#000" />

                    <Line
                      type="monotone"
                      dataKey="totalAccounts"
                      stroke="#82ca9d"
                      dot={{ r: 4 }}
                      activeDot={{ r: 4 }}
                      fill="#82ca9d"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Stack>
        </Card>
      </div>
    </ScrollArea.Autosize>
  );
};
export default DesignerReport;
const timeRanges = [
  { value: "CurrentQuarter", label: "Quarter" },
  { value: "YTD", label: "YTD" },
  { value: "OneYear", label: "1Y" },
  { value: "TwoYear", label: "2Y" },
  { value: "FiveYear", label: "5Y" },
  { value: "AllTime", label: "Max" },
];
