import { useApolloClient } from "@apollo/client";
import { TextInput, Button, Group, Box } from "@mantine/core";
import { MdCheck, MdCancel } from "react-icons/md";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { CreateDesigner } from "src/api/graphql/mutations/Designer";
import {
  CreateDesignerInput,
  CreateDesignerMutation,
  CreateDesignerMutationVariables,
} from "src/generated/graphql";

type CreateDesignerFormProps = {
  onSubmit: () => void;
};

const CreateDesignerForm = (props: CreateDesignerFormProps) => {
  const { mutate } = useApolloClient();

  const form = useForm({
    initialValues: {
      email: "",
      //phoneNumber: "",
      firstName: "",
      lastName: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      // phoneNumber: (value) =>
      //   /^\+[1-9]{1}[0-9]{3,14}$/.test(value)
      //     ? null
      //     : "Invalid phone number, make sure you include country code! e.g. +1",
      firstName: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a first name!",
      lastName: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a last name!",
    },
  });

  const createDesigner = async (values: CreateDesignerInput) => {
    showNotification({
      id: "create-designer",
      loading: true,
      title: "Loading",
      message: "Attempting to create a new designer...",
      autoClose: false,
      disallowClose: true,
    });

    try {
      let response = await mutate<
        CreateDesignerMutation,
        CreateDesignerMutationVariables
      >({
        mutation: CreateDesigner,
        variables: {
          data: values,
        },
        fetchPolicy: "no-cache",
      });

      if (response) {
        updateNotification({
          id: "create-designer",
          color: response.data?.createDesigner ? "green" : "red",
          title: response.data?.createDesigner ? "Success!" : "Failure!",
          message: response.data?.createDesigner
            ? "A confirmation email has been sent to the designer."
            : "An error occured during designer creation...",
          icon: response.data?.createDesigner ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
        if (response.data?.createDesigner) {
          props.onSubmit();
        } else {
          console.log(response.errors);
        }
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);

      if (message.includes("UsernameExistsException")) {
        updateNotification({
          id: "create-designer",
          color: "red",
          title: "Failure!",
          message: "A user with this email already exists!",
          icon: <MdCancel size={16} />,
          autoClose: 2000,
        });
      } else {
        console.log(error);
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 300 }} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          createDesigner(values);
        })}
      >
        <TextInput
          withAsterisk
          label="Email"
          placeholder="designer@email.com"
          {...form.getInputProps("email")}
        />
        {/* <TextInput
          withAsterisk
          label="Phone"
          placeholder="+11112223333"
          {...form.getInputProps("phoneNumber")}
        /> */}
        <TextInput
          withAsterisk
          label="First Name"
          placeholder="John"
          {...form.getInputProps("firstName")}
        />
        <TextInput
          withAsterisk
          label="Last Name"
          placeholder="Doe"
          {...form.getInputProps("lastName")}
        />
        <Group position="right" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
};

export default CreateDesignerForm;
