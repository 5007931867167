import { useContext, useEffect, useState } from "react";
import {
  Group,
  Button,
  Divider,
  Card,
  Center,
  Spoiler,
  useMantineTheme,
} from "@mantine/core";
import { Stack, Title, Text } from "@mantine/core";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import {
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
} from "src/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { GetAccountOne } from "src/api/graphql/queries/Account";
import { useParams } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useTogglableTheme } from "src/utils/theme";

export type AccountData = NonNullable<GetAccountOneQuery["accountOne"]>;

const DesignerAccountDetails = () => {
  const { setCurrentPageTitle, isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { id } = useParams();
  const { query } = useApolloClient();
  const [account, setAccount] = useState<AccountData>();
  const navigate = useNavigate();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getAccount = async (accountOneId: string) => {
    let response = await query<GetAccountOneQuery, GetAccountOneQueryVariables>(
      {
        query: GetAccountOne,
        variables: {
          accountOneId,
        },
        fetchPolicy: "no-cache",
      }
    );

    if (response && response.data !== null && response.data.accountOne) {
      setAccount(response.data.accountOne);
    }
  };

  useEffect(() => {
    if (id) {
      getAccount(id);
    }
  }, [id]);

  useEffect(() => {
    setCurrentPageTitle("Account Details");

    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [setCurrentPageTitle]);

  return (
    <div>
      <Button
        variant="outline"
        onClick={() => navigate(-1)}
        size="xs"
        style={{
          fontSize: 16,
          lineHeight: "16px",
          color: theme.colors.greenMachine[7],
          border: "none",
          paddingLeft: 0,
        }}
      >
        <MdChevronLeft size={22} /> Back
      </Button>
      <Group
        position="apart"
        style={{
          marginLeft: "3%",
          marginRight: "3%",
          marginTop: 20,
          marginBottom: 8,
        }}
      >
        <Group position="left" spacing={4}>
          <Title size={24} weight={500}>
            {account?.name}
          </Title>
        </Group>
        <Text weight={500} size={20}>
          {formatter.format(account?.totalValue || 0)}
        </Text>
      </Group>
      <Group
        position="apart"
        style={{ marginLeft: "3%", marginRight: "3%", marginBottom: 10 }}
      >
        <Text size={14} weight={500}>{`Unallocated Cash:`}</Text>
        <Text weight={500} size={14}>
          {formatter.format(account?.unallocatedFunds || 0)}
        </Text>
      </Group>

      <div style={{ padding: "1.5% 3%" }}>
        <Group position="apart">
          <Text weight={500} size={18} style={{ marginBottom: -3 }}>
            {"Applied Strategy"}
          </Text>
          {account?.strategy && (
            <Button
              style={{
                marginTop: 2,
                border: "none",
                padding: 0,
                marginBottom: -8,
              }}
              rightIcon={
                <MdChevronRight size={20} style={{ marginLeft: -10 }} />
              }
              variant="outline"
              compact
              radius="xl"
              size="sm"
              onClick={() => navigate(`/strategy/${account.strategy?.id}`)}
            >
              {`View Strategy`}
            </Button>
          )}
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />

        {account?.strategy == null || undefined ? (
          <Center style={{ marginTop: "20px" }}>
            <Text color={"dimmed"}>{`No strategy currently applied`}</Text>
          </Center>
        ) : (
          <React.Fragment>
            <Card
              style={{
                margin: 4,
                backgroundColor: toggleTheme.card_bg,
                color: toggleTheme.text,
                borderRadius: 8,
                marginBottom: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              radius="lg"
            >
              <Group position="apart">
                <div>
                  <Title size={13} weight={500}>
                    {"Name"}
                  </Title>
                  <Text>{account.strategy.name}</Text>
                </div>
                <div>
                  <Title size={13} weight={500}>
                    {"Min. Investment"}
                  </Title>
                  <Text align="right">{`$${account.strategy.cashMin}`}</Text>
                </div>
              </Group>
              <Group
                position="apart"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <div>
                  <Title size={13} weight={500}>
                    {"Risk Level"}
                  </Title>
                  <Text>{account.strategy?.riskLevel}</Text>
                </div>

                <div>
                  <Title size={13} weight={500}>
                    {"Duration"}
                  </Title>
                  <Text>{account.strategy.termDuration}</Text>
                </div>
              </Group>
              <div>
                <Title size={13} weight={500}>
                  {"Description"}
                </Title>
                <Text>{account.strategy.description}</Text>
              </div>
            </Card>
          </React.Fragment>
        )}
      </div>
      <div style={{ padding: "1.5% 3%" }}>
        <Group position="apart">
          <Text weight={500} size={18}>
            {"Current Positions"}
          </Text>
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />
        {account?.positions && account?.positions?.length > 0 ? (
          account?.positions
            .sort((a, b) => {
              if (!a.stockData) {
                return -1;
              }
              if (!b.stockData) {
                return -1;
              }
              return a.stockData.symbol.localeCompare(b.stockData.symbol);
            })
            .map((position, index) => {
              return (
                <Card
                  key={account.id + index}
                  style={{
                    padding: "5px 10px",
                    marginBottom: "-1px",
                    backgroundColor: position.stockData
                      ? position.selected
                        ? toggleTheme.card_bg
                        : toggleTheme.card_disabled_bg
                      : "rgba(180,0,0,0.44)",

                    borderBottomRightRadius:
                      index < (account?.positions?.length || 0) - 1 ? 0 : 8,
                    borderBottomLeftRadius:
                      index < (account?.positions?.length || 0) - 1 ? 0 : 8,
                    borderTopRightRadius: index > 0 ? 0 : 8,
                    borderTopLeftRadius: index > 0 ? 0 : 8,
                    color: position.stockData
                      ? position.selected
                        ? toggleTheme.text
                        : toggleTheme.card_disabled_text
                      : theme.white,
                  }}
                  radius="md"
                >
                  <Group spacing={0} position="apart">
                    <Stack spacing={5} style={{ width: "100%" }}>
                      <Group position="apart" spacing={"xs"}>
                        <Group
                          position="left"
                          spacing={"xs"}
                          style={{ width: "50%" }}
                        >
                          <Text weight={700} style={{ width: "40%" }}>
                            {position.stockData
                              ? position.stockData.symbol
                              : "Unsupported Position"}
                          </Text>
                          {position.stockData && (
                            <Text weight={500} size={13}>
                              {`x ${position.shares}`}
                            </Text>
                          )}
                        </Group>
                        <Text size="md" weight={600}>
                          {position.stockData
                            ? formatter.format(position.value || 0)
                            : "N/A"}
                        </Text>
                      </Group>
                      <Group position="apart" spacing={"xs"}>
                        <Text
                          size="xs"
                          style={{
                            textOverflow: "ellipsis",
                            maxWidth: "75%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {position.stockData
                            ? position.stockData.longName
                            : position.description}
                        </Text>
                        <Text size="xs">
                          {formatter.format(
                            position.stockData
                              ? position.stockData.price
                              : position.institutionValue || 0
                          )}
                        </Text>
                      </Group>
                    </Stack>
                  </Group>
                </Card>
              );
            })
        ) : (
          <Text style={{ marginLeft: "8px" }}>{`No position(s) found`}</Text>
        )}
      </div>
    </div>
  );
};
export default DesignerAccountDetails;
