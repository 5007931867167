import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import DesignerLinkPrompt from "src/modules/MobileScreens/DesignerLinkPrompt";

const UnSubscribedRoutes: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<DesignerLinkPrompt />} />
      {/* A route will be added here to verify the designer link, which would be provided at the designer website. */}
    </Routes>
  );
};

export default UnSubscribedRoutes;
