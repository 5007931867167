import { useApolloClient } from "@apollo/client";
import {
  Badge,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { useContext, useEffect, useState } from "react";
import { MdChevronRight, MdLink } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { UpdateLoginTag } from "src/api/graphql/mutations/Accounts";
import { GetAccountMany } from "src/api/graphql/queries/Account";
import {
  GetAccountManyQuery,
  GetAccountManyQueryVariables,
  GetSubscriberQuery,
  UpdateLoginTagMutation,
  UpdateLoginTagMutationVariables,
} from "src/generated/graphql";
import { currencyFormatter } from "src/utils/formatting";
import { ArrayElement } from "src/utils/helperTypes";
import { useTogglableTheme } from "src/utils/theme";
import LoginForm from "./LoginForm";

type AccountData = NonNullable<GetAccountManyQuery["accountMany"]>;
type SubscriberData = NonNullable<GetSubscriberQuery["subscriberOne"]>;

export type NestedLogin = ArrayElement<SubscriberData["custodians"]> & {
  accounts: (ArrayElement<AccountData> & { sameDay?: boolean })[];
};

const MyAccounts = () => {
  const {
    setCurrentPageTitle,
    currentSubscriber,
    isDarkMode,
    refetchUserData,
  } = useContext(AppContext);
  const [accounts, setAccounts] = useState<AccountData>([]);
  const [loading, setLoading] = useState(true);
  const [nestedLoginData, setNestedLoginData] = useState<NestedLogin[]>([]);

  const { query, mutate } = useApolloClient();
  const modals = useModals();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const desktopView = useMediaQuery("(min-width: 550px)");

  const toggleTheme = useTogglableTheme(isDarkMode, theme);

  const handleAccountClick = (id: string) => {
    navigate(`${id}`);
  };
  const getAccounts = async () => {
    const response = await query<
      GetAccountManyQuery,
      GetAccountManyQueryVariables
    >({
      query: GetAccountMany,
      variables: {
        subscriberId: currentSubscriber?.id,
      },
    });
    if (response.data.accountMany.length > 0) {
      setAccounts(response.data.accountMany);
    }
    setLoading(false);
  };

  const parseAccounts = (sub: SubscriberData, accs: AccountData) => {
    let activeCustodians = sub.custodians.filter(
      (custodian) => custodian.isActive
    );
    let data = activeCustodians.map((login) => {
      let accountData = accs
        .filter((account) => account.custodian.id === login.id)
        .map((account) => {
          let sameDay =
            new Date().getDate() === new Date(account?.lastSynced).getDate() &&
            new Date().valueOf() - new Date(account?.lastSynced).valueOf() <
            1000 * 60 * 60 * 24;
          return {
            ...account,
            sameDay,
          };
        });
      return {
        accounts: accountData,
        ...login,
      };
    });
    setNestedLoginData(data);
  };

  const updateLoginTag = async (id: string, userTag: string) => {
    let response = await mutate<
      UpdateLoginTagMutation,
      UpdateLoginTagMutationVariables
    >({
      mutation: UpdateLoginTag,
      variables: {
        id,
        userTag,
      },
      fetchPolicy: "no-cache",
    });

    if (response) {
      refetchUserData();
    }
  };

  useEffect(() => {
    setCurrentPageTitle("My Accounts");
    if (currentSubscriber?.id) {
      getAccounts();
    }
    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [currentSubscriber?.id, setCurrentPageTitle]);

  useEffect(() => {
    if (currentSubscriber && accounts) {
      parseAccounts(currentSubscriber, accounts);
    }
  }, [accounts, currentSubscriber]);

  if (loading) {
    return (
      <Container
        style={{
          height:
            "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px + 46px)) )",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Loader />
      </Container>
    );
  }
  return (
    <Container>
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px + 46px)) )"
        }
        scrollbarSize={6}
      >
        <Group position="right">
          <Button
            radius={"xl"}
            size={desktopView ? "lg" : undefined}
            onClick={() => {
              navigate("/plaid-link");
            }}
            compact
            leftIcon={<MdLink />}
          >
            Link an Account
          </Button>
        </Group>
        <Stack spacing={15} style={{ marginTop: "20px" }}>
          {nestedLoginData.length > 0 ? (
            nestedLoginData.map((login) => (
              <Stack spacing={8} key={login.id}>
                <Group position="apart">
                  <Group>
                    <Text size={20} weight={500}>{`${login.name}: ${login.userTag || "Unnamed"
                      }`}</Text>
                    {login.reAuthRequired && (
                      <Badge
                        variant="filled"
                        size="sm"
                        color={"red"}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          modals.closeAll();
                          modals.openConfirmModal({
                            title: "Confirmation",
                            children: (
                              <Text size="sm">
                                {`Please, be careful to select all the accounts you want to use in Freedom Folios during the login step. You will link them one at a time after the auth step. Failure to select a previously linked account during the re-auth step will result in losing real time updates to that account.This strategy has reached 100% position opinion allocation. Would you like to enable it to be discovered by your subscribers?`}
                              </Text>
                            ),
                            labels: { confirm: "Continue", cancel: "" },
                            cancelProps: {
                              display: "none",
                            },
                            confirmProps: {
                              color: theme.colors.greenMachine[7],
                            },
                            onCancel: () => console.log("Cancel"),
                            onConfirm: () => {
                              modals.closeAll();
                              navigate(`/plaid-link/${login.id}`);
                            },
                          });
                        }}
                      >
                        Needs Re-Authorization
                      </Badge>
                    )}
                  </Group>
                  <Button
                    style={{
                      marginTop: 0,
                      border: "none",
                      padding: 0,
                      marginBottom: -8,
                    }}
                    rightIcon={
                      <MdChevronRight
                        size={16}
                        style={{ marginLeft: -10, marginBottom: -2 }}
                      />
                    }
                    variant="outline"
                    compact
                    radius="xl"
                    size="sm"
                    onClick={() =>
                      modals.openModal({
                        styles: {
                          title: {
                            fontWeight: 500,
                            fontSize: 20,
                          },
                        },
                        title: "Institution Login Rename",
                        size: "min(400px, 80vw)",
                        children: (
                          <Center>
                            <LoginForm
                              header={`Please enter a name to help keep track of which login this account is associated.`}
                              onSubmit={async (name) => {
                                modals.closeAll();
                                updateLoginTag(login.id, name);
                              }}
                              onCancel={() => modals.closeAll()}
                              initialName={login.userTag || undefined}
                              institutionName={login.name}
                            />
                          </Center>
                        ),
                        closeOnClickOutside: false,
                        closeOnEscape: false,
                        withCloseButton: false,
                      })
                    }
                  >
                    {"Rename"}
                  </Button>
                </Group>
                <Divider style={{ marginTop: -4 }} />
                <Stack spacing={0} style={{ paddingLeft: 0 }}>
                  {login.accounts.map((account, index) => (
                    <Card
                      key={account.id}
                      style={{
                        cursor: "pointer",
                        background: toggleTheme.card_bg,
                        padding: "5px 10px",
                        paddingRight: 4,
                        marginTop: index > 0 ? -1 : 0,
                        borderTop:
                          index > 0
                            ? `1px solid ${toggleTheme.card_disabled_bg}`
                            : undefined,
                        color: toggleTheme.text,
                        borderBottomRightRadius:
                          index < accounts.length - 1 ? 0 : 8,
                        borderBottomLeftRadius:
                          index < accounts.length - 1 ? 0 : 8,
                        borderTopRightRadius: index > 0 ? 0 : 8,
                        borderTopLeftRadius: index > 0 ? 0 : 8,
                      }}
                      onClick={() => {
                        handleAccountClick(account.id);
                      }}
                    >
                      <Group spacing={0} position="apart">
                        <div style={{ width: "calc(100% - 25px)" }}>
                          <Group
                            position="apart"
                            style={{ alignItems: "baseline" }}
                          >
                            <Group spacing={6} align="center">
                              <Text
                                size={20}
                                weight={500}
                                color={toggleTheme.primaryText}
                              >
                                {login.name}
                                {account.mask ? " - #" + account.mask : ""}
                              </Text>
                              {(account.sameDay ||
                                !account.strategy ||
                                (!account.sameDay &&
                                  !account.withinThreshold)) && (
                                  <Badge
                                    variant="filled"
                                    size="sm"
                                    color={
                                      account.sameDay
                                        ? "blue"
                                        : !account.strategy
                                          ? "red"
                                          : "orange"
                                    }
                                  >
                                    {account.sameDay
                                      ? "Pending . . ."
                                      : !account.strategy
                                        ? "No Strategy"
                                        : !account.sameDay
                                          ? "Out-of-Sync"
                                          : ""}
                                  </Badge>
                                )}
                            </Group>
                            <Text weight={500} size={14}>
                              {`${currencyFormatter.format(
                                account.totalValue || 0
                              )}`}
                            </Text>
                          </Group>
                          <Group
                            position="apart"
                            style={{ alignItems: "baseline" }}
                          >
                            <Text weight={500} size={14}>
                              {account.name}
                            </Text>
                            <Text weight={500} size={13}>
                              {`${new Date(
                                account.createdDate
                              ).toDateString()}`}
                            </Text>
                          </Group>
                          <Divider style={{ marginBottom: 2, marginTop: 2 }} />
                          <Group position="apart" style={{ marginBottom: 6 }}>
                            <Text weight={500} size={13}>
                              {"Applied Strategy:"}
                            </Text>
                            <Text weight={400} size={13}>
                              {account.strategy
                                ? `"${account.strategy.name}"`
                                : "None"}
                            </Text>
                          </Group>
                        </div>
                        <div style={{ maxWidth: 25 }}>
                          <MdChevronRight
                            size={28}
                            color={toggleTheme.primaryText}
                          />
                        </div>
                      </Group>
                    </Card>
                  ))}
                </Stack>
              </Stack>
            ))
          ) : (
            <Text align="center" size={20}>
              No accounts to display
            </Text>
          )}
        </Stack>
      </ScrollArea.Autosize>
    </Container>
  );
};

export default MyAccounts;

/* {accounts.length > 0 ? (
    accounts.map((account, index) => {
      let sameDay =
        new Date().getDate() ==
          new Date(account?.lastSynced).getDate() &&
        new Date().valueOf() - new Date(account?.lastSynced).valueOf() <
          1000 * 60 * 60 * 24;
      return (
        <Card
          key={account.id}
          style={{
            cursor: "pointer",
            background: toggleTheme.card_bg,
            padding: "5px 10px",
            marginTop: index > 0 ? -1 : 0,
            borderTop:
              index > 0
                ? `1px solid ${toggleTheme.card_disabled_bg}`
                : undefined,
            color: toggleTheme.text,
            borderBottomRightRadius:
              index < accounts.length - 1 ? 0 : 8,
            borderBottomLeftRadius: index < accounts.length - 1 ? 0 : 8,
            borderTopRightRadius: index > 0 ? 0 : 8,
            borderTopLeftRadius: index > 0 ? 0 : 8,
          }}
          onClick={() => {
            handleAccountClick(account.id);
          }}
        >
          <Group position="apart" style={{ alignItems: "baseline" }}>
            <Group spacing={6}>
              <Text size={20} weight={500}>
                {account.name}
              </Text>
              {(sameDay ||
                !account.strategy ||
                (!sameDay && !account.withinThreshold)) && (
                <Badge
                  variant="filled"
                  size="sm"
                  color={
                    sameDay
                      ? "blue"
                      : !account.strategy
                      ? "red"
                      : "orange"
                  }
                >
                  {sameDay
                    ? "Pending . . ."
                    : !account.strategy
                    ? "No Strategy"
                    : !sameDay
                    ? "Out-of-Sync"
                    : ""}
                </Badge>
              )}
            </Group>
            <Text weight={500} size={14}>
              {`${currencyFormatter.format(account.totalValue || 0)}`}
            </Text>
          </Group>
          <Group position="apart" style={{ alignItems: "baseline" }}>
            <Text weight={500} size={14}>
              {`${account.custodian.name}${
                account.mask ? " - " + account.mask : ""
              }`}
            </Text>
            <Text weight={500} size={13}>
              {`${new Date(account.createdDate).toDateString()}`}
            </Text>
          </Group>
          <Divider style={{ marginBottom: 2, marginTop: 2 }} />
          <Group position="apart" style={{ marginBottom: 6 }}>
            <Text weight={500} size={13}>
              {"Applied Strategy:"}
            </Text>
            <Text weight={400} size={13}>
              {account.strategy ? `"${account.strategy.name}"` : "None"}
            </Text>
          </Group>
        </Card>
      );
    }) */
