import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useCookies } from "react-cookie";
import { AmplifyUser } from "@aws-amplify/ui";
import awsConfig from "../aws-config/aws-config.json";

import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { EnvConfig, GetEnv } from "src/utils/EnvConfig";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";

export type ApolloClientContextData = {
  amplifyUser: AmplifyUser | undefined;
  setAmplifyUser: Dispatch<
    SetStateAction<ApolloClientContextData["amplifyUser"]>
  >;
  logout: () => void;
};
export const ApolloClientContext = createContext<ApolloClientContextData>(
  {} as ApolloClientContextData
);

interface Props {
  uri: string;
  wsUri: string;
  authChildren: React.ReactNode;
  noAuthChildren: React.ReactNode;
}

const env = GetEnv() as EnvConfig;

const ApolloClientProvider: FC<Props> = ({
  children,
  uri,
  wsUri,
  authChildren,
  noAuthChildren,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();

  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(
    undefined
  );

  const [amplifyUser, setAmplifyUser] =
    useState<ApolloClientContextData["amplifyUser"]>(undefined);

  const checkCookieCreds = async () => {
    let userData = await Auth.currentAuthenticatedUser();
    if (userData) {
      setCognitoUser(userData);
    } else {
      logout();
    }
  };

  const getAuthToken = () => {
    let authorization = "";

    if (amplifyUser) {
      return amplifyUser.getSignInUserSession()?.getAccessToken().getJwtToken();
    }

    const userId =
      cookies[
        `CognitoIdentityServiceProvider.${
          awsConfig[env.env].Auth.userPoolWebClientId
        }.LastAuthUser`
      ];
    if (userId) {
      authorization =
        cookies[
          `CognitoIdentityServiceProvider.${
            awsConfig[env.env].Auth.userPoolWebClientId
          }.${userId}.accessToken`
        ];
    }
    return authorization;
  };

  const authLink = setContext((_, { headers }) => {
    let authorization = getAuthToken();
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });

  const httpLink = new HttpLink({
    uri,
    credentials: "same-origin",
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUri,
      connectionParams: {
        authorization: getAuthToken(),
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const cache = new InMemoryCache().restore({});

  const client = new ApolloClient({
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, extensions, locations, path }) => {
            console.log(`GraphQL error: ${message}`);
            if (extensions.code === "UNAUTHENTICATED") {
              logout();
            }
          });
        }
        if (networkError) {
          console.log(`Network error: ${networkError}`);
        }
      }),
      new RetryLink({ attempts: { max: 3 } }),
      authLink,
      splitLink,
    ]),
  });

  useEffect(() => {
    if (amplifyUser) {
      checkCookieCreds();
    }
  }, [amplifyUser]);

  useEffect(() => {
    Auth.currentSession().then(() => {
      checkCookieCreds();
    });
  }, []);

  const logout = () => {
    localStorage.setItem("showReauthLogin", "true");
    Auth.signOut().then(() => {
      if (env) {
        let userId =
          cookies[
            `CognitoIdentityServiceProvider.${
              awsConfig[env.env].Auth.userPoolWebClientId
            }.LastAuthUser`
          ];
        removeCookie(
          `CognitoIdentityServiceProvider.${
            awsConfig[env.env].Auth.userPoolWebClientId
          }.LastAuthUser`
        );
        if (userId) {
          removeCookie(
            `CognitoIdentityServiceProvider.${
              awsConfig[env.env].Auth.userPoolWebClientId
            }.${userId}.accessToken`
          );
        }
      }
      setAmplifyUser(undefined);
      setCognitoUser(undefined);
      navigate("/");
    });
  };

  return (
    <ApolloClientContext.Provider
      value={{
        amplifyUser,
        logout,
        setAmplifyUser,
      }}
    >
      {cognitoUser ? (
        <ApolloProvider client={client}>{authChildren}</ApolloProvider>
      ) : (
        <ApolloProvider client={client}>{noAuthChildren}</ApolloProvider>
      )}
    </ApolloClientContext.Provider>
  );
};

export default ApolloClientProvider;
