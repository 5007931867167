import { useApolloClient } from "@apollo/client";
import {
  Button,
  Group,
  Text,
  Stack,
  Title,
  useMantineTheme,
  Divider,
} from "@mantine/core";
import { HiExternalLink } from "react-icons/hi";
import React, { useContext, useEffect, useState } from "react";
import {
  GetStripeAccountQuery,
  GetStripeAccountQueryVariables,
  GetStripeDashboardLinkQuery,
  GetStripeDashboardLinkQueryVariables,
} from "src/generated/graphql";
import { AppContext } from "src/api/AppContext";
import {
  GetStripeAccount,
  GetStripeDashboardLink,
} from "src/api/graphql/queries/Designer";

type StripeAccountData = NonNullable<GetStripeAccountQuery["getStripeAccount"]>;

type StripeInfoProps = {};

const StripeInfo = (props: StripeInfoProps) => {
  const theme = useMantineTheme();
  const { query } = useApolloClient();
  const { currentDesigner } = useContext(AppContext);

  const [stripeAccount, setStripeAccount] = useState<
    StripeAccountData | undefined
  >(undefined);

  useEffect(() => {
    if (!stripeAccount) {
      getStripeAccount();
    }
  }, []);

  const getStripeAccount = async () => {
    let response = await query<
      GetStripeAccountQuery,
      GetStripeAccountQueryVariables
    >({
      query: GetStripeAccount,
      fetchPolicy: "no-cache",
    });

    if (response.data.getStripeAccount) {
      setStripeAccount(response.data.getStripeAccount);
    }
  };

  const getStripeDashboardLink = async () => {
    let response = await query<
      GetStripeDashboardLinkQuery,
      GetStripeDashboardLinkQueryVariables
    >({
      query: GetStripeDashboardLink,
      fetchPolicy: "no-cache",
    });

    if (response.data.getStripeDashboardLink) {
      window.open(
        response.data.getStripeDashboardLink,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div
      style={{
        borderRadius: 8,
        padding: 10,
        margin: "3px 0px",
        marginBottom: 10,
      }}
    >
      <Group position="apart">
        <Text weight={500} size={20}>
          {"Stripe Account Info"}
        </Text>
      </Group>
      <Divider style={{ marginBottom: 5 }} />
      <div style={{ marginBottom: 0 }}>
        {stripeAccount ? (
          <React.Fragment>
            <Stack align="flex-start">
              {stripeAccount.transferTotal !== undefined &&
                stripeAccount.transferTotal !== null && (
                  <div>
                    <Title size={14} weight={500}>
                      {"Transfer Total"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {`$${stripeAccount.transferTotal.toFixed(2)}`}
                    </Text>
                  </div>
                )}
              <div>
                <Title size={14} weight={500}>
                  {"External Account Type"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>
                  {stripeAccount.objectType === "bank_account"
                    ? "Bank Account"
                    : "Linked Card"}
                </Text>
              </div>
              <div>
                <Title size={14} weight={500}>
                  {"Status"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>
                  {stripeAccount.status}
                </Text>
              </div>
              {stripeAccount.objectType === "bank_account" ? (
                <React.Fragment>
                  <div>
                    <Title size={14} weight={500}>
                      {"Bank Name"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {stripeAccount.bankName}
                    </Text>
                  </div>
                  <div>
                    <Title size={14} weight={500}>
                      {"Routing Number"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {stripeAccount.routingNumber}
                    </Text>
                  </div>
                  <div>
                    <Title size={14} weight={500}>
                      {"Account Number"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {`*****${stripeAccount.last4}`}
                    </Text>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div>
                    <Title size={14} weight={500}>
                      {"Brand"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {stripeAccount.brand}
                    </Text>
                  </div>
                  <div>
                    <Title size={14} weight={500}>
                      {"Card Number"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {`**** **** **** ${stripeAccount.last4}`}
                    </Text>
                  </div>
                  <div>
                    <Title size={14} weight={500}>
                      {"Expiry Date"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {`${stripeAccount.expMonth}-${stripeAccount.expYear}`}
                    </Text>
                  </div>
                </React.Fragment>
              )}
            </Stack>
            <Button
              radius={"xl"}
              onClick={getStripeDashboardLink}
              style={{ marginTop: 16 }}
              rightIcon={<HiExternalLink />}
            >{`Open Dashboard`}</Button>
          </React.Fragment>
        ) : (
          <Text
            color={"dimmed"}
            weight={500}
            align="center"
            style={{ marginTop: 30 }}
          >{`Unable to retrieve a connected Stripe account.`}</Text>
        )}
      </div>
    </div>
  );
};

export default StripeInfo;
