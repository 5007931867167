import { gql } from "@apollo/client";

export const CreateOpinion = gql`
  mutation CreateOpinion($data: CreateOpinionInput!) {
    createOpinion(data: $data) {
      id
      createdDate
      strategyId
      stockDataId
      accountPercentage
    }
  }
`;

export const UpdateOpinion = gql`
  mutation UpdateOpinion(
    $updateOpinionId: String!
    $data: UpdateOpinionInput!
  ) {
    updateOpinion(id: $updateOpinionId, data: $data) {
      id
      createdDate
      strategyId
      stockDataId
      accountPercentage
    }
  }
`;

export const DeleteOpinion = gql`
  mutation DeleteOpinion($deleteOpinionId: String!) {
    deleteOpinion(id: $deleteOpinionId)
  }
`;
