import { MantineTheme } from "@mantine/core";

export const useTogglableTheme = (isDark: boolean, theme: MantineTheme) => {
  return {
    text: isDark ? "#fff" : theme.colors.dark[6],
    card_bg: isDark ? "rgba(255,255,255,0.15)" : "rgba(0,0,0,0.04)",
    card_disabled_bg: isDark ? "rgba(200,200,200,0.4)" : "rgba(0,0,0,0.12)",
    card_disabled_text: isDark ? theme.colors.dark[0] : theme.colors.dark[3],
    primary: theme.colors.greenMachine[6],
    primaryText: isDark
      ? theme.colors.greenMachine[6]
      : theme.colors.greenMachine[7],
  };
};
