import React, { Children, FC, useContext, useEffect, useState } from "react";
import {
  AppShell as MantineAppShell,
  MediaQuery,
  ScrollArea,
} from "@mantine/core";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { AppContext } from "src/api/AppContext";
import { UserRole } from "src/generated/graphql";
import MobileHeader from "./components/MobileHeader";
import Onboarding from "../Onboarding";
import UnSubscribedRoutes from "src/navigation/UnSubscribedRoutes";
import { useNavigate } from "react-router-dom";
import { bgGR, bgGRDark } from "src/assets";
import { useMediaQuery } from "@mantine/hooks";

const AppShell: FC = ({ children }) => {
  const {
    currentUser,
    currentSubscriber,
    currentPageTitle,
    isDarkMode,
    refetchUserData,
  } = useContext(AppContext);
  const [isNavbarOpen, setNavbarOpened] = useState<boolean>(false);
  const toURL = window.location.pathname?.split("/")[1];

  const subscriberWithoutLink =
    currentUser?.role === UserRole.Subscriber &&
    currentSubscriber?.subscription === null &&
    toURL !== "designer" &&
    toURL !== "subscription";
  const navigate = useNavigate();

  const desktopView = useMediaQuery("(min-width: 550px)");

  useEffect(() => {
    let toUrl = window.localStorage.getItem("toURL");
    if (toUrl && currentUser) {
      window.localStorage.removeItem("toURL");
      navigate(toUrl);
    }
  }, [currentUser]);

  return currentUser?.completedOnboarding ? (
    subscriberWithoutLink ? (
      <MantineAppShell>
        <UnSubscribedRoutes />
      </MantineAppShell>
    ) : (
      <MantineAppShell
        fixed
        navbarOffsetBreakpoint={"md"}
        styles={(theme) => ({
          //styles added to main apply to appshell children
          main: {
            backgroundColor: "#f2f2f2",
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(${
              isDarkMode ? bgGRDark : bgGR
            })`,
            backgroundSize: 600,
            color: isDarkMode ? theme.white : theme.black,
          },
        })}
        header={
          currentUser?.role === UserRole.Subscriber ? (
            <MobileHeader
              isNavbarOpen={isNavbarOpen}
              setNavbarOpened={setNavbarOpened}
              //showBellIcon
              showThemeIcon
              showUserMenu
              title={currentPageTitle}
            />
          ) : (
            <Header
              showUserMenu={true}
              isNavbarOpen={isNavbarOpen}
              setNavbarOpened={setNavbarOpened}
              showThemeIcon
            />
          )
        }
        navbar={
          <>
            {(currentUser.role !== UserRole.Subscriber ||
              currentSubscriber?.subscription) && (
              // (
              //   currentSubscriber?.subscription?.status === "active" ||
              //   currentSubscriber?.subscription?.status === "trialing"
              // )
              <Navbar
                isOpened={isNavbarOpen}
                setNavbarOpened={setNavbarOpened}
              />
            )}
          </>
        }
        footer={
          <Footer
            isMobile={currentUser?.role === UserRole.Subscriber ? true : false}
            title="Copyright © 2022 Freedom Folios LLC."
          />
        }
      >
        {desktopView ? (
          <ScrollArea.Autosize
            maxHeight={
              "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px )) )"
            }
            type="auto"
          >
            {children}
          </ScrollArea.Autosize>
        ) : (
          children
        )}
      </MantineAppShell>
    )
  ) : (
    <Onboarding />
  );
};

export default AppShell;

export const UnsubAppShell: FC = ({ children }) => {
  return (
    <MantineAppShell
      fixed
      navbarOffsetBreakpoint={"md"}
      padding={0}
      styles={(theme) => ({
        //styles added to main apply to appshell children
        main: {
          backgroundColor: "#f2f2f2",
          backgroundSize: 600,
          color: theme.black,
        },
      })}
      header={
        <Header
          showUserMenu={false}
          isNavbarOpen={false}
          setNavbarOpened={() => {}}
          hideNavMenu
        />
      }
      navbar={<></>}
      footer={
        <Footer
          isMobile={false}
          title={`Copyright © ${new Date().getFullYear()} Freedom Folios LLC.`}
        />
      }
    >
      {children}
    </MantineAppShell>
  );
};
