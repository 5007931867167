import { useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  Center,
  Divider,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "src/api/AppContext";
import { ArrayElement } from "src/utils/helperTypes";
import { useTogglableTheme } from "src/utils/theme";

const Home = () => {
  const desktopView = useMediaQuery("(min-width: 550px)");
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);

  return (
    <div>
      <Text>{"Admin/Designer Home Placeholder"}</Text>
    </div>
  );
};

export default Home;
