import { gql } from "@apollo/client";

export const CreateDesigner = gql`
  mutation CreateDesigner($data: CreateDesignerInput!) {
    createDesigner(data: $data) {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
      }
      profilePicURL
      bio
      enabled
    }
  }
`;

export const UpdateDesigner = gql`
  mutation UpdateDesigner($id: String!, $data: UpdateDesignerInput!) {
    updateDesigner(id: $id, data: $data) {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
      }
      profilePicURL
      bio
      enabled
    }
  }
`;

export const UpdatePreferredSupportContact = gql`
  mutation UpdatePreferredSupportContact(
    $designerId: String!
    $data: UpdatePreferredSupportContactInput!
  ) {
    updatePreferredSupportContact(designerId: $designerId, data: $data) {
      id
      supportEmail
      supportPhone
      preferredSupportContact
    }
  }
`;


export const DeleteDesigner = gql`
  mutation DeleteDesigner($deleteDesignerId: String!) {
    deleteDesigner(id: $deleteDesignerId)
  } 
`;