import { } from "@mantine/core";
import { useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { useTogglableTheme } from "src/utils/theme";
import { Center, Stack, Button, Group, Text, Card, useMantineTheme } from "@mantine/core";
import { CustodianAccount } from "src/modules/Home/SubscriberDashboard";
import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
type CustodianReauthProps = {
  custodians: CustodianAccount[] | undefined;
};

const CustodianReauth = ({
  custodians,
}: CustodianReauthProps) => {

  const theme = useMantineTheme();
  const { isDarkMode } =
    useContext(AppContext);
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const navigate = useNavigate();
  const modals = useModals();

  return (
    <Center>
      <Stack style={{ width: '100%' }}>
        <Text>
          {
            "Following custodian(s) needs re-authorization."
          }
        </Text>
        <div style={{ width: "100%" }}>
          {custodians &&
            custodians.map((custodian, index) => {
              return (
                <Card
                  key={custodian.id}
                  style={{
                    marginTop: -1,
                    backgroundColor: toggleTheme.card_bg,
                    borderBottomRightRadius:
                      index === (custodians && custodians?.length - 1)
                        ? 8
                        : 0,
                    borderBottomLeftRadius:
                      index === (custodians && custodians.length - 1)
                        ? 8
                        : 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    color: toggleTheme.text,
                    borderTop: "none",
                    cursor: "pointer",
                  }}
                >
                  <Group position="apart">
                    <Text
                      weight={500}
                      size={16}
                      color={toggleTheme.primaryText}
                    >
                      {custodian.name} - {custodian.userTag}
                    </Text>
                    <Button
                      type="submit"
                      color={theme.colors.greenMachine[6]}
                      // disabled={isAccountActive}
                      onClick={() => {
                        modals.closeAll();
                        modals.openConfirmModal({
                          title: "Confirmation",
                          children: (
                            <Text size="sm">
                              {`Please, be careful to select all the accounts you want to use in Freedom Folios during the login step. You will link them one at a time after the auth step. Failure to select a previously linked account during the re-auth step will result in losing real time updates to that account.This strategy has reached 100% position opinion allocation. Would you like to enable it to be discovered by your subscribers?`}
                            </Text>
                          ),
                          labels: { confirm: "Continue", cancel: "" },
                          cancelProps: {
                            display: 'none'
                          },
                          confirmProps: { color: theme.colors.greenMachine[7] },
                          onCancel: () => console.log("Cancel"),
                          onConfirm: () => {
                            modals.closeAll();
                            navigate(`/plaid-link/${custodian.id}`);
                          },
                        });
                      }}
                      size="xs"
                      style={{
                        fontSize: 14,
                        lineHeight: "16px",
                        border: "none",
                        paddingLeft: 0,
                        paddingRight: 0,
                        padding: 6,
                        minWidth: 56.6
                      }}
                    >
                      Re-authorize
                    </Button>
                  </Group>
                </Card>
              );
            })}
        </div>
      </Stack>
    </Center>
  );
};

export default CustodianReauth;
