import { useApolloClient } from "@apollo/client";
import {
  TextInput,
  Image,
  Button,
  Group,
  Text,
  Textarea,
  Stack,
  Title,
  ActionIcon,
  useMantineTheme,
  Switch,
  Divider,
  MediaQuery,
  Input,
} from "@mantine/core";
import { MdCheck, MdCancel, MdEdit, MdCheckCircle } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import React, { useContext, useState } from "react";
import InputMask from "react-input-mask";
import { UpdateDesigner } from "src/api/graphql/mutations/Designer";
import {
  UpdateDesignerInput,
  UpdateDesignerMutation,
  UpdateDesignerMutationVariables,
  UserRole,
} from "src/generated/graphql";
import ImageUpload from "./ImageUpload";
import { AppContext } from "src/api/AppContext";
import { DesignerData } from "./DesignerDetails";
import { formatPhoneNumber } from "src/utils/formatting";

type BasicInfoProps = {
  designer: DesignerData;
  onSave: () => void;
};

const BasicInfo = (props: BasicInfoProps) => {
  const theme = useMantineTheme();
  const { mutate } = useApolloClient();
  const { currentUser } = useContext(AppContext);

  const [editing, setEditing] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      profilePicURL: undefined as string | undefined,
      bio: undefined as string | undefined,
      firstName: "",
      lastName: "",
      enabled: false,
      phoneNumber: undefined as string | undefined,
    },

    validate: {
      firstName: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a first name!",
      lastName: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a last name!",
    },
  });

  const updateDesigner = async (values: UpdateDesignerInput) => {
    showNotification({
      id: "update-designer",
      loading: true,
      title: "Loading",
      message: "Attempting to update a new designer...",
      autoClose: false,
      disallowClose: true,
    });
    if (currentUser?.role != UserRole.PlatformAdmin) {
      delete values.enabled;
    }

    if (values.phoneNumber) {
      values.phoneNumber = "+" + ("" + values.phoneNumber).replace(/\D/g, "");
    }

    try {
      let response = await mutate<
        UpdateDesignerMutation,
        UpdateDesignerMutationVariables
      >({
        mutation: UpdateDesigner,
        variables: {
          id: props.designer.id,
          data: values,
        },
        fetchPolicy: "no-cache",
      });

      if (response) {
        updateNotification({
          id: "update-designer",
          color: response.data?.updateDesigner ? "green" : "red",
          title: response.data?.updateDesigner ? "Success!" : "Failure!",
          message: response.data?.updateDesigner
            ? "Designer details updated!"
            : "An error occured while updating the designer...",
          icon: response.data?.updateDesigner ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
        if (response.data?.updateDesigner) {
          props.onSave();
        } else {
          console.log(response.errors);
        }
      }
    } catch (error) {
      updateNotification({
        id: "update-designer",
        color: "red",
        title: "Failure!",
        message: "Unable to update the designer!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  return (
    <div
      style={{
        boxShadow: editing
          ? `0px 0px 4px 0px ${theme.colors.gray[5]}`
          : undefined,
        backgroundColor: editing ? theme.colors.gray[0] : undefined,
        borderRadius: 8,
        padding: 10,
        margin: "3px 0px",
        marginBottom: 10,
        maxWidth: 815,
      }}
    >
      <Group position="apart">
        <Text weight={500} size={20}>
          {"Basic Info"}
        </Text>
        {!editing && (
          <ActionIcon
            onClick={() => {
              form.setValues({
                profilePicURL: props.designer.profilePicURL || undefined,
                bio: props.designer.bio || undefined,
                firstName: props.designer.user?.firstName,
                lastName: props.designer.user?.lastName,
                enabled: props.designer.enabled,
                phoneNumber: props.designer.user?.phoneNumber || undefined,
              });
              setEditing(true);
            }}
          >
            <MdEdit />
          </ActionIcon>
        )}
      </Group>
      <Divider style={{ marginBottom: 5 }} />
      <div style={{ marginBottom: 0 }}>
        {editing ? (
          <form
            onSubmit={form.onSubmit((values) => {
              setEditing(false);
              updateDesigner(values);
            })}
            onReset={() => setEditing(false)}
            style={{ display: "flex" }}
          >
            <Group spacing={20} style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  maxWidth: 300,
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "100%", maxWidth: 300, aspectRatio: "1" }}>
                  <ImageUpload
                    onReadSuccess={(b64) =>
                      form.setFieldValue("profilePicURL", b64)
                    }
                    initialImage={form.values.profilePicURL}
                    imageAlt="Designer Profile Picture Upload"
                    targetSize={300}
                  />
                </div>
              </div>
              <Group style={{ width: "100%", maxWidth: 410 }}>
                {currentUser?.role == UserRole.PlatformAdmin && (
                  <div style={{ width: "100%", marginTop: 10 }}>
                    <Title size={14} weight={500}>
                      {"Status"}
                    </Title>
                    <Switch
                      disabled={currentUser?.role !== UserRole.PlatformAdmin}
                      onLabel="Enabled"
                      offLabel="Disabled"
                      size="xl"
                      {...form.getInputProps("enabled")}
                      checked={form.values.enabled}
                    />
                  </div>
                )}
                <Group spacing={10} style={{ width: "100%", maxWidth: 400 }}>
                  <TextInput
                    style={{ marginTop: 12 }}
                    disabled
                    label="Email"
                    value={props.designer.user?.email}
                  />
                  <div style={{ marginTop: 14 }}>
                    <Text size={14} weight={500} style={{ marginBottom: 1 }}>
                      {"Phone"}
                    </Text>
                    <Input
                      component={InputMask}
                      mask="+1 (999) 999-9999"
                      placeholder="Your phone"
                      {...form.getInputProps("phoneNumber")}
                    />
                  </div>
                  {/* <TextInput
                    style={{ marginTop: 12 }}
                    label="Phone"
                    value={props.designer.user?.phoneNumber || undefined}
                  /> */}
                </Group>
                <Group spacing={10} style={{ width: "100%", maxWidth: 400 }}>
                  <TextInput
                    style={{ marginTop: 12 }}
                    withAsterisk
                    label="First Name"
                    placeholder="John"
                    {...form.getInputProps("firstName")}
                  />
                  <TextInput
                    style={{ marginTop: 12 }}
                    withAsterisk
                    label="Last Name"
                    placeholder="Doe"
                    {...form.getInputProps("lastName")}
                  />
                </Group>
              </Group>
              <Textarea
                style={{ marginTop: 12, width: "100%" }}
                placeholder="Hello, my name is John Doe. I specialize in long-term, retirement investment. Subscribe to my quarterly newsletter to see how I help make this possible for new investors."
                label="Personal Bio"
                minRows={4}
                maxLength={500}
                {...form.getInputProps("bio")}
              />
              <Group position="right" style={{ width: "100%", marginTop: -20 }}>
                <Text color="dimmed" size={14} weight={500}>{`${
                  form.values.bio?.length || 0
                }/500`}</Text>
              </Group>
              <Group position="right" mt="md">
                <Button type="reset">Cancel</Button>
                <Button type="submit">Save</Button>
              </Group>
            </Group>
          </form>
        ) : (
          <Stack align="flex-start" spacing={30}>
            <Group spacing={10} style={{ alignItems: "flex-start" }}>
              {props.designer.profilePicURL ? (
                <div
                  style={{
                    width: "min(100%, 300px)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    width={300}
                    height={300}
                    style={{
                      aspectRatio: "1",
                      borderRadius: 5,
                      overflow: "hidden",
                    }}
                    src={props.designer.profilePicURL}
                    alt="Designer Profile Picture"
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "min(100%, 300px)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      maxWidth: 300,
                      aspectRatio: "1",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack align={"center"}>
                      <FaUser size={100} color={theme.colors.gray[5]} />
                      <Text size={14} weight={500}>
                        No Image Uploaded
                      </Text>
                    </Stack>
                  </div>
                </div>
              )}
              <Group
                spacing={0}
                style={{ width: "100%", maxWidth: 410, rowGap: 30 }}
              >
                <div style={{ width: "100%" }}>
                  <Title size={14} weight={500}>
                    {"Status"}
                  </Title>
                  <Text style={{ marginLeft: "8px" }}>
                    <Group spacing="xs">
                      {props.designer.enabled ? (
                        <MdCheckCircle
                          color={theme.colors.green[5]}
                          size={16}
                        />
                      ) : (
                        <MdCancel
                          color={theme.colors.red[5]}
                          size={16}
                          style={{ lineHeight: 16 }}
                        />
                      )}
                      <Text style={{ fontWeight: 500 }}>
                        {props.designer.enabled ? "Enabled" : "Disabled"}
                      </Text>
                    </Group>
                  </Text>
                </div>
                <Group
                  spacing={10}
                  style={{ width: "min(100%, 410px)" }}
                  position="apart"
                >
                  <div style={{ width: 220 }}>
                    <Title size={14} weight={500}>
                      {"Email"}
                    </Title>
                    <Text
                      style={{
                        marginLeft: "8px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {props.designer.user?.email}
                    </Text>
                  </div>
                  <div style={{ minWidth: 170 }}>
                    <Title size={14} weight={500}>
                      {"Phone"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {props.designer.user?.phoneNumber
                        ? formatPhoneNumber(props.designer.user?.phoneNumber)
                        : "None Provided"}
                    </Text>
                  </div>
                </Group>
                <Group
                  style={{
                    width: "100%",
                    maxWidth: 410,
                  }}
                  position={"apart"}
                >
                  <div style={{ minWidth: 170 }}>
                    <Title size={14} weight={500}>
                      {"First Name"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {props.designer.user?.firstName}
                    </Text>
                  </div>
                  <div style={{ minWidth: 170 }}>
                    <Title size={14} weight={500}>
                      {"Last Name"}
                    </Title>
                    <Text style={{ marginLeft: "8px" }}>
                      {props.designer.user?.lastName}
                    </Text>
                  </div>
                </Group>
              </Group>
            </Group>
            <div style={{ width: "100%" }}>
              <Title size={14} weight={500}>
                {"Personal Bio"}
              </Title>
              <div
                style={{
                  height: "calc(3em + 22px)",
                  overflowY: "auto",
                  width: "100%",
                  lineHeight: 1.55,
                  paddingLeft: "8px",
                }}
              >
                <Text>{props.designer.bio}</Text>
              </div>
            </div>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default BasicInfo;
