import { gql } from "@apollo/client";

export const CreateStrategy = gql`
  mutation CreateStrategy($data: CreateStrategyInput!) {
    createStrategy(data: $data) {
      id
      createdDate
      designerId
      name
      riskLevel
      termDuration
      syncThreshold
      opinions {
        id
        createdDate
        strategyId
        stockDataId
        accountPercentage
        stockData {
          id
          createdDate
          price
          symbol
          shortName
          longName
        }
      }
    }
  }
`;

export const UpdateStrategy = gql`
  mutation UpdateStrategy(
    $data: UpdateStrategyInput!
    $updateStrategyId: String!
  ) {
    updateStrategy(data: $data, id: $updateStrategyId) {
      id
      createdDate
      updatedDate
      seedId
      designerId
      name
      riskLevel
      termDuration
      syncThreshold
      cashPercentage
      opinions {
        id
        createdDate
        updatedDate
        seedId
        strategyId
        stockDataId
        accountPercentage
        stockData {
          id
          createdDate
          updatedDate
          seedId
          price
          symbol
          shortName
          longName
        }
      }
    }
  }
`;
