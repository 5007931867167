import { useApolloClient } from "@apollo/client";
import { ScrollArea, Text, useMantineTheme } from "@mantine/core";
import { FetchParams, KapexTable, QueryResponse } from "@kapex/tables";
import { GetPlatformAdminPage } from "src/api/graphql/queries/PlatformAdmin";
import {
  GetPlatformAdminPageQuery,
  GetPlatformAdminPageQueryVariables,
} from "src/generated/graphql";
import { UserData } from "../User";
import { useContext } from "react";
import { AppContext } from "src/api/AppContext";
import { useTogglableTheme } from "src/utils/theme";

type PlatformAdminData = {
  id: string;
  user: UserData;
};

const PlatformAdmin = () => {
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { query } = useApolloClient();

  const getPlatformAdmin = async (
    fetchParams: FetchParams
  ): Promise<QueryResponse<PlatformAdminData>> => {
    let response = await query<
      GetPlatformAdminPageQuery,
      GetPlatformAdminPageQueryVariables
    >({
      query: GetPlatformAdminPage,
      variables: {
        pagination: {
          ...fetchParams,
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.platformAdminPage) {
      return {
        rowCount: response.data.platformAdminPage.totalRows,
        rowData: response.data.platformAdminPage.items as PlatformAdminData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  };

  return (
    <div>
      <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
        {"Platform Admin"}
      </Text>
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 40px)) - 48px )"
        }
        style={{
          minHeight: 300,
          marginBottom: 8,
        }}
        type="auto"
        scrollbarSize={9}
        sx={(theme) => ({
          ".mantine-ScrollArea-root": {
            borderRadius: 4,
            paddingRight: 1,
          },
          ".mantine-ScrollArea-viewport": {
            borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
          },
          ".mantine-ScrollArea-scrollbar": {
            background: theme.colors.dark[4],
            ".mantine-ScrollArea-thumb": {
              backgroundColor: theme.colors.greenMachine[6],
            },
          },
        })}
      >
        <KapexTable<PlatformAdminData>
          tableSx={{
            thead: {
              th: {
                ">div": {
                  color: "#fff",
                },
              },
              "::after": {
                left: "-1px",
              },
            },
            tbody: {
              tr: {
                td: {
                  borderColor: toggleTheme.card_disabled_bg,
                  ">div": {
                    color: toggleTheme.text,
                  },
                },
              },
              "tr:hover": {
                backgroundColor: `${toggleTheme.card_disabled_bg} !important`,
              },
            },
            tfoot: {
              td: {
                div: {
                  color: "#fff",
                },
              },
            },
          }}
          headerColor={{ r: 255, g: 255, b: 255 }}
          headerBg={theme.colors.greenMachine[6]}
          borderColor={theme.colors.greenMachine[8]}
          columnConfigs={[
            {
              field: "name",
              name: "Name",
              valueParser: (rowItem) =>
                `${rowItem.user.firstName} ${rowItem.user.lastName}`,
              filter: {
                type: "text",
                field: "name",
              },
              sortable: true,
            },
            {
              field: "email",
              parentRelationField: "user",
              name: "Email",
              filter: {
                field: "email",
                parentRelationField: "user",
                type: "text",
              },
              sortable: true,
            },
            {
              field: "phoneNumber",
              parentRelationField: "user",
              name: "Phone",
              filter: {
                field: "phoneNumber",
                parentRelationField: "user",
                type: "text",
              },
              sortable: true,
              valueParser: (rowItem) => {
                if (!rowItem.user) {
                  return "N/A";
                }
                let num = rowItem.user.phoneNumber;
                if (!num) {
                  return "N/A";
                }
                let len = num.length;
                if (len >= 10) {
                  return `(${num.slice(len - 10, len - 7)}) ${num.slice(
                    len - 7,
                    len - 4
                  )}-${num.slice(len - 4)}`;
                } else {
                  return "N/A";
                }
              },
            },
            {
              field: "createdDate",
              parentRelationField: "user",
              name: "Created",
              filter: {
                field: "createdDate",
                parentRelationField: "user",
                type: "dateRange",
              },
              sortable: true,
              valueParser: (rowItem) => {
                return rowItem.user
                  ? new Date(rowItem.user.createdDate).toDateString()
                  : "N/A";
              },
            },
          ]}
          dataFetch={getPlatformAdmin}
        />
      </ScrollArea.Autosize>
    </div>
  );
};

export default PlatformAdmin;
