import { Button, Group, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { forwardRef, useImperativeHandle, useState } from 'react';

interface LoginFormProps {
  header: string;
  initialName?: string;
  institutionName: string;
  ref: any;
  onCancel?: () => void;
  onSubmit: (name: string) => Promise<void>;
};
export interface LoginFormType {
  updateLoadingState: (loading: boolean) => void;
}
const LoginForm: React.ForwardRefRenderFunction<LoginFormType, LoginFormProps> = ({
  header,
  onSubmit,
  initialName,
  institutionName,
  onCancel,
}, ref) => {
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm({
    initialValues: {
      name: initialName || "",
    },
  });

  const updateLoadingState = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  // Expose the child function to the parent through the ref
  useImperativeHandle(ref, () => ({
    updateLoadingState,
  }));

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values.name);
      })}
      onReset={() => onCancel && onCancel()}
    >
      <Stack>
        <Text>{header}</Text>
        <Group position="center">
          <Text weight={500} size={21}>{`${institutionName}: `}</Text>
          <TextInput
            {...form.getInputProps("name")}
            placeholder={"e.g username@email.com"}
          />
        </Group>

        <Group position="right" mt="md">
          {onCancel != undefined && (
            <Button type="reset" color={"red"} variant="outline">
              {`Cancel`}
            </Button>
          )}
          <Button type="submit" loading={loading}>{`Save`}</Button>
        </Group>
      </Stack>
    </form>
  );

};

const LoginFormComponent = forwardRef(LoginForm);

export default LoginFormComponent;
