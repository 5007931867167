import  { useContext } from "react";
import { Button, Grid, Group, Modal, Text } from "@mantine/core";
import { AppContext } from "src/api/AppContext";
import { useApolloClient } from "@apollo/client";
import {
  CreateSwapRequestMutation,
  CreateSwapRequestMutationVariables,
} from "src/generated/graphql";
import { CreateSwapRequest } from "src/api/graphql/mutations/SwapRequest";

interface SwapRequestFormProps {
  designerId: string | undefined;
  onClose: () => void;
}
const SwapRequestForm = (props: SwapRequestFormProps) => {
  const { designerId } = props;
  const { currentSubscriber } = useContext(AppContext);
  const { mutate } = useApolloClient();

  const getFormattedDate = (date: string | null | undefined) => {
    if (!date) return "";

    const d = new Date(date);
    return `${d.toLocaleString("default", {
      month: "short",
    })} ${d.getUTCDate()} ${d.getFullYear()}`;
  };

  const handleSwapRequest = async () => {
    if (!designerId || !currentSubscriber) {
      console.log(
        "Cannot find required subsription and/or subscriber ids required to perform swap request."
      );
      return;
    }
    const response = await mutate<
      CreateSwapRequestMutation,
      CreateSwapRequestMutationVariables
    >({
      mutation: CreateSwapRequest,
      variables: {
        data: {
          designer: {
            id: designerId,
          },
          subscriber: {
            id: currentSubscriber?.id,
          },
        },
      },
    });
    if (response) {
      props.onClose();
    }
  };

  return (
    <Group position="center">
      <Text>
        {`Are you sure you want to submit a swap request and cancel your
            current subscription? You will still have access to your current
            designer until your subscription expires on ${`${getFormattedDate(
          currentSubscriber?.subscription?.renewalDate
        )}`}. Do you
            still wish to submit the request?`}
      </Text>
      <Grid>
        <Grid.Col span={4}>
          <Button
            style={{
              margin: 4,
              backgroundColor: "#fff",
              border: "none",
              color: "#4dab69",
              filter: "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25))",
            }}
            onClick={() => props.onClose()}
          >
            {`Cancel`}
          </Button>
        </Grid.Col>
        <Grid.Col span={4}>
          <Button
            style={{
              margin: 4,
              border: "none",
              filter: "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25))",
            }}
            onClick={handleSwapRequest}
          >
            {`Submit Request`}
          </Button>
        </Grid.Col>
      </Grid>
    </Group>
  );
};

export default SwapRequestForm;