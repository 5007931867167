import { gql } from "@apollo/client";

export const GetStockDataBySymbol = gql`
  query GetStockDataBySymbol($searchTerm: String!) {
    stockDataSearchBySymbol(searchTerm: $searchTerm) {
      id
      createdDate
      price
      symbol
      shortName
      longName
    }
  }
`;
