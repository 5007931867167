import { gql } from "@apollo/client";

export const CreateNewsletterTemplate = gql`
  mutation CreateNewsletterTemplate($data: NewsletterTemplateInput!) {
    createNewsletterTemplate(data: $data) {
      id
      createdDate
      updatedDate
      header
      body
    }
  }
`;

export const UpdateNewsletterTemplate = gql`
  mutation UpdateNewsletterTemplate(
    $updateNewsletterTemplateId: String!
    $data: NewsletterTemplateInput!
  ) {
    updateNewsletterTemplate(id: $updateNewsletterTemplateId, data: $data) {
      id
      createdDate
      header
      body
    }
  }
`;
