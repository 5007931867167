import React, { useContext } from "react";
import {
  DefaultMantineColor,
  Navbar as MantineNavbar,
  Tabs,
  useMantineTheme,
} from "@mantine/core";
import {
  FaChartLine,
  FaHome,
  FaUser,
  FaUserFriends,
  FaUserShield,
  FaUsers,
  FaUserTie,
  FaFileInvoiceDollar,
  FaUserLock,
  FaQuestionCircle,
} from "react-icons/fa";
import { AiFillDashboard, AiOutlineBarChart } from "react-icons/ai";
import { AppContext } from "src/api/AppContext";
import { useStyles } from "./styles";
import { UserRole } from "src/generated/graphql";
import { MdEmail, MdReport } from "react-icons/md";

interface NavbarProps {
  isOpened: boolean;
  setNavbarOpened: (opened: boolean) => void;
}

type TabType = {
  key: string;
  label: string;
  roles?: UserRole[];
  icon?: React.ReactNode;
  color?: DefaultMantineColor;
  elementOverride?: React.ReactNode;
};

const Navbar: React.FC<NavbarProps> = ({
  isOpened,
  setNavbarOpened,
}: NavbarProps) => {
  const { changeTab, activeTabKey, currentUser } = useContext(AppContext);
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const Tablist: TabType[] = [
    {
      key: "Home",
      label: "Home",
      icon: <FaHome size={19} />,
      roles: [UserRole.PlatformAdmin],
    },
    {
      key: "DesignerDashboard",
      label: "Dashboard",
      icon: <AiFillDashboard size={19} />,
      roles: [UserRole.Designer],
    },
    {
      key: "Dashboard",
      label: "Dashboard",
      icon: <AiFillDashboard size={19} />,
      roles: [UserRole.Subscriber],
    },
    {
      key: "MyAccounts",
      label: "Accounts",
      icon: <FaUserLock size={18} />,
      roles: [UserRole.Subscriber],
    },
    {
      key: "MyProfile",
      label: "Profile",
      icon: <FaUser size={18} />,
      roles: [UserRole.Subscriber, UserRole.Designer],
    },
    {
      key: "User",
      label: "Users",
      icon: <FaUsers size={18} />,
      roles: [UserRole.PlatformAdmin],
    },
    {
      key: "PlatformAdmin",
      label: "Platform Admin",
      icon: <FaUserShield size={18} />,
      roles: [UserRole.PlatformAdmin],
    },
    {
      key: "Designer",
      label: "Designers",
      icon: <FaUserTie size={18} />,
      roles: [UserRole.PlatformAdmin],
    },
    {
      key: "Subscriber",
      label: "Subscribers",
      icon: <FaUserFriends size={18} />,
      roles: [UserRole.PlatformAdmin],
    },
    {
      key: "Strategy",
      label: "Strategies",
      icon: <FaChartLine size={18} />,
      roles: [UserRole.PlatformAdmin, UserRole.Designer],
    },
    {
      key: "MySubscription",
      label: "Subscription",
      icon: <FaFileInvoiceDollar size={18} />,
      roles: [UserRole.Subscriber],
    },
    {
      key: "Account",
      label: "Accounts",
      icon: <FaUserLock size={18} />,
      roles: [UserRole.PlatformAdmin],
    },
    {
      key: "NewsletterAdmin",
      label: "Newsletters",
      icon: <MdEmail size={18} />,
      roles: [UserRole.PlatformAdmin],
    },
    {
      key: "NewsletterDesigner",
      label: "Newsletters",
      icon: <MdEmail size={18} />,
      roles: [UserRole.Designer],
    },
    {
      key: "DesignerReport",
      label: "Reports",
      icon: <MdReport />,
      roles: [UserRole.Designer],
    },
    {
      key: "ReportsSubscriber",
      label: "Reports",
      icon: <AiOutlineBarChart size={19} />,
      roles: [UserRole.Subscriber],
    },
    {
      key: "HelpAndSupport",
      label: "Help & Support",
      icon: <FaQuestionCircle size={18} />,
      roles: [UserRole.Subscriber],
    },
    {
      key: "ReportsAdmin",
      label: "Reports",
      icon: <MdEmail />,
      roles: [UserRole.PlatformAdmin],
    },
  ];

  return (
    <MantineNavbar
      p="md"
      className={classes.navbar}
      width={{ sm: 180, md: 180, lg: 180, base: 180 }}
      style={{
        backgroundColor: theme.colors.charcoal[0],
        borderRight: "none",
        marginLeft: !isOpened ? -180 : 0,
        transition: "margin-left 0.3s",
      }}
    >
      <MantineNavbar.Section>
        <Tabs
          orientation="vertical"
          value={activeTabKey}
          onTabChange={(key) => {
            changeTab(key || "");
            setNavbarOpened(false);
          }}
        >
          <Tabs.List style={{ borderRight: "none" }} grow>
            {Tablist.map((tab) => {
              let permitted = false;
              tab.roles?.forEach((userRole) => {
                if (currentUser?.role === userRole) {
                  permitted = true;
                }
              });
              if (tab.roles && !permitted) {
                return undefined;
              } else if (tab.elementOverride) {
                return tab.elementOverride;
              } else
                return (
                  <Tabs.Tab
                    key={tab.key}
                    value={tab.key}
                    icon={tab.icon}
                    color={tab.color}
                    sx={{
                      "&:hover": {
                        backgroundColor: theme.colors.greenMachine[1],
                        color: "#1b1b1b !important",
                      },
                    }}
                    style={{
                      ...{
                        borderRadius: 0,
                        borderWidth: 0,
                        borderRight: "none !important",
                        color: theme.white,
                        width: 180,
                      },
                      ...(activeTabKey === tab.key
                        ? {
                            backgroundColor: theme.colors.greenMachine[2],
                            color: theme.black,
                            fontWeight: 500,
                          }
                        : {}),
                    }}
                  >
                    {tab.label}
                  </Tabs.Tab>
                );
            })}
          </Tabs.List>
        </Tabs>
      </MantineNavbar.Section>
    </MantineNavbar>
  );
};

export default Navbar;
