import React, { useContext } from "react";
import {
  Header as MantineHeader,
  MediaQuery,
  Burger,
  useMantineTheme,
  Avatar,
  Menu,
  Image,
  Group,
  ActionIcon,
} from "@mantine/core";
import { FaBell } from "react-icons/fa";
import { ffLogoExtraThin, ffIcon } from "src/assets";
import { useStyles } from "./styles";
import { Auth } from "aws-amplify";
import { AppContext } from "src/api/AppContext";
import { Navigate, useNavigate } from "react-router-dom";
import { BsMoonStarsFill, BsSunFill } from "react-icons/bs";
import { ApolloClientContext } from "src/api/ApolloClientContext";
import { UserRole } from "src/generated/graphql";

interface HeaderProps {
  setNavbarOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isNavbarOpen: boolean;
  showUserMenu: boolean;
  showThemeIcon?: boolean;
  hideNavMenu?: boolean;
}

const Header = ({
  setNavbarOpened,
  isNavbarOpen,
  showUserMenu,
  showThemeIcon,
  hideNavMenu,
}: HeaderProps) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { currentUser, currentDesigner, isDarkMode, setIsDarkMode } =
    useContext(AppContext);
  const { logout } = useContext(ApolloClientContext);

  return (
    <MantineHeader
      height={75}
      p="sm"
      style={{
        backgroundColor: theme.colors.greenMachine[6],
        borderBottom: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {!hideNavMenu && (
          <MediaQuery largerThan="md" styles={{ display: "none" }}>
            <Burger
              color={theme.colors.gray[0]}
              mr="xl"
              onClick={() => setNavbarOpened((isOpen) => !isOpen)}
              opened={isNavbarOpen}
              size="sm"
            />
          </MediaQuery>
        )}
        {/* <MediaQuery smallerThan={"md"} styles={{ display: "none" }}>
          <div
            style={{
              alignItems: "center",
              height: "100%",
              width: 168,
            }}
          >
            <img
              src={ffIcon}
              style={{ height: "100%", borderRadius: 10 }}
              alt="Freedom Folios Icon"
            />
          </div>
        </MediaQuery> */}
        <MediaQuery largerThan={"sm"} styles={{ display: "none" }}>
          <Group position="center" style={{ width: "100%", height: "100%" }}>
            <div style={{ height: "100%" }}>
              <img
                src={ffLogoExtraThin}
                style={{ height: "100%" }}
                alt="Freedom Folios Logo"
              />
            </div>
          </Group>
        </MediaQuery>
        <MediaQuery smallerThan={"md"} styles={{ display: "none" }}>
          <Group position="apart" style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                minWidth: 200,
                justifyContent: "flex-start",
              }}
            ></div>
            <div style={{ height: "100%" }}>
              <img
                src={ffLogoExtraThin}
                style={{ height: "100%" }}
                alt="Freedom Folios Logo"
              />
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                minWidth: 200,
                justifyContent: "flex-end",
                paddingRight: 10,
              }}
            >
              {showThemeIcon && (
                <ActionIcon
                  onClick={() => setIsDarkMode(!isDarkMode)}
                  style={{ marginRight: 16 }}
                >
                  {isDarkMode ? (
                    <BsSunFill
                      color="white"
                      size={24}
                      //className={classes.headerItem}
                    />
                  ) : (
                    <BsMoonStarsFill
                      color="white"
                      size={24}
                      //className={classes.headerItem}
                    />
                  )}
                </ActionIcon>
              )}
              {showUserMenu && (
                <Menu>
                  <Menu.Target>
                    <Avatar
                      radius="xl"
                      sx={{
                        ":root": {
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        },
                      }}
                      src={currentDesigner?.profilePicURL}
                    />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>{"Account"}</Menu.Label>
                    {currentUser?.role == UserRole.Subscriber && (
                      <Menu.Item onClick={() => navigate("/profile")}>
                        {"My Profile"}
                      </Menu.Item>
                    )}
                    <Menu.Item color="red" onClick={() => logout()}>
                      {"Sign out"}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </div>
          </Group>
        </MediaQuery>
        {showUserMenu && (
          <MediaQuery largerThan="md" styles={{ display: "none" }}>
            <Group position="right" style={{ width: "100%", height: "100%" }}>
              {showThemeIcon && (
                <ActionIcon
                  onClick={() => setIsDarkMode(!isDarkMode)}
                  style={{ marginRight: 16 }}
                >
                  {isDarkMode ? (
                    <BsSunFill
                      color="white"
                      size={24}
                      //className={classes.headerItem}
                    />
                  ) : (
                    <BsMoonStarsFill
                      color="white"
                      size={24}
                      //className={classes.headerItem}
                    />
                  )}
                </ActionIcon>
              )}
              <Menu>
                <Menu.Target>
                  <Avatar
                    radius="xl"
                    sx={{
                      ":root": {
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      },
                    }}
                    src={currentDesigner?.profilePicURL}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>{"Account"}</Menu.Label>
                  <Menu.Item onClick={() => navigate("/profile")}>
                    {"My Profile"}
                  </Menu.Item>
                  <Menu.Item color="red" onClick={() => logout()}>
                    {"Sign out"}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </MediaQuery>
        )}
      </div>
    </MantineHeader>
  );
};

export default Header;
