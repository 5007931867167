import { useApolloClient } from "@apollo/client";
import {
  Button,
  Container,
  Group,
  ScrollArea,
  Textarea,
  TextInput,
  Text,
  Stack,
  Center,
  Image,
  Card,
  Loader,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { MdCancel, MdCheck, MdSend } from "react-icons/md";
import { AppContext } from "src/api/AppContext";
import { CreateSupportTicket } from "src/api/graphql/mutations/SupportTicket";
import { GetDesigner } from "src/api/graphql/queries/Designer";
import {
  CreateSupportTicketMutation,
  CreateSupportTicketMutationVariables,
  GetDesignerQuery,
  GetDesignerQueryVariables,
  SupportTicketInput,
} from "src/generated/graphql";
import { useTogglableTheme } from "src/utils/theme";

export type DesignerData = NonNullable<GetDesignerQuery["designerOne"]>;

const HelpSupport = () => {
  const { setCurrentPageTitle, currentSubscriber, isDarkMode } =
    useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { mutate, query } = useApolloClient();
  const [designer, setDesigner] = useState<DesignerData>();
  const [loading, setLoading] = useState<boolean>(true);

  const form = useForm({
    initialValues: {
      title: "" as string,
      body: "" as string,
    },
    validate: {
      title: (value: string) =>
        value !== undefined && value !== "" ? null : "You must supply a title!",
      body: (value: string | undefined) =>
        value !== undefined && value !== "" ? null : "You must supply a body!",
    },
  });

  const getDesigner = async () => {
    if (currentSubscriber?.subscription !== null) {
      const response = await query<GetDesignerQuery, GetDesignerQueryVariables>(
        {
          query: GetDesigner,
          variables: {
            id: currentSubscriber?.subscription?.designerId,
          },
        }
      );
      if (response.data.designerOne !== null) {
        setLoading(false);
        setDesigner(response?.data?.designerOne);
      }
    }
    return undefined;
  };

  useEffect(() => {
    setCurrentPageTitle("Help & Support");
    if (currentSubscriber?.subscription?.id !== undefined) {
      getDesigner();
    }
    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [currentSubscriber?.subscription?.id, setCurrentPageTitle]);

  const createSupportTicket = async (values: SupportTicketInput) => {
    if (currentSubscriber?.id) {
      showNotification({
        id: "create-support-ticket",
        loading: true,
        title: "Loading",
        message: "Attempting to create support ticket...",
        autoClose: false,
        disallowClose: true,
      });
      const response = await mutate<
        CreateSupportTicketMutation,
        CreateSupportTicketMutationVariables
      >({
        mutation: CreateSupportTicket,
        variables: {
          data: values,
          subscriberId: currentSubscriber?.id,
        },
      });
      if (response) {
        updateNotification({
          id: "create-support-ticket",
          color: response.data?.createSupportTicket ? "green" : "red",
          title: response.data?.createSupportTicket ? "Success!" : "Failure!",
          message: response.data?.createSupportTicket
            ? "Support Ticket Created!"
            : "An error occured while creating a support ticket...",
          icon: response.data?.createSupportTicket ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
      }
    }
  };
  if (currentSubscriber && !currentSubscriber.subscription) {
    return (
      <Text>
        You need to have an active subscription in order to create a support
        ticket
      </Text>
    );
  }
  return (
    <Container
      style={{ maxHeight: "100%", maxWidth: isMobile ? 600 : undefined }}
    >
      {currentSubscriber && designer && (
        <ScrollArea.Autosize
          maxHeight={
            "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )"
          }
          scrollbarSize={6}
        >
          <Stack spacing={0} style={{ marginBottom: 12 }}>
            <Center>
              <Image
                alt={"Designer Profile Picture"}
                withPlaceholder
                src={designer?.profilePicURL}
                radius={1000}
                style={{
                  maxHeight: "18vh",
                  width: "auto",
                  margin: "0 30px",
                  aspectRatio: "1",
                }}
                sx={{
                  ".mantine-Image-imageWrapper": {
                    aspectRatio: "1/1 !important",
                    ">img": {
                      opacity: designer?.profilePicURL ? 1 : 0,
                    },
                  },
                }}
              />
            </Center>
            <Text size={16} weight={500} align="center">
              {designer?.user?.firstName} {designer?.user?.lastName}
            </Text>
          </Stack>
          <Text size={14} align="left">
            {
              "Fill out the form below to send a support email to your subscribed designer."
            }
          </Text>
          <form
            onSubmit={form.onSubmit((values) => {
              if (currentSubscriber?.subscription?.designerId) {
                createSupportTicket({
                  ...values,
                  designer: { id: currentSubscriber?.subscription?.designerId },
                });
              }
            })}
          >
            <TextInput
              sx={{
                ".mantine-InputWrapper-label": {
                  color: toggleTheme.text,
                },
                ".mantine-Input-input": {
                  background: toggleTheme.card_bg,
                  color: toggleTheme.text,
                },
              }}
              style={{ marginTop: 12 }}
              label="Title"
              {...form.getInputProps("title")}
            />
            <Textarea
              sx={{
                ".mantine-InputWrapper-label": {
                  color: toggleTheme.text,
                },
                ".mantine-Input-input": {
                  background: toggleTheme.card_bg,
                  color: toggleTheme.text,
                },
              }}
              minRows={10}
              style={{ marginTop: 12 }}
              label="Body"
              {...form.getInputProps("body")}
            />
            <Group position="right" mt="md">
              <Button rightIcon={<MdSend size={18} />} type="submit">
                Send
              </Button>
            </Group>
          </form>
        </ScrollArea.Autosize>
      )}
      {loading && <Loader />}
    </Container>
  );
};

export default HelpSupport;
