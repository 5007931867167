import { Box, Button, Group, NumberInput, Text } from "@mantine/core";
import { useForm } from "@mantine/form";

type Props = {
  onSubmit: (cashPercentage: number) => void;
  opinionPercentage: number;
  cashPercentage: number;
};

const CashPositionForm = ({
  onSubmit,
  opinionPercentage,
  cashPercentage,
}: Props) => {

  const formUpdate = useForm({
    initialValues: {
      cashPercentage: cashPercentage || 0,
    },

    validate: {
      cashPercentage: (value) => {
        if (value !== null && value !== undefined) {
          if (opinionPercentage >= 100) {
            return "Account limit has been reached.";
          }
          if (value < 0) {
            return "Invalid Account Percentage.";
          }
          if (value + opinionPercentage > 100) {
            return `Account percentage cannot be greater than ${(
              100 - opinionPercentage
            ).toFixed(2)} %`;
          }
        } else {
          return "You must supply an Account Percentage.";
        }
      },
    },
  });
  return (
    <Box sx={{ maxWidth: 300 }} mx="auto">
      <form
        onSubmit={formUpdate.onSubmit((values) => {
          onSubmit(values.cashPercentage);
        })}
      >
        <Text>{`USD - Unallocated Cash`}</Text>
        <NumberInput
          style={{ marginTop: 12 }}
          decimalSeparator="."
          precision={2}
          label="Account Percentage"
          //value={opinion?.accountPercentage}
          {...formUpdate.getInputProps("cashPercentage")}
        />
        <Group position="right" mt="md">
          <Button type="submit">Update</Button>
        </Group>
      </form>
    </Box>
  );
};

export default CashPositionForm;
