import { Button, Card, Center, Divider, Group, Spoiler, Text, Title, useMantineTheme } from "@mantine/core";
import React, { useContext } from "react"
import { MdChevronRight } from "react-icons/md";
import { AppContext } from "src/api/AppContext";
import { GetAccountOneQuery } from "src/generated/graphql";
import { currencyFormatter } from "src/utils/formatting";
import { useTogglableTheme } from "src/utils/theme";
import { useNavigate } from "react-router-dom";
import { AccountData } from "..";

type Props = {
  account: AccountData;
  id: string;
}

const StrategySection = ({account, id}: Props) => {
  const navigate = useNavigate();
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);

  const onStrategyApplyClick = () => {
    navigate(`/strategy/account/${id}`);
  };

  return (
    <div>
      <Group position="apart">
          <Text weight={500} size={18} style={{ marginBottom: -3 }}>
            {"Applied Strategy"}
          </Text>
          <Button
            style={{
              marginTop: 2,
              border: "none",
              padding: 0,
              marginBottom: -8,
            }}
            rightIcon={<MdChevronRight size={20} style={{ marginLeft: -10 }} />}
            variant="outline"
            compact
            radius="xl"
            size="sm"
            onClick={onStrategyApplyClick}
          >
            {account?.strategy ? `Change Strategy` : `Select Strategy`}
          </Button>
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />

        {account?.strategy == null || undefined ? (
          <Center style={{ marginTop: "20px" }}>
            <Text color={"dimmed"}>{`No strategy currently applied`}</Text>
          </Center>
        ) : (
          <React.Fragment>
            <Card
              style={{
                margin: 4,
                backgroundColor: toggleTheme.card_bg,
                color: toggleTheme.text,
                borderRadius: 8,
                marginBottom: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              radius="lg"
            >
              <Group position="apart">
                <div>
                  <Title size={13} weight={500}>
                    {"Name"}
                  </Title>
                  <Text>{account.strategy.name}</Text>
                </div>
                <div>
                  <Title size={13} weight={500}>
                    {"Min. Investment"}
                  </Title>
                  <Text align="right">
                    {currencyFormatter.format(
                      (account.strategy.cashMin || 0) +
                        account.strategy.cashPercentage
                    )}
                  </Text>
                </div>
              </Group>
              <Group position="apart" style={{ marginTop: 10 }}>
                <div>
                  <Title size={13} weight={500}>
                    {"Risk Level"}
                  </Title>
                  <Text>{account.strategy?.riskLevel}</Text>
                </div>

                <div>
                  <Title size={13} weight={500}>
                    {"Duration"}
                  </Title>
                  <Text>{account.strategy.termDuration}</Text>
                </div>
              </Group>
              <Spoiler
                maxHeight={1}
                showLabel={`Description . .`}
                hideLabel="Hide"
                style={{ marginTop: 10 }}
                sx={{
                  "button.mantine-Spoiler-control": {
                    fontSize: 13,
                    fontWeight: 500,
                  },
                }}
              >
                <div>
                  <Title size={13} weight={500}>
                    {"Description"}
                  </Title>
                  <Text>{account.strategy.description}</Text>
                </div>
              </Spoiler>
            </Card>
          </React.Fragment>
        )}
    </div>
  )
};

export default StrategySection;