import React, { useContext, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  Brush,
  Label,
  ResponsiveContainer,
  AreaChart,
  Line,
  Area,
} from "recharts";
import { useApolloClient } from "@apollo/client";
import { AppContext } from "src/api/AppContext";
import {
  GetAccountManyQuery,
  GetAccountManyQueryVariables,
  GetSubscriberAccountReportQuery,
  GetSubscriberAccountReportQueryVariables,
} from "src/generated/graphql";
import { GetSubscriberAccountReport } from "src/api/graphql/queries/SubscriberReports";
import {
  Card,
  Divider,
  Group,
  ScrollArea,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { GetAccountMany } from "src/api/graphql/queries/Account";
import { useMediaQuery } from "@mantine/hooks";
import { useTogglableTheme } from "src/utils/theme";
import { showNotification, updateNotification } from "@mantine/notifications";
import { MdCancel, MdCheck } from "react-icons/md";

export type ReportData = NonNullable<
  GetSubscriberAccountReportQuery["getSubscriberAccountReport"]
>;
type AccountData = NonNullable<GetAccountManyQuery["accountMany"]>;

const SubscriberReport = () => {
  const { query } = useApolloClient();
  const [reportData, setReportData] = useState<ReportData | undefined>();
  const [accounts, setAccounts] = useState<AccountData | undefined>();
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState<string | null>(
    null
  );
  const { currentSubscriber, isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const desktopView = useMediaQuery("(min-width: 550px)");
  const sideBar = useMediaQuery("(min-width: 990px)");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getSubscriberReport = async (
    subscriberId: string,
    accountId: string,
    timeRange: string
  ) => {
    try {
      showNotification({
        id: "get-subscriber-report",
        loading: true,
        title: "Loading",
        message: "Getting Report Data...",
        autoClose: false,
        disallowClose: true,
      });
      let response = await query<
        GetSubscriberAccountReportQuery,
        GetSubscriberAccountReportQueryVariables
      >({
        query: GetSubscriberAccountReport,
        variables: {
          accountId,
          subscriberId,
          timeRange: timeRange,
        },
        fetchPolicy: "no-cache",
      });
      if (
        response &&
        response.data !== null &&
        response.data.getSubscriberAccountReport
      ) {
        updateNotification({
          id: "get-subscriber-report",
          color: "green",
          title: "Success!",
          message: "Successfully retrieved report data!",
          icon: <MdCheck size={16} />,
          autoClose: 2000,
        });
        setReportData(response.data.getSubscriberAccountReport);
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      updateNotification({
        id: "get-subscriber-report",
        color: "red",
        title: "Failure!",
        message: message || "Unable to get the report!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };

  const getAccounts = async () => {
    const response = await query<
      GetAccountManyQuery,
      GetAccountManyQueryVariables
    >({
      query: GetAccountMany,
      variables: {
        subscriberId: currentSubscriber?.id,
      },
    });
    if (response.data.accountMany.length > 0) {
      setAccounts(response.data.accountMany);
      if (response.data.accountMany.length > 0) {
        setSelectedAccountId(response.data.accountMany[0].id);
      }
    }
  };

  useEffect(() => {
    getAccounts();
    if (timeRanges && timeRanges.length > 0)
      setSelectedTimeRange(timeRanges[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      currentSubscriber &&
      currentSubscriber.id &&
      selectedAccountId &&
      selectedTimeRange
    ) {
      getSubscriberReport(
        currentSubscriber.id,
        selectedAccountId,
        selectedTimeRange
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubscriber, selectedAccountId, selectedTimeRange]);

  return (
    <ScrollArea
      style={{
        height:
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )",
      }}
      sx={{
        ".mantine-ScrollArea-viewport > div": {
          display: "block !important",
        },
      }}
    >
      <div style={{ padding: "1.5% 3%" }}>
        <Group position="apart">
          <Text weight={500} size={18} style={{ marginBottom: -3 }}>
            {"Overview"}
          </Text>
        </Group>

        <Divider style={{ marginBottom: 5, marginTop: 2 }} />

        <Card
          style={{
            margin: 4,
            backgroundColor: toggleTheme.card_bg,
            color: toggleTheme.text,
            borderRadius: 8,
            marginBottom: 10,
          }}
        >
          <Group
            spacing={desktopView ? 120 : 0}
            position={desktopView ? "left" : "apart"}
          >
            <Stack>
              <div>
                <Title size={13} weight={500}>
                  {"Net Worth"}
                </Title>
                <Text>{formatter.format(reportData?.netWorth || 0)}</Text>
              </div>
            </Stack>
            <Stack>
              <div>
                <Title size={13} weight={500}>
                  {"Number Of Accounts"}
                </Title>
                <Text>{reportData?.numberOfAccounts}</Text>
              </div>
            </Stack>
          </Group>
        </Card>
      </div>

      <div style={{ padding: "1.5% 3%" }}>
        <Group position="apart">
          <Text weight={500} size={18} style={{ maxWidth: "50%" }}>
            {"Account Analysis"}
          </Text>
          <Select
            //label="Account"
            style={{ maxWidth: "50%" }}
            sx={{
              ".mantine-Input-input": {
                fontWeight: 500,
                fontSize: 16,
                padding: 4,
                minHeight: 0,
                height: 24,
                color: theme.colors.greenMachine[6],
                background: "none",
                border: "1px solid",
              },
              ".mantine-Input-rightSection": {
                color: theme.colors.greenMachine[6],
              },
            }}
            variant="filled"
            placeholder="Select Account"
            onChange={(accountId) => {
              setSelectedAccountId(accountId);
            }}
            value={selectedAccountId}
            data={
              accounts
                ? accounts.map((account) => ({
                    value: account.id,
                    label: account.mask
                      ? account.custodian.name + " - #" + account.mask
                      : account.name,
                  }))
                : []
            }
          />
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />

        <Card
          style={{
            margin: 4,
            backgroundColor: toggleTheme.card_bg,
            color: toggleTheme.text,
            borderRadius: 8,
          }}
        >
          <Stack spacing={desktopView ? 24 : 8}>
            <Group
              spacing={desktopView ? 120 : 0}
              position={desktopView ? "left" : "apart"}
              style={{ marginLeft: desktopView ? 60 : 0 }}
            >
              <Stack>
                <div>
                  <Title size={13} weight={500}>
                    {"Account Total"}
                  </Title>
                  <Text>
                    {formatter.format(
                      reportData?.values[reportData?.values.length - 1]
                        ?.totalValue || 0
                    )}
                  </Text>
                </div>
              </Stack>
              <Stack>
                <div>
                  <Title size={13} weight={500}>
                    {"Lifetime Growth"}
                  </Title>
                  <Text>
                    {formatter.format(
                      reportData?.values[reportData?.values.length - 1]
                        ?.lifetimeDelta || 0
                    )}
                  </Text>
                </div>
              </Stack>
            </Group>
            <div
              style={{
                position: "relative",
                // width: `calc(100vw - ${
                //   desktopView ? (sideBar ? 300 : 120) : 60
                // }px - 32px)`,
                paddingBottom: "350px",
              }}
            >
              <SegmentedControl
                style={{
                  marginLeft: desktopView ? 63 : 0,
                  background: "none",
                  color: toggleTheme.text,
                }}
                sx={{
                  ".mantine-SegmentedControl-active": {
                    background: toggleTheme.card_disabled_bg,
                  },
                  ".mantine-SegmentedControl-label": {
                    color: toggleTheme.text,
                  },
                }}
                onChange={setSelectedTimeRange}
                value={selectedTimeRange || undefined}
                fullWidth
                data={timeRanges}
              />
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  top: 32,
                }}
              >
                <ResponsiveContainer height={350}>
                  <AreaChart
                    height={500}
                    data={reportData?.values || []}
                    margin={{ top: 5, right: 0, left: 5, bottom: 5 }}
                  >
                    <Label>Account Value</Label>
                    <CartesianGrid
                      strokeDasharray="1 9"
                      stroke={toggleTheme.text}
                    />
                    <XAxis
                      height={60}
                      textAnchor="end"
                      angle={-40}
                      dataKey={"createdDate"}
                      tick={{
                        stroke: toggleTheme.text,
                        strokeWidth: 0.4,
                      }}
                    ></XAxis>
                    <YAxis
                      tick={{
                        stroke: toggleTheme.text,
                        strokeWidth: 0.4,
                      }}
                      width={desktopView ? undefined : 0}
                      domain={["dataMin - 2000", "dataMax + 2000"]}
                      label={{
                        angle: -90,
                        position: "insideLeft",
                      }}
                      tickFormatter={(value, index) => {
                        return formatter
                          .format(value || 0)
                          .replace(/\.[0-9]{2}/, "");
                      }}
                    />
                    <Tooltip
                      cursor={{ fill: "transparent" }}
                      contentStyle={{
                        borderRadius: 4,
                        background: "rgba(255,255,255,0.7)",
                        fontWeight: 500,
                        border: "none",
                        padding: "4px 10px",
                      }}
                      labelFormatter={(label, payload) => {
                        return (
                          <Stack spacing={0} style={{ marginBottom: -8 }}>
                            <Text>
                              {formatter.format(payload[0]?.payload.totalValue)}
                            </Text>
                            <Text>{payload[0]?.payload.createdDate}</Text>
                          </Stack>
                        );
                      }}
                      formatter={(value, name, item, index, payload) => {
                        return [];
                      }}
                    />
                    <ReferenceLine y={0} stroke="#000" />
                    <Brush
                      dataKey="name"
                      height={20}
                      fill={toggleTheme.card_bg}
                      stroke={toggleTheme.primary}
                      tickFormatter={(value, index) => {
                        return reportData?.values[index].createdDate || index;
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="totalValue"
                      stroke="#82ca9d"
                      fillOpacity={0.4}
                      fill="#82ca9d"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Stack>
        </Card>
      </div>
    </ScrollArea>
  );
};

export default SubscriberReport;

const timeRanges = [
  { value: "CurrentQuarter", label: "Quarter" },
  { value: "YTD", label: "YTD" },
  { value: "OneYear", label: "1Y" },
  { value: "TwoYear", label: "2Y" },
  { value: "FiveYear", label: "5Y" },
  { value: "AllTime", label: "Max" },
];
