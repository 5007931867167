import { Container, Text, Center, Button, Card } from "@mantine/core";
import { useContext } from "react";
import { ApolloClientContext } from "src/api/ApolloClientContext";
import { ffLogo } from "../../../assets";

const DesignerLinkPrompt = () => {
  const{logout} = useContext(ApolloClientContext);
  return (
    <Container>
      <div style={{marginTop: "2vh"}}>
        <Center>
          <img
            src={ffLogo}
            style={{ height: "100%" }}
            alt="Freedom Folios Logo"
          />
        </Center>
      </div>
      <Card
        style={{
          marginTop: "22vh",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
          paddingTop: "10%",
          paddingBottom: "10%",
        }}
        shadow="sm"
        radius="md"
        withBorder
      >
        <div style={{ marginBottom: "5vh" }}>
          <Text size="lg" align="center">
            To use this app you need a link from a designer's website.
          </Text>
        </div>
        <Center>
          <Button
            size="md"
            onClick={() => logout()}
            style={{
              border: "none",
              filter: "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25))",
            }}
          >
            Sign Out
          </Button>
        </Center>
      </Card>
      <div style={{ marginTop: "22vh" }}>
        <Text size="sm" align="center" color="dimmed">
          Copyright © 2022 Freedom Folios LLC.
        </Text>
      </div>
    </Container>
  );
};

export default DesignerLinkPrompt;
