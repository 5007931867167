import { useApolloClient } from "@apollo/client";
import {
  ActionIcon,
  Button,
  Group,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { MdCheckCircle, MdCancel, MdAdd, MdDelete, MdCheck } from "react-icons/md";
import { FetchParams, KapexTable, QueryResponse } from "@kapex/tables";
import { GetDesignerPage } from "src/api/graphql/queries/Designer";

import {
  DeleteDesignerMutation,
  DeleteDesignerMutationVariables,
  GetDesignerPageQuery,
  GetDesignerPageQueryVariables,
  UserRole,
} from "src/generated/graphql";
import { UserData } from "../User";
import { useModals } from "@mantine/modals";
import CreateDesignerForm from "./components/CreateDesignerForm";
import { useContext } from "react";
import { AppContext } from "src/api/AppContext";
import DesignerDetails from "./components/DesignerDetails";
import { useTogglableTheme } from "src/utils/theme";
import { DeleteDesigner } from "src/api/graphql/mutations/Designer";
import { showNotification, updateNotification } from "@mantine/notifications";

type DesignerData = {
  id: string;
  enabled: boolean;
  user: UserData;
  subscribers: { id: string }[];
};

const Designer = () => {
  const { currentUser, isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { query, mutate } = useApolloClient();
  const modals = useModals();

  const getDesigner = async (
    fetchParams: FetchParams
  ): Promise<QueryResponse<DesignerData>> => {
    let response = await query<
      GetDesignerPageQuery,
      GetDesignerPageQueryVariables
    >({
      query: GetDesignerPage,
      variables: {
        pagination: {
          ...fetchParams,
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.designerPage) {
      return {
        rowCount: response.data.designerPage.totalRows,
        rowData: response.data.designerPage.items as DesignerData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  };

  const deleteDesigner = async (id: string) => {
    try {
      showNotification({
        id: "delete-designer",
        loading: true,
        title: "Loading",
        message: "Attempting to delete a designer...",
        autoClose: false,
        disallowClose: true,
      });

      let response = await mutate<
        DeleteDesignerMutation,
        DeleteDesignerMutationVariables
      >({
        mutation: DeleteDesigner,
        variables: {
          deleteDesignerId: id
        },
        fetchPolicy: "no-cache",
      });

      updateNotification({
        id: "delete-designer",
        color: response.data ? "green" : "red",
        title: response.data ? "Success!" : "Failure!",
        message: response.data
          ? "Designer deleted!"
          : "An error occured while updating the designer...",
        icon: response.data ? (
          <MdCheck size={16} />
        ) : (
          <MdCancel size={16} />
        ),
        autoClose: 2000,
      });
      if (response.data) {
        window.location.reload();
      }
    }
    catch (e) {
      updateNotification({
        id: "delete-designer",
        color: "red",
        title: "Failure!",
        message: "Unable to delete the designer!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  }

  return (
    <div>
      <Group position="apart">
        <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
          {"Designers"}
        </Text>
        <Button
          disabled={currentUser?.role !== UserRole.PlatformAdmin}
          leftIcon={<MdAdd size={24} />}
          radius={"xl"}
          compact
          style={{ fontSize: 16 }}
          onClick={() => {
            modals.openModal({
              styles: {
                title: {
                  fontWeight: 500,
                  fontSize: 20,
                },
              },
              title: "Create Designer",
              size: "min(400px, 80vw)",
              children: (
                <CreateDesignerForm
                  onSubmit={() => {
                    modals.closeAll();
                    window.location.reload();
                  }}
                />
              ),
            });
          }}
        >
          New Designer
        </Button>
      </Group>
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 40px)) - 48px )"
        }
        style={{
          minHeight: 300,
          marginBottom: 8,
        }}
        type="auto"
        scrollbarSize={9}
        sx={(theme) => ({
          ".mantine-ScrollArea-root": {
            borderRadius: 4,
            paddingRight: 1,
          },
          ".mantine-ScrollArea-viewport": {
            borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
          },
          ".mantine-ScrollArea-scrollbar": {
            background: theme.colors.dark[4],
            ".mantine-ScrollArea-thumb": {
              backgroundColor: theme.colors.greenMachine[6],
            },
          },
        })}
      >
        <KapexTable<DesignerData>
          tableSx={{
            thead: {
              th: {
                ">div": {
                  color: "#fff",
                },
              },
              "::after": {
                left: "-1px",
              },
            },
            tbody: {
              tr: {
                td: {
                  borderColor: toggleTheme.card_disabled_bg,
                  ">div": {
                    color: toggleTheme.text,
                  },
                },
              },
              "tr:hover": {
                backgroundColor: `${toggleTheme.card_disabled_bg} !important`,
              },
            },
            tfoot: {
              td: {
                div: {
                  color: "#fff",
                },
              },
            },
          }}
          headerColor={{ r: 255, g: 255, b: 255 }}
          headerBg={theme.colors.greenMachine[6]}
          borderColor={theme.colors.greenMachine[8]}
          columnConfigs={[
            {
              name: "Name",
              filter: {
                type: "text",
                field: "name",
              },
              sortable: true,
              cellRenderer: (rowItem) => {
                return (
                  <Text
                    sx={{
                      fontWeight: 500,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      modals.openModal({
                        styles: {
                          title: {
                            fontWeight: 500,
                            fontSize: 20,
                          },
                          body: {
                            padding: 16,
                          },
                        },
                        title: "Designer Details",
                        size: "min(500px, 80vw)",
                        overflow: "inside",
                        children: (
                          <DesignerDetails
                            designerId={rowItem.id}
                            onSubmit={() => {
                              modals.closeAll();
                              window.location.reload();
                            }}
                          />
                        ),
                        onClose: () => {
                          window.location.reload();
                        },
                      });
                    }}
                  >
                    {`${rowItem.user?.firstName} ${rowItem.user?.lastName}`}
                  </Text>
                );
              },
            },
            {
              field: "email",
              parentRelationField: "user",
              name: "Email",
              filter: {
                field: "email",
                parentRelationField: "user",
                type: "text",
              },
              sortable: true,
            },
            {
              field: "phoneNumber",
              parentRelationField: "user",
              name: "Phone",
              filter: {
                field: "phoneNumber",
                parentRelationField: "user",
                type: "text",
              },
              sortable: true,
              valueParser: (rowItem) => {
                if (!rowItem.user) {
                  return "N/A";
                }
                let num = rowItem.user.phoneNumber;
                if (!num) {
                  return "N/A";
                }
                let len = num.length;
                if (len >= 10) {
                  return `(${num.slice(len - 10, len - 7)}) ${num.slice(
                    len - 7,
                    len - 4
                  )}-${num.slice(len - 4)}`;
                } else {
                  return "N/A";
                }
              },
            },
            {
              field: "createdDate",
              parentRelationField: "user",
              name: "Created",
              filter: {
                field: "createdDate",
                parentRelationField: "user",
                type: "dateRange",
              },
              sortable: true,
              valueParser: (rowItem) => {
                return rowItem.user
                  ? new Date(rowItem.user.createdDate).toDateString()
                  : "N/A";
              },
            },
            {
              field: "enabled",
              name: "Enabled",
              sortable: true,
              cellRenderer: (rowItem) => (
                <Group style={{ alignItems: "center" }}>
                  {rowItem.enabled ? (
                    <MdCheckCircle color={theme.colors.green[5]} size={16} />
                  ) : (
                    <MdCancel
                      color={theme.colors.red[5]}
                      size={16}
                      style={{ lineHeight: 16 }}
                    />
                  )}
                  <Text style={{ fontWeight: 500 }}>
                    {rowItem.enabled ? "Enabled" : "Disabled"}
                  </Text>
                </Group>
              ),
            },
            {
              field: "",
              name: "# of Subscribers",
              sortable: false,
              valueParser: (rowItem) => {
                return `${rowItem.subscribers?.length || 0}`;
              },
            },
            {
              field: "",
              name: "",
              cellRenderer: (rowItem) => (
                <ActionIcon
                  onClick={() => modals.openConfirmModal({
                    styles: {
                      title: {
                        fontWeight: 500,
                        fontSize: 20,
                      },
                    },
                    title: "Delete Designer",
                    size: "min(400px, 80vw)",
                    children: (
                      <Text>
                        Are you sure you want to delete this designer?
                      </Text>
                    ),
                    labels: { confirm: "Continue", cancel: "Cancel" },
                    cancelProps: {
                    },
                    confirmProps: {
                      color: theme.colors.greenMachine[7],
                    },
                    onConfirm: async () => {
                      deleteDesigner(rowItem.id);
                    }
                  })}
                  style={{
                    position: "initial",
                  }}
                >
                  <MdDelete color={`${theme.colors.red[6]}`} size={23} />
                </ActionIcon>
              ),
            },
          ]}
          dataFetch={getDesigner}
        />
      </ScrollArea.Autosize>
    </div>
  );
};

export default Designer;
