import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  navbar: {
    padding: '0px !important',
    // Media query with value from theme
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      marginLeft: '0 !important'
    },
  },
}));