import { Box, Button, Group, NumberInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import StockSearch from "./StockSearch";

type OpinionData = {
  id?: string;
  createdDate?: any;
  updatedDate?: any;
  longName?: string;
  deletedDate?: any;
  seedId?: string | null;
  strategyId?: string | null;
  stockDataId?: string | null;
  accountPercentage?: number;
  stockData: {
    id?: string;
    createdDate?: any;
    updatedDate?: any;
    deletedDate?: any;
    seedId?: string | null;
    price?: number;
    symbol?: string;
    shortName?: string;
    longName?: string;
  };
};

type Props = {
  isEdit?: boolean;
  onSubmit: (values: any, opinionId?: string) => Promise<void>;
  opinion?: OpinionData;
  strategyId: string;
  opinionPercentage: number;
};

const OpinionForm = ({
  isEdit,
  onSubmit,
  opinion,
  strategyId,
  opinionPercentage,
}: Props) => {
  const form = useForm({
    initialValues: {
      stockData:
        {
          id: opinion?.stockData.id,
        } || undefined,
      accountPercentage: opinion?.accountPercentage || undefined,
      strategy: { id: strategyId },
    },

    validate: {
      accountPercentage: (value) => {
        if (value) {
          if (opinionPercentage === 100) {
            return "Account limit has reached.";
          }
          if (value < 0) {
            return "Invalid Account Percentage.";
          }
          if (value + opinionPercentage > 100) {
            return `Account percentage cannot be greater than ${(
              100 - opinionPercentage
            ).toFixed(2)} %`;
          }
        } else {
          return "You must supply an Account Percentage.";
        }
      },
    },
  });

  const formUpdate = useForm({
    initialValues: {
      stockData:
        {
          id: opinion?.stockData.id,
        } || undefined,
      accountPercentage: opinion?.accountPercentage || undefined,
    },

    validate: {
      accountPercentage: (value) => {
        if (value) {
          if (opinionPercentage === 0) {
            return "Account limit has reached.";
          }
          if (value < 0) {
            return "Invalid Account Percentage.";
          }
          if (value + opinionPercentage > 100) {
            return `Account percentage cannot be greater than ${(
              100 - opinionPercentage
            ).toFixed(2)} %`;
          }
        } else {
          return "You must supply an Account Percentage.";
        }
      },
    },
  });
  return (
    <Box sx={{ maxWidth: 300 }} mx="auto">
      {isEdit ? (
        <form
          onSubmit={formUpdate.onSubmit((values) => {
            onSubmit(values, opinion?.id);
          })}
        >
          <StockSearch
            form={formUpdate}
            propName="stockData"
            initialValue={`${opinion?.stockData.symbol} - ${opinion?.stockData.shortName}`}
          />
          <NumberInput
            style={{ marginTop: 12 }}
            decimalSeparator="."
            precision={2}
            label="Account Percentage"
            //value={opinion?.accountPercentage}
            {...formUpdate.getInputProps("accountPercentage")}
          />
          <Group position="right" mt="md">
            <Button type="submit">Update</Button>
          </Group>
        </form>
      ) : (
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <StockSearch form={form} propName="stockData" />
          <NumberInput
            style={{ marginTop: 12 }}
            decimalSeparator="."
            precision={2}
            label="Account Percentage"
            {...form.getInputProps("accountPercentage")}
          />
          <Group position="right" mt="md">
            <Button type="submit">Create</Button>
          </Group>
        </form>
      )}
    </Box>
  );
};

export default OpinionForm;
