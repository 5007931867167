import { gql } from "@apollo/client";

export const UpdateUser = gql`
  mutation UpdateUser($id: String!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
      smsConsent
    }
  }
`;

export const DeleteUser = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;

export const AcceptTAC = gql`
  mutation AcceptTAC {
    acceptTAC
  }
`;
