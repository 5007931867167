import { useApolloClient } from "@apollo/client";
import { FetchParams, KapexTable, QueryResponse } from "@kapex/tables";
import {
  Button,
  Group,
  ScrollArea,
  Stack,
  Text,
  TypographyStylesProvider,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { useCallback, useContext, useEffect, useState } from "react";
import { MdAddCircle, MdEdit } from "react-icons/md";
import { GetQuarterlyNewsletter } from "src/api/graphql/queries/QuarterlyNewsletter";
import {
  DesignerNewsletterPageQuery,
  DesignerNewsletterPageQueryVariables,
  GetDesignerNewsletterQuery,
  GetDesignerNewsletterQueryVariables,
  GetQuarterlyNewsletterQuery,
  GetQuarterlyNewsletterQueryVariables,
} from "src/generated/graphql";
import CreateNewsletterForm from "./components/CreateNewsletterForm";
import {
  DesignerNewsletterPage,
  GetDesignerNewsletter,
} from "src/api/graphql/queries/DesignerNewsletter";
import { AppContext } from "src/api/AppContext";
import { GetDesigner } from "src/api/graphql/queries/Designer";

type DesignerNewsletterData = {
  id: string;
  updatedDate: Date;
  header: string;
  body: string;
  quarter: number;
  year: number;
  designerId: string;
};

export type QuarterlyNewsletterData = {
  id: string;
  updatedDate: Date;
  header: string;
  body: string;
  quarter: number;
  year: number;
};

const NewsletterDesignerView = () => {
  const modals = useModals();
  const { query, mutate } = useApolloClient();
  const { currentDesigner } = useContext(AppContext);
  const theme = useMantineTheme();
  const scrolling = useMediaQuery("(max-height: 862px)");
  const [refetchTableData, setRefetchTableData] = useState(false);
  const [currentQuarter, setCurrentQuarter] = useState<
    QuarterlyNewsletterData | undefined
  >(undefined);

  const [currentDesignerNewsletter, setCurrentDesignerNewsletter] = useState<
    DesignerNewsletterData | undefined
  >(undefined);

  useEffect(() => {
    if (!currentQuarter) {
      getCurrentQuarter();
    }
    if (!currentDesignerNewsletter) {
      getCurrentDesignerNewsletter();
    }
  }, []);

  const getCurrentDesignerNewsletter = async () => {
    let response = await query<
      GetDesignerNewsletterQuery,
      GetDesignerNewsletterQueryVariables
    >({
      query: GetDesignerNewsletter,
      fetchPolicy: "no-cache",
    });

    if (response.data.designerNewsletter) {
      setCurrentDesignerNewsletter(response.data.designerNewsletter);
    }
  };

  const getCurrentQuarter = async () => {
    let response = await query<
      GetQuarterlyNewsletterQuery,
      GetQuarterlyNewsletterQueryVariables
    >({
      query: GetQuarterlyNewsletter,
      fetchPolicy: "no-cache",
    });

    if (response.data.quarterlyNewsletter) {
      setCurrentQuarter(response.data.quarterlyNewsletter);
    }
  };

  const getDesignerNewsletters = useCallback(async (
    fetchParams: FetchParams
  ): Promise<QueryResponse<DesignerNewsletterData>> => {
    let response = await query<
      DesignerNewsletterPageQuery,
      DesignerNewsletterPageQueryVariables
    >({
      query: DesignerNewsletterPage,
      variables: {
        pagination: {
          ...fetchParams,
          filters: [
            ...(fetchParams.filters || []),
            {
              field: "designerId",
              type: "text",
              value: currentDesigner?.id,
            },
          ],
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.designerNewsletterPage) {
      return {
        rowCount: response.data.designerNewsletterPage.totalRows,
        rowData: response.data.designerNewsletterPage
          .items as DesignerNewsletterData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTableData]);

  const GetDesignerNewsLetter = useCallback(() => {
    return (<ScrollArea.Autosize
      maxHeight={
        "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px)) - 40px)"
      }
      style={{
        minHeight: 300,
      }}
      type="auto"
      scrollbarSize={9}
      sx={(theme) => ({
        ".mantine-ScrollArea-root": {
          borderRadius: 4,
          paddingRight: 1,
        },
        ".mantine-ScrollArea-viewport": {
          borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
        },
        ".mantine-ScrollArea-scrollbar": {
          background: theme.colors.dark[4],
          ".mantine-ScrollArea-thumb": {
            backgroundColor: theme.colors.greenMachine[6],
          },
        },
      })}
    >
      <KapexTable<DesignerNewsletterData>
        tableSx={{
          thead: {
            th: {
              ">div": {
                color: "#fff",
              },
            },
          },
          tbody: {
            "tr:hover": {
              backgroundColor: `${theme.colors.greenMachine[1]} !important`,
            },
          },
          tfoot: {
            td: {
              borderRight: "none",
              div: {
                color: "#fff",
              },
            },
          },
        }}
        headerColor={{ r: 255, g: 255, b: 255 }}
        headerBg={theme.colors.greenMachine[6]}
        borderColor={theme.colors.greenMachine[8]}
        columnConfigs={[
          {
            field: "header",
            name: "Subject",
            filter: {
              type: "text",
              field: "header",
            },
            cellRenderer: (rowItem) => {
              return (
                <Text
                  sx={{
                    fontWeight: 500,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    modals.openModal({
                      styles: {
                        title: {
                          fontWeight: 500,
                          fontSize: 20,
                        },
                        body: {
                          minHeight: 200,
                        },
                      },
                      title: "View Newsletter",
                      size: "min(800px, 80vw)",
                      children: (
                        <div>
                          <Text
                            size={18}
                            weight={500}
                            style={{ marginBottom: 16 }}
                          >
                            {rowItem.header}
                          </Text>
                          <TypographyStylesProvider>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: rowItem.body,
                              }}
                            />
                          </TypographyStylesProvider>
                        </div>
                      ),
                    });
                  }}
                >
                  {`${rowItem.header}`}
                </Text>
              );
            },
          },
          {
            field: "quarter",
            name: "Quarter",
            filter: {
              field: "quarter",
              type: "number",
            },
          },
          {
            field: "year",
            name: "Year",
            filter: {
              field: "year",
              type: "number",
            },
          },
          {
            field: "updatedDate",
            name: "Sent On",
            filter: {
              field: "updatedDate",
              type: "dateRange",
            },
            valueParser: (rowItem) => {
              return rowItem.updatedDate
                ? new Date(rowItem.updatedDate).toDateString()
                : "N/A";
            },
          },
        ]}
        dataFetch={getDesignerNewsletters}
      />
    </ScrollArea.Autosize>)
  }, [refetchTableData])

  return (
    <div style={{ width: "100%", marginRight: scrolling ? 30 : 0 }}>
      <Group position="apart" style={{ marginBottom: 4 }}>
        <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
          {"Sent Newsletters"}
        </Text>
        <Button
          //disabled={!currentQuarter}
          radius={"xl"}
          compact
          onClick={() => {
            modals.openModal({
              styles: {
                title: {
                  fontWeight: 500,
                  fontSize: 20,
                },
              },
              title: "Send a Newsletter",
              size: "min(600px, 80vw)",
              children: currentQuarter ? (
                <div>
                  <Text>{`There is a Freedom Folios approved newsletter for this quarter. Would you like to start with this template or craft your own custom mass email?`}</Text>
                  <Stack
                    align={"center"}
                    spacing={"xl"}
                    style={{ marginTop: 32 }}
                  >
                    <Button
                      variant="outline"
                      onClick={() =>
                        modals.openModal({
                          styles: {
                            title: {
                              fontWeight: 500,
                              fontSize: 20,
                            },
                          },
                          title: "Send a Newsletter",
                          size: "min(600px, 80vw)",
                          children: (
                            <CreateNewsletterForm
                              initial={currentQuarter}
                              refresh={() => { setRefetchTableData(!refetchTableData); }}
                              onClose={() => {
                                modals.closeAll();
                              }}
                            />
                          ),
                        })
                      }
                      style={{ fontSize: 16 }}
                    >{`Use the template`}</Button>
                    <Button
                      onClick={() =>
                        modals.openModal({
                          styles: {
                            title: {
                              fontWeight: 500,
                              fontSize: 20,
                            },
                          },
                          title: "Send a Newsletter",
                          size: "min(600px, 80vw)",
                          children: (
                            <CreateNewsletterForm
                              refresh={() => { setRefetchTableData(!refetchTableData); }}
                              onClose={() => {
                                modals.closeAll();

                              }}
                            />
                          ),
                        })
                      }
                      style={{ fontSize: 16 }}
                    >{`Draft my own`}</Button>
                  </Stack>
                </div>
              ) : (
                <CreateNewsletterForm
                  refresh={() => { setRefetchTableData(!refetchTableData); }}
                  onClose={() => {
                    modals.closeAll();
                  }}
                />
              ),
            });
          }}
          leftIcon={<MdEdit />}
          size="lg"
        >
          Draft a Newsletter
        </Button>
      </Group>
      <GetDesignerNewsLetter />
    </div>
  );
};

export default NewsletterDesignerView;
