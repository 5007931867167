import { useApolloClient } from "@apollo/client";
import {
  Group,
  useMantineTheme,
  Text,
  ActionIcon,
  Divider,
  TextInput,
  Button,
  Stack,
  Title,
  Select,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useContext, useState } from "react";
import { MdCancel, MdCheck, MdEdit } from "react-icons/md";
import { AppContext } from "src/api/AppContext";
import { UpdatePreferredSupportContact } from "src/api/graphql/mutations/Designer";
import {
  PreferredSupportContact,
  UpdatePreferredSupportContactInput,
  UpdatePreferredSupportContactMutation,
  UpdatePreferredSupportContactMutationVariables,
} from "src/generated/graphql";

type Props = {
  onSave: () => void;
};
const SupportContact = ({ onSave }: Props) => {
  const theme = useMantineTheme();
  const { mutate } = useApolloClient();
  const { currentDesigner } = useContext(AppContext);
  const [editing, setEditing] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      supportEmail: currentDesigner?.supportEmail,
      supportPhone: currentDesigner?.supportPhone,
      preferredSupportContact:
        currentDesigner?.preferredSupportContact ||
        PreferredSupportContact.Both,
    },

    validate: {
      supportEmail: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a first name!",
      supportPhone: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a last name!",
    },
  });

  const updatePreferredSupportContact = async (
    values: UpdatePreferredSupportContactInput
  ) => {
    if (currentDesigner?.id) {
      showNotification({
        id: "update-support-contact",
        loading: true,
        title: "Loading",
        message: "Attempting to update support contact...",
        autoClose: false,
        disallowClose: true,
      });
      const response = await mutate<
        UpdatePreferredSupportContactMutation,
        UpdatePreferredSupportContactMutationVariables
      >({
        mutation: UpdatePreferredSupportContact,
        variables: {
          data: values,
          designerId: currentDesigner?.id,
        },
      });

      if (response.data?.updatePreferredSupportContact) {
        onSave();
        updateNotification({
          id: "update-support-contact",
          color: response.data?.updatePreferredSupportContact ? "green" : "red",
          title: response.data?.updatePreferredSupportContact
            ? "Success!"
            : "Failure!",
          message: response.data?.updatePreferredSupportContact
            ? "Support contact details updated!"
            : "An error occured while updating the support contact...",
          icon: response.data?.updatePreferredSupportContact ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <div
      style={{
        boxShadow: editing
          ? `0px 0px 4px 0px ${theme.colors.gray[5]}`
          : undefined,
        backgroundColor: editing ? theme.colors.gray[0] : undefined,
        borderRadius: 8,
        padding: 10,
        margin: "3px 0px",
        marginBottom: 10,
      }}
    >
      <Group position="apart">
        <Text weight={500} size={20}>
          {"Support Contact Info"}
        </Text>
        {!editing && (
          <ActionIcon
            onClick={() => {
              form.setValues({
                supportEmail: currentDesigner?.supportEmail,
                supportPhone: currentDesigner?.supportPhone,
                preferredSupportContact:
                  currentDesigner?.preferredSupportContact,
              });
              setEditing(true);
            }}
          >
            <MdEdit />
          </ActionIcon>
        )}
      </Group>
      <Divider style={{ marginBottom: 5 }} />
      {editing ? (
        <form
          onSubmit={form.onSubmit((values) => {
            setEditing(false);
            updatePreferredSupportContact(values);
          })}
          onReset={() => setEditing(false)}
        >
          <TextInput
            style={{ marginTop: 12 }}
            label="Support Email"
            {...form.getInputProps("supportEmail")}
          />
          <TextInput
            style={{ marginTop: 12 }}
            label="Support Phone"
            {...form.getInputProps("supportPhone")}
          />
          <Select
            label="Preferred Mode Of Support Contact"
            placeholder="Select"
            data={[
              {
                value: PreferredSupportContact.Email,
                label: PreferredSupportContact.Email,
              },
              {
                value: PreferredSupportContact.Phone,
                label: PreferredSupportContact.Phone,
              },
              {
                value: PreferredSupportContact.Both,
                label: PreferredSupportContact.Both,
              },
            ]}
            {...form.getInputProps("preferredSupportContact")}
          />
          <Group position="right" mt="md">
            <Button type="reset">Cancel</Button>
            <Button type="submit">Save</Button>
          </Group>
        </form>
      ) : (
        <Stack align="flex-start">
          <div>
            <Title size={14} weight={500}>
              {"Email"}
            </Title>
            <Text style={{ marginLeft: "8px" }}>
              {currentDesigner?.supportEmail}
            </Text>
          </div>
          <div>
            <Title size={14} weight={500}>
              {"Phone"}
            </Title>
            <Text style={{ marginLeft: "8px" }}>
              {currentDesigner?.supportPhone}
            </Text>
          </div>
          <div>
            <Title size={14} weight={500}>
              {"Preferred Mode Of Support Contact"}
            </Title>
            <Text style={{ marginLeft: "8px" }}>
              {currentDesigner?.preferredSupportContact}
            </Text>
          </div>
        </Stack>
      )}
    </div>
  );
};

export default SupportContact;
