import { gql } from "@apollo/client";

export const GetSubscriberPage = gql`
  query GetSubscriberPage($pagination: PaginationInput!) {
    subscriberPage(pagination: $pagination) {
      items {
        id
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          createdDate
        }
        designer{
          user{
            id
            firstName
            lastName
          }
        }
      }
      totalRows
    }
  }
`;

export const GetSubscriber = gql`
  query GetSubscriber($id: String) {
    subscriberOne(id: $id) {
      id
      trialAvailable
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        createdDate
        smsConsent
      }
      subscription {
        id
        designerId
        renewalDate
        createdDate
        renewalFrequency
        cost
        cancelled
        trial
        swapRequest {
          id
          designerId
        }
        status
        isExpired
      }
      custodians {
        id
        name
        userTag
        plaidInstitutionId
        isActive
        reAuthRequired
      }
      designer {
        id
        user {
          id
          firstName
          lastName
        }
        strategies {
          id
          name
          description
          cashMin
          riskLevel
          termDuration
          opinions {
            stockData {
              id
              price
              symbol
              shortName
              longName
            }
            accountPercentage
          }
        }
      }
      customerPaymentMethods {
        card {
          brand
          country
          exp_month
          exp_year
          fingerprint
          last4
        }
      }
    }
  }
`;
