import { gql } from "@apollo/client";

export const GetAdminDesignerReport = gql`
query GetAdminDesignerReport($designerId: String!) {
  getAdminDesignerReport(designerId: $designerId) {
    noOfSubscribers
    noOfStrategies
    totalAmount
    strategies {
      name
      riskLevel
      termDuration
    }
  }
}
`;


export const GetAdminReport = gql`
query GetAdminReport {
  getAdminReport {
    numberOfAccounts
    averageValueOfAccounts
    noOfActiveDesigners
    designers {
      id
      userId
      enabled
      profilePicURL
      user {
        firstName
        lastName
      }
    }
  }
}
`;