import { useApolloClient } from "@apollo/client";
import { Center, Loader } from "@mantine/core";
import { FC, useContext, useEffect } from "react";
import { AppContext } from "src/api/AppContext";
import { AcceptTAC } from "src/api/graphql/mutations/User";
import {
  GetStripeOnboardingLink,
  GetStripeAccount,
} from "src/api/graphql/queries/Designer";
import {
  AcceptTacMutation,
  AcceptTacMutationVariables,
  GetStripeAccountQuery,
  GetStripeAccountQueryVariables,
  GetStripeAccountLinkQuery,
  GetStripeAccountLinkQueryVariables,
} from "src/generated/graphql";
import TermsAndConditions from "../../components/TermsAndConditions";

const DesignerOnboarding: FC = () => {
  const { query, mutate } = useApolloClient();
  const { currentUser, refetchUserData } = useContext(AppContext);

  useEffect(() => {
    if (currentUser?.acceptedTAC) {
      checkStripeOnboarding();
    }
  }, [currentUser?.acceptedTAC]);

  const checkStripeOnboarding = async () => {
    let response = await query<
      GetStripeAccountQuery,
      GetStripeAccountQueryVariables
    >({
      query: GetStripeAccount,
      fetchPolicy: "no-cache",
    });

    if (response.data.getStripeAccount) {
      refetchUserData();
    } else {
      connectAccount();
    }
  };

  const connectAccount = async () => {
    let response = await query<
      GetStripeAccountLinkQuery,
      GetStripeAccountLinkQueryVariables
    >({
      query: GetStripeOnboardingLink,
      fetchPolicy: "no-cache",
    });

    if (response.data.getAccountOnboardingLink) {
      window.location.replace(response.data.getAccountOnboardingLink);
    }
  };

  const handleCheckTerms = async () => {
    try {
      const response = await mutate<
        AcceptTacMutation,
        AcceptTacMutationVariables
      >({ mutation: AcceptTAC });

      if (response.data?.acceptTAC) {
        refetchUserData();
      }
    } catch (err) {
      throw err;
    }
  };

  const renderNextStep = () => {
    if (!currentUser?.acceptedTAC) {
      return (
        <TermsAndConditions
          handleCheckTerms={handleCheckTerms}
        ></TermsAndConditions>
      );
    }

    return (
      <Center style={{ width: "100%", height: "100%" }}>
        <Loader />
      </Center>
    );
  };

  return renderNextStep();
};

export default DesignerOnboarding;
