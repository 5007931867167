import { Stack, Title, Text, Group } from "@mantine/core";
import { StrategyData } from "..";

type Props = {
  strategy: StrategyData | undefined;
};
const StrategyInfo = ({ strategy }: Props) => {
  return (
    <div>
      <div
        style={{
          borderRadius: 8,
          padding: 10,
          margin: "3px 0px",
          marginBottom: 10,
        }}
      >
        <Group position="apart">
          <Group position="apart" style={{ width: "100%", maxWidth: 455 }}>
            <Stack style={{ marginTop: 10 }} align="flex-start">
              <div style={{ minWidth: 200 }}>
                <Title size={14} weight={500}>
                  {"Name"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>{strategy?.name}</Text>
              </div>
            </Stack>
            <Stack style={{ marginTop: 10 }} align="flex-start">
              <div style={{ minWidth: 200 }}>
                <Title size={14} weight={500}>
                  {"Sync Threshold"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>
                  {strategy?.syncThreshold}
                </Text>
              </div>
            </Stack>
          </Group>
          <Group position="apart" style={{ width: "100%", maxWidth: 455 }}>
            <Stack style={{ marginTop: 10 }} align="flex-start">
              <div style={{ minWidth: 200 }}>
                <Title size={14} weight={500}>
                  {"Risk Level"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>{strategy?.riskLevel}</Text>
              </div>
            </Stack>
            <Stack style={{ marginTop: 10 }} align="flex-start">
              <div style={{ minWidth: 200 }}>
                <Title size={14} weight={500}>
                  {"Term Duration"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>
                  {strategy?.termDuration}
                </Text>
              </div>
            </Stack>
          </Group>
          <Group position="apart" style={{ width: "100%", maxWidth: 455 }}>
            <Stack style={{ marginTop: 10, }} align="flex-start">
              <div>
                <Title size={14} weight={500}>
                  {"Description"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>{strategy?.description}</Text>
              </div>
            </Stack>
            <Stack style={{ marginTop: 10 }} align="flex-start">
              <div style={{ minWidth: 200 }}>
                <Title size={14} weight={500}>
                  {"Designer"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>
                  {strategy?.designer?.user ? `${strategy.designer.user?.firstName} ${strategy.designer.user?.lastName}` : ""}
                </Text>
              </div>
            </Stack>
          </Group>

        </Group>
      </div>
    </div>
  );
};

export default StrategyInfo;
