import { gql } from "@apollo/client";

export const NewsletterTemplatePage = gql`
  query NewsletterTemplatePage($pagination: PaginationInput!) {
    newsletterTemplatePage(pagination: $pagination) {
      items {
        id
        updatedDate
        header
        body
      }
      totalRows
    }
  }
`;

export const GetNewsletterTemplate = gql`
  query GetNewsletterTemplate($newsletterTemplateOneId: String!) {
    newsletterTemplateOne(id: $newsletterTemplateOneId) {
      id
      createdDate
      seedId
      header
      body
    }
  }
`;
