import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/api/AppContext";
import { isMobile } from "react-device-detect";
import {
  Badge,
  Card,
  Center,
  Container,
  Group,
  ScrollArea,
  Stack,
  Image,
  Text,
  Loader,
  Button,
  useMantineTheme,
  Alert,
} from "@mantine/core";
import { useApolloClient } from "@apollo/client";
import {
  CancelSubscriptionRenewalMutation,
  CancelSubscriptionRenewalMutationVariables,
  GetDesignerQuery,
  GetDesignerQueryVariables,
} from "src/generated/graphql";
import { GetDesigner } from "src/api/graphql/queries/Designer";
import { useNavigate } from "react-router-dom";
import { CancelSubscriptionRenewal } from "src/api/graphql/mutations/Subscription";
import { useModals } from "@mantine/modals";
import { useTogglableTheme } from "src/utils/theme";
import { MdCircleNotifications } from "react-icons/md";

export type DesignerData = NonNullable<GetDesignerQuery["designerOne"]>;

const SubscriptionDetails = () => {
  const {
    currentSubscriber,
    setCurrentPageTitle,
    refetchUserData,
    isDarkMode,
  } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const navigate = useNavigate();
  const { query, mutate } = useApolloClient();
  const [designer, setDesigner] = useState<DesignerData>();
  const [loading, setLoading] = useState<boolean>(true);

  const modals = useModals();

  const getDesigner = async () => {
    if (currentSubscriber?.subscription !== null) {
      const response = await query<GetDesignerQuery, GetDesignerQueryVariables>(
        {
          query: GetDesigner,
          variables: {
            id: currentSubscriber?.subscription?.designerId,
          },
        }
      );
      if (response.data.designerOne !== null) {
        setDesigner(response?.data?.designerOne);
      }
    }
    return undefined;
  };

  useEffect(() => {
    setCurrentPageTitle("Subscription Details");
    if (currentSubscriber?.subscription?.id !== undefined) {
      getDesigner();
      setLoading(false);
    }
    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [setCurrentPageTitle, currentSubscriber, query]);

  const cancelRenewal = async () => {
    setLoading(true);
    if (currentSubscriber?.subscription !== null) {
      const response = await mutate<
        CancelSubscriptionRenewalMutation,
        CancelSubscriptionRenewalMutationVariables
      >({
        mutation: CancelSubscriptionRenewal,
        fetchPolicy: "no-cache",
      });
      if (response.data?.cancelCurrentSubscription) {
        refetchUserData();
        setLoading(false);
      }
    }
    return undefined;
  };

  if (
    currentSubscriber &&
    currentSubscriber?.subscription?.id === undefined &&
    !loading
  ) {
    return (
      <Center>
        <Badge color="#fff">
          <Text align="center" color="#1b1b1b" size="lg">
            No Subscription Found
          </Text>
        </Badge>
      </Center>
    );
  }

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  let isExpired =
    new Date(currentSubscriber?.subscription?.renewalDate).valueOf() <
    new Date().valueOf();
  let isCancelled = currentSubscriber?.subscription?.cancelled;
  let isTrial = currentSubscriber?.subscription?.trial;
  return (
    <Container
      style={{ maxHeight: "100%", maxWidth: isMobile ? 600 : undefined }}
    >
      <ScrollArea.Autosize
        maxHeight={
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )"
        }
        scrollbarSize={6}
      >
        <Stack spacing={0}>
          <Card
            style={{
              padding: "12px 10px",
              paddingTop: 4,
              background: toggleTheme.card_bg,
              color: toggleTheme.text,
              borderRadius: 8,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            radius="md"
            //withBorder
          >
            <Text align="center" size={18} weight={500}>
              Subscription Status
            </Text>
            <Group position="center" style={{ rowGap: 4 }}>
              <Stack spacing={0}>
                <Text align="center" weight={500} size={14}>
                  Status
                </Text>
                <Badge
                  color={isCancelled || isExpired ? "red" : "green"}
                  size="md"
                  variant="filled"
                >
                  {isExpired
                    ? "Expired"
                    : isTrial
                    ? "Trial"
                    : isCancelled
                    ? "Cancelled"
                    : "Active"}
                </Badge>
              </Stack>
              <Stack spacing={0}>
                <Text align="center" weight={500} size={14}>
                  Start Date
                </Text>
                <Badge color="green" size="md" variant="outline">
                  {new Date(
                    currentSubscriber?.subscription?.createdDate
                  ).toDateString()}
                </Badge>
              </Stack>
              <Stack spacing={0}>
                <Text align="center" weight={500} size={14}>
                  {isTrial
                    ? "Trial Ends"
                    : isCancelled
                    ? "Subscription Expires"
                    : "Renewal Date"}
                </Text>
                <Badge color="red" size="md" variant="outline">
                  {new Date(
                    currentSubscriber?.subscription?.renewalDate
                  ).toDateString()}
                </Badge>
              </Stack>
            </Group>
          </Card>

          <Card
            onClick={() => {
              navigate(`/designer/${designer?.id}`);
            }}
            style={{
              // boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
              padding: "12px 10px",
              paddingTop: 4,
              cursor: "pointer",
              color: toggleTheme.text,
              background: toggleTheme.card_bg,
              borderRadius: 0,
              marginTop: -1,
            }}
            //radius="md"
            //withBorder
          >
            <Text align="center" size={18} weight={500}>
              Your Designer
            </Text>

            <Stack spacing={4}>
              <Center>
                <Image
                  alt={"Designer Profile Picture"}
                  withPlaceholder
                  src={designer?.profilePicURL}
                  radius={1000}
                  style={{
                    maxHeight: "25vh",
                    width: "auto",
                    margin: "0 30px",
                    aspectRatio: "1",
                  }}
                  sx={{
                    ".mantine-Image-imageWrapper": {
                      aspectRatio: "1/1 !important",
                      ">img": {
                        opacity: designer?.profilePicURL ? 1 : 0,
                      },
                    },
                  }}
                />
              </Center>
              <Stack spacing={0}>
                <Text size={18} weight={500} align="center">
                  {designer?.user?.firstName} {designer?.user?.lastName}
                </Text>
                <Text weight={500} size={14} align="center">
                  {designer?.user?.email}
                </Text>
              </Stack>
            </Stack>
          </Card>
          <Card
            style={{
              padding: "12px 10px",
              paddingTop: 4,
              color: toggleTheme.text,
              background: toggleTheme.card_bg,
              borderRadius: 8,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              marginTop: -1,
            }}
          >
            <Text align="center" size={18} weight={500}>
              Subscription Details
            </Text>

            <Group position="apart">
              <Stack spacing={0}>
                <Text weight={500} size={13}>
                  Subscription Type
                </Text>
                <Text>
                  {isTrial
                    ? "Trial"
                    : currentSubscriber?.subscription?.renewalFrequency}
                </Text>
              </Stack>
              <Group>
                <Stack align="end" spacing={0}>
                  <Text size={13} weight={500}>
                    Cost
                  </Text>
                  <Text>
                    {isTrial
                      ? "N/A"
                      : `$${Number(
                          currentSubscriber?.subscription?.cost
                        ).toFixed(2)}`}
                  </Text>
                </Stack>
              </Group>
            </Group>
          </Card>
        </Stack>
        {!isTrial && !currentSubscriber?.subscription?.cancelled && (
          <Center>
            <Button
              disabled={isCancelled}
              onClick={() => {
                modals.openConfirmModal({
                  title: "Cancel Your Subscription",
                  centered: true,
                  children: (
                    <Text size="sm">
                      {`Are you certain you want to cancel your subscription's auto-renewal? You will still have access to paid features until your current subscription expires.`}
                    </Text>
                  ),
                  labels: {
                    confirm: "Cancel Subscription",
                    cancel: "Don't Cancel",
                  },
                  confirmProps: { color: "red" },
                  onConfirm: () => {
                    cancelRenewal();
                  },
                });
              }}
              style={{ marginTop: "16px", width: "80%", maxWidth: 300 }}
              compact={isMobile ? true : false}
              color="red"
              radius="xl"
              size="lg"
            >
              Cancel Subscription
            </Button>
          </Center>
        )}
        {currentSubscriber?.subscription?.isExpired && (
          <Alert
            style={{ marginTop: 10 }}
            icon={<MdCircleNotifications size={16} />}
            title={`${
              currentSubscriber.subscription.trial ? "Trial" : "Subscription"
            } Expired!`}
            color="red"
            onClick={() =>
              navigate(
                `/designer/${currentSubscriber.subscription?.designerId}`
              )
            }
          >
            <div>
              {`Your ${
                currentSubscriber.subscription.trial ? "trial" : "subscription"
              } period has expired, subscribe to continue using
            our services!`}
              <Text
                weight={500}
                style={{ marginTop: 4 }}
                color="red"
              >{`Click here to view subscription options`}</Text>
            </div>
          </Alert>
        )}
      </ScrollArea.Autosize>
    </Container>
  );
};

export default SubscriptionDetails;
