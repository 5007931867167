import { Center, Stack, Text } from "@mantine/core";
import AccountListItem from "./listItem";

type AccountsMigration = {
  id: string;
  name: string;
  externalId: string;
  totalValue?: number | null;
  isActive?: boolean | null;
  mask?: string | null;
};
type UpdatePlaidAccountsProps = {
  accounts: AccountsMigration[] | undefined;
};

const UpdatePlaidAccounts = ({ accounts }: UpdatePlaidAccountsProps) => {
  return (
    <Center>
      <Stack style={{ width: "100%" }}>
        <Text>
          {"Select an account below to begin strategizing with Freedom Folios."}
        </Text>
        <div style={{ width: "100%" }}>
          {accounts &&
            accounts.map((account, index) => {
              return (
                <AccountListItem
                  key={account.id + "_update_account_status"}
                  account={account}
                  index={index}
                  accounts={accounts}
                />
              );
            })}
        </div>
      </Stack>
    </Center>
  );
};

export default UpdatePlaidAccounts;
