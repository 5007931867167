import {
  Center,
  Container,
  ScrollArea,
  Title,
  Text,
  Checkbox,
  Button,
  Header as MantineHeader,
} from "@mantine/core";
import { ChangeEvent, useState } from "react";
import { ffLogo } from "src/assets";

interface Props {
  handleCheckTerms: () => void;
}
const TermsAndConditions = ({ handleCheckTerms }: Props) => {
  const [accepted, setAccepted] = useState<boolean>(false);

  const handleOnCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };
  return (
    <>
      <MantineHeader height={75} p="sm">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ height: "100%" }}>
            <img src={ffLogo} alt="Logo" style={{ height: "100%" }} />
          </div>
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              height: "100%",
              justifyContent: "center",
            }}
          ></div>
        </div>
      </MantineHeader>
      <Container size="md">
        <br />
        <Center>
          <Title order={4}>Terms & Conditions</Title>
        </Center>
        <br></br>
        <ScrollArea style={{ height: "50vh" }} type="auto">
          <div id="lipsum">
            <Text align="justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              eu rutrum tellus. Cras consectetur orci sed neque cursus, eu
              vehicula ante rutrum. Nulla tempor risus sit amet maximus
              vehicula. Nulla eget orci ac tortor iaculis dapibus in in magna.
              Praesent iaculis neque at ipsum ultricies mollis. Sed nunc lorem,
              ullamcorper et venenatis et, finibus at felis. Vivamus ligula
              lacus, molestie ac arcu ut, aliquet cursus nulla. Vivamus pulvinar
              ullamcorper enim, at ullamcorper dolor auctor quis. Praesent
              eleifend ligula et eros gravida, sed pretium erat sagittis.
              Vivamus placerat quis ante vel facilisis. Integer et nibh at est
              iaculis convallis. Nulla dolor sapien, ultricies commodo ante ut,
              semper condimentum orci. Ut eu lacinia leo. Cras euismod efficitur
              nisl, eu vulputate purus convallis sed.
            </Text>
            <Text align="justify">
              Suspendisse potenti. Nulla viverra faucibus nisl, sed dapibus
              mauris malesuada a. Quisque eget nunc felis. Praesent feugiat quam
              quis orci feugiat, sit amet vestibulum ex eleifend. Interdum et
              malesuada fames ac ante ipsum primis in faucibus. Vestibulum nulla
              enim, sodales et odio vel, ultricies porttitor leo. Praesent id
              mattis velit. Nam posuere auctor neque, tincidunt mollis quam
              rutrum a. Vestibulum et pellentesque nisl. Maecenas eleifend,
              felis ut scelerisque accumsan, augue neque imperdiet felis, id
              feugiat leo sem vitae orci. Pellentesque suscipit est quis quam
              imperdiet malesuada. Pellentesque malesuada, justo porta semper
              malesuada, nisi tellus vulputate ante, eu pharetra nisl turpis id
              enim. Interdum et malesuada fames ac ante ipsum primis in
              faucibus. Morbi ut arcu lobortis, ultrices leo vitae, ultrices
              dolor. Aliquam ipsum eros, faucibus eu tellus vitae, porttitor
              tempor ipsum.
            </Text>
            <Text align="justify">
              Maecenas volutpat varius porttitor. Quisque mollis odio nulla, ut
              feugiat leo blandit vel. Vestibulum ante ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia curae; Duis enim neque,
              tincidunt non odio ac, luctus dapibus nisi. Ut sagittis dolor
              eros, mattis sagittis odio consequat sit amet. In fringilla odio
              at consequat facilisis. Nunc non vehicula ligula, eget efficitur
              neque. Cras a justo pellentesque, consequat nulla eget, placerat
              tortor. Curabitur ac sem rutrum, vestibulum purus ac, posuere
              magna. Quisque cursus, neque a viverra cursus, nulla ligula
              egestas mauris, nec bibendum quam nulla vel lorem.
            </Text>
            <Text align="justify">
              Pellentesque nec viverra est, vitae finibus erat. Aenean sit amet
              gravida diam, et varius felis. Proin viverra aliquam turpis vel
              lobortis. Nulla pellentesque erat sapien, at gravida nulla
              lobortis sit amet. Ut suscipit eros iaculis ligula viverra
              euismod. In nec egestas tellus. Ut vehicula odio ut sapien auctor
              sollicitudin. Vestibulum auctor purus vitae maximus scelerisque.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              ultrices suscipit lorem at consectetur. Praesent ac diam egestas,
              mattis dolor ac, laoreet risus.
            </Text>
            <Text align="justify">
              Sed mi nisl, semper nec nulla vel, dapibus venenatis libero.
              Phasellus ac eros urna. Vestibulum ante ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia curae; Curabitur a
              ultricies mi. Cras eget nunc interdum, ultricies ipsum elementum,
              feugiat elit. Nulla facilisi. Vestibulum sapien purus, pretium non
              lacus fringilla, ultricies aliquam nisi. Sed egestas ligula eu
              sodales bibendum. Pellentesque habitant morbi tristique senectus
              et netus et malesuada fames ac turpis egestas.
            </Text>
          </div>
        </ScrollArea>
        <br></br>
        <br></br>
        <Checkbox
          onChange={handleOnCheck}
          label="I accept the terms & conditions"
          required
        />
        <br></br>
        <br></br>
        {accepted && <Button onClick={handleCheckTerms}>Submit</Button>}
      </Container>
    </>
  );
};

export default TermsAndConditions;
