import { gql } from "@apollo/client";

export const GetAccountPage = gql`
  query GetAccountPage($pagination: PaginationInput!) {
    accountPage(pagination: $pagination) {
      items {
        id
        createdDate
        name
        strategy {
          id
          name
        }
        subscriber {
          user {
            firstName
            lastName
          }
        }
        totalValue
      }
      totalRows
    }
  }
`;
export const GetAccountOne = gql`
  query GetAccountOne($accountOneId: String!) {
    accountOne(id: $accountOneId) {
      id
      externalId
      name
      unallocatedFunds
      cashReserve
      strategy {
        id
        designer {
          id
        }
        name
        description
        enabled
        riskLevel
        termDuration
        cashMin
        syncThreshold
        cashPercentage
      }
      positions {
        id
        selected
        stockData {
          id
          createdDate
          price
          symbol
          shortName
          longName
        }
        shares
        value
        unsupported
        institutionValue
        description
      }
      assessment {
        riskLevel
        termDuration
        id
      }
      custodian{
        id
        name
      }
      totalValue
      mask
      withinThreshold
      lastSynced
    }
  }
`;

export const GetAccountMany = gql`
  query GetAccountMany($subscriberId: String) {
    accountMany(subscriberId: $subscriberId) {
      id
      createdDate
      name
      externalId
      strategy {
        id
        name
      }
      totalValue
      custodian {
        id
        name
        userTag
        plaidInstitutionId
        reAuthRequired
        isActive
      }
      withinThreshold
      mask
      lastSynced
      cashReserve
      isActive
    }
  }
`;

export const GetCustodianAccounts = gql`
query GetCustodianAccounts($custodianId: String) {
  getCustodianAccounts(custodianId: $custodianId) {
    id
    externalId
    name
    mask
    isActive
    totalValue
  }
}
`;
export const GenerateTradeToSync = gql`
  query GenerateTradeToSync($generateTradesToSyncId: String!, $amount: Float) {
    generateTradesToSync(id: $generateTradesToSyncId, amount: $amount) {
      operation
      shares
      stockData {
        id
        symbol
        shortName
        price
      }
    }
  }
`;

export const GenerateAllocateCashTrades = gql`
  query GenerateAllocateCashTrades(
    $generateTradesToSyncId: String!
    $amount: Float!
  ) {
    generateAllocateCashTrades(id: $generateTradesToSyncId, amount: $amount) {
      operation
      shares
      stockData {
        id
        symbol
        shortName
        price
      }
    }
  }
`;

export const GenerateDeallocateCashTrades = gql`
  query GenerateDeallocateCashTrades(
    $generateTradesToSyncId: String!
    $amount: Float!
  ) {
    generateTradesToDeallocate(id: $generateTradesToSyncId, amount: $amount) {
      operation
      shares
      stockData {
        id
        symbol
        shortName
        price
      }
    }
  }
`;

export const GetPlaidLinkToken = gql`
  query GetPlaidLinkToken {
    getPlaidLinkToken
  }
`;

export const GetPlaidUpdateLinkToken = gql`
  query GetPlaidUpdateLinkToken($custodianId: String!) {
    getPlaidUpdateLinkToken(custodianId: $custodianId)
  }
`;
