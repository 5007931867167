import { useApolloClient } from "@apollo/client";
import {
  Button,
  Group,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useContext } from "react";
import { MdCancel, MdCheck } from "react-icons/md";
import { AppContext } from "src/api/AppContext";
import { CreateStrategy } from "src/api/graphql/mutations/Strategy";
import {
  CreateStrategyInput,
  CreateStrategyMutation,
  CreateStrategyMutationVariables,
  RiskLevel,
  TermDuration,
} from "src/generated/graphql";
interface CreateStrategyFormProps {
  onClose: () => void;
}
const CreateStrategyForm = (props: CreateStrategyFormProps) => {
  const { currentDesigner } = useContext(AppContext);
  const { mutate } = useApolloClient();
  const form = useForm({
    initialValues: {
      name: "" as string,
      riskLevel: RiskLevel.Aggressive as RiskLevel,
      termDuration: TermDuration.ShortTerm as TermDuration,
      description: undefined as string | undefined,
      syncThreshold: undefined as number | undefined,
    },

    validate: {
      name: (value: string) =>
        value !== undefined && value !== "" ? null : "You must supply a Name!",
      riskLevel: (value: RiskLevel) =>
        value !== undefined ? null : "You must supply a Risk Level!",
      termDuration: (value: TermDuration) =>
        value !== undefined ? null : "You must supply a Term Duration!",
    },
  });

  const createStrategy = async (
    values: Omit<CreateStrategyInput, "designer">
  ) => {
    if (!currentDesigner) {
      console.log(
        "Missing designer in local context. This value is required to create a new strategy."
      );
      return;
    }
    showNotification({
      id: "create-strategy",
      loading: true,
      title: "Loading",
      message: "Attempting to create strategy...",
      autoClose: false,
      disallowClose: true,
    });
    const response = await mutate<
      CreateStrategyMutation,
      CreateStrategyMutationVariables
    >({
      mutation: CreateStrategy,
      variables: {
        data: {
          ...values,
          designer: {
            id: currentDesigner.id,
          },
        },
      },
    });
    if (response) {
      updateNotification({
        id: "create-strategy",
        color: response.data?.createStrategy ? "green" : "red",
        title: response.data?.createStrategy ? "Success!" : "Failure!",
        message: response.data?.createStrategy
          ? "Strategy Updated Successfully"
          : "An error occured during updating strategy...",
        icon: response.data?.createStrategy ? (
          <MdCheck size={16} />
        ) : (
          <MdCancel size={16} />
        ),
        autoClose: 2000,
      });

      if (response.data?.createStrategy) {
        props.onClose();
      }
    }
  };
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        createStrategy(values);
      })}
      onReset={() => props.onClose()}
    >
      <TextInput
        style={{ marginTop: 12 }}
        label="Name"
        {...form.getInputProps("name")}
      />
      <Textarea
        style={{ marginTop: 12 }}
        minRows={3}
        label="Description"
        {...form.getInputProps("description")}
      />
      <Select
        label="Risk Level"
        placeholder="Select"
        data={[
          { value: RiskLevel.Aggressive, label: RiskLevel.Aggressive },
          {
            value: RiskLevel.Conservative,
            label: RiskLevel.Conservative,
          },
          { value: RiskLevel.Moderate, label: RiskLevel.Moderate },
        ]}
        {...form.getInputProps("riskLevel")}
      />
      <Select
        label="Term Duration"
        placeholder="Select"
        data={[
          { value: TermDuration.Growth, label: TermDuration.Growth },
          { value: TermDuration.Income, label: TermDuration.Income },
          {
            value: TermDuration.ShortTerm,
            label: TermDuration.ShortTerm,
          },
        ]}
        {...form.getInputProps("termDuration")}
      />
      <NumberInput
        label="Sync Threshold"
        placeholder={"Percentage Value"}
        {...form.getInputProps("syncThreshold")}
      />

      <Group position="right" mt="md">
        <Button type="reset">Cancel</Button>
        <Button type="submit">Save</Button>
      </Group>
    </form>
  );
};

export default CreateStrategyForm;
