import { gql } from "@apollo/client";

export const CreateSupportTicket = gql`
  mutation CreateSupportTicket(
    $data: SupportTicketInput!
    $subscriberId: String!
  ) {
    createSupportTicket(data: $data, subscriberId: $subscriberId) {
      id
    }
  }
`;
