import { gql } from "@apollo/client";

export const CreateRevenueShare = gql`
  mutation CreateRevenueShare($data: CreateRevenueShareInput!) {
    createRevenueShare(data: $data) {
      id
      designerId
      designerFee
      serviceFee
      designerFeeAnnual
      serviceFeeAnnual
      designer {
        id
        user {
          id
          cognitoId
          role
          firstName
          lastName
          email
          phoneNumber
        }
        profilePicURL
        bio
        enabled
      }
    }
  }
`;

export const UpdateRevenueShare = gql`
  mutation UpdateRevenueShare($id: String!, $data: UpdateRevenueShareInput!) {
    updateRevenueShare(id: $id, data: $data) {
      id
      designerId
      designerFee
      serviceFee
      designer {
        id
        user {
          id
          cognitoId
          role
          firstName
          lastName
          email
          phoneNumber
        }
        profilePicURL
        bio
        enabled
      }
    }
  }
`;
