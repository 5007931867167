import { useApolloClient } from "@apollo/client";
import { Loader } from "@mantine/core";
import { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "src/api/AppContext";
import { AcceptTAC } from "src/api/graphql/mutations/User";
import { GetSubscriber } from "src/api/graphql/queries/Subscriber";
import {
  AcceptTacMutation,
  AcceptTacMutationVariables,
  GetSubscriberQuery,
  GetSubscriberQueryVariables,
} from "src/generated/graphql";
import TermsAndConditions from "../../components/TermsAndConditions";

type SubscriberData = NonNullable<GetSubscriberQuery["subscriberOne"]>;

const SubscriberOnboarding: FC = () => {
  const { query, mutate } = useApolloClient();
  const { currentUser, refetchUserData } = useContext(AppContext);

  const [subscriber, setSubscriber] = useState<SubscriberData | undefined>(
    undefined
  );

  useEffect(() => {
    getSubscriber();
  }, []);

  const getSubscriber = async () => {
    let response = await query<GetSubscriberQuery, GetSubscriberQueryVariables>(
      {
        query: GetSubscriber,
        fetchPolicy: "no-cache",
      }
    );

    if (response.data.subscriberOne) {
      setSubscriber(response.data.subscriberOne);
    }
  };

  const handleCheckTerms = async () => {
    try {
      const response = await mutate<
        AcceptTacMutation,
        AcceptTacMutationVariables
      >({ mutation: AcceptTAC });

      if (response.data?.acceptTAC) {
        refetchUserData();
      }
    } catch (err) {
      throw err;
    }
  };

  const renderNextStep = () => {
    if (!currentUser?.acceptedTAC) {
      return (
        <TermsAndConditions
          handleCheckTerms={handleCheckTerms}
        ></TermsAndConditions>
      );
    }
    return (
      <div>
        <Loader />{" "}
      </div>
    );
  };

  return renderNextStep();
};

export default SubscriberOnboarding;
