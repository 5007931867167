import { useApolloClient } from "@apollo/client";
import {
  Group,
  useMantineTheme,
  Text,
  Divider,
  Card,
  ScrollArea,
  ActionIcon,
  Stack,
  Center,
  Button,
  Title,
  Switch,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useModals } from "@mantine/modals";
import { showNotification, updateNotification } from "@mantine/notifications";
import React, { useContext, useEffect, useState } from "react";
import {
  MdAdd,
  MdCancel,
  MdCheck,
  MdChevronLeft,
  MdDelete,
  MdEdit,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "src/api/AppContext";
import {
  CreateOpinion,
  DeleteOpinion,
  UpdateOpinion,
} from "src/api/graphql/mutations/Opinion";
import { UpdateStrategy } from "src/api/graphql/mutations/Strategy";
import { GetStrategy } from "src/api/graphql/queries/Strategy";
import {
  CreateOpinionInput,
  CreateOpinionMutation,
  CreateOpinionMutationVariables,
  DeleteOpinionMutation,
  DeleteOpinionMutationVariables,
  GetStrategyQuery,
  GetStrategyQueryVariables,
  RiskLevel,
  TermDuration,
  UpdateOpinionInput,
  UpdateOpinionMutation,
  UpdateOpinionMutationVariables,
  UpdateStrategyInput,
  UpdateStrategyMutation,
  UpdateStrategyMutationVariables,
} from "src/generated/graphql";
import { currencyFormatter } from "src/utils/formatting";
import { useTogglableTheme } from "src/utils/theme";
import CashPositionForm from "./components/CashPositionForm";
import OpinionForm from "./components/OpinionForm";
import StrategyInfo from "./components/StrategyInfo";
import StrategyUpdateForm from "./components/StrategyUpdateForm";

export type StrategyData = NonNullable<GetStrategyQuery["strategyOne"]>;

const StrategyDetails = () => {
  const { id } = useParams();
  const { query, mutate } = useApolloClient();
  const { currentDesigner, setCurrentPageTitle, isDarkMode } =
    useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const modals = useModals();
  const navigate = useNavigate();

  const [strategy, setStrategy] = useState<StrategyData | undefined>(undefined);
  const [editing, setEditing] = useState(false);

  const form = useForm({
    initialValues: {
      name: undefined as string | undefined,
      riskLevel: undefined as RiskLevel | undefined,
      termDuration: undefined as TermDuration | undefined,
      description: undefined as string | undefined,
      enabled: undefined as boolean | undefined,
      syncThreshold: undefined as number | undefined,
    },

    validate: {
      name: (value) =>
        value !== undefined && value !== "" ? null : "You must supply a Name!",
      riskLevel: (value) =>
        value !== undefined ? null : "You must supply a Risk Level!",
      termDuration: (value) =>
        value !== undefined ? null : "You must supply a Term Duration!",
    },
  });

  useEffect(() => {
    setCurrentPageTitle("Strategy Details");
    getStrategy();

    return () => {
      setStrategy(undefined);
      setCurrentPageTitle(undefined);
    };
  }, []);

  const getStrategy = async () => {
    if (!id) {
      console.log("Missing required strategyId to query details!");
      return;
    }
    const response = await query<GetStrategyQuery, GetStrategyQueryVariables>({
      query: GetStrategy,
      variables: {
        strategyOneId: id,
      },
    });
    if (response.data.strategyOne) {
      setStrategy(response.data.strategyOne);
      return strategy;
    }
  };

  const updateStrategy = async (values: UpdateStrategyInput) => {
    if (!strategy) {
      console.log("Missing required strategyId to update");
      return;
    }
    showNotification({
      id: "update-strategy",
      loading: true,
      title: "Loading",
      message: "Attempting to update strategy...",
      autoClose: false,
      disallowClose: true,
    });
    let totalPercentage = values.cashPercentage || 0;
    if (values.cashPercentage) {
      strategy.opinions.forEach((opinion) => {
        totalPercentage = parseFloat(
          (totalPercentage += opinion.accountPercentage).toFixed(2)
        );
      });
    }
    const response = await mutate<
      UpdateStrategyMutation,
      UpdateStrategyMutationVariables
    >({
      mutation: UpdateStrategy,
      variables: {
        data: values,
        updateStrategyId: strategy.id,
      },
    });
    if (response) {
      updateNotification({
        id: "update-strategy",
        color: response.data?.updateStrategy ? "green" : "red",
        title: response.data?.updateStrategy ? "Success!" : "Failure!",
        message: response.data?.updateStrategy
          ? "Strategy Updated Successfully"
          : "An error occured during updating strategy...",
        icon: response.data?.updateStrategy ? (
          <MdCheck size={16} />
        ) : (
          <MdCancel size={16} />
        ),
        autoClose: 2000,
      });
      getStrategy().then(() => {
        if (totalPercentage >= 100) {
          modals.openConfirmModal({
            title: "Strategy Fully Allocated",
            centered: true,
            children: (
              <Text size="sm">
                {`This strategy has reached 100% position opinion allocation. Would you like to enable it to be discovered by your subscribers?`}
              </Text>
            ),
            labels: { confirm: "Yes", cancel: "No" },
            cancelProps: {
              color: "#fa5252",
              style: {
                background: "#fa5252",
                color: "white",
              },
            },
            confirmProps: { color: theme.colors.greenMachine[7] },
            onCancel: () => console.log("Cancel"),
            onConfirm: () => {
              updateStrategy({ enabled: true });
              modals.closeAll();
            },
          });
        }
      });
    }
  };

  const showOpinionHelpPopup = (
    opinionId?: string,
    addedAccountPercentage?: number | undefined | null
  ) => {
    let opinonPercentage = calculateAccountPercentage(
      strategy,
      opinionId || null
    );
    if (addedAccountPercentage) {
      opinonPercentage += addedAccountPercentage;
    }

    if (opinonPercentage === 100) {
      modals.openConfirmModal({
        title: "Strategy Fully Allocated",
        centered: true,
        children: (
          <Text size="sm">
            {`This strategy has reached 100% position opinion allocation. Would you like to enable it to be discovered by your subscribers?`}
          </Text>
        ),
        labels: { confirm: "Yes", cancel: "No" },
        cancelProps: {
          color: "#fa5252",
          style: {
            background: "#fa5252",
            color: "white",
          },
        },
        confirmProps: { color: theme.colors.greenMachine[7] },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          updateStrategy({ enabled: true });
          modals.closeAll();
        },
      });
    }
  };

  const createOpinion = async (values: CreateOpinionInput) => {
    showNotification({
      id: "create-opinion",
      loading: true,
      title: "Loading",
      message: "Attempting to create a new opinion...",
      autoClose: false,
      disallowClose: true,
    });
    try {
      const response = await mutate<
        CreateOpinionMutation,
        CreateOpinionMutationVariables
      >({
        mutation: CreateOpinion,
        variables: { data: values },
      });
      if (response) {
        updateNotification({
          id: "create-opinion",
          color: response.data?.createOpinion ? "green" : "red",
          title: response.data?.createOpinion ? "Success!" : "Failure!",
          message: response.data?.createOpinion
            ? "Opinion Created Successfully"
            : "An error occured during opinion creation...",
          icon: response.data?.createOpinion ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
        if (response.data?.createOpinion) {
          modals.closeAll();
        }
        await getStrategy();
      }
      showOpinionHelpPopup(undefined, values?.accountPercentage);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);

      if (message.includes("A position already exists")) {
        updateNotification({
          id: "create-opinion",
          color: "red",
          title: "Failure!",
          message: "A position already exists for this stock on this strategy!",
          icon: <MdCancel size={16} />,
          autoClose: 2000,
        });
      }
    }
  };

  const updateOpinion = async (
    values: UpdateOpinionInput,
    updateOpinionId?: string
  ) => {
    if (!updateOpinionId) {
      console.log("Missing required opinionId to perform update!");
      return;
    }
    showNotification({
      id: "update-opinion",
      loading: true,
      title: "Loading",
      message: "Attempting to update opinion...",
      autoClose: false,
      disallowClose: true,
    });
    try {
      const response = await mutate<
        UpdateOpinionMutation,
        UpdateOpinionMutationVariables
      >({
        mutation: UpdateOpinion,
        variables: { updateOpinionId, data: values },
      });
      if (response) {
        getStrategy();
        updateNotification({
          id: "update-opinion",
          color: response.data?.updateOpinion ? "green" : "red",
          title: response.data?.updateOpinion ? "Success!" : "Failure!",
          message: response.data?.updateOpinion
            ? "Opinion Updated Successfully"
            : "An error occured during opinion updation...",
          icon: response.data?.updateOpinion ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
      }
      modals.closeAll();
      showOpinionHelpPopup(updateOpinionId, values?.accountPercentage);
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);

      if (message.includes("A position already exists")) {
        updateNotification({
          id: "update-opinion",
          color: "red",
          title: "Failure!",
          message: "A position already exists for this stock on this strategy!",
          icon: <MdCancel size={16} />,
          autoClose: 2000,
        });
      }
    }
  };

  const deleteOpinion = async (deleteOpinionId: string) => {
    showNotification({
      id: "delete-opinion",
      loading: true,
      title: "Loading",
      message: "Attempting to delete opinion...",
      autoClose: false,
      disallowClose: true,
    });
    const response = await mutate<
      DeleteOpinionMutation,
      DeleteOpinionMutationVariables
    >({
      mutation: DeleteOpinion,
      variables: { deleteOpinionId: deleteOpinionId },
    });
    if (response) {
      updateNotification({
        id: "delete-opinion",
        color: response.data?.deleteOpinion ? "green" : "red",
        title: response.data?.deleteOpinion ? "Success!" : "Failure!",
        message: response.data?.deleteOpinion
          ? "Opinion Updated Successfully"
          : "An error occured during opinion updation...",
        icon: response.data?.deleteOpinion ? (
          <MdCheck size={16} />
        ) : (
          <MdCancel size={16} />
        ),
        autoClose: 2000,
      });
      getStrategy();
    }
  };

  const calculateAccountPercentage = (
    strategy: StrategyData | undefined,
    opinionId: string | null
  ): number => {
    let total = strategy?.cashPercentage || 0.0;
    if (strategy && strategy.opinions) {
      strategy.opinions.forEach((opinion) => {
        if (!opinionId || opinion.id !== opinionId) {
          total = parseFloat((total += opinion.accountPercentage).toFixed(2));
        }
      });
    }
    return total;
  };

  let fullyAllocated =
    strategy?.opinions
      .map((op) => op.accountPercentage)
      .reduce((a, b) => a + b, strategy.cashPercentage || 0) == 100;

  return (
    <ScrollArea.Autosize
      maxHeight={
        "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 40px)))"
      }
      type="auto"
      scrollbarSize={9}
    >
      <Button
        variant="outline"
        onClick={() => navigate(-1)}
        size="xs"
        style={{
          fontSize: 16,
          lineHeight: "16px",
          color: theme.colors.greenMachine[7],
          border: "none",
          paddingLeft: 0,
        }}
      >
        <MdChevronLeft size={22} /> Back
      </Button>
      <Center>
        <div style={{ maxWidth: 950 }}>
          {currentDesigner && (
            <>
              <Group position="apart">
                <Text weight={600} size={20}>
                  {"Strategy Details"}
                </Text>
                {!editing && currentDesigner?.id === strategy?.designerId && (
                  <ActionIcon
                    onClick={() => {
                      form.setValues({
                        name: strategy?.name || undefined,
                        riskLevel: strategy?.riskLevel,
                        termDuration: strategy?.termDuration,
                        description: strategy?.description || undefined,
                        enabled: strategy?.enabled,
                        syncThreshold: strategy?.syncThreshold,
                      });
                      setEditing(true);
                    }}
                  >
                    <MdEdit size={20} color={theme.colors.greenMachine[6]} />
                  </ActionIcon>
                )}
              </Group>
              <Divider style={{ marginBottom: 5 }} />
            </>
          )}
          {!editing ? (
            <StrategyInfo strategy={strategy} />
          ) : (
            <StrategyUpdateForm
              form={form}
              setEditing={setEditing}
              updateStrategy={updateStrategy}
              opinionPercentage={calculateAccountPercentage(strategy, null)}
            />
          )}
          <Divider />
          <Group
            style={{
              marginTop: 10,
              width: "100%",
              paddingLeft: 10,
              paddingRight: 10,
            }}
            align="flex-start"
          >
            <Tooltip
              withArrow
              label={
                "Value determined by the stock values and relative percentages. This represents the minimum amount needed to adhere to the strategy."
              }
            >
              <div>
                <Title size={14} weight={500}>
                  {"Minimum Allocation"}
                </Title>
                <Text style={{ marginLeft: "8px" }}>
                  {currencyFormatter.format(strategy?.cashMin || 0)}
                </Text>
              </div>
            </Tooltip>
          </Group>
          <Center style={{ marginTop: 30 }}>
            <div
              style={{
                borderRadius: 8,
                width: "80vw",
              }}
            >
              <Group position="apart">
                <Text weight={500} size={22}>
                  {"Positions"}
                </Text>
              </Group>
              <Divider style={{ marginBottom: 8 }} />
              <Text
                weight={400}
                size={14}
                style={{ marginBottom: 20, padding: "0px 10px" }}
              >
                Position opinions are used to structure an account's positional
                breakdown by percentage. When a strategy has 100% of its
                position opinions allocated it can be enabled. Only enabled
                strategies will be discoverable by your subscribers.
              </Text>
              <Group position="apart">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: 200,
                  }}
                >
                  <Text weight={500} size={14} color={"dark"}>
                    Enabled:
                  </Text>
                  <Switch
                    checked={strategy?.enabled || false}
                    size={"sm"}
                    style={{ display: "flex", marginLeft: 8 }}
                    onChange={(event) => {
                      if (fullyAllocated) {
                        updateStrategy({
                          enabled: !(strategy?.enabled || false),
                        });
                      } else {
                        showNotification({
                          id: "opinion-value-validation",
                          color: "red",
                          title: "Error",
                          message:
                            "Cannot enable this strategy: The total position allocation opinions do not add up to 100% of the portfoilo.",
                          autoClose: true,
                          disallowClose: true,
                        });
                      }
                    }}
                  />
                </div>
                <Group position="apart" style={{ maxWidth: 200 }}>
                  <Text weight={500}>{`Total: ${(strategy?.opinions || [])
                    .map((opinion) => opinion.accountPercentage)
                    .reduce(
                      (a, b) => a + b,
                      strategy?.cashPercentage || 0
                    )}%`}</Text>

                  {currentDesigner?.id === strategy?.designerId && (
                    <ActionIcon
                      onClick={() => {
                        modals.openModal({
                          styles: {
                            title: {
                              fontWeight: 500,
                              fontSize: 20,
                            },
                          },
                          title: "Create Opinion",
                          size: "min(400px, 80vw)",
                          children: (
                            <OpinionForm
                              strategyId={id!}
                              isEdit={false}
                              onSubmit={createOpinion}
                              opinionPercentage={calculateAccountPercentage(
                                strategy,
                                null
                              )}
                            />
                          ),
                        });
                      }}
                    >
                      <MdAdd size={28} color={theme.colors.greenMachine[6]} />
                    </ActionIcon>
                  )}
                </Group>
              </Group>
              {strategy && (
                <Card
                  style={{
                    padding: "5px 10px",
                    marginTop: -1,
                    backgroundColor: toggleTheme.card_bg,
                    borderBottomRightRadius:
                      (strategy.opinions?.length || 0) - 1 ? 0 : 8,
                    borderBottomLeftRadius:
                      (strategy.opinions?.length || 0) - 1 ? 0 : 8,
                    borderTopRightRadius: 8,
                    borderTopLeftRadius: 8,
                    color: toggleTheme.text,
                    borderTop: "none",
                  }}
                  key={strategy.id + "_cash_position"}
                >
                  <Group position="apart">
                    <Stack spacing={0}>
                      <Text weight={700}>{"Cash"}</Text>
                      <Text size="xs">{"Unallocated Cash"}</Text>
                    </Stack>
                    <Group>
                      <Stack align="end" spacing={0}>
                        <Text size="md" weight={600}>
                          {`${Number(strategy.cashPercentage).toFixed(2)}%`}
                        </Text>
                        <Text size="xs">{``}</Text>
                      </Stack>
                      {currentDesigner?.id === strategy.designerId && (
                        <>
                          <MdEdit
                            size={18}
                            color={theme.colors.dark[2]}
                            onClick={() => {
                              modals.openModal({
                                styles: {
                                  title: {
                                    fontWeight: 500,
                                    fontSize: 20,
                                  },
                                },
                                title: "Edit Cash Reserve",
                                size: "min(400px, 80vw)",
                                children: (
                                  <CashPositionForm
                                    cashPercentage={strategy.cashPercentage}
                                    onSubmit={(cashPercentage) => {
                                      updateStrategy({
                                        cashPercentage,
                                      });
                                      modals.closeAll();
                                    }}
                                    opinionPercentage={
                                      calculateAccountPercentage(
                                        strategy,
                                        null
                                      ) - strategy.cashPercentage
                                    }
                                  />
                                ),
                              });
                            }}
                          />
                          <MdDelete size={17} style={{ opacity: 0 }} />
                        </>
                      )}
                    </Group>
                  </Group>
                </Card>
              )}
              {strategy && strategy.opinions && strategy.opinions.length > 0 ? (
                strategy.opinions
                  .sort((a, b) =>
                    a.stockData.symbol.localeCompare(b.stockData.symbol)
                  )
                  .map((opinion, index) => (
                    <Card
                      style={{
                        padding: "5px 10px",
                        marginTop: -1,
                        backgroundColor: toggleTheme.card_bg,
                        borderBottomRightRadius:
                          index < (strategy.opinions?.length || 0) - 1 ? 0 : 8,
                        borderBottomLeftRadius:
                          index < (strategy.opinions?.length || 0) - 1 ? 0 : 8,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        color: toggleTheme.text,
                        borderTop: `2px solid rgba(0,0,0,0.04)`,
                      }}
                      key={opinion.id!}
                    >
                      <Group position="apart">
                        <Stack spacing={0}>
                          <Text weight={700}>{opinion.stockData.symbol}</Text>
                          <Text size="xs">{opinion.stockData.shortName}</Text>
                        </Stack>
                        <Group>
                          <Stack align="end" spacing={0}>
                            <Text size="md" weight={600}>
                              {Number(opinion.accountPercentage).toFixed(2)}%
                            </Text>
                            <Text size="xs">
                              ${Number(opinion.stockData.price)}
                            </Text>
                          </Stack>
                          {currentDesigner?.id === strategy.designerId && (
                            <>
                              <MdEdit
                                size={18}
                                color={theme.colors.dark[2]}
                                onClick={() => {
                                  modals.openModal({
                                    styles: {
                                      title: {
                                        fontWeight: 500,
                                        fontSize: 20,
                                      },
                                    },
                                    title: "Edit Opinion",
                                    size: "min(400px, 80vw)",
                                    children: (
                                      <OpinionForm
                                        strategyId={id!}
                                        isEdit
                                        opinion={opinion}
                                        onSubmit={updateOpinion}
                                        opinionPercentage={calculateAccountPercentage(
                                          strategy,
                                          opinion.id
                                        )}
                                      />
                                    ),
                                  });
                                }}
                              />
                              <MdDelete
                                size={17}
                                color={theme.colors.red[6]}
                                onClick={() => {
                                  modals.openConfirmModal({
                                    title: "Delete Position",
                                    centered: true,
                                    children: (
                                      <Text size="sm">
                                        {`Are you sure you want to delete this position from this strategy?`}
                                      </Text>
                                    ),
                                    labels: {
                                      confirm: "Delete position",
                                      cancel: "No don't delete it",
                                    },
                                    confirmProps: { color: "red" },
                                    onConfirm: () => {
                                      deleteOpinion(opinion.id!);
                                    },
                                  });
                                }}
                              />
                            </>
                          )}
                        </Group>
                      </Group>
                    </Card>
                  ))
              ) : (
                <Text>No Opinion(s) found.</Text>
              )}
            </div>
          </Center>
        </div>
      </Center>
    </ScrollArea.Autosize>
  );
};

export default StrategyDetails;
