import React from "react";
import {
  Center,
  Footer as MantineFooter,
  Text,
  useMantineTheme,
} from "@mantine/core";

interface FooterProps {
  isMobile?: boolean;
  title?: string;
}

const Footer = ({ title, isMobile }: FooterProps) => {
  const theme = useMantineTheme();
  return (
    <MantineFooter
      style={{ background: theme.colors.greenMachine[6], borderTop: "none" }}
      height={60}
      p="md"
    >
      <Center>
        <Text color={"white"} weight={500}>
          {title}
        </Text>
      </Center>
    </MantineFooter>
  );
};

export default Footer;
