import { gql } from "@apollo/client";

export const GetStrategyPage = gql`
  query GetStrategyPage($pagination: PaginationInput!) {
    strategyPage(pagination: $pagination) {
      items {
        id
        createdDate
        designerId
        name
        cashMin
        riskLevel
        termDuration
        designer{
          user{
            id
            firstName
            lastName
          }
        }
      }
      totalRows
    }
  }
`;
export const GetStrategy = gql`
  query GetStrategy($strategyOneId: String!) {
    strategyOne(id: $strategyOneId) {
      id
      createdDate
      designerId
      name
      cashMin
      riskLevel
      termDuration
      description
      enabled
      syncThreshold
      cashPercentage
      designer{
        user{
          firstName
          lastName
        }
      }
      opinions {
        id
        createdDate
        strategyId
        stockDataId
        accountPercentage
        stockData {
          id
          createdDate
          price
          symbol
          shortName
          longName
        }
      }
    }
  }
`;

export const GetSubscriberStrategies = gql`
  query GetSubscriberStrategies($accountId: String!) {
    subscriberRelevantStrategy(accountId: $accountId) {
      id
      createdDate
      designerId
      name
      cashMin
      riskLevel
      termDuration
    }
  }
`;
