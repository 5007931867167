import { useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  Divider,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useModals } from "@mantine/modals";
import React, { useContext, useEffect } from "react";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { UpdateAccount } from "src/api/graphql/mutations/Accounts";
import {
  GetAccountOneQuery,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from "src/generated/graphql";
import { currencyFormatter } from "src/utils/formatting";
import { useTogglableTheme } from "src/utils/theme";
import { AccountData } from "..";
import AllocateCashForm from "../../AllocateCash/AllocateCashForm";

type Props = {
  account: AccountData;
  sameDay: boolean;
};

const CashSection = ({ account, sameDay }: Props) => {
  const navigate = useNavigate();
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const { query, mutate } = useApolloClient();
  const modals = useModals();

  const updateAccountReserve = async (amount: number) => {
    let response = await mutate<
      UpdateAccountMutation,
      UpdateAccountMutationVariables
    >({
      mutation: UpdateAccount,
      fetchPolicy: "no-cache",
      variables: {
        updateAccountId: account.id,
        data: {
          cashReserve: amount,
        },
      },
    });

    if (response.data?.updateAccount) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (account.cashReserve > account.unallocatedFunds && !sameDay) {
      let totalDist =
        account.positions
          ?.filter((pos) => pos.selected && !pos.unsupported)
          .map((pos) => pos.value || pos.shares * (pos.stockData?.price || 0))
          .reduce((a, b) => a + b, account?.unallocatedFunds || 0) || 0;

      modals.openModal({
        styles: {
          title: {
            fontWeight: 500,
            fontSize: 20,
          },
        },
        title: "Cash Overdraw!",
        size: "min(400px, 80vw)",
        closeOnClickOutside: false,
        closeOnEscape: false,
        withCloseButton: false,
        children: (
          <AllocateCashForm
            description={`You have a negative balance on your unallocated cash. This is usually occurs when either you remove cash from your account through your custodian dashbaord, or you have performed trades and that have yet to be reflected in your position section. To resolve this issue you will need to adjust your reserved cash value. You may also choose to ignore this if you believe it to be correct.`}
            onClose={() => {
              modals.closeAll();
            }}
            allocableCash={Math.floor(
              Math.max(totalDist - (account.strategy?.cashMin || 0))
            )}
            onSubmit={(amount) => {
              updateAccountReserve(amount).then((resp) => {
                if (resp) {
                  modals.closeAll();
                  navigate(`/account-sync/${account?.id}`);
                }
              });
            }}
            submitLabel={"Adjust"}
            closeLabel={"Ignore"}
          />
        ),
      });
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <Text weight={500} size={18}>
          {"Cash"}
        </Text>
        <Button
          style={{
            marginTop: 2,
            border: "none",
            padding: 0,
            marginBottom: -8,
          }}
          rightIcon={<MdChevronRight size={20} style={{ marginLeft: -10 }} />}
          variant="outline"
          compact
          radius="xl"
          size="sm"
          onClick={() => {
            navigate(`/account-sync/${account?.id}/true`);
          }}
        >
          {`Change Reserved`}
        </Button>
      </Group>
      <Divider style={{ marginBottom: 5, marginTop: 2 }} />
      {account && (
        <React.Fragment>
          <Card
            style={{
              padding: "5px 10px",
              marginBottom: -1,
              backgroundColor: toggleTheme.card_bg,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              color: toggleTheme.text,
              borderTop: "none",
            }}
            key={account.id + "_cash_position"}
          >
            <Group position="apart" spacing={"xs"}>
              <Text weight={600} style={{ width: "40%" }}>
                {"Unallocated"}
              </Text>
              <Text size="md" weight={600}>
                {currencyFormatter.format(
                  (account.unallocatedFunds || 0) - account.cashReserve
                )}
              </Text>
            </Group>
          </Card>
          <Card
            style={{
              padding: "5px 10px",
              marginBottom: -1,
              backgroundColor: toggleTheme.card_bg,
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              color: toggleTheme.text,
              borderTop: "none",
            }}
            key={account.id + "_cash_reserved"}
          >
            <Group position="apart" spacing={"xs"}>
              <Text weight={600} style={{ width: "40%" }}>
                {"Reserved"}
              </Text>
              <Text size="md" weight={600}>
                {currencyFormatter.format(account.cashReserve)}
              </Text>
            </Group>
          </Card>
          <Divider variant="dashed" style={{ margin: 4 }} />
          <Card
            style={{
              padding: "5px 10px",
              marginBottom: -1,
              backgroundColor: toggleTheme.card_bg,
              borderRadius: 8,
              color: toggleTheme.text,
              borderTop: "none",
            }}
            key={account.id + "_cash_total"}
          >
            <Group position="apart" spacing={"xs"}>
              <Text weight={600} style={{ width: "40%" }}>
                {"Total"}
              </Text>
              <Text size="md" weight={600}>
                {currencyFormatter.format(account.unallocatedFunds)}
              </Text>
            </Group>
          </Card>
        </React.Fragment>
      )}
    </div>
  );
};

export default CashSection;
