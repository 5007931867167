import { useApolloClient } from "@apollo/client";
import {
  Card,
  Center,
  Container,
  ScrollArea,
  Stack,
  Text,
  Image,
  Group,
  Badge,
  Button,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { MdCancel, MdCheck } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import { CreateSubscription } from "src/api/graphql/mutations/Subscription";
import {
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables,
  GetDesignerQuery,
  SubscriptionRenewalFrequency,
} from "src/generated/graphql";

export type DesignerData = NonNullable<GetDesignerQuery["designerOne"]>;

const SubscriptionConfirmation = () => {
  const navigate = useNavigate();
  const { currentSubscriber, setCurrentPageTitle } = useContext(AppContext);
  const { mutate } = useApolloClient();
  const location = useLocation();
  const [designer, setDesigner] = useState<DesignerData | undefined>(undefined);
  const [frequency, setFrequency] = useState<String | undefined>(undefined);

  const createSubscription = async (
    renewalFrequency: SubscriptionRenewalFrequency
  ) => {
    if (!designer || !currentSubscriber) {
      console.log(
        "Missing relation ids needed to create subscription! designer and/or subscriber"
      );
      return;
    }
    try {
      showNotification({
        id: "create-subscription",
        loading: true,
        title: "Loading",
        message: "Creating subscription...",
        autoClose: false,
        disallowClose: true,
      });

      const response = await mutate<
        CreateSubscriptionMutation,
        CreateSubscriptionMutationVariables
      >({
        mutation: CreateSubscription,
        variables: {
          data: {
            designer: {
              id: designer.id,
            },
            subscriber: {
              id: currentSubscriber.id,
            },
            renewalFrequency,
          },
        },
      });
      if (response.data?.createSubscription) {
        updateNotification({
          id: "create-subscription",
          color: "green",
          title: "Success!",
          message: "Subscription created!",
          icon: <MdCheck size={16} />,
          autoClose: 2000,
        });
        return navigate("/subscription/success");
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      updateNotification({
        id: "create-subscription",
        color: "red",
        title: "Failure!",
        message: message || "Unable to create the subscription!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    }
  }, [location.state, navigate]);

  useEffect(() => {
    setCurrentPageTitle("Subscription Confirmation");
    if (location.state) {
      setDesigner(location.state.designer);
      setFrequency(location.state.frequency);
    }

    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [location.state, setCurrentPageTitle]);

  return (
    <>
      {designer && (
        <Container
          style={{ maxHeight: "100%", maxWidth: isMobile ? 600 : undefined }}
        >
          <ScrollArea.Autosize
            maxHeight={
              "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )"
            }
            scrollbarSize={6}
          >
            <Center>
              <Text
                align="center"
                size={22}
                style={{ fontFamily: "Inter-SemiBold", marginBottom: 10 }}
              >
                Confirm Your Subscription
              </Text>
            </Center>
            <Card
              style={{
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                padding: "8px 10px",
                paddingTop: 4,
                cursor: "pointer",
              }}
              radius="md"
              withBorder
            >
              <Text
                align="center"
                style={{
                  fontFamily: "Inter-SemiBold",
                  fontSize: "18px",
                  color: "#1B1B1B",
                }}
              >
                Designer
              </Text>

              <Stack spacing={4}>
                <Center>
                  <Image
                    alt={"Designer Profile Picture"}
                    withPlaceholder
                    src={designer?.profilePicURL}
                    radius={1000}
                    style={{
                      maxHeight: "25vh",
                      width: "auto",
                      margin: "0 30px",
                      aspectRatio: "1",
                    }}
                    sx={{
                      ".mantine-Image-imageWrapper": {
                        aspectRatio: "1/1 !important",
                        ">img": {
                          opacity: designer?.profilePicURL ? 1 : 0,
                        },
                      },
                    }}
                  />
                </Center>
                <Stack spacing={0}>
                  <Text
                    style={{
                      fontFamily: "Inter-Medium",
                      fontSize: "18px",
                      color: "#1B1B1B",
                    }}
                    align="center"
                  >
                    {designer?.user?.firstName} {designer?.user?.lastName}
                  </Text>
                  <Text
                    weight={500}
                    style={{
                      fontSize: "14px",
                      color: "#1B1B1B",
                    }}
                    align="center"
                  >
                    {designer?.user?.email}
                  </Text>
                </Stack>
              </Stack>
            </Card>
            <Card
              style={{
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                padding: "5px 10px",
                margin: "15px 0px",
              }}
              shadow="sm"
              radius="md"
              withBorder
            >
              <Group position="apart" mt="md" mb="xs">
                <Text
                  size={isMobile ? 16 : 20}
                  style={{ fontFamily: "Inter-Medium" }}
                >
                  {frequency} Subscription
                </Text>
                <Text align="center" weight={500} size={20}>
                  <Badge
                    color="pink"
                    style={{ fontFamily: "Inter-Medium" }}
                    size="lg"
                    variant="light"
                  >
                    {frequency === SubscriptionRenewalFrequency.Annually
                      ? "1 Year"
                      : "3 Months"}
                  </Badge>
                </Text>
              </Group>

              <Text align="center" size="md" color="dimmed">
                Subscription Fee: $
                {frequency === SubscriptionRenewalFrequency.Quarterly
                  ? Number(
                      (designer?.revenueShare?.designerFee || 0) +
                        (designer?.revenueShare?.serviceFee || 0)
                    ).toFixed(2)
                  : Number(
                      (designer?.revenueShare?.designerFeeAnnual || 0) +
                        (designer?.revenueShare?.serviceFeeAnnual || 0)
                    ).toFixed(2)}
              </Text>
            </Card>
            <Text align="center" size={14} weight={500}>
              {`All subscriptions are pre-paid for their respective durations. All
              transactions are final, and no pro-rations or refunds will be
              provided. By completing this subscription request you are
              acknowledging these terms.`}
            </Text>
            <Center style={{ marginTop: 10 }}>
              <Button
                onClick={
                  frequency === SubscriptionRenewalFrequency.Annually
                    ? () => {
                        createSubscription(
                          SubscriptionRenewalFrequency.Annually
                        );
                      }
                    : () => {
                        createSubscription(
                          SubscriptionRenewalFrequency.Quarterly
                        );
                      }
                }
              >
                Confirm & Pay
              </Button>
            </Center>
          </ScrollArea.Autosize>
        </Container>
      )}
    </>
  );
};
export default SubscriptionConfirmation;
