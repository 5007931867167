import { useApolloClient } from "@apollo/client";
import { ActionIcon, Box, CopyButton, Group, Loader, Text, Tooltip } from "@mantine/core";
import React, { useEffect, useState } from "react";
import {
  GetDesignerQuery,
  GetDesignerQueryVariables,
} from "src/generated/graphql";
import { GetDesigner } from "src/api/graphql/queries/Designer";
import { UserData } from "../../User";
import BasicInfo from "./BasicInfo";
import RevenueShare from "./RevenueShare";
import { IconCheck, IconCopy } from "@tabler/icons";

const baseUrl = window.location.origin;

export type DesignerData = {
  id: string;
  user?: UserData | null;
  profilePicURL?: string | null;
  bio?: string | null;
  enabled: boolean;
  subscribers: {
    id: string;
    user?: {
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }[];
  revenueShare?: {
    id: string;
    designerFee: number;
    serviceFee: number;
    designerFeeAnnual: number;
    serviceFeeAnnual: number;
  } | null;
};

type DesignerDetailsProps = {
  designerId: string;
  onSubmit: () => void;
  maxWidth?: number | string;
};

const DesignerDetails = (props: DesignerDetailsProps) => {
  const { query } = useApolloClient();

  const [designer, setDesigner] = useState<DesignerData | undefined>(undefined);

  useEffect(() => {
    fetchDesigner();
    // eslint-disable-next-line
  }, []);

  const fetchDesigner = async () => {
    let response = await query<GetDesignerQuery, GetDesignerQueryVariables>({
      query: GetDesigner,
      variables: {
        id: props.designerId,
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.designerOne && response.data.designerOne.user) {
      setDesigner(response.data.designerOne);
    }
  };

  return (
    <Box sx={{ maxWidth: 440 }}>
      {designer ? (
        <React.Fragment>
         <Group
          style={{ width: "80%", paddingRight: "3%" }}
          spacing={2}
          position="right"
        >
          <Text
            size={14}
            weight={500}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: '90%'
            }}
          >{`Link: ${baseUrl}/designer/${designer?.id}`}</Text>
          <CopyButton
            value={`${baseUrl}/designer/${designer?.id}`}
            timeout={2000}
          >
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                  {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>

          <BasicInfo designer={designer} onSave={() => fetchDesigner()} />
          <RevenueShare designer={designer} onSave={() => fetchDesigner()} />
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default DesignerDetails;
