import { gql } from "@apollo/client";

export const QuarterlyNewsletterPage = gql`
  query QuarterlyNewsletterPage($pagination: PaginationInput!) {
    quarterlyNewsletterPage(pagination: $pagination) {
      items {
        id
        updatedDate
        header
        body
        quarter
        year
      }
      totalRows
    }
  }
`;

export const GetQuarterlyNewsletter = gql`
  query GetQuarterlyNewsletter {
    quarterlyNewsletter {
      id
      updatedDate
      header
      body
      quarter
      year
    }
  }
`;
