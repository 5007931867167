import { useApolloClient } from "@apollo/client";
import { Button, Group, Text, TextInput } from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { MdCancel, MdCheck } from "react-icons/md";
import { UpdateNewsletterTemplate } from "src/api/graphql/mutations/NewsletterTemplate";
import { GetNewsletterTemplate } from "src/api/graphql/queries/NewsletterTemplate";
import {
  GetNewsletterTemplateQuery,
  GetNewsletterTemplateQueryVariables,
  NewsletterTemplateInput,
  UpdateNewsletterTemplateMutation,
  UpdateNewsletterTemplateMutationVariables,
} from "src/generated/graphql";
import TextEditor from "./TextEditor";

type Props = {
  onClose: () => void;
  id: string;
};

const UpdateNewsletterForm = ({ onClose, id }: Props) => {
  const { mutate, query } = useApolloClient();
  const [bodyValue, setBodyValue] = useState<string | undefined>();
  const [headerValue, setHeaderValue] = useState<string | undefined>();

  const getNewsletterTemplate = async (newsletterTemplateOneId: string) => {
    const response = await query<
      GetNewsletterTemplateQuery,
      GetNewsletterTemplateQueryVariables
    >({
      query: GetNewsletterTemplate,
      variables: {
        newsletterTemplateOneId,
      },
    });

    if (response) {
      setBodyValue(response.data.newsletterTemplateOne?.body);
      setHeaderValue(response.data.newsletterTemplateOne?.header);
    }
  };

  useEffect(() => {
    if (id) {
      getNewsletterTemplate(id);
    }
  }, [id]);

  const updateNewsletterTemplate = async (
    values: NewsletterTemplateInput,
    id: string
  ) => {
    showNotification({
      id: "update-newsletter-template",
      loading: true,
      title: "Loading",
      message: "Attempting to update newsletter template...",
      autoClose: false,
      disallowClose: true,
    });
    try {
      const response = await mutate<
        UpdateNewsletterTemplateMutation,
        UpdateNewsletterTemplateMutationVariables
      >({
        mutation: UpdateNewsletterTemplate,
        variables: {
          data: values,
          updateNewsletterTemplateId: id,
        },
      });

      if (response) {
        updateNotification({
          id: "update-newsletter-template",
          color: response.data?.updateNewsletterTemplate ? "green" : "red",
          title: response.data?.updateNewsletterTemplate
            ? "Success!"
            : "Failure!",
          message: response.data?.updateNewsletterTemplate
            ? "Newsletter Templated Updated Successfully"
            : "An error occured during updating newsletter template...",
          icon: response.data?.updateNewsletterTemplate ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });

        if (response.data?.updateNewsletterTemplate) {
          onClose();
        }
      }
    } catch (err) {
      updateNotification({
        id: "update-newsletter-template",
        color: "red",
        title: "Failure!",
        message: "An error occured during updating newsletter template...",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };
  return (
    <form
      onSubmit={() => {
        if (
          headerValue &&
          bodyValue &&
          headerValue !== "" &&
          bodyValue !== ""
        ) {
          updateNewsletterTemplate(
            { header: headerValue, body: bodyValue },
            id
          );
          onClose();
        } else {
          throw Error("Empty Values");
        }
      }}
      onReset={() => onClose()}
    >
      <TextInput
        style={{ marginTop: 12 }}
        label="Header"
        value={headerValue}
        onChange={(e) => {
          setHeaderValue(e.currentTarget.value);
        }}
      />
      <Text style={{ marginTop: 12 }}>Body</Text>
      <TextEditor value={bodyValue} setValue={setBodyValue} />
      <Group position="right" mt="md">
        <Button type="reset">Cancel</Button>
        <Button type="submit">Update</Button>
      </Group>
    </form>
  );
};

export default UpdateNewsletterForm;
