import { gql } from "@apollo/client";

export const CreateStockData = gql`
  mutation CreateStockData($data: CreateStockDataInput!) {
    createStockData(data: $data) {
      id
      createdDate
      updatedDate
      price
      symbol
      shortName
      longName
    }
  }
`;
