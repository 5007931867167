import { FC, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "src/modules/Home";
import User from "src/modules/WebScreens/User";
import PlatformAdmin from "src/modules/WebScreens/PlatformAdmin";
import Designer from "src/modules/WebScreens/Designer";
import Subscriber from "src/modules/WebScreens/Subscriber";
import Strategy from "src/modules/WebScreens/Strategy";
import StrategyDetails from "src/modules/WebScreens/StrategyDetails";
import DesignerDetails from "src/modules/WebScreens/DesignerDetails";
import { AppContext } from "src/api/AppContext";
import DesignerProfile from "src/modules/WebScreens/DesignerProfile";
import StrategyList from "src/modules/MobileScreens/StrategyList";
import { UserRole } from "src/generated/graphql";
import SubscriptionPackage from "src/modules/WebScreens/SubscriptionPackage";
import PaymentSuccess from "src/modules/WebScreens/SubscriptionPackage/components/PaymentSuccess";
import PaymentFailure from "src/modules/WebScreens/SubscriptionPackage/components/PaymentFailure";
import TrialDetails from "src/modules/WebScreens/TrialDetails";
import SubscriptionDetails from "src/modules/MobileScreens/SubscriptionDetails";
import SubscriberProfile from "src/modules/MobileScreens/SubscriberProfile";
import SubscriptionConfirmation from "src/modules/MobileScreens/SubscriptionConfirmation";
import AccountList from "src/modules/WebScreens/AccountsList";
import AccountDetails from "src/modules/MobileScreens/AccountDetails";
import MyAccounts from "src/modules/MobileScreens/MyAccounts";
import StrategyDetailsSubscriber from "src/modules/MobileScreens/StrategyDetailsSubscriber";
import HelpSupport from "src/modules/MobileScreens/HelpSupport";
import AccountSync from "src/modules/MobileScreens/AccountSync";
import PlaidLink from "src/modules/MobileScreens/PlaidLink";
import SubscriberReport from "src/modules/MobileScreens/SubscriberReports";
import Newsletter from "src/modules/WebScreens/Newsletter";
import NewsletterDesignerView from "src/modules/WebScreens/NewsletterDesigner";
import PlaidSuccess from "src/modules/MobileScreens/PlaidSuccess";
import SubscriberDashboard from "src/modules/Home/SubscriberDashboard";
import DesignerReport from "src/modules/WebScreens/DesignerReports";
import PlatformAdminReport from "src/modules/WebScreens/PlatformAdminReport";
import TrialSuccess from "src/modules/WebScreens/TrialSuccess";
import DesignerDashboard from "src/modules/Home/DesignerDashboard";
import SubscriberDetails from "src/modules/WebScreens/SubscriberDetails";
import DesignerAccountDetails from "src/modules/WebScreens/DesignerAccountDetails";
import AllocateCash from "src/modules/MobileScreens/AllocateCash";
import DeallocateCash from "src/modules/MobileScreens/DeallocateCash";

type RouteDetails = {
  path: string;
  element: JSX.Element;
  roles: UserRole[];
};

const Router: FC = () => {
  const { currentDesigner, currentUser } = useContext(AppContext);

  const routes: RouteDetails[] = [
    {
      path: "/designer",
      element: <Designer />,
      roles: [],
    },
    {
      path: "/designer/:id",
      element: <DesignerDetails />,
      roles: [],
    },
    {
      path: "/home",
      element: <Home />,
      roles: [],
    },
    {
      path: "/dashboard",
      element: <SubscriberDashboard />,
      roles: [],
    },
    {
      path: "/platformAdmin",
      element: <PlatformAdmin />,
      roles: [],
    },
    {
      path: "/strategy/:id",
      element: <StrategyDetails />,
      roles: [],
    },
    {
      path: "/strategy/account/:accountId",
      element: <StrategyList />,
      roles: [],
    },
    {
      path: "/subscriber",
      element: <Subscriber />,
      roles: [],
    },
    {
      path: "/user",
      element: <User />,
      roles: [],
    },
    {
      path: "/subscription",
      element: <SubscriptionDetails />,
      roles: [],
    },
    {
      path: "/subscription/success",
      element: <PaymentSuccess />,
      roles: [],
    },
    {
      path: "/subscription/error/:id",
      element: <PaymentFailure />,
      roles: [],
    },
    {
      path: "/trial-details",
      element: <TrialDetails />,
      roles: [],
    },
    {
      path: "/account",
      element: <AccountList />,
      roles: [],
    },
    {
      path: "/my-accounts/:id",
      element: <AccountDetails />,
      roles: [],
    },
    {
      path: "/my-accounts",
      element: <MyAccounts />,
      roles: [],
    },
    {
      path: "/plaid-link",
      element: <PlaidLink />,
      roles: [],
    },
    {
      path: "/plaid-link/:custodianId",
      element: <PlaidLink />,
      roles: [],
    },
    {
      path: "/plaid-success/:id",
      element: <PlaidSuccess />,
      roles: [],
    },
    {
      path: "/account-sync/:id",
      element: <AccountSync />,
      roles: [],
    },
    {
      path: "/subscriber-reports",
      element: <SubscriberReport />,
      roles: [],
    },
    {
      path: "/newsletter-admin",
      element: <Newsletter />,
      roles: [],
    },
    {
      path: "/newsletter-designer",
      element: <NewsletterDesignerView />,
      roles: [],
    },
    {
      path: "/help-and-support",
      element: <HelpSupport />,
      roles: [],
    },
  ];

  return (
    <Routes>
      <Route path="/designer" element={<Designer />} />
      <Route path="/designer/:id" element={<DesignerDetails />} />
      <Route path="/home" element={<Home />} />
      <Route
        path="/"
        element={
          currentUser?.role == UserRole.Subscriber ? (
            <SubscriberDashboard />
          ) : currentUser?.role == UserRole.Designer ? (
            <DesignerDashboard />
          ) : (
            <Home />
          )
        }
      />
      <Route path="/dashboard-designer" element={<DesignerDashboard />} />
      <Route path="/subscriber/:id" element={<SubscriberDetails />} />
      <Route path="/account/:id" element={<DesignerAccountDetails />} />
      <Route path="/dashboard" element={<SubscriberDashboard />} />
      <Route path="/platformAdmin" element={<PlatformAdmin />} />
      <Route
        path="/strategy"
        element={
          currentDesigner || currentUser?.role === UserRole.PlatformAdmin ? (
            <Strategy />
          ) : (
            <StrategyList />
          )
        }
      />
      <Route path="/strategy/:id" element={<StrategyDetails />} />
      <Route path="/strategy/account/:accountId" element={<StrategyList />} />
      <Route
        path="/strategy/:id/account/:accountId"
        element={<StrategyDetailsSubscriber />}
      />
      <Route path="/strategy/:id" element={<StrategyDetails />} />
      <Route path="/subscriber" element={<Subscriber />} />
      <Route path="/user" element={<User />} />
      <Route
        path="/profile"
        element={currentDesigner ? <DesignerProfile /> : <SubscriberProfile />}
      />
      <Route path="/subscription" element={<SubscriptionDetails />} />
      <Route
        path="/subscription/packages/:id"
        element={<SubscriptionPackage />}
      />
      <Route path="/subscription/success" element={<PaymentSuccess />} />
      <Route path="/subscription/error/:id" element={<PaymentFailure />} />
      <Route path="/trial-details" element={<TrialDetails />} />
      <Route
        path="/subscription/confirmation"
        element={<SubscriptionConfirmation />}
      />
      <Route path="/account" element={<AccountList />} />
      <Route path="/my-accounts/:id" element={<AccountDetails />} />
      <Route path="/my-accounts" element={<MyAccounts />} />
      <Route path="/plaid-link" element={<PlaidLink />} />
      <Route path="/plaid-link/:custodianId" element={<PlaidLink />} />
      <Route path="/plaid-success/:id" element={<PlaidSuccess />} />
      <Route
        path="/account-sync/:id/:includeReserveAdjust?"
        element={<AccountSync />}
      />
      <Route path="/account-allocate/:id" element={<AllocateCash />} />
      <Route path="/account-deallocate/:id" element={<DeallocateCash />} />
      <Route path="/trial-success" element={<TrialSuccess />} />
      <Route path="/subscriber-reports" element={<SubscriberReport />} />
      <Route path="/designer-reports" element={<DesignerReport />} />
      <Route path="/admin-reports" element={<PlatformAdminReport />} />
      <Route path="/newsletter-admin" element={<Newsletter />} />
      <Route path="/newsletter-designer" element={<NewsletterDesignerView />} />
      <Route path="/help-and-support" element={<HelpSupport />} />
    </Routes>
  );
};

export default Router;
