import { useApolloClient } from "@apollo/client";
import {
  Group,
  useMantineTheme,
  Text,
  Divider,
  Card,
  ScrollArea,
  Stack,
  Button,
  Center,
  Loader,
} from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification, updateNotification } from "@mantine/notifications";
import React, { useContext, useEffect, useState } from "react";
import { MdCancel, MdCheck, MdChevronLeft } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "src/api/AppContext";
import { GetStrategy } from "src/api/graphql/queries/Strategy";
import {
  GetStrategyQuery,
  GetStrategyQueryVariables,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
} from "src/generated/graphql";
import { UpdateAccount } from "src/api/graphql/mutations/Accounts";
import { GetAccountOne } from "src/api/graphql/queries/Account";
import { AccountData } from "src/modules/MobileScreens/AccountDetails";
import { useTogglableTheme } from "src/utils/theme";
import { currencyFormatter } from "src/utils/formatting";

export type StrategyData = NonNullable<GetStrategyQuery["strategyOne"]>;

const StrategyDetailsSubscriber = () => {
  const { id, accountId } = useParams();
  const { setCurrentPageTitle, isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const modals = useModals();
  const navigate = useNavigate();
  const { query, mutate } = useApolloClient();

  const [accountDetails, setAccountDetails] = useState<AccountData>();
  const [strategy, setStrategy] = useState<StrategyData | undefined>(undefined);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    setCurrentPageTitle("Strategy Details");
    getStrategy();
    if (accountId) getAccount(accountId);

    return () => {
      setStrategy(undefined);
      setCurrentPageTitle(undefined);
    };
  }, []);

  const getStrategy = async () => {
    if (!id) {
      console.log("Missing required strategyId to query details!");
      return;
    }
    const response = await query<GetStrategyQuery, GetStrategyQueryVariables>({
      query: GetStrategy,
      variables: {
        strategyOneId: id,
      },
    });
    if (response.data.strategyOne) {
      setStrategy(response.data.strategyOne);
    }
  };

  const getAccount = async (accountOneId: string) => {
    let response = await query<GetAccountOneQuery, GetAccountOneQueryVariables>(
      {
        query: GetAccountOne,
        variables: {
          accountOneId,
        },
        fetchPolicy: "no-cache",
      }
    );

    if (response && response.data !== null && response.data.accountOne) {
      setAccountDetails(response.data.accountOne);
    }
  };

  const updateAccount = async (updateAccountId: string, strategyId: string) => {
    try {
      showNotification({
        id: "update-account",
        loading: true,
        title: "Loading",
        message: "Updating Account...",
        autoClose: false,
        disallowClose: true,
      });

      const response = await mutate<
        UpdateAccountMutation,
        UpdateAccountMutationVariables
      >({
        mutation: UpdateAccount,
        variables: {
          updateAccountId,
          data: {
            strategy: {
              id: strategyId,
            },
          },
        },
      });

      if (response.data?.updateAccount) {
        updateNotification({
          id: "update-account",
          color: "green",
          title: "Success!",
          message: "Account updated!",
          icon: <MdCheck size={16} />,
          autoClose: 2000,
        });
        navigate(`/my-accounts/${accountId}`);
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      updateNotification({
        id: "update-account",
        color: "red",
        title: "Failure!",
        message: message || "Unable to update the account!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };

  const showMinimumCashError = () => {
    showNotification({
      id: "cash-min-error",
      color: "red",
      title: "Failure!",
      message: "Total value of account should be greater than cashmin.",
      icon: <MdCancel size={16} />,
      autoClose: 2000,
    });
  };

  return (
    <React.Fragment>
      <Button
        variant="outline"
        onClick={() => navigate(-1)}
        size="xs"
        style={{
          fontSize: 16,
          lineHeight: "16px",
          color: theme.colors.greenMachine[7],
          border: "none",
          paddingLeft: 0,
        }}
      >
        <MdChevronLeft size={22} /> Back
      </Button>
      <ScrollArea.Autosize
        maxHeight="calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px + 62px + 30px)) )"
        style={{ marginBottom: "10px" }}
        type="auto"
        scrollbarSize={6}
      >
        <div>
          {strategy ? (
            <div style={{ marginLeft: "3%", marginRight: "3%", marginTop: 8 }}>
              <Text
                style={{ marginTop: 8 }}
                size={12}
                weight={500}
              >{`Name`}</Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: 1,
                }}
              >
                {strategy.name}
              </Text>

              <Text
                style={{ marginTop: 18 }}
                size={12}
                weight={500}
              >{`Minimum Investment`}</Text>
              <Text
                size={20}
                style={{
                  fontWeight: 600,
                  lineHeight: 1,
                }}
              >
                {formatter.format(strategy.cashMin || 0)}
              </Text>

              <Text
                style={{ marginTop: 18 }}
                size={12}
                weight={500}
              >{`Description`}</Text>
              <Card
                style={{
                  marginTop: "5px",
                  borderRadius: 8,
                  background: toggleTheme.card_bg,
                  padding: "5px 10px",
                  minHeight: 100,
                }}
              >
                <Text
                  size="xs"
                  weight={500}
                  style={{
                    textAlign: "left",
                    lineHeight: 1.3,
                  }}
                  color={toggleTheme.text}
                >
                  {strategy.description ||
                    "The author has not yet provided a description for this strategy..."}
                </Text>
              </Card>
            </div>
          ) : (
            <Center>
              <Loader />
            </Center>
          )}
        </div>
        <div
          style={{
            borderRadius: 8,
            margin: "3%",
          }}
        >
          <Group position="apart">
            <Text weight={500} size={14}>
              {"Positions"}
            </Text>
          </Group>
          <Divider style={{ marginBottom: 8 }} />

          {strategy && (
            <Card
              style={{
                padding: "5px 10px",
                marginTop: -1,
                backgroundColor: toggleTheme.card_bg,
                borderBottomRightRadius:
                  (strategy.opinions?.length || 0) - 1 ? 0 : 8,
                borderBottomLeftRadius:
                  (strategy.opinions?.length || 0) - 1 ? 0 : 8,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                color: toggleTheme.text,
                borderTop: "none",
              }}
              key={strategy.id + "_cash_position"}
            >
              <Group position="apart">
                <Stack spacing={0}>
                  <Text weight={700}>{"Cash"}</Text>
                  <Text size="xs">{"Unallocated Cash"}</Text>
                </Stack>
                <Group>
                  <Stack align="end" spacing={0}>
                    <Text size="md" weight={600}>
                      {`${Number(strategy.cashPercentage).toFixed(2)}%`}
                    </Text>
                    <Text size="xs">{``}</Text>
                  </Stack>
                </Group>
              </Group>
            </Card>
          )}
          {strategy && strategy.opinions && strategy.opinions.length > 0 ? (
            strategy.opinions
              .sort((a, b) =>
                a.stockData.symbol.localeCompare(b.stockData.symbol)
              )
              .map((opinion, index) => (
                <Card
                  style={{
                    background: toggleTheme.card_bg,
                    padding: "5px 10px",
                    marginBottom: "-1px",
                    color: toggleTheme.text,
                    borderBottomRightRadius:
                      index < strategy.opinions.length - 1 ? 0 : 8,
                    borderBottomLeftRadius:
                      index < strategy.opinions.length - 1 ? 0 : 8,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                  }}
                  key={opinion.id!}
                >
                  <Group position="apart">
                    <Stack spacing={0}>
                      <Text weight={700}>{opinion.stockData.symbol}</Text>
                      <Text size="xs">{opinion.stockData.shortName}</Text>
                    </Stack>
                    <Group>
                      <Stack align="end" spacing={0}>
                        <Text size="md" weight={600}>
                          {Number(opinion.accountPercentage).toFixed(2)}%
                        </Text>
                        <Text size="xs">
                          {formatter.format(opinion.stockData.price)}
                        </Text>
                      </Stack>
                    </Group>
                  </Group>
                </Card>
              ))
          ) : (
            <Text>No Opinion(s) found.</Text>
          )}
        </div>
      </ScrollArea.Autosize>
      <Center>
        <Button
          disabled={accountDetails?.strategy?.id == strategy?.id}
          radius="xl"
          size="md"
          leftIcon={
            accountDetails?.strategy?.id == strategy?.id ? (
              <MdCheck color={theme.colors.gray[6]} />
            ) : undefined
          }
          style={{
            backgroundColor:
              accountDetails?.strategy?.id == strategy?.id
                ? undefined
                : theme.colors.greenMachine[6],
            fontSize: "20px",
          }}
          onClick={() => {
            if (
              accountDetails &&
              strategy &&
              strategy.cashMin &&
              accountDetails.totalValue &&
              accountDetails.totalValue >= strategy.cashMin
            ) {
              modals.openConfirmModal({
                title: "Apply Strategy",
                centered: true,
                children: (
                  <Text size="sm">
                    {`Are you sure you want to apply this strategy to the account ${accountDetails?.name} ?`}
                  </Text>
                ),
                labels: {
                  confirm: "Apply",
                  cancel: "Cancel",
                },
                onConfirm: () => {
                  if (strategy && accountId) {
                    updateAccount(accountId, strategy.id);
                  }
                },
              });
            } else {
              showMinimumCashError();
            }
          }}
        >
          {accountDetails?.strategy?.id == strategy?.id
            ? `Already Applied`
            : `Apply Strategy`}
        </Button>
      </Center>
    </React.Fragment>
  );
};

export default StrategyDetailsSubscriber;
