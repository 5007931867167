import React from "react";
import { Container, Text } from "@mantine/core";

const TrialDetails = () => {
  return (
    <Container>
      <Text size="lg"> Trial Details </Text>
    </Container>
  );
};

export default TrialDetails;
