import { useApolloClient } from "@apollo/client";
import {
  Card,
  Divider,
  Group,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "src/api/AppContext";
import { GetAccountPage } from "src/api/graphql/queries/Account";
import {
  GetAccountPageQuery,
  GetAccountPageQueryVariables,
  GetSubscriberPageQuery,
  GetSubscriberPageQueryVariables,
} from "src/generated/graphql";
import { UserData } from "../../WebScreens/User";
import { GetSubscriberPage } from "src/api/graphql/queries/Subscriber";
import { useTogglableTheme } from "src/utils/theme";
import { FetchParams, KapexTable, QueryResponse } from "@kapex/tables";
import { currencyFormatter } from "src/utils/formatting";

type SubscriberData = {
  id: string;
  user: UserData;
};

type AccountsData = {
  id: string;
  createdDate: any;
  name: string;
  strategy: { id: string; name: string };
  totalValue: number;
  subscriber: {
    user: {
      firstName: string;
      lastName: string;
    };
  };
};

const DesignerDashboard = () => {
  const { query } = useApolloClient();
  const { currentDesigner, isDarkMode } = useContext(AppContext);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);

  const getSubscriber = async (
    fetchParams: FetchParams
  ): Promise<QueryResponse<SubscriberData>> => {
    let response = await query<
      GetSubscriberPageQuery,
      GetSubscriberPageQueryVariables
    >({
      query: GetSubscriberPage,
      variables: {
        pagination: {
          ...fetchParams,
          filters: [
            {
              field: "designerId",
              //parentRelationField: "designer",
              value: currentDesigner?.id || "",
              type: "text",
            },
            //...(fetchParams.filters || []),
          ],
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.subscriberPage) {
      return {
        rowCount: response.data.subscriberPage.totalRows,
        rowData: response.data.subscriberPage.items as SubscriberData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  };

  const getAccounts = async (fetchParams: FetchParams) => {
    const response = await query<
      GetAccountPageQuery,
      GetAccountPageQueryVariables
    >({
      query: GetAccountPage,
      variables: {
        pagination: {
          ...fetchParams,
          filters: [
            {
              parentRelationField: "subscriber",
              field: "designerId",
              value: currentDesigner?.id || "",
              type: "text",
            },
          ],
        },
      },
      fetchPolicy: "no-cache",
    });

    if (response.data.accountPage) {
      return {
        rowCount: response.data.accountPage.totalRows,
        rowData: response.data.accountPage.items as AccountsData[],
      };
    } else {
      return {
        rowCount: 0,
        rowData: [],
      };
    }
  };

  return (
    <Group style={{ alignItems: "flex-start" }}>
      <Card
        style={{
          background: toggleTheme.card_bg,
          color: toggleTheme.text,
          borderRadius: 8,
          padding: "8px 16px",
          width: "100%",
        }}
      >
        <Group position="apart">
          <Text size={20} weight={500}>{`Subscribers`}</Text>
          {/* <Button
            variant="outline"
            onClick={() => navigate("/my-accounts")}
            size="xs"
            style={{
              fontSize: 17,
              lineHeight: "18px",
              color: theme.colors.greenMachine[6],
              border: "none",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            Manage
            <MdChevronRight size={22} style={{ marginLeft: 2, marginTop: 3 }} />
          </Button> */}
        </Group>
        <Divider style={{ marginBottom: 10 }} />
        <ScrollArea.Autosize
          maxHeight={
            "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px) - 150px) * 0.5)"
          }
          style={{
            minHeight: 300,
            marginBottom: 8,
          }}
          type="auto"
          scrollbarSize={9}
          sx={(theme) => ({
            ".mantine-ScrollArea-root": {
              borderRadius: 4,
              paddingRight: 1,
            },
            ".mantine-ScrollArea-viewport": {
              borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
            },
            ".mantine-ScrollArea-scrollbar": {
              background: theme.colors.dark[4],
              ".mantine-ScrollArea-thumb": {
                backgroundColor: theme.colors.greenMachine[6],
              },
            },
          })}
        >
          {currentDesigner && (
            <KapexTable<SubscriberData>
              tableSx={{
                thead: {
                  th: {
                    ">div": {
                      color: "#fff",
                    },
                  },
                  "::after": {
                    left: "-1px",
                  },
                },
                tbody: {
                  tr: {
                    td: {
                      borderColor: toggleTheme.card_disabled_bg,
                      ">div": {
                        color: toggleTheme.text,
                      },
                    },
                  },
                  "tr:hover": {
                    backgroundColor: `${toggleTheme.card_disabled_bg} !important`,
                  },
                },
                tfoot: {
                  td: {
                    div: {
                      color: "#fff",
                    },
                  },
                },
              }}
              headerColor={{ r: 255, g: 255, b: 255 }}
              headerBg={theme.colors.greenMachine[6]}
              borderColor={theme.colors.greenMachine[8]}
              columnConfigs={[
                {
                  field: "name",
                  name: "Name",
                  valueParser: (rowItem) =>
                    `${rowItem.user.firstName} ${rowItem.user.lastName}`,
                  // filter: {
                  //   type: "text",
                  //   field: "name",
                  //   parentRelationField: "user",
                  // },
                  sortable: true,
                  cellRenderer: (rowItem) => {
                    return (
                      <Text
                        sx={{
                          fontWeight: 500,
                          color: toggleTheme.primary,
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          navigate(`/subscriber/${rowItem.id}`);
                        }}
                      >
                        {`${rowItem.user.firstName} ${rowItem.user.lastName}`}
                      </Text>
                    );
                  },
                },
                {
                  field: "email",
                  parentRelationField: "user",
                  name: "Email",
                  // filter: {
                  //   field: "email",
                  //   parentRelationField: "user",
                  //   type: "text",
                  // },
                  sortable: true,
                },
                {
                  field: "phoneNumber",
                  parentRelationField: "user",
                  name: "Phone",
                  // filter: {
                  //   field: "phoneNumber",
                  //   parentRelationField: "user",
                  //   type: "text",
                  // },
                  sortable: true,
                  valueParser: (rowItem) => {
                    if (!rowItem.user) {
                      return "N/A";
                    }
                    let num = rowItem.user.phoneNumber;
                    if (!num) {
                      return "N/A";
                    }
                    let len = num.length;
                    if (len >= 10) {
                      return `(${num.slice(len - 10, len - 7)}) ${num.slice(
                        len - 7,
                        len - 4
                      )}-${num.slice(len - 4)}`;
                    } else {
                      return "N/A";
                    }
                  },
                },
                {
                  field: "createdDate",
                  parentRelationField: "user",
                  name: "Created",
                  // filter: {
                  //   field: "createdDate",
                  //   parentRelationField: "user",
                  //   type: "dateRange",
                  // },
                  sortable: true,
                  valueParser: (rowItem) => {
                    return rowItem.user
                      ? new Date(rowItem.user.createdDate).toDateString()
                      : "N/A";
                  },
                },
              ]}
              dataFetch={getSubscriber}
            />
          )}
        </ScrollArea.Autosize>
      </Card>
      <Card
        style={{
          background: toggleTheme.card_bg,
          color: toggleTheme.text,
          borderRadius: 8,
          padding: "8px 16px",
          width: "100%",
        }}
      >
        <Group position="apart">
          <Text size={20} weight={500}>{`Accounts`}</Text>
        </Group>
        <Divider style={{ marginBottom: 10 }} />
        <ScrollArea.Autosize
          maxHeight={
            "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 32px) - 150px) * 0.5)"
          }
          style={{
            minHeight: 300,
            marginBottom: 8,
          }}
          type="auto"
          scrollbarSize={9}
          sx={(theme) => ({
            ".mantine-ScrollArea-root": {
              borderRadius: 4,
              paddingRight: 1,
            },
            ".mantine-ScrollArea-viewport": {
              borderRight: `1px solid ${theme.colors.greenMachine[7]}`,
            },
            ".mantine-ScrollArea-scrollbar": {
              background: theme.colors.dark[4],
              ".mantine-ScrollArea-thumb": {
                backgroundColor: theme.colors.greenMachine[6],
              },
            },
          })}
        >
          {currentDesigner && (
            <KapexTable<AccountsData>
              tableSx={{
                thead: {
                  th: {
                    ">div": {
                      color: "#fff",
                    },
                  },
                  "::after": {
                    left: "-1px",
                  },
                },
                tbody: {
                  tr: {
                    td: {
                      borderColor: toggleTheme.card_disabled_bg,
                      ">div": {
                        color: toggleTheme.text,
                      },
                    },
                  },
                  "tr:hover": {
                    backgroundColor: `${toggleTheme.card_disabled_bg} !important`,
                  },
                },
                tfoot: {
                  td: {
                    div: {
                      color: "#fff",
                    },
                  },
                },
              }}
              headerColor={{ r: 255, g: 255, b: 255 }}
              headerBg={theme.colors.greenMachine[6]}
              borderColor={theme.colors.greenMachine[8]}
              columnConfigs={[
                {
                  field: "name",
                  name: "Name",
                  // filter: {
                  //   type: "text",
                  //   field: "name",
                  // },
                  sortable: true,
                  cellRenderer: (rowItem) => {
                    return (
                      <Text
                        sx={{
                          fontWeight: 500,
                          color: `${toggleTheme.primary}`,
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          navigate(`/account/${rowItem.id}`);
                        }}
                      >
                        {`${rowItem.name}`}
                      </Text>
                    );
                  },
                },
                {
                  field: "name",
                  parentRelationField: "subscriber",
                  name: "Subscriber Name",
                  // filter: {
                  //   type: "text",
                  //   parentRelationField: "subscriber",
                  //   field: "name",
                  // },
                  sortable: true,
                  valueParser: (rowItem) => {
                    return `${rowItem.subscriber.user?.firstName} ${rowItem.subscriber.user?.lastName}`;
                  },
                },
                {
                  field: "name",
                  parentRelationField: "strategy",
                  name: "Strategy Name",
                  // filter: {
                  //   field: "name",
                  //   parentRelationField: "strategy",
                  //   type: "text",
                  // },
                  valueParser: (rowItem) => {
                    return rowItem.strategy ? rowItem.strategy.name : "N/A";
                  },
                  sortable: true,
                },
                {
                  field: "totalValue",
                  name: "Total Value",
                  // filter: {
                  //   field: "totalValue",
                  //   type: "text",
                  // },
                  sortable: true,
                  valueParser: (rowItem) => {
                    return currencyFormatter.format(rowItem.totalValue || 0);
                  },
                },
                {
                  field: "createdDate",
                  name: "Created Date",
                  // filter: {
                  //   field: "createdDate",
                  //   type: "text",
                  // },
                  sortable: true,
                  valueParser: (rowItem) => {
                    return rowItem
                      ? new Date(rowItem.createdDate).toDateString()
                      : "N/A";
                  },
                },
              ]}
              dataFetch={getAccounts}
            />
          )}
        </ScrollArea.Autosize>
      </Card>
    </Group>
  );
};
export default DesignerDashboard;
