import { useContext, useEffect, useState } from "react";
import {
  Group,
  Button,
  Divider,
  Card,
  ScrollArea,
  Center,
  Spoiler,
  useMantineTheme,
} from "@mantine/core";
import { Stack, Title, Text } from "@mantine/core";
import { MdChevronLeft } from "react-icons/md";
import {
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
} from "src/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { GetAccountOne } from "src/api/graphql/queries/Account";
import { AppContext } from "src/api/AppContext";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useTogglableTheme } from "src/utils/theme";

export type AccountData = NonNullable<GetAccountOneQuery["accountOne"]>;

const AccountDetailsReadOnly = ({ id }: { id: string }) => {
  const { query } = useApolloClient();
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const [account, setAccount] = useState<AccountData>();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getAccount = async (accountOneId: string) => {
    let response = await query<GetAccountOneQuery, GetAccountOneQueryVariables>(
      {
        query: GetAccountOne,
        variables: {
          accountOneId,
        },
        fetchPolicy: "no-cache",
      }
    );

    if (response && response.data !== null && response.data.accountOne) {
      setAccount(response.data.accountOne);
    }
  };

  useEffect(() => {
    if (id) {
      getAccount(id);
    }
  }, [id]);

  return (
    <div>
      <Group
        position="apart"
        style={{
          marginLeft: "3%",
          marginRight: "3%",
          marginBottom: 8,
        }}
      >
        <Group position="left" spacing={4}>
          <Title size={24} weight={500}>
            {account?.name}
          </Title>
        </Group>
        <Text weight={500} size={20}>
          {formatter.format(account?.totalValue || 0)}
        </Text>
      </Group>

      <div style={{ padding: "1.5% 3%" }}>
        <Group position="apart">
          <Text weight={500} size={18} style={{ marginBottom: -3 }}>
            {"Applied Strategy"}
          </Text>
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />

        {account?.strategy == null || undefined ? (
          <Center style={{ marginTop: "20px" }}>
            <Text color={"dimmed"}>{`No strategy currently applied`}</Text>
          </Center>
        ) : (
          <React.Fragment>
            <Card
              style={{
                margin: 4,
                backgroundColor: toggleTheme.card_bg,
                color: toggleTheme.text,
                borderRadius: 8,
                marginBottom: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              radius="lg"
            >
              <Group position="apart">
                <div>
                  <Title size={13} weight={500}>
                    {"Name"}
                  </Title>
                  <Text>{account.strategy.name}</Text>
                </div>
                <div>
                  <Title size={13} weight={500}>
                    {"Min. Investment"}
                  </Title>
                  <Text align="right">{`$${account.strategy.cashMin}`}</Text>
                </div>
              </Group>
              <Group position="apart" style={{ marginTop: 10 }}>
                <div>
                  <Title size={13} weight={500}>
                    {"Risk Level"}
                  </Title>
                  <Text>{account.strategy?.riskLevel}</Text>
                </div>

                <div>
                  <Title size={13} weight={500}>
                    {"Duration"}
                  </Title>
                  <Text>{account.strategy.termDuration}</Text>
                </div>
              </Group>

              <div style={{ marginTop: 10 }}>
                <Title size={13} weight={500}>
                  {"Description"}
                </Title>
                <Text>{account.strategy.description}</Text>
              </div>
            </Card>
          </React.Fragment>
        )}
      </div>
      <div style={{ padding: "1.5% 3%" }}>
        <Group position="apart">
          <Text weight={500} size={18}>
            {"Current Positions"}
          </Text>
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />
        {account && (
          <Card
            style={{
              padding: "5px 10px",
              marginTop: -1,
              backgroundColor: toggleTheme.card_bg,
              borderBottomRightRadius:
                (account.positions?.length || 0) - 1 ? 0 : 8,
              borderBottomLeftRadius:
                (account.positions?.length || 0) - 1 ? 0 : 8,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              color: toggleTheme.text,
              borderTop: "none",
            }}
            key={account.id + "_cash_position"}
          >
            <Group spacing={0} position="apart">
              <Stack
                spacing={5}
                style={{
                  width: "100%",
                }}
              >
                <Group position="apart" spacing={"xs"}>
                  <Group
                    position="left"
                    spacing={"xs"}
                    style={{ width: "50%" }}
                  >
                    <Text weight={700} style={{ width: "40%" }}>
                      {"Cash"}
                    </Text>
                    <Text weight={500} size={13}>
                      {``}
                    </Text>
                  </Group>
                  <Text size="md" weight={600}>
                    {formatter.format(account.unallocatedFunds || 0)}
                  </Text>
                </Group>
                <Group position="apart" spacing={"xs"}>
                  <Text
                    size="xs"
                    style={{
                      textOverflow: "ellipsis",
                      maxWidth: "75%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {"Unallocated Cash"}
                  </Text>
                  <Text size="xs">{""}</Text>
                </Group>
              </Stack>
            </Group>
          </Card>
        )}
        {account?.positions && account?.positions?.length > 0 ? (
          account?.positions
            .sort((a, b) => {
              if (!a.stockData) {
                return 1;
              }
              if (!b.stockData) {
                return -1;
              }
              return a.stockData.symbol.localeCompare(b.stockData.symbol);
            })
            .map((position, index) => {
              return (
                <Card
                  key={account.id + index}
                  style={{
                    padding: "5px 10px",
                    marginBottom: "-1px",
                    backgroundColor: position.stockData
                      ? position.selected
                        ? toggleTheme.card_bg
                        : toggleTheme.card_disabled_bg
                      : "rgba(180,0,0,0.44)",

                    borderBottomRightRadius:
                      index < (account?.positions?.length || 0) - 1 ? 0 : 8,
                    borderBottomLeftRadius:
                      index < (account?.positions?.length || 0) - 1 ? 0 : 8,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    color: position.stockData
                      ? position.selected
                        ? toggleTheme.text
                        : toggleTheme.card_disabled_text
                      : theme.white,
                  }}
                  radius="md"
                >
                  <Group spacing={0} position="apart">
                    <Stack
                      spacing={5}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Group position="apart" spacing={"xs"}>
                        <Group
                          position="left"
                          spacing={"xs"}
                          style={{ width: "50%" }}
                        >
                          <Text weight={700} style={{ width: "40%" }}>
                            {position.stockData
                              ? position.stockData.symbol
                              : "Unsupported"}
                          </Text>
                          {position.stockData && (
                            <Text weight={500} size={13}>
                              {`x ${position.shares}`}
                            </Text>
                          )}
                        </Group>
                        <Text size="md" weight={600}>
                          {position.stockData
                            ? formatter.format(position.value || 0)
                            : "N/A"}
                        </Text>
                      </Group>
                      <Group position="apart" spacing={"xs"}>
                        <Text
                          size="xs"
                          style={{
                            textOverflow: "ellipsis",
                            maxWidth: "75%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {position.stockData
                            ? position.stockData.longName
                            : position.description}
                        </Text>
                        <Text size="xs">
                          {formatter.format(
                            position.stockData
                              ? position.stockData.price
                              : position.institutionValue || 0
                          )}
                        </Text>
                      </Group>
                    </Stack>
                  </Group>
                </Card>
              );
            })
        ) : (
          <Text style={{ marginLeft: "8px" }}>{`No position(s) found`}</Text>
        )}
      </div>
    </div>
  );
};
export default AccountDetailsReadOnly;
