import {
  Button,
  Group,
  Text,
  useMantineTheme,
  Slider,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { currencyFormatter } from "src/utils/formatting";

type Props = {
  allocableCash: number;
  description?: string;
  onSubmit: (amount: number) => void;
  onClose: () => void;
  initial?: number;
  submitLabel?: string;
  closeLabel?: string;
};
const AllocateCashForm = ({
  allocableCash,
  onSubmit,
  onClose,
  description,
  initial,
  submitLabel,
  closeLabel,
}: Props) => {
  const theme = useMantineTheme();

  const form = useForm({
    initialValues: {
      amount: (initial || 0) as number,
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values.amount);
      })}
      onReset={() => onClose()}
    >
      <Text>
        {description ||
          'Select how much you intend to allocate and click "Calculate" to begin.'}
      </Text>
      <Slider
        style={{ marginTop: 28 }}
        color={theme.primaryColor}
        min={0}
        max={allocableCash}
        label={(value) => <Text>{currencyFormatter.format(value)}</Text>}
        step={1}
        value={form.values.amount}
        onChange={(value) => form.setFieldValue("amount", value)}
      />
      <Group position="center">
        <Text weight={500} size={21}>{`$`}</Text>
        <NumberInput
          style={{
            marginTop: 8,
            marginBottom: 8,
          }}
          {...form.getInputProps("amount")}
          //step={5}
          min={0}
          max={allocableCash}
        />
      </Group>

      <Group position="right" mt="md">
        <Button type="reset" color={"red"}>
          {closeLabel || `Cancel`}
        </Button>
        <Button type="submit">{submitLabel || `Calculate`}</Button>
      </Group>
    </form>
  );
};

export default AllocateCashForm;
