import { useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  Container,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  MdAttachMoney,
  MdChevronLeft,
  MdLock,
} from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { AiOutlineNumber } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import {
  GenerateDeallocateCashTrades,
  GetAccountOne,
} from "src/api/graphql/queries/Account";
import { GetPositions } from "src/api/graphql/queries/Positions";
import {
  GenerateDeallocateCashTradesQuery,
  GenerateDeallocateCashTradesQueryVariables,
  GenerateTradeToSyncQuery,
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
  GetPositionsQuery,
  GetPositionsQueryVariables,
  TradesOperation,
} from "src/generated/graphql";
import DownloadCsvButton from "../DownCsvButton";
import { ArrayElement } from "src/utils/helperTypes";
import { AppContext } from "src/api/AppContext";
import { useTogglableTheme } from "src/utils/theme";
import AllocateCashForm from "../AllocateCash/AllocateCashForm";
import { useModals } from "@mantine/modals";

type PositionData = ArrayElement<GetPositionsQuery["positionMany"]>;
type AccountData = NonNullable<GetAccountOneQuery["accountOne"]>;
type TradeSyncData = ArrayElement<
  GenerateTradeToSyncQuery["generateTradesToSync"]
>;
type TradeItems = {
  currentShares: number | null | undefined;
  currentValue: number | undefined;
  futureShares: number | null | undefined;
  futureValue: number | undefined;
  operation: TradesOperation | null | undefined | string;
  stockSymbol: string | undefined;
  stockName: string | undefined;
  selected: boolean | null | undefined;
  actionShares: number | null | undefined;
  pricePerShare: number | undefined;
};
const DeallocateCash = () => {
  const { id } = useParams();
  const { query } = useApolloClient();
  const navigate = useNavigate();
  const { isDarkMode } = useContext(AppContext);
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const modals = useModals();

  const [positions, setPositions] = useState<PositionData[]>([]);
  const [account, setAccount] = useState<AccountData>();
  const [tradeItems, setTradeItems] = useState<TradeItems[]>([]);
  const [tradeSync, setTradeSync] = useState<TradeSyncData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showShares, setShowShares] = useState<boolean>(true);
  const [toDeallocate, setToDeallocate] = useState<number>(0);
  const [allocableFunds, setAllocableFunds] = useState<number>(1);
  const [currentAllocation, setCurrentAllocation] = useState<number>(1);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getDeallocateToSync = async (amount: number) => {
    if (id) {
      let response = await query<
        GenerateDeallocateCashTradesQuery,
        GenerateDeallocateCashTradesQueryVariables
      >({
        query: GenerateDeallocateCashTrades,
        variables: {
          generateTradesToSyncId: id,
          amount,
        },
        fetchPolicy: "no-cache",
      });
      if (
        response &&
        response.data !== null &&
        response.data.generateTradesToDeallocate
      ) {
        setTradeSync(response.data.generateTradesToDeallocate);
      }
    }
  };

  const getPositions = async () => {
    if (id) {
      let response = await query<GetPositionsQuery, GetPositionsQueryVariables>(
        {
          query: GetPositions,
          variables: {
            accountId: id,
          },
          fetchPolicy: "no-cache",
        }
      );
      if (response && response.data !== null && response.data.positionMany) {
        setPositions(response.data.positionMany);
      }
    }
  };

  const getAccount = async () => {
    if (id) {
      let response = await query<
        GetAccountOneQuery,
        GetAccountOneQueryVariables
      >({
        query: GetAccountOne,
        variables: {
          accountOneId: id,
        },
        fetchPolicy: "no-cache",
      });

      if (response && response.data !== null && response.data.accountOne) {
        setAccount(response.data.accountOne);
      }
    }
  };

  const mapTradeObjects = async () => {
    const items: TradeItems[] = [];
    let currentPositions = positions
      .filter((pos) => pos.stockData)
      .map((position) => ({
        ...position,
        skip: false,
      }));

    tradeSync?.forEach((trade) => {
      const position = positions.find((pos) => {
        if (pos.stockData) {
          return pos.stockData.id == trade.stockData?.id;
        }
        return false;
      });
      if (position) {
        let futureShares =
          trade.operation === "Buy"
            ? Number(position.shares) + Number(trade.shares)
            : Number(position.shares) - Number(trade.shares);
        let pricePerShare = trade.stockData?.price || 0;
        items.push({
          currentShares: position.shares,
          currentValue: position.shares * pricePerShare,
          futureShares,
          futureValue: futureShares * pricePerShare,
          stockName: trade.stockData?.shortName,
          stockSymbol: trade.stockData?.symbol,
          operation: trade.operation,
          selected: position.selected,
          actionShares: trade.shares,
          pricePerShare,
        });
        currentPositions = currentPositions.filter(
          (pos) => pos.id != position.id
        );
      } else {
        let pricePerShare = trade.stockData?.price || 0;
        items.push({
          stockName: trade.stockData?.shortName,
          stockSymbol: trade.stockData?.symbol,
          currentShares: 0,
          currentValue: 0,
          futureShares: trade.shares,
          futureValue: (trade.shares || 0) * pricePerShare,
          operation: trade.operation,
          selected: true,
          actionShares: trade.shares,
          pricePerShare,
        });
      }
    });

    currentPositions.forEach((position) => {
      items.push({
        stockName: position.stockData?.shortName,
        stockSymbol: position.stockData?.symbol,
        currentShares: position.shares,
        currentValue: position.value || 0,
        futureShares: position.shares,
        futureValue: position.value || 0,
        selected: position.selected,
        operation: "NoAction",
        actionShares: 0,
        pricePerShare: (position.value || 0) / position.shares,
      });
    });

    setTradeItems(items);
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      getAccount();
      getPositions();
    }
  }, [id]);

  useEffect(() => {
    mapTradeObjects();
  }, [tradeSync, positions]);

  useEffect(() => {
    if (account) {
      let currAllocation = account.positions
        ?.filter((pos) => pos.selected && !pos.unsupported)
        .map((pos) => pos.value || 0)
        .reduce((a, b) => a + b, 0) || 0;
      setCurrentAllocation(currAllocation);
      modals.openModal({
        styles: {
          title: {
            fontWeight: 500,
            fontSize: 20,
          },
        },
        title: "De-Allocate Cash",
        size: "min(400px, 80vw)",
        children: (
          <AllocateCashForm
            onClose={() => {
              modals.closeAll();
              navigate(-1);
            }}
            description={
              "Select how much cash you want to deallocate from your positions. Note: You cannot remove an amount that would leave you less than the minimum cash investment of your current strategy."
            }
            allocableCash={currAllocation - (account.strategy?.cashMin || 0)}
            onSubmit={(amount) => {
              getDeallocateToSync(amount);
              setToDeallocate(amount);
              setAllocableFunds(currAllocation + account.unallocatedFunds);
              modals.closeAll();
            }}
          />
        ),
        onClose: () => {
          navigate(-1);
        },
      });
    }
  }, [account]);

  if (loading) {
    return <Loader size={20} />;
  }
  return (
    <>
      {!loading && tradeItems.length > 0 ? (
        <React.Fragment>
          <Group mb={6} position="apart">
            <Button
              variant="outline"
              onClick={() => navigate(-1)}
              size="xs"
              style={{
                fontSize: 16,
                lineHeight: "16px",
                color: theme.colors.greenMachine[7],
                border: "none",
                paddingLeft: 0,
              }}
            >
              <MdChevronLeft size={22} /> Back
            </Button>
            <DownloadCsvButton
              account={account}
              positions={positions}
              trades={tradeSync} /**CSV_Trade_sync and moved calculation inside the component */
              suffix={`CashDeallocation_${toDeallocate}`}
            />
          </Group>
          <Container
            style={{
              maxHeight: "100%",
              maxWidth: isMobile ? 600 : undefined,
              paddingLeft: "3%",
              paddingRight: "3%",
            }}
          >
            <Stack spacing={0}>
              <Text size={18} weight={500}>
                {account?.name}
              </Text>
              {/* <Group position="apart">
                <Text size={14} weight={500}>
                  {"Unallocated Cash:"}
                </Text>
                <Text size={14} weight={500}>
                  {formatter.format(
                    (account?.unallocatedFunds || 0) - toAllocate
                  )}
                </Text>
              </Group> */}
              <Group spacing={0}>
                <Text
                  style={{ width: "18%" }}
                  size={14}
                  weight={700}
                  align="right"
                >
                  {"Symbol"}
                </Text>
                <Group
                  position="apart"
                  style={{
                    padding:
                      /*showShares ? "2px 10% 4px 8%" :*/ "2px 1% 4px 15%",
                    width: "82%",
                  }}
                >
                  <Text size={14} weight={700}>
                    {`Before`}
                  </Text>
                  <Switch
                    style={{ display: "contents" }}
                    sx={{
                      ".mantine-Switch-body": {
                        marginLeft: -60,
                      },
                    }}
                    checked={showShares}
                    onChange={() => setShowShares(!showShares)}
                    onLabel={
                      <MdAttachMoney size={20} style={{ marginRight: 8 }} />
                    }
                    offLabel={
                      <AiOutlineNumber size={20} style={{ marginLeft: 8 }} />
                    }
                  />
                  <Text size={14} weight={700}>
                    {`After`}
                  </Text>
                </Group>
              </Group>
            </Stack>
            <ScrollArea.Autosize
              maxHeight={
                "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px + 75px + 36px)) )"
              }
              scrollbarSize={6}
            >
              <Card
                key={account?.id + "_trade_cash"}
                style={{
                  padding: "5px 5px",
                  marginBottom: 4,
                  background: toggleTheme.card_bg,
                  color: toggleTheme.text,
                  borderRadius: 8,
                }}
              >
                <Group spacing={0}>
                  <div style={{ width: "18%" }}>
                    <Text weight={500} size={16} align="right">
                      {`Cash:`}
                    </Text>
                  </div>

                  <Group position="apart" style={{ width: "82%" }}>
                    <Stack
                      spacing={0}
                      align={"flex-end"}
                      style={{ width: "34%" }}
                    >
                      <Text weight={600} size={16}>
                        {formatter.format(account?.unallocatedFunds || 0)}
                      </Text>
                      <Text weight={600} size={12}></Text>
                    </Stack>
                    <Stack
                      align="center"
                      spacing={0}
                      style={{
                        color: undefined,
                      }}
                    >
                      <React.Fragment>
                        <HiOutlineArrowNarrowRight size={26} />
                        <Text weight={700} size={12}></Text>
                      </React.Fragment>
                    </Stack>
                    <Stack
                      spacing={0}
                      align={"flex-end"}
                      style={{ width: "32%" }}
                    >
                      <Text weight={600} size={16}>
                        {formatter.format(
                          Math.max(
                            (account?.unallocatedFunds || 0) +
                            (tradeSync || [])
                              .map(
                                (trade) =>
                                  (trade.operation == TradesOperation.Sell
                                    ? 1
                                    : -1) *
                                  (trade.shares || 0) *
                                  (trade.stockData?.price || 0)
                              )
                              .reduce((a, b) => a + b, 0) || 0,
                            0
                          )
                        )}
                      </Text>
                      <Text weight={600} size={12}></Text>
                    </Stack>
                  </Group>
                </Group>
              </Card>
              {tradeItems.map((item, index) => {
                return (
                  <Card
                    key={item.stockSymbol || "trade" + index}
                    style={{
                      padding: "5px 5px",
                      marginBottom: 4,
                      background: item.selected
                        ? toggleTheme.card_bg
                        : toggleTheme.card_disabled_bg,
                      color: item.selected
                        ? toggleTheme.text
                        : toggleTheme.card_disabled_text,
                      borderRadius: 8,
                    }}
                  >
                    <Group spacing={0}>
                      <div style={{ width: "18%" }}>
                        <Text weight={500} size={16} align="right">
                          {`${item.stockSymbol}:`}
                        </Text>
                      </div>

                      <Group position="apart" style={{ width: "82%" }}>
                        <Stack
                          spacing={0}
                          align={"flex-end"}
                          style={{ width: "34%" }}
                        >
                          <Text weight={600} size={16}>
                            {showShares
                              ? formatter.format(item.currentValue || 0)
                              : item.currentShares}
                          </Text>
                          <Text weight={600} size={12}>
                            {showShares
                              ? `${Math.floor(
                                ((item?.currentValue || 0) /
                                  currentAllocation) *
                                1000
                              ) / 10
                              }%`
                              : `${formatter.format(
                                item.pricePerShare || 0
                              )}/s`}
                          </Text>
                        </Stack>
                        <Stack
                          align="center"
                          spacing={0}
                          style={{
                            color:
                              item.operation === "Buy"
                                ? theme.colors.red[6]
                                : item.operation === "Sell"
                                  ? theme.colors.greenMachine[6]
                                  : undefined,
                          }}
                        >
                          {item.operation == "NoAction" ? (
                            item.selected ? (
                              <Text>{`--`}</Text>
                            ) : (
                              <MdLock />
                            )
                          ) : (
                            <React.Fragment>
                              <HiOutlineArrowNarrowRight size={26} />
                              <Text weight={700} size={12}>
                                {`${item.operation?.toUpperCase()} ${item.actionShares == item.currentShares
                                    ? "ALL"
                                    : item.actionShares
                                  }`}
                              </Text>
                            </React.Fragment>
                          )}
                        </Stack>
                        <Stack
                          spacing={0}
                          align={"flex-end"}
                          style={{ width: "32%", paddingRight: 2 }}
                        >
                          <Text weight={600} size={16}>
                            {showShares
                              ? formatter.format(item.futureValue || 0)
                              : item.futureShares}
                          </Text>
                          <Text weight={600} size={12}>
                            {showShares
                              ? `${Math.floor(
                                ((item?.futureValue || 0) /
                                  (allocableFunds - toDeallocate)) *
                                1000
                              ) / 10
                              }%`
                              : `${formatter.format(
                                item.pricePerShare || 0
                              )}/s`}
                          </Text>
                        </Stack>
                      </Group>
                    </Group>
                  </Card>
                );
              })}
            </ScrollArea.Autosize>
          </Container>
        </React.Fragment>
      ) : (
        <Text>No Trade Sync Found For This Account</Text>
      )}
    </>
  );
};

export default DeallocateCash;
