import { useContext, useEffect, useState } from "react";
import {
  Button,
  Center,
  ScrollArea,
  useMantineTheme,
  Text,
  Stack,
  Group,
} from "@mantine/core";
import {
  MdCheck,
  MdCheckCircleOutline,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";
import {
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
} from "src/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { GetAccountOne } from "src/api/graphql/queries/Account";
import { AppContext } from "src/api/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

type AccountData = NonNullable<GetAccountOneQuery["accountOne"]>;

const PlaidSuccess = () => {
  const theme = useMantineTheme();
  const { setCurrentPageTitle, refetchUserData } = useContext(AppContext);
  const { query } = useApolloClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [account, setAccount] = useState<AccountData | undefined>(undefined);

  useEffect(() => {
    refetchUserData();
    setCurrentPageTitle("Link Success!");
    if (id && !account) {
      getAccount();
    }

    return () => {
      setCurrentPageTitle(undefined);
    };
  }, []);

  const getAccount = async () => {
    if (!id) {
      return;
    }
    let response = await query<GetAccountOneQuery, GetAccountOneQueryVariables>(
      {
        query: GetAccountOne,
        variables: {
          accountOneId: id,
        },
        fetchPolicy: "no-cache",
      }
    );

    if (response.data.accountOne) {
      setAccount(response.data.accountOne);
    }
  };

  return (
    <ScrollArea
      style={{
        height:
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )",
      }}
      sx={{
        ".mantine-ScrollArea-viewport > div": {
          display: "block !important",
        },
      }}
      scrollbarSize={6}
    >
      <Button
        variant="outline"
        onClick={() => navigate("/my-accounts")}
        size="xs"
        style={{
          fontSize: 16,
          lineHeight: "16px",
          color: theme.colors.greenMachine[7],
          border: "none",
          paddingLeft: 0,
        }}
      >
        <MdChevronLeft size={22} /> Back
      </Button>
      <Center style={{ marginBottom: 20, marginTop: 25 }}>
        <Text
          color={theme.colors.greenMachine[6]}
          align="center"
          size={32}
          weight={500}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <MdCheckCircleOutline
            size={32}
            color={theme.colors.greenMachine[6]}
            style={{ marginRight: 8, marginTop: 4 }}
          />
          {`Success!`}
        </Text>
      </Center>
      <Stack
        align={"flex-start"}
        spacing="xl"
        style={{ paddingLeft: "5%", paddingRight: "5%" }}
      >
        <Text>
          {`Linked external account: `}
          <span style={{ fontWeight: 500 }}>{account?.name}</span>
        </Text>
        <Text>{`The next step in bolstering your investment performance is applying one of your designer's hand-crafted strategies.`}</Text>
        <Text>{`Applying a strategy helps you manage your account goals and visualize the steps needed to achieve them.`}</Text>
        <Text>{`View strategies crafted by your designer on the following page.`}</Text>

        <Group position="right" style={{ width: "100%" }}>
          <Button
            compact
            style={{
              padding: "8px 12px 10px 18px",
              height: 40,
            }}
            size="lg"
            onClick={() => {
              navigate(`/strategy/account/${id}`);
            }}
          >
            {"Continue"} <MdChevronRight size={20} style={{ marginTop: 4 }} />
          </Button>
        </Group>
      </Stack>
    </ScrollArea>
  );
};
export default PlaidSuccess;
