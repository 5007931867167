import { useApolloClient } from "@apollo/client";
import {
  Button,
  Group,
  Text,
  Stack,
  Title,
  ActionIcon,
  useMantineTheme,
  Divider,
  NumberInput,
} from "@mantine/core";
import { MdCheck, MdCancel, MdEdit, MdAdd } from "react-icons/md";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import React, { useContext, useState } from "react";

import {
  CreateRevenueShareInput,
  CreateRevenueShareMutation,
  CreateRevenueShareMutationVariables,
  UpdateRevenueShareInput,
  UpdateRevenueShareMutation,
  UpdateRevenueShareMutationVariables,
  UserRole,
} from "src/generated/graphql";
import { DesignerData } from "./DesignerDetails";
import {
  CreateRevenueShare,
  UpdateRevenueShare,
} from "src/api/graphql/mutations/RevenueShare";
import { AppContext } from "src/api/AppContext";

type BasicInfoProps = {
  designer: DesignerData;
  onSave: () => void;
};

const RevenueShare = (props: BasicInfoProps) => {
  const theme = useMantineTheme();
  const { currentUser } = useContext(AppContext);
  const { mutate } = useApolloClient();
  const [newRevenueShare, setNewRevenueShare] = useState(
    props.designer.revenueShare == null
  );
  const [editing, setEditing] = useState<boolean>(false);
  const formRevenue = useForm({
    initialValues: {
      designerFee: 0 as number,
      serviceFee: 0 as number,
      designerFeeAnnual: 0 as number,
      serviceFeeAnnual: 0 as number,
      designer: { id: props.designer.id },
    },

    validate: {
      designerFee: (value: number) =>
        value !== undefined && value !== null
          ? null
          : "You must supply a designer fee!",
      serviceFee: (value: number) =>
        value !== undefined && value !== null
          ? null
          : "You must supply a service fee!",
      designerFeeAnnual: (value: number) =>
        value !== undefined && value !== null
          ? null
          : "You must supply a designer fee!",
      serviceFeeAnnual: (value: number) =>
        value !== undefined && value !== null
          ? null
          : "You must supply a service fee!",
    },
  });

  const createRevenueShare = async (values: CreateRevenueShareInput) => {
    showNotification({
      id: "create-revenue-share",
      loading: true,
      title: "Loading",
      message: "Attempting to update revenue share...",
      autoClose: false,
      disallowClose: true,
    });
    try {
      let response = await mutate<
        CreateRevenueShareMutation,
        CreateRevenueShareMutationVariables
      >({
        mutation: CreateRevenueShare,
        variables: {
          data: values,
        },
        fetchPolicy: "no-cache",
      });

      if (response) {
        setNewRevenueShare(false);
        updateNotification({
          id: "create-revenue-share",
          color: response.data?.createRevenueShare ? "green" : "red",
          title: response.data?.createRevenueShare ? "Success!" : "Failure!",
          message: response.data?.createRevenueShare
            ? "Revenue share details updated!"
            : "An error occured while updating the revenue share...",
          icon: response.data?.createRevenueShare ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
        if (response.data?.createRevenueShare) {
          props.onSave();
        } else {
          console.log(response.errors);
        }
      }
    } catch (error) {
      updateNotification({
        id: "create-revenue-share",
        color: "red",
        title: "Failure!",
        message: "Unable to create the designer's revenue share!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  const updateRevenueShare = async (values: UpdateRevenueShareInput) => {
    showNotification({
      id: "update-revenue-share",
      loading: true,
      title: "Loading",
      message: "Attempting to update revenue share...",
      autoClose: false,
      disallowClose: true,
    });

    try {
      let response = await mutate<
        UpdateRevenueShareMutation,
        UpdateRevenueShareMutationVariables
      >({
        mutation: UpdateRevenueShare,
        variables: {
          id: props.designer.revenueShare?.id!,
          data: {
            designerFee: values.designerFee,
            serviceFee: values.serviceFee,
            designerFeeAnnual: values.designerFeeAnnual,
            serviceFeeAnnual: values.serviceFeeAnnual,
          },
        },
        fetchPolicy: "no-cache",
      });

      if (response) {
        updateNotification({
          id: "update-revenue-share",
          color: response.data?.updateRevenueShare ? "green" : "red",
          title: response.data?.updateRevenueShare ? "Success!" : "Failure!",
          message: response.data?.updateRevenueShare
            ? "Revenue share details updated!"
            : "An error occured while updating the revenue share...",
          icon: response.data?.updateRevenueShare ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });
        if (response.data?.updateRevenueShare) {
          props.onSave();
        } else {
          console.log(response.errors);
        }
      }
    } catch (error) {
      updateNotification({
        id: "update-revenue-share",
        color: "red",
        title: "Failure!",
        message: "Unable to update the designer's revenue share!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  return (
    <div
      style={{
        boxShadow: editing
          ? `0px 0px 4px 0px ${theme.colors.gray[5]}`
          : undefined,
        backgroundColor: editing ? theme.colors.gray[0] : undefined,
        borderRadius: 8,
        padding: 10,
        margin: "3px 0px",
        marginBottom: 10,
      }}
    >
      <Group position="apart">
        <Text weight={500} size={20}>
          {"Revenue Share"}
        </Text>
        {currentUser?.role === UserRole.PlatformAdmin &&
          !editing &&
          (newRevenueShare ? (
            <ActionIcon
              onClick={() => {
                formRevenue.setValues({
                  designerFee:
                    props.designer.revenueShare?.designerFee || undefined,
                  serviceFee:
                    props.designer.revenueShare?.serviceFee || undefined,
                  designerFeeAnnual:
                    props.designer.revenueShare?.designerFeeAnnual || undefined,
                  serviceFeeAnnual:
                    props.designer.revenueShare?.serviceFeeAnnual || undefined,
                });
                setEditing(true);
              }}
            >
              <MdAdd />
            </ActionIcon>
          ) : (
            <ActionIcon
              onClick={() => {
                formRevenue.setValues({
                  designerFee:
                    props.designer.revenueShare?.designerFee || undefined,
                  serviceFee:
                    props.designer.revenueShare?.serviceFee || undefined,
                  designerFeeAnnual:
                    props.designer.revenueShare?.designerFeeAnnual || undefined,
                  serviceFeeAnnual:
                    props.designer.revenueShare?.serviceFeeAnnual || undefined,
                });
                setEditing(true);
              }}
            >
              <MdEdit />
            </ActionIcon>
          ))}
      </Group>
      <Divider style={{ marginBottom: 5 }} />
      <div style={{ marginBottom: 0 }}>
        {editing ? (
          <form
            onSubmit={formRevenue.onSubmit((values) => {
              setEditing(false);
              newRevenueShare
                ? createRevenueShare(values)
                : updateRevenueShare(values);
            })}
            onReset={() => {
              setEditing(false);
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            ></div>

            <NumberInput
              style={{ marginTop: 12 }}
              decimalSeparator="."
              precision={2}
              label="Quarterly Designer Fee"
              //value={props.designer.revenueShare?.designerFee}
              {...formRevenue.getInputProps("designerFee")}
            />
            <NumberInput
              style={{ marginTop: 12 }}
              decimalSeparator="."
              precision={2}
              label="Quarterly Service Fee"
              //value={props.designer.revenueShare?.serviceFee}
              {...formRevenue.getInputProps("serviceFee")}
            />
            <NumberInput
              style={{ marginTop: 12 }}
              decimalSeparator="."
              precision={2}
              label="Annual Designer Fee"
              //value={props.designer.revenueShare?.designerFeeAnnual}
              {...formRevenue.getInputProps("designerFeeAnnual")}
            />
            <NumberInput
              style={{ marginTop: 12 }}
              decimalSeparator="."
              precision={2}
              label="Annual Service Fee"
              //value={props.designer.revenueShare?.serviceFeeAnnual}
              {...formRevenue.getInputProps("serviceFeeAnnual")}
            />
            <Group position="right" mt="md">
              <Button type="reset">Cancel</Button>
              <Button type="submit">Save</Button>
            </Group>
          </form>
        ) : props.designer.revenueShare ? (
          <Stack align="flex-start">
            <div>
              <Title size={14} weight={500}>
                {"Quarterly Designer Fee"}
              </Title>
              <Text style={{ marginLeft: "8px" }}>
                $ {Number(props.designer.revenueShare?.designerFee).toFixed(2)}
              </Text>
            </div>
            <div>
              <Title size={14} weight={500}>
                {"Quarterly Service Fee"}
              </Title>
              <Text style={{ marginLeft: "8px" }}>
                $ {Number(props.designer.revenueShare?.serviceFee).toFixed(2)}
              </Text>
            </div>
            <div>
              <Title size={14} weight={500}>
                {"Annual Designer Fee"}
              </Title>
              <Text style={{ marginLeft: "8px" }}>
                ${" "}
                {Number(props.designer.revenueShare?.designerFeeAnnual).toFixed(
                  2
                )}
              </Text>
            </div>
            <div>
              <Title size={14} weight={500}>
                {"Annual Service Fee"}
              </Title>
              <Text style={{ marginLeft: "8px" }}>
                ${" "}
                {Number(props.designer.revenueShare?.serviceFeeAnnual).toFixed(
                  2
                )}
              </Text>
            </div>
          </Stack>
        ) : (
          <Text
            color={"dimmed"}
            weight={500}
            align="center"
            style={{ marginTop: 30 }}
          >{`Revenue Share not yet created.`}</Text>
        )}
      </div>
    </div>
  );
};

export default RevenueShare;
