import { gql } from "@apollo/client";

export const PublishQuarterlyNewsletter = gql`
  mutation PublishQuarterlyNewsletter($newsletterTemplateId: String!) {
    publishQuarterlyNewsletter(newsletterTemplateId: $newsletterTemplateId) {
      id
      header
      body
      quarter
      year
    }
  }
`;
