import {
  Button,
  Group,
  Select,
  Textarea,
  TextInput,
  Text,
  NumberInput,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { Switch } from "@mantine/core";

import {
  RiskLevel,
  TermDuration,
  UpdateStrategyInput,
} from "src/generated/graphql";
import { useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";

type Props = {
  form: UseFormReturnType<{
    description: string | undefined;
    enabled: boolean | undefined;
    name: string | undefined;
    riskLevel: RiskLevel | undefined;
    termDuration: TermDuration | undefined;
    syncThreshold: number | undefined;
  }>;
  opinionPercentage: number;
  setEditing: (value: boolean) => void;
  updateStrategy: (value: UpdateStrategyInput) => void;
};
const StrategyUpdateForm = ({
  form,
  opinionPercentage,
  setEditing,
  updateStrategy,
}: Props) => {
  const [canToggle, setCanToggle] = useState(Boolean);

  useEffect(() => {
    setCanToggle(opinionPercentage == 100);
  }, [opinionPercentage]);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        setEditing(false);
        updateStrategy({ ...values });
      })}
      onReset={() => setEditing(false)}
      style={{ marginBottom: 16 }}
    >
      <Group position="apart" style={{ marginTop: 12 }}>
        <Group position="apart" style={{ width: "100%", maxWidth: 455 }}>
          <TextInput
            style={{ width: 200 }}
            label="Name"
            {...form.getInputProps("name")}
          />

          <NumberInput
            label="Sync Threshold"
            placeholder={"Percentage Value"}
            {...form.getInputProps("syncThreshold")}
            max={30}
            min={1}
          />
        </Group>
        <Group position="apart" style={{ width: "100%", maxWidth: 455 }}>
          <Select
            style={{ width: 200 }}
            label="Risk Level"
            placeholder="Select"
            data={[
              { value: RiskLevel.Aggressive, label: RiskLevel.Aggressive },
              {
                value: RiskLevel.Conservative,
                label: RiskLevel.Conservative,
              },
              { value: RiskLevel.Moderate, label: RiskLevel.Moderate },
            ]}
            {...form.getInputProps("riskLevel")}
          />
          <Select
            style={{ width: 200 }}
            label="Term Duration"
            placeholder="Select"
            data={[
              { value: TermDuration.Growth, label: TermDuration.Growth },
              { value: TermDuration.Income, label: TermDuration.Income },
              {
                value: TermDuration.ShortTerm,
                label: TermDuration.ShortTerm,
              },
            ]}
            {...form.getInputProps("termDuration")}
          />
        </Group>
        <Textarea
          style={{ marginTop: 12, width: "100%" }}
          minRows={3}
          label="Description"
          {...form.getInputProps("description")}
        />
      </Group>

      <Group position="right" mt="md">
        <Button type="reset">Cancel</Button>
        <Button type="submit">Save</Button>
      </Group>
    </form>
  );
};

export default StrategyUpdateForm;
