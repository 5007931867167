import { useApolloClient } from "@apollo/client";
import {
  Alert,
  Button,
  Card,
  Center,
  Container,
  ScrollArea,
  Stack,
  Image,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MdCircleNotifications } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { GetDesignerShort } from "src/api/graphql/queries/Designer";
import {
  DesignerShortQuery,
  DesignerShortQueryVariables,
} from "src/generated/graphql";

type DesignerData = NonNullable<DesignerShortQuery["designerShort"]>;

const UnsubscribedDesignerDetails = () => {
  const { id } = useParams();
  const { query } = useApolloClient();
  const [designer, setDesigner] = useState<DesignerData | undefined>(undefined);
  const navigate = useNavigate();

  const getDesigner = async (id: string) => {
    const response = await query<
      DesignerShortQuery,
      DesignerShortQueryVariables
    >({
      query: GetDesignerShort,
      fetchPolicy: "no-cache",
      variables: {
        designerShortId: id,
      },
    });
    if (response.data.designerShort) {
      setDesigner(response.data.designerShort);
    }
  };

  useEffect(() => {
    if (id && !designer) {
      getDesigner(id);
    }
  }, [id]);

  return (
    <ScrollArea style={{ height: "78vh" }} type="auto">
      <Container>
        <Alert
          icon={<MdCircleNotifications size={16} />}
          title="Login Required!"
          color="red"
        >
          To subscribe to this designer you need to log in or sign up!
        </Alert>

        <Center>
          <Image
            alt={"Designer Profile Picture"}
            withPlaceholder
            src={designer?.profilePicURL}
            height={250}
            width={250}
            radius={150}
            style={{
              marginBottom: "10%",
              marginTop: "5%",
            }}
            sx={{
              ".mantine-Image-imageWrapper": {
                aspectRatio: "1/1 !important",
                ">img": {
                  opacity: designer?.profilePicURL ? 1 : 0,
                },
              },
            }}
          />
        </Center>
        <Center>
          <Text
            align="center"
            weight={600}
            size={28}
          >{`${designer?.firstName} ${designer?.lastName}`}</Text>
        </Center>
        <Center>
          <Card
            style={{
              marginTop: "5px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
              padding: "5px 10px",
            }}
            shadow="sm"
            radius="md"
            withBorder
          >
            <Text align="center">
              {designer?.bio || "No saved bio associated with this designer."}
            </Text>
          </Card>
        </Center>
        <Stack align="stretch" spacing={"lg"} style={{ marginTop: 20 }}>
          <Button
            radius="xl"
            size="lg"
            style={{
              backgroundColor: "#4dab69",
              fontSize: "20px",
            }}
            onClick={() => {
              window.localStorage.setItem("toURL", window.location.pathname);
              navigate("/");
            }}
          >
            Login / Sign up
          </Button>
        </Stack>
      </Container>
    </ScrollArea>
  );
};
export default UnsubscribedDesignerDetails;
