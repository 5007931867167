import { gql } from "@apollo/client";

export const PublishDesignerNewsletter = gql`
  mutation PublishDesignerNewsletter($data: CreateDesignerNewsletterInput!) {
    publishDesignerNewsletter(data: $data) {
      id
      header
      body
      quarter
      year
      designerId
    }
  }
`;
