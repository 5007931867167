import { useApolloClient } from "@apollo/client";
import {
  ActionIcon,
  Box,
  CopyButton,
  Group,
  Loader,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons";
import { useContext, useState } from "react";
import { AppContext } from "src/api/AppContext";
import { GetEnv } from "src/utils/EnvConfig";
import BasicInfo from "../Designer/components/BasicInfo";
import DesignerDetails from "../Designer/components/DesignerDetails";
import RevenueShare from "../Designer/components/RevenueShare";
import SupportContact from "../Designer/components/SupportContact";
import StripeInfo from "./components/StripeInfo";

const baseUrl = window.location.origin;

const Designer = () => {
  const { currentDesigner, refetchUserData } = useContext(AppContext);

  return (
    <div>
      <Group position="apart">
        <Text style={{ fontWeight: 400, marginBottom: 5 }} size={20}>
          {"My Profile"}
        </Text>
        <Group
          style={{ width: "50%", paddingRight: "3%" }}
          spacing={2}
          position="right"
        >
          <Text
            size={14}
            weight={500}
            style={{
              width: "calc(100% - 30px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >{`Designer Link: ${baseUrl}/designer/${currentDesigner?.id}`}</Text>
          <CopyButton
            value={`${baseUrl}/designer/${currentDesigner?.id}`}
            timeout={2000}
          >
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                  {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      </Group>
      {currentDesigner ? (
        <Group
          position="left"
          spacing={5}
          style={{ alignItems: "flex-start", rowGap: 30 }}
        >
          <Box mx={"sm"}>
            <BasicInfo
              designer={currentDesigner}
              onSave={() => refetchUserData()}
            />
          </Box>
          <Box mx={"sm"}>
            <StripeInfo />
          </Box>
          <Box mx={"sm"}>
            <RevenueShare
              designer={currentDesigner}
              onSave={() => refetchUserData()}
            />
          </Box>
          <Box mx={"sm"}>
            <SupportContact onSave={() => refetchUserData()} />
          </Box>
        </Group>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Designer;
