import { gql } from "@apollo/client";

export const CreateSwapRequest = gql`
    mutation CreateSwapRequest($data: CreateSwapRequestInput!) {
    createSwapRequest(data: $data) {
      id
      createdDate
      designerId
      subscriptionId
    }
  }
`;