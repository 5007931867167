import { gql } from "@apollo/client";

export const GetDesignerReport = gql`
  query GetDesignerReport(
    $timeRange: String!
    $designerId: String!
    $strategyId: String!
  ) {
    getDesignerReport(
      timeRange: $timeRange
      designerId: $designerId
      strategyId: $strategyId
    ) {
      totalAccounts
      totalSubscribers
      strategyData {
        totalAccounts
        performance
        date
        quarter
      }
    }
  }
`;
