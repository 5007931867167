/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AppContext } from "src/api/AppContext";
import {
  BeginFreeTrialMutation,
  BeginFreeTrialMutationVariables,
  GetDesignerQuery,
  GetDesignerQueryVariables,
} from "src/generated/graphql";
import { GetDesigner } from "src/api/graphql/queries/Designer";
import {
  Container,
  ScrollArea,
  Button,
  Center,
  Image,
  Text,
  Stack,
  Card,
  useMantineTheme,
  Alert,
} from "@mantine/core";
import { BeginFreeTrial } from "src/api/graphql/mutations/Subscription";
import { showNotification, updateNotification } from "@mantine/notifications";
import { MdCancel, MdCheck, MdCircleNotifications } from "react-icons/md";
import SwapRequestForm from "./components/SwapRequestForm";
import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";

type DesignerData = NonNullable<GetDesignerQuery["designerOne"]>;

const DesignerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useMantineTheme();
  const modals = useModals();
  const { query, mutate } = useApolloClient();
  const { currentSubscriber, refetchUserData, setCurrentPageTitle } =
    useContext(AppContext);

  const [designer, setDesigner] = useState<DesignerData | undefined>(undefined);

  const getDesigner = async (id: string) => {
    const response = await query<GetDesignerQuery, GetDesignerQueryVariables>({
      query: GetDesigner,
      fetchPolicy: "no-cache",
      variables: {
        id,
      },
    });
    if (response.data.designerOne) {
      setDesigner(response.data.designerOne);
    }
  };

  useEffect(() => {
    setCurrentPageTitle("About Designer");
    if (id && !designer) {
      getDesigner(id);
    }
    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [id]);

  const handleSwap = () => {
    modals.openModal({
      title: "Swap Request",
      style: {
        width: "85%",
      },
      children: (
        <SwapRequestForm
          designerId={id}
          onClose={() => {
            modals.closeAll();
            refetchUserData();
          }}
        />
      ),
    });
  };

  const handleBeginFreeTrial = async () => {
    if (!designer || !currentSubscriber) {
      return console.log("Either designer or subscriber are not available.");
    }

    try {
      showNotification({
        id: "begin-free-trial",
        loading: true,
        title: "Loading",
        message: "Creating free trial...",
        autoClose: false,
        disallowClose: true,
      });

      const response = await mutate<
        BeginFreeTrialMutation,
        BeginFreeTrialMutationVariables
      >({
        mutation: BeginFreeTrial,
        variables: {
          data: {
            designer: {
              id: designer.id,
            },
            subscriber: {
              id: currentSubscriber.id,
            },
          },
        },
      });

      if (response?.data?.beginFreeTrial) {
        refetchUserData();
        navigate("/trial-success");
      }

      updateNotification({
        id: "begin-free-trial",
        color: response.data?.beginFreeTrial ? "green" : "red",
        title: response.data?.beginFreeTrial ? "Success!" : "Failure!",
        message: response.data?.beginFreeTrial
          ? "Free trial created successfully."
          : "An error occured while creating Free Trial...",
        icon: response.data?.beginFreeTrial ? (
          <MdCheck size={16} />
        ) : (
          <MdCancel size={16} />
        ),
        autoClose: 2000,
      });
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      updateNotification({
        id: "begin-free-trial",
        color: "red",
        title: "Failure!",
        message: message || "Unable to start free trial!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };

  const handleSubscribe = async () => {
    navigate(`/subscription/packages/${designer?.id}`);
  };

  let subbedToCurrentDesigner =
    currentSubscriber?.subscription?.designerId === designer?.id;
  let pendingSwapToCurrentDesigner =
    currentSubscriber?.subscription?.swapRequest?.designerId === designer?.id;
  let isExpired = currentSubscriber?.subscription?.isExpired;

  return (
    <ScrollArea style={{ height: "78vh" }} type="auto">
      <Container>
        {currentSubscriber?.subscription?.trial &&
          currentSubscriber?.subscription?.isExpired && (
            // currentSubscriber.subscription.status !== "trialing" &&
            // currentSubscriber.subscription.status !== "active" &&
            <Alert
              icon={<MdCircleNotifications size={16} />}
              title={`${
                currentSubscriber?.subscription?.trial
                  ? "Trial"
                  : "Subscription"
              } Expired!`}
              color="red"
            >
              {`Your ${
                currentSubscriber.subscription.trial ? "trial" : "subscription"
              } period has expired, subscribe to continue using
            our services!`}
            </Alert>
          )}
        <Center>
          <Image
            alt={"Designer Profile Picture"}
            withPlaceholder
            src={designer?.profilePicURL}
            height={250}
            width={250}
            radius={150}
            style={{
              marginBottom: "10%",
              marginTop: "5%",
            }}
            sx={{
              ".mantine-Image-imageWrapper": {
                aspectRatio: "1/1 !important",
                ">img": {
                  opacity: designer?.profilePicURL ? 1 : 0,
                },
              },
            }}
          />
        </Center>
        <Center>
          <Text
            align="center"
            weight={600}
            size={28}
          >{`${designer?.user?.firstName} ${designer?.user?.lastName}`}</Text>
        </Center>
        <Center>
          <Card
            style={{
              marginTop: "5px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
              padding: "5px 10px",
            }}
            shadow="sm"
            radius="md"
            withBorder
          >
            <Text align="center">
              {designer?.bio || "No saved bio associated with this designer."}
            </Text>
          </Card>
        </Center>
        <Stack align="stretch" spacing={"lg"} style={{ marginTop: 20 }}>
          <Button
            disabled={
              (subbedToCurrentDesigner && !isExpired) ||
              pendingSwapToCurrentDesigner
            }
            radius="xl"
            size="lg"
            leftIcon={
              ((subbedToCurrentDesigner && !isExpired) ||
                pendingSwapToCurrentDesigner) && (
                <MdCheck color={theme.colors.gray[6]} />
              )
            }
            style={{
              backgroundColor: "#4dab69",
              fontSize: "20px",
            }}
            onClick={
              currentSubscriber?.trialAvailable
                ? handleBeginFreeTrial
                : isExpired
                ? handleSubscribe
                : subbedToCurrentDesigner
                ? () => {}
                : pendingSwapToCurrentDesigner
                ? () => {}
                : handleSwap
            }
          >
            {currentSubscriber?.trialAvailable
              ? "Begin Free Trial"
              : isExpired
              ? "Subscribe"
              : subbedToCurrentDesigner
              ? "Subscribed"
              : pendingSwapToCurrentDesigner
              ? "Swap Requested"
              : "Swap Designer"}
          </Button>

          {currentSubscriber?.subscription && !isExpired ? (
            <Button
              radius="xl"
              size="lg"
              style={{
                backgroundColor: "#fff",
                border: "none",
                color: "#4dab69",
                fontSize: "20px",
                filter: "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25))",
              }}
              onClick={() => {
                navigate("/subscription");
              }}
            >
              {`View My Subscription`}
            </Button>
          ) : (
            <>
              {!currentSubscriber?.trialAvailable && (
                <Text
                  align="center"
                  weight={500}
                  size={18}
                  color={theme.colors.gray[5]}
                >
                  {`Subscription Fee: $${Number(
                    (designer?.revenueShare?.designerFee || 0) +
                      (designer?.revenueShare?.serviceFee || 0)
                  ).toFixed(2)}`}
                </Text>
              )}
            </>
          )}
        </Stack>
      </Container>
    </ScrollArea>
  );
};

export default DesignerDetails;
