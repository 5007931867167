import { gql } from "@apollo/client";

export const GetUsers = gql`
  query GetUsers {
    userMany {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
      createdDate
    }
  }
`;

export const GetUser = gql`
  query GetUser($id: String) {
    userOne(id: $id) {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
      createdDate
      acceptedTAC
      smsConsent
      completedOnboarding
    }
  }
`;

export const GetUserPage = gql`
  query GetUserPage($pagination: PaginationInput!) {
    userPage(pagination: $pagination) {
      items {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
        createdDate
      }
      totalRows
    }
  }
`;
