import {
  Badge,
  Card,
  Group,
  ScrollArea,
  Stack,
  Text,
  Grid,
  useMantineTheme,
  Button,
  Divider,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/api/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { GetSubscriberStrategies } from "src/api/graphql/queries/Strategy";
import {
  GetAccountOneQuery,
  GetAccountOneQueryVariables,
  GetSubscriberQuery,
  GetSubscriberStrategiesQuery,
  GetSubscriberStrategiesQueryVariables,
} from "src/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { GetAccountOne } from "src/api/graphql/queries/Account";
import { AccountData } from "src/modules/MobileScreens/AccountDetails";
import AccountRiskAssessment from "src/modules/Onboarding/components/AccountRiskAssessment";
import { useMediaQuery } from "@mantine/hooks";

type CurrentSubscriber = NonNullable<GetSubscriberQuery["subscriberOne"]>;
type DesignerData = NonNullable<CurrentSubscriber["designer"]>;
type SubscriberRelevantStrategiesData = NonNullable<
  GetSubscriberStrategiesQuery["subscriberRelevantStrategy"]
>;

const StrategyList = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { setCurrentPageTitle, currentSubscriber } = useContext(AppContext);
  const [strategies, setStrategies] = useState<
    DesignerData["strategies"] | SubscriberRelevantStrategiesData
  >();
  const [accountDetails, setAccountDetails] = useState<AccountData>();
  // const [riskAssessment, setRiskAssessment] = useState<boolean | undefined>(
  //   undefined
  // );

  const { query } = useApolloClient();
  const theme = useMantineTheme();
  const strategyClickHandler = (strategyId: string) => {
    navigate(`/strategy/${strategyId}/account/${accountId}`);
  };

  const isMobile = useMediaQuery("(max-width: 500px)");

  const getSubscriberRelevantStrategies = async (accountId: string) => {
    const response = await query<
      GetSubscriberStrategiesQuery,
      GetSubscriberStrategiesQueryVariables
    >({
      query: GetSubscriberStrategies,
      variables: {
        accountId,
      },
      fetchPolicy: "no-cache",
    });
    if (response.data.subscriberRelevantStrategy) {
      setStrategies(response.data.subscriberRelevantStrategy);
    }
  };

  const getAccount = async (accountOneId: string) => {
    let response = await query<GetAccountOneQuery, GetAccountOneQueryVariables>(
      {
        query: GetAccountOne,
        variables: {
          accountOneId,
        },
        fetchPolicy: "no-cache",
      }
    );

    if (response && response.data !== null && response.data.accountOne) {
      setAccountDetails(response.data.accountOne);
    }
    if (response.data.accountOne?.assessment) {
      //setRiskAssessment(true);
    }
  };

  useEffect(() => {
    setCurrentPageTitle("Strategies");
    if (accountId) {
      getSubscriberRelevantStrategies(accountId);
      getAccount(accountId);
    } else {
      setStrategies(currentSubscriber?.designer?.strategies);
    }

    return () => {
      setCurrentPageTitle(undefined);
    };
  }, [accountId, currentSubscriber?.designer?.strategies, setCurrentPageTitle]);

  // if (!riskAssessment && accountDetails) {
  //   return (
  //     <AccountRiskAssessment
  //       setRiskAssessment={setRiskAssessment}
  //       accountId={accountDetails?.id}
  //     />
  //   );
  // }

  return (
    <ScrollArea.Autosize
      maxHeight="calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px )) )"
      style={{ marginBottom: "10px" }}
      type="auto"
      scrollbarSize={5}
    >
      <Button
        variant="outline"
        onClick={() => navigate(-1)}
        size="xs"
        style={{
          fontSize: 16,
          lineHeight: "16px",
          color: theme.colors.greenMachine[7],
          border: "none",
          paddingLeft: 0,
        }}
      >
        <MdChevronLeft size={22} /> Back
      </Button>

      <Text
        style={{
          marginLeft: "3%",
          marginRight: "3%",
          marginTop: 25,
          marginBottom: 10,
        }}
      >
        {`Viewing strategy options for: `}
        <span style={{ color: theme.colors.greenMachine[7], fontWeight: 500 }}>
          {accountDetails &&
            `${accountDetails.custodian.name} ${
              accountDetails.mask ? " - #" + accountDetails.mask : ""
            }`}
        </span>
      </Text>
      <Text style={{ marginLeft: "3%", marginRight: "3%", marginBottom: 20 }}>
        {`Click on a strategy below to view its details.`}
      </Text>
      <Stack spacing={5} style={{ marginTop: "20px" }}>
        <Group
          position="apart"
          style={{
            marginLeft: "3%",
            marginRight: "3%",
          }}
        >
          <Text align="left" weight={500} size={20}>
            {`Strategies`}
          </Text>
          <Text
            align="right"
            style={{ alignSelf: "end", paddingBottom: "4px" }}
            weight={500}
            size={12}
          >
            {`By ${currentSubscriber?.designer?.user?.firstName} ${currentSubscriber?.designer?.user?.lastName}`}
          </Text>
        </Group>
        <Divider style={{ margin: "0px 3%" }} />
        <Stack p={10} spacing={0} style={{ marginTop: -8, padding: "1.5% 3%" }}>
          {strategies &&
            strategies.map((strategy, index) => {
              let selected = strategy.id == accountDetails?.strategy?.id;
              return (
                <Card
                  style={{
                    padding: "5px 10px",
                    marginBottom: -1,
                    backgroundColor: selected
                      ? theme.colors.greenMachine[6]
                      : "rgba(0,0,0,0.03)",
                    color: selected ? theme.white : theme.black,
                    borderBottomRightRadius:
                      index < (strategies.length || 0) - 1 ? 0 : 8,
                    borderBottomLeftRadius:
                      index < (strategies.length || 0) - 1 ? 0 : 8,
                    borderTopRightRadius: index > 0 ? 0 : 8,
                    borderTopLeftRadius: index > 0 ? 0 : 8,
                  }}
                  key={strategy.id}
                  onClick={() => {
                    strategyClickHandler(strategy.id);
                  }}
                >
                  <Grid columns={12} justify="space-between" align="center">
                    <Grid.Col span={7}>
                      <Text size={16} weight={600}>
                        {strategy.name}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      {selected ? (
                        <Text
                          align="center"
                          size={isMobile ? 12 : 16}
                          style={{
                            minHeight: 32,
                            lineHeight: isMobile ? "16px" : undefined,
                          }}
                          weight={500}
                        >
                          {"Currently Applied"}
                        </Text>
                      ) : (
                        <Stack align="flex-end" spacing={0}>
                          <Badge
                            color="lime"
                            size={isMobile ? "xs" : "lg"}
                            variant="dot"
                            style={{ border: "none" }}
                          >
                            {strategy.termDuration.toLowerCase()}
                          </Badge>
                          <Badge
                            color="lime"
                            variant="dot"
                            style={{
                              border: "none",
                            }}
                            size={isMobile ? "xs" : "lg"}
                          >
                            {strategy.riskLevel.toLowerCase()}
                          </Badge>
                        </Stack>
                      )}
                    </Grid.Col>
                    <Grid.Col style={{ textAlign: "right" }} span={2}>
                      <MdChevronRight
                        color={
                          selected ? theme.white : theme.colors.greenMachine[6]
                        }
                        style={{
                          cursor: "pointer",
                        }}
                        size={25}
                      />
                    </Grid.Col>
                  </Grid>
                </Card>
              );
            })}
        </Stack>
      </Stack>
    </ScrollArea.Autosize>
  );
};

export default StrategyList;
