import { useApolloClient } from "@apollo/client";
import { Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useLayoutEffect, useRef, useState } from "react";
import { CreateStockData } from "src/api/graphql/mutations/StockData";
import { GetStockDataBySymbol } from "src/api/graphql/queries/StockData";
import {
  CreateStockDataMutation,
  CreateStockDataMutationVariables,
  GetStockDataBySymbolQuery,
  GetStockDataBySymbolQueryVariables,
} from "src/generated/graphql";

type Data = {
  value: string;
  label: string;
};

type Props = {
  setStockId?: (value: string) => void;
  form: UseFormReturnType<any>;
  propName: string;
  initialValue?: string;
};
const StockSearch = ({ setStockId, form, propName, initialValue }: Props) => {
  const [value, setValue] = useState<any>(initialValue || undefined);
  const { query, mutate } = useApolloClient();
  const [data, setData] = useState<Data[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onSearchInputChange = async (value: string) => {
    if (
      data.filter((stock: Data) => stock.label.includes(value)).length === 0
    ) {
      const symbol = value.split(" - ")[0];
      const stockArray = await getStockData(symbol);
      const stockData: Data[] = stockArray.map((stock) => {
        return {
          label: `${stock.symbol} - ${stock.shortName}`,
          value: stock.id,
        };
      });
      setData(stockData);
    }
  };

  useLayoutEffect(() => {
    // field reference is enable after few seconds of the view generate
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 1000)
  }, []);

  const getStockData = async (symbol: string) => {
    const response = await query<
      GetStockDataBySymbolQuery,
      GetStockDataBySymbolQueryVariables
    >({
      query: GetStockDataBySymbol,
      variables: {
        searchTerm: symbol,
      },
    });
    return response.data.stockDataSearchBySymbol;
  };

  const createStockData = async (symbol: string) => {
    try {
      const response = await mutate<
        CreateStockDataMutation,
        CreateStockDataMutationVariables
      >({
        mutation: CreateStockData,
        variables: {
          data: { symbol: symbol },
        },
      });
      if (response.data?.createStockData.id) {
        setData([
          ...data,
          {
            label: `${response.data?.createStockData.symbol}-${response.data?.createStockData.shortName}`,
            value: response.data?.createStockData.id,
          },
        ]);
      }
    } catch (err) { }
  };

  return (
    <Select
      label="Stock Symbol"
      placeholder="Search"
      ref={inputRef}
      data={data}
      onSearchChange={onSearchInputChange}
      searchable
      value={
        value
          ? data.filter((stock: Data) => stock.label.includes(value))[0]?.value
          : undefined
      }
      creatable
      clearButtonLabel="Clear"
      clearable
      allowDeselect
      onCreate={(value) => {
        createStockData(value);
        return "";
      }}
      onChange={(value: string) => {
        setValue(value);
        form.setFieldValue(propName, { id: value });
      }}
    />
  );
};

export default StockSearch;
