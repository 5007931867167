import { useApolloClient } from "@apollo/client";
import { Button, Group, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useContext, useState } from "react";
import { MdCancel, MdCheck, MdSend } from "react-icons/md";
import { AppContext } from "src/api/AppContext";
import { PublishDesignerNewsletter } from "src/api/graphql/mutations/DesignerNewsletter";
import {
  CreateDesignerNewsletterInput,
  PublishDesignerNewsletterMutation,
  PublishDesignerNewsletterMutationVariables,
} from "src/generated/graphql";
import { QuarterlyNewsletterData } from "..";
import TextEditor from "./TextEditor";

type Props = {
  initial?: QuarterlyNewsletterData;
  onClose: () => void;
  refresh?: () => void;
};

const CreateNewsletterForm = ({ onClose, initial, refresh }: Props) => {
  const { currentDesigner } = useContext(AppContext);
  const { mutate } = useApolloClient();
  const [bodyValue, setBodyValue] = useState<string | undefined>(initial?.body);
  const form = useForm({
    initialValues: {
      header: initial?.header || ("" as string),
      body: initial?.body || ("" as string),
      designer: { id: currentDesigner?.id || "" },
    },

    validate: {
      header: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a Header!",
    },
  });

  const sendDesignerNewsletter = async (
    data: CreateDesignerNewsletterInput
  ) => {
    showNotification({
      id: "create-designer-newsletter",
      loading: true,
      title: "Loading",
      message: "Attempting to send newsletter...",
      autoClose: false,
      disallowClose: true,
    });
    try {
      const response = await mutate<
        PublishDesignerNewsletterMutation,
        PublishDesignerNewsletterMutationVariables
      >({
        mutation: PublishDesignerNewsletter,
        variables: {
          data,
        },
      });

      if (response) {
        updateNotification({
          id: "create-designer-newsletter",
          color: response.data?.publishDesignerNewsletter ? "green" : "red",
          title: response.data?.publishDesignerNewsletter
            ? "Success!"
            : "Failure!",
          message: response.data?.publishDesignerNewsletter
            ? "Newsletter Sent!"
            : "An error occured while sending the newsletter...",
          icon: response.data?.publishDesignerNewsletter ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });

        if (response.data?.publishDesignerNewsletter) {
          if (refresh) {
            refresh();
          }
          onClose();
        }
      }
    } catch (err) {
      updateNotification({
        id: "create-designer-newsletter",
        color: "red",
        title: "Failure!",
        message: "An error occured while sending the newsletter...",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values && bodyValue) {
          sendDesignerNewsletter({ ...values, body: bodyValue });
        }
        onClose();
      })}
      onReset={() => onClose()}
    >
      <TextInput
        style={{ marginTop: 12 }}
        label="Subject"
        {...form.getInputProps("header")}
      />
      <Text style={{ marginTop: 12 }}>Body</Text>
      <TextEditor value={bodyValue} setValue={setBodyValue} />
      <Group position="right" mt="md">
        <Button type="reset">Cancel</Button>
        <Button type="submit" rightIcon={<MdSend size={18} />}>
          Send
        </Button>
      </Group>
    </form>
  );
};

export default CreateNewsletterForm;
