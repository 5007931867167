import { useApolloClient } from "@apollo/client";
import { Button, Group, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useState } from "react";
import { MdCancel, MdCheck } from "react-icons/md";
import { CreateNewsletterTemplate } from "src/api/graphql/mutations/NewsletterTemplate";
import {
  CreateNewsletterTemplateMutation,
  CreateNewsletterTemplateMutationVariables,
  NewsletterTemplateInput,
} from "src/generated/graphql";
import TextEditor from "./TextEditor";

type Props = {
  onClose: () => void;
  refresh?: () => void;
};

const CreateNewsletterForm = ({ onClose, refresh }: Props) => {
  const { mutate } = useApolloClient();
  const [bodyValue, setBodyValue] = useState<string | undefined>();
  const form = useForm({
    initialValues: {
      header: "" as string,
    },

    validate: {
      header: (value) =>
        value !== undefined && value !== ""
          ? null
          : "You must supply a Header!",
    },
  });

  const createNewsletterTemplate = async (values: NewsletterTemplateInput) => {
    showNotification({
      id: "create-newsletter-template",
      loading: true,
      title: "Loading",
      message: "Attempting to create newsletter template...",
      autoClose: false,
      disallowClose: true,
    });
    try {
      const response = await mutate<
        CreateNewsletterTemplateMutation,
        CreateNewsletterTemplateMutationVariables
      >({
        mutation: CreateNewsletterTemplate,
        variables: {
          data: values,
        },
      });

      if (response) {
        updateNotification({
          id: "create-newsletter-template",
          color: response.data?.createNewsletterTemplate ? "green" : "red",
          title: response.data?.createNewsletterTemplate
            ? "Success!"
            : "Failure!",
          message: response.data?.createNewsletterTemplate
            ? "Newsletter Templated Created Successfully"
            : "An error occured during creating newsletter template...",
          icon: response.data?.createNewsletterTemplate ? (
            <MdCheck size={16} />
          ) : (
            <MdCancel size={16} />
          ),
          autoClose: 2000,
        });

        if (response.data?.createNewsletterTemplate) {
          if (refresh) {
            refresh();
          }
          onClose();
        }
      }
    } catch (err) {
      updateNotification({
        id: "create-newsletter-template",
        color: "red",
        title: "Failure!",
        message: "An error occured during creating newsletter template...",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values && bodyValue) {
          createNewsletterTemplate({ ...values, body: bodyValue });
        }
        onClose();
      })}
      onReset={() => onClose()}
    >
      <TextInput
        style={{ marginTop: 12 }}
        label="Subject"
        {...form.getInputProps("header")}
      />
      <Text style={{ marginTop: 12 }}>Body</Text>
      <TextEditor value={bodyValue} setValue={setBodyValue} />
      <Group position="right" mt="md">
        <Button type="reset">Cancel</Button>
        <Button type="submit">Save</Button>
      </Group>
    </form>
  );
};

export default CreateNewsletterForm;
