import { gql } from "@apollo/client";

export const GetSubscriberAccountReport = gql`
query GetSubscriberAccountReport($timeRange: String!, $subscriberId: String!, $accountId: String!) {
  getSubscriberAccountReport(timeRange: $timeRange, subscriberId: $subscriberId, accountId: $accountId) {
    numberOfAccounts
    netWorth
    values {
      totalValue
      lifetimeDelta
      createdDate
      quarter
      year
    }
    accounts {
      id
      name
      unallocatedFunds
      totalValue
    }
  }
}
`;