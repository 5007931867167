import { gql } from "@apollo/client";

export const GetStripePaymentMethodLink = gql`
  query GetStripePaymentMethodLink(
    $cancelSubRoute: String
    $successSubRoute: String
  ) {
    getStripePaymentMethodLink(
      cancelSubRoute: $cancelSubRoute
      successSubRoute: $successSubRoute
    )
  }
`;
