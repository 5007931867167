import {
  Center,
  Container,
  useMantineTheme,
  Text,
  Stack,
  Button,
} from "@mantine/core";
import { MdError } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

const PaymentFailure = () => {
  const theme = useMantineTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Container>
      <Stack>
        <Center>
          <MdError size={100} color={theme.colors.red[6]} />{" "}
        </Center>
        <Text style={{ fontFamily: "Inter-SemiBold" }} size={50} align="center">
          Payment Failed
        </Text>
        <Button
          radius="xl"
          size="lg"
          style={{
            backgroundColor: "#fff",
            border: "none",
            color: "red",
            fontSize: "20px",
            marginTop: "10px",
            filter: "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25))",
          }}
          onClick={() => {
            navigate(`/designer/${id}`);
          }}
        >
          {`Try Again`}
        </Button>
      </Stack>
    </Container>
  );
};
export default PaymentFailure;
