import React, { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import {
  GetAdminDesignerReportQuery,
  GetAdminDesignerReportQueryVariables,
  GetAdminReportQuery,
  GetAdminReportQueryVariables,
} from "src/generated/graphql";
import {
  Card,
  Divider,
  Group,
  ScrollArea,
  Select,
  Stack,
  Text,
  Title,
  Grid,
  useMantineTheme,
  Badge,
} from "@mantine/core";
import { isMobile } from "react-device-detect";

import "./style.css";
import {
  GetAdminDesignerReport,
  GetAdminReport,
} from "src/api/graphql/queries/AdminReports";
import { MdCancel, MdCheck } from "react-icons/md";
import { showNotification, updateNotification } from "@mantine/notifications";
import { currencyFormatter } from "src/utils/formatting";
export type DesignerReportData = NonNullable<
  GetAdminDesignerReportQuery["getAdminDesignerReport"]
>;
export type AdminReportData = NonNullable<
  GetAdminReportQuery["getAdminReport"]
>;

const PlatformAdminReport = () => {
  const { query } = useApolloClient();
  const [designerReportData, setDesignerReportData] = useState<
    DesignerReportData | undefined
  >();
  const [reportData, setReportData] = useState<AdminReportData | undefined>();

  const [selectedDesignerId, setSelectedDesignerId] = useState<string>();
  const theme = useMantineTheme();
  const getDesignerData = async (designerId: string) => {
    let response = await query<
      GetAdminDesignerReportQuery,
      GetAdminDesignerReportQueryVariables
    >({
      query: GetAdminDesignerReport,
      variables: {
        designerId,
      },
      fetchPolicy: "no-cache",
    });
    if (
      response &&
      response.data !== null &&
      response.data.getAdminDesignerReport
    ) {
      setDesignerReportData(response.data.getAdminDesignerReport);
    }
  };

  const getAdminReport = async () => {
    try {
      showNotification({
        id: "get-admin-report",
        loading: true,
        title: "Loading",
        message: "Getting Report Data...",
        autoClose: false,
        disallowClose: true,
      });

      const response = await query<
        GetAdminReportQuery,
        GetAdminReportQueryVariables
      >({
        query: GetAdminReport,
        variables: {},
      });
      if (response.data.getAdminReport) {
        updateNotification({
          id: "get-admin-report",
          color: "green",
          title: "Success!",
          message: "Successful!",
          icon: <MdCheck size={16} />,
          autoClose: 2000,
        });

        setReportData(response.data.getAdminReport);
        if (
          response.data.getAdminReport &&
          response.data.getAdminReport.designers &&
          response.data.getAdminReport.designers.length > 0
        ) {
          setSelectedDesignerId(response.data.getAdminReport.designers[0].id);
        }
      }
    } catch (error) {
      let message;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      updateNotification({
        id: "get-admin-report",
        color: "red",
        title: "Failure!",
        message: message || "Unable to get the report!",
        icon: <MdCancel size={16} />,
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    getAdminReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDesignerId) {
      getDesignerData(selectedDesignerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDesignerId]);

  return (
    <ScrollArea
      style={{
        height:
          "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 36px)) )",
      }}
      sx={{
        ".mantine-ScrollArea-viewport > div": {
          display: "block !important",
        },
      }}
    >
      <div className="section2">
        <Group position="apart">
          <Text weight={500} size={18} style={{ marginBottom: -3 }}>
            {"Overview"}
          </Text>
        </Group>

        <Divider style={{ marginBottom: 5, marginTop: 2 }} />
        <Card
          style={{
            margin: 4,
            backgroundColor: "rgba(0,0,0,0.06)",
            borderRadius: 8,
            marginBottom: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            marginRight: 40,
          }}
          radius="lg"
        >
          <Group position="apart">
            <div>
              <Title size={13} weight={500}>
                {"Designers"}
              </Title>
              <Text>{`${reportData?.noOfActiveDesigners || ""}`}</Text>
            </div>
          </Group>

          <Group position="apart" style={{ marginTop: 10 }}>
            <div>
              <Title size={13} weight={500}>
                {"Accounts"}
              </Title>
              <Text>{reportData?.numberOfAccounts || ""}</Text>
            </div>
          </Group>

          <Group position="apart" style={{ marginTop: 10 }}>
            <div>
              <Title size={13} weight={500}>
                {"Avg account value"}
              </Title>
              <Text>{`${currencyFormatter.format(
                reportData?.averageValueOfAccounts || 0
              )}`}</Text>
            </div>
          </Group>
        </Card>
      </div>

      <div className="section2">
        <Group position="apart">
          <Text weight={500} size={18}>
            {"Designer Analysis"}
          </Text>
        </Group>
        <Divider style={{ marginBottom: 5, marginTop: 2 }} />

        <Card radius="md">
          <Stack spacing={2}>
            <Select
              label="Select Designer"
              placeholder="Select Designer"
              style={{
                width: "40%",
                marginBottom: 20,
              }}
              onChange={(designerId) => {
                if (designerId) setSelectedDesignerId(designerId);
              }}
              value={selectedDesignerId}
              data={
                reportData && reportData.designers
                  ? reportData.designers.map((designer) => ({
                    value: designer.id,
                    label: `${designer.user?.firstName} ${designer.user?.lastName}`,
                  }))
                  : []
              }
            />

            <div
              style={{
                position: "relative",
                width: `calc(100vw - ${isMobile ? 60 : 300}px)`,
                paddingBottom: "350px",
              }}
            >
              {selectedDesignerId ? (
                <Card
                  style={{
                    margin: 4,
                    backgroundColor: "rgba(0,0,0,0.06)",
                    borderRadius: 8,
                    marginBottom: 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    marginRight: 40,
                  }}
                  radius="lg"
                >
                  <Group position="apart">
                    <div>
                      <Title size={13} weight={500}>
                        {"Subscribers"}
                      </Title>
                      <Text>{designerReportData?.noOfSubscribers}</Text>
                    </div>
                  </Group>

                  <Group position="apart" style={{ marginTop: 10 }}>
                    <div>
                      <Title size={13} weight={500}>
                        {"Strategies"}
                      </Title>
                      <Text>{`${designerReportData?.noOfStrategies}`}</Text>
                    </div>
                  </Group>

                  <Group position="apart" style={{ marginTop: 10 }}>
                    <div>
                      <Title size={13} weight={500}>
                        {"Total Value of subscribed accounts"}
                      </Title>
                      <Text>{`${currencyFormatter.format(
                        designerReportData?.totalAmount || 0
                      )}`}</Text>
                    </div>
                  </Group>
                  <Title size={16} weight={700} style={{ marginTop: 6, }} >
                    {"Strategies"}
                  </Title>
                  <Stack p={10} spacing={0} style={{ marginTop: -8, padding: "1% 1%" }}>

                    {designerReportData?.strategies &&
                      designerReportData.strategies.map((strategy, index) => {
                        return (
                          <Card
                            style={{
                              padding: "3px 10px",
                              marginBottom: -1,
                              backgroundColor: "rgba(0,0,0,0.03)",
                              color: theme.black,
                              borderBottomRightRadius:
                                index < (designerReportData?.strategies.length || 0) - 1 ? 0 : 8,
                              borderBottomLeftRadius:
                                index < (designerReportData?.strategies.length || 0) - 1 ? 0 : 8,
                              borderTopRightRadius: index > 0 ? 0 : 8,
                              borderTopLeftRadius: index > 0 ? 0 : 8,
                            }}
                            key={strategy.name}

                          >
                            <Grid columns={12} justify="space-between" align="center">
                              <Grid.Col span={7}>
                                <Text size={16} weight={600}>
                                  {strategy.name}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={3}>
                                <Stack align="flex-end" spacing={0}>
                                  <Badge
                                    color="lime"
                                    size={isMobile ? "xs" : "lg"}
                                    variant="dot"
                                    style={{ border: "none" }}
                                  >
                                    {strategy.termDuration.toLowerCase()}
                                  </Badge>
                                  <Badge
                                    color="lime"
                                    variant="dot"
                                    style={{
                                      border: "none",
                                    }}
                                    size={isMobile ? "xs" : "lg"}
                                  >
                                    {strategy.riskLevel.toLowerCase()}
                                  </Badge>
                                </Stack>
                              </Grid.Col>
                              <Grid.Col style={{ textAlign: "right" }} span={2}>
                              </Grid.Col>
                            </Grid>
                          </Card>
                        );
                      })}
                  </Stack>
                </Card>
              ) : (
                <Card>
                  <Text>Please Select Desinger!</Text>
                </Card>
              )}
            </div>
          </Stack>
        </Card>
      </div>
    </ScrollArea>
  );
};

export default PlatformAdminReport;
