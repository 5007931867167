import { useApolloClient } from "@apollo/client";
import {
  Group,
  useMantineTheme,
  Text,
  Divider,
  Card,
  ScrollArea,
  Stack,
  Center,
  Badge,
  Button,
} from "@mantine/core";
import { useModals } from "@mantine/modals";
import React, { useContext, useEffect, useState } from "react";
import { MdChevronLeft, MdEmail, MdPerson, MdPhone } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "src/api/AppContext";
import { GetAccountMany } from "src/api/graphql/queries/Account";
import { GetSubscriber } from "src/api/graphql/queries/Subscriber";
import {
  GetAccountManyQuery,
  GetAccountManyQueryVariables,
  GetSubscriberQuery,
  GetSubscriberQueryVariables,
} from "src/generated/graphql";
import { currencyFormatter, formatPhoneNumber } from "src/utils/formatting";
import { useTogglableTheme } from "src/utils/theme";

export type SubscriberData = NonNullable<GetSubscriberQuery["subscriberOne"]>;
type AccountData = NonNullable<GetAccountManyQuery["accountMany"]>;

const SubscriberDetails = () => {
  const { id } = useParams();
  const { currentDesigner, setCurrentPageTitle, isDarkMode } =
    useContext(AppContext);
  const { query } = useApolloClient();
  const modals = useModals();
  const theme = useMantineTheme();
  const toggleTheme = useTogglableTheme(isDarkMode, theme);
  const navigate = useNavigate();

  const [subscriber, setSubscriber] = useState<SubscriberData | undefined>(
    undefined
  );
  const [accounts, setAccounts] = useState<AccountData>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCurrentPageTitle("Subscriber Details");
    getSubscriber();
    getAccounts();

    return () => {
      setSubscriber(undefined);
      setCurrentPageTitle(undefined);
    };
  }, []);

  const getSubscriber = async () => {
    if (!id) {
      console.log("Missing required subscriberId to query details!");
      return;
    }
    const response = await query<
      GetSubscriberQuery,
      GetSubscriberQueryVariables
    >({
      query: GetSubscriber,
      variables: {
        id,
      },
    });
    if (response.data.subscriberOne) {
      setSubscriber(response.data.subscriberOne);
    }
  };

  const getAccounts = async () => {
    const response = await query<
      GetAccountManyQuery,
      GetAccountManyQueryVariables
    >({
      query: GetAccountMany,
      variables: {
        subscriberId: id,
      },
    });
    if (response.data.accountMany.length > 0) {
      setAccounts(response.data.accountMany);
    }
    setLoading(false);
  };

  return (
    <ScrollArea.Autosize
      maxHeight={
        "calc((100vh - (var(--mantine-header-height, 0px) + var(--mantine-footer-height, 0px) + 40px)))"
      }
      type="auto"
      scrollbarSize={9}
    >
      <Button
        variant="outline"
        onClick={() => navigate(-1)}
        size="xs"
        style={{
          fontSize: 16,
          lineHeight: "16px",
          color: theme.colors.greenMachine[7],
          border: "none",
          paddingLeft: 0,
        }}
      >
        <MdChevronLeft size={22} /> Back
      </Button>
      <Center>
        <div style={{ maxWidth: 950 }}>
          <Group position="apart">
            <Text weight={500} size={20}>
              {"Basic Info"}
            </Text>
          </Group>
          <Divider style={{ marginBottom: 10 }} />
          {subscriber && (
            <Card
              style={{
                background: toggleTheme.card_bg,
                padding: "5px 10px",
                color: toggleTheme.text,
                borderRadius: 8,
                marginBottom: 10,
              }}
            >
              <Stack spacing={2} align="left">
                <Group align="center" position="left">
                  <MdPerson size={15} />
                  <Text
                    size={15}
                    style={{ fontFamily: "Inter-Medium" }}
                  >{`${subscriber.user?.firstName} ${subscriber.user?.lastName}`}</Text>
                </Group>
                <Divider my="sm" variant="dotted" />
                <Group align="center" position="left">
                  <MdEmail size={18} />
                  <Text
                    size={15}
                    style={{ fontFamily: "Inter-Medium" }}
                  >{`${subscriber.user?.email}`}</Text>
                </Group>
                <Divider my="sm" variant="dotted" />

                <Group align="center" position="left">
                  <MdPhone size={18} />
                  <Text size={15} style={{ fontFamily: "Inter-Medium" }}>{`${
                    subscriber.user?.phoneNumber
                      ? formatPhoneNumber(subscriber.user?.phoneNumber)
                      : "None Provided"
                  }`}</Text>
                </Group>
              </Stack>
            </Card>
          )}

          <Center>
            <div
              style={{
                borderRadius: 8,
                width: "80vw",
              }}
            >
              <Group position="apart">
                <Text weight={500} size={20}>
                  {"Accounts"}
                </Text>
              </Group>
              <Divider />
              <Stack spacing={0} style={{ marginTop: "10px" }}>
                {accounts.length > 0 ? (
                  accounts.map((account, index) => (
                    <Card
                      key={account.id}
                      style={{
                        cursor: "pointer",
                        background: toggleTheme.card_bg,
                        padding: "5px 10px",
                        marginTop: index > 0 ? -1 : 0,
                        color: toggleTheme.text,
                        borderBottomRightRadius:
                          index < accounts.length - 1 ? 0 : 8,
                        borderBottomLeftRadius:
                          index < accounts.length - 1 ? 0 : 8,
                        borderTopRightRadius: index > 0 ? 0 : 8,
                        borderTopLeftRadius: index > 0 ? 0 : 8,
                      }}
                      onClick={() => {
                        navigate(`/account/${account.id}`);
                      }}
                    >
                      <Group align="center" position="apart" spacing={"xs"}>
                        <Stack spacing="xs">
                          <Text size={20} weight={500}>
                            {account.name}
                          </Text>
                          {account.strategy?.name ? (
                            <Badge
                              variant="dot"
                              size="md"
                              color="green"
                              style={{
                                border: "none",
                                color: toggleTheme.text,
                              }}
                            >
                              {account.strategy.name}
                            </Badge>
                          ) : (
                            <Badge size="xs" color="red">
                              No Strategy Applied
                            </Badge>
                          )}
                        </Stack>
                        <Stack spacing="xs">
                          <Group align="center" position="apart">
                            <Text weight={500} size={13}>{``}</Text>
                            <Text weight={500} size={14}>
                              {`${currencyFormatter.format(
                                account.totalValue || 0
                              )}`}
                            </Text>
                          </Group>
                          <Group align="center" position="apart">
                            <Text weight={500} size={13}>{``}</Text>
                            <Text weight={500} size={13}>
                              {`${new Date(
                                account.createdDate
                              ).toDateString()}`}
                            </Text>
                          </Group>
                        </Stack>
                      </Group>
                    </Card>
                  ))
                ) : (
                  <Text align="center" size={20}>
                    No accounts to display
                  </Text>
                )}
              </Stack>
            </div>
          </Center>
        </div>
      </Center>
    </ScrollArea.Autosize>
  );
};

export default SubscriberDetails;
