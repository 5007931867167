import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Account = {
  __typename?: 'Account';
  assessment?: Maybe<AccountAssessment>;
  cashReserve: Scalars['Float'];
  createdDate: Scalars['DateTime'];
  custodian: Custodian;
  custodianId?: Maybe<Scalars['ID']>;
  deletedDate?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastFetched?: Maybe<Scalars['DateTime']>;
  lastSynced?: Maybe<Scalars['DateTime']>;
  mask?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positions?: Maybe<Array<Position>>;
  seedId?: Maybe<Scalars['String']>;
  strategy?: Maybe<Strategy>;
  strategyId?: Maybe<Scalars['ID']>;
  subscriber: Subscriber;
  subscriberId?: Maybe<Scalars['ID']>;
  subtype: Scalars['String'];
  totalValue?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  unallocatedFunds: Scalars['Float'];
  updatedDate: Scalars['DateTime'];
  withinThreshold: Scalars['Boolean'];
};

export type AccountAssessment = {
  __typename?: 'AccountAssessment';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']>;
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  riskLevel: RiskLevel;
  seedId?: Maybe<Scalars['String']>;
  termDuration: TermDuration;
  updatedDate: Scalars['DateTime'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AdminReport = {
  __typename?: 'AdminReport';
  averageValueOfAccounts: Scalars['Float'];
  designers: Array<Designer>;
  noOfActiveDesigners: Scalars['Float'];
  numberOfAccounts: Scalars['Float'];
};

export type BeginFreeTrialInput = {
  designer: GenericConnectInput;
  subscriber: GenericConnectInput;
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Card = {
  __typename?: 'Card';
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['String']>;
  exp_year?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type CombinedSubscription = {
  __typename?: 'CombinedSubscription';
  cancelled: Scalars['Boolean'];
  cost: Scalars['Float'];
  createdDate: Scalars['DateTime'];
  designerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isExpired: Scalars['Boolean'];
  renewalDate?: Maybe<Scalars['DateTime']>;
  renewalFrequency: SubscriptionRenewalFrequency;
  status?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  swapRequest?: Maybe<SwapRequest>;
  trial: Scalars['Boolean'];
};

export type CreateAccountAssessmentInput = {
  account: GenericConnectInput;
  riskLevel: RiskLevel;
  termDuration: TermDuration;
};

export type CreateDesignerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateDesignerNewsletterInput = {
  body: Scalars['String'];
  designer: GenericConnectInput;
  header: Scalars['String'];
};

export type CreateDesignerPaymentInfoInput = {
  designer: GenericConnectInput;
};

export type CreateOpinionInput = {
  accountPercentage: Scalars['Float'];
  stockData: GenericConnectInput;
  strategy: GenericConnectInput;
};

export type CreateRevenueShareInput = {
  designer: GenericConnectInput;
  designerFee: Scalars['Float'];
  designerFeeAnnual: Scalars['Float'];
  serviceFee: Scalars['Float'];
  serviceFeeAnnual: Scalars['Float'];
};

export type CreateStockDataInput = {
  symbol: Scalars['String'];
};

export type CreateStrategyInput = {
  description?: InputMaybe<Scalars['String']>;
  designer: GenericConnectInput;
  enabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  riskLevel: RiskLevel;
  syncThreshold?: InputMaybe<Scalars['Float']>;
  termDuration: TermDuration;
};

export type CreateSubscriptionInput = {
  designer: GenericConnectInput;
  renewalFrequency: SubscriptionRenewalFrequency;
  subscriber: GenericConnectInput;
};

export type CreateSwapRequestInput = {
  designer: GenericConnectInput;
  subscriber: GenericConnectInput;
};

export type Custodian = {
  __typename?: 'Custodian';
  accounts: Array<Account>;
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  plaidAccessToken: Scalars['String'];
  plaidInstitutionId: Scalars['String'];
  plaidItemId: Scalars['String'];
  reAuthRequired: Scalars['Boolean'];
  seedId?: Maybe<Scalars['String']>;
  subscriber: Subscriber;
  subscriberId?: Maybe<Scalars['ID']>;
  updatedDate: Scalars['DateTime'];
  userTag?: Maybe<Scalars['String']>;
};

export type Designer = {
  __typename?: 'Designer';
  bio?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  paymentInfo?: Maybe<DesignerPaymentInfo>;
  preferredSupportContact: PreferredSupportContact;
  profilePicURL?: Maybe<Scalars['String']>;
  revenueShare?: Maybe<RevenueShare>;
  seedId?: Maybe<Scalars['String']>;
  strategies: Array<Strategy>;
  subscribers: Array<Subscriber>;
  supportEmail?: Maybe<Scalars['String']>;
  supportPhone?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type DesignerDetails = {
  __typename?: 'DesignerDetails';
  noOfStrategies: Scalars['Float'];
  noOfSubscribers: Scalars['Float'];
  strategies: Array<Strategy>;
  totalAmount: Scalars['Float'];
};

export type DesignerNewsletter = {
  __typename?: 'DesignerNewsletter';
  body: Scalars['String'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer: Designer;
  designerId: Scalars['ID'];
  header: Scalars['String'];
  id: Scalars['ID'];
  quarter: Scalars['Float'];
  seedId?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  year: Scalars['Float'];
};

export type DesignerPaymentInfo = {
  __typename?: 'DesignerPaymentInfo';
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer?: Maybe<Designer>;
  designerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  seedId?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeOnboardingComplete: Scalars['Boolean'];
  updatedDate: Scalars['DateTime'];
};

export type DesignerReport = {
  __typename?: 'DesignerReport';
  strategyData: Array<StrategyDataPoint>;
  totalAccounts: Scalars['Int'];
  totalSubscribers: Scalars['Float'];
};

export type DesignerShort = {
  __typename?: 'DesignerShort';
  bio?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePicURL?: Maybe<Scalars['String']>;
};

export type FilterBy = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  field: Scalars['String'];
  parentRelationField?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type GenericConnectInput = {
  id: Scalars['ID'];
};

export type MigratePlaidAccountInput = {
  plaidAccountIds: Array<Scalars['String']>;
  plaidInstitutionId: Scalars['String'];
  publicToken: Scalars['String'];
  update?: InputMaybe<Scalars['Boolean']>;
  userTag?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTAC: Scalars['Boolean'];
  beginFreeTrial?: Maybe<Subscription>;
  cancelCurrentSubscription: Scalars['Boolean'];
  closeCustodians: Scalars['Boolean'];
  createAccountAssessment: AccountAssessment;
  createDesigner: Designer;
  createDesignerPaymentInfo: DesignerPaymentInfo;
  createNewsletterTemplate: NewsletterTemplate;
  createOpinion: Opinion;
  createRevenueShare: RevenueShare;
  createStockData: StockData;
  createStrategy: Strategy;
  createSubscription?: Maybe<CombinedSubscription>;
  createSupportTicket?: Maybe<SupportTicket>;
  createSwapRequest: SwapRequest;
  deleteAccount: Scalars['Boolean'];
  deleteDesigner: Scalars['Boolean'];
  deleteOpinion: Scalars['Boolean'];
  deletePosition: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  generateSnapshotTestData: Scalars['Boolean'];
  manuallyTriggerIndex: Scalars['Boolean'];
  markedSynced: Scalars['Boolean'];
  migratePlaidAccount: Array<Account>;
  publishDesignerNewsletter: DesignerNewsletter;
  publishQuarterlyNewsletter: QuarterlyNewsletter;
  updateAccount: Account;
  updateAccountAssessment: AccountAssessment;
  updateCustodianTag: Custodian;
  updateDesigner: Designer;
  updateNewsletterTemplate: NewsletterTemplate;
  updateOpinion: Opinion;
  updatePlaidAccount: Account;
  updatePosition: Position;
  updatePreferredSupportContact: Designer;
  updateRevenueShare: RevenueShare;
  updateStrategy: Strategy;
  updateSubscriber: Subscriber;
  updateUser: User;
};


export type MutationBeginFreeTrialArgs = {
  data: BeginFreeTrialInput;
};


export type MutationCloseCustodiansArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationCreateAccountAssessmentArgs = {
  data: CreateAccountAssessmentInput;
};


export type MutationCreateDesignerArgs = {
  data: CreateDesignerInput;
};


export type MutationCreateDesignerPaymentInfoArgs = {
  data: CreateDesignerPaymentInfoInput;
};


export type MutationCreateNewsletterTemplateArgs = {
  data: NewsletterTemplateInput;
};


export type MutationCreateOpinionArgs = {
  data: CreateOpinionInput;
};


export type MutationCreateRevenueShareArgs = {
  data: CreateRevenueShareInput;
};


export type MutationCreateStockDataArgs = {
  data: CreateStockDataInput;
};


export type MutationCreateStrategyArgs = {
  data: CreateStrategyInput;
};


export type MutationCreateSubscriptionArgs = {
  data: CreateSubscriptionInput;
};


export type MutationCreateSupportTicketArgs = {
  data: SupportTicketInput;
  subscriberId: Scalars['String'];
};


export type MutationCreateSwapRequestArgs = {
  data: CreateSwapRequestInput;
};


export type MutationDeleteAccountArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDesignerArgs = {
  id: Scalars['String'];
};


export type MutationDeleteOpinionArgs = {
  id: Scalars['String'];
};


export type MutationDeletePositionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationGenerateSnapshotTestDataArgs = {
  accountIds: Array<Scalars['String']>;
  noq: Scalars['Float'];
};


export type MutationManuallyTriggerIndexArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};


export type MutationMarkedSyncedArgs = {
  id: Scalars['String'];
};


export type MutationMigratePlaidAccountArgs = {
  data: MigratePlaidAccountInput;
};


export type MutationPublishDesignerNewsletterArgs = {
  data: CreateDesignerNewsletterInput;
};


export type MutationPublishQuarterlyNewsletterArgs = {
  newsletterTemplateId: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  data: UpdateAccountInput;
  id: Scalars['String'];
};


export type MutationUpdateAccountAssessmentArgs = {
  data: UpdateAccountAssessmentInput;
  id: Scalars['String'];
};


export type MutationUpdateCustodianTagArgs = {
  custodianId: Scalars['String'];
  userTag: Scalars['String'];
};


export type MutationUpdateDesignerArgs = {
  data: UpdateDesignerInput;
  id: Scalars['String'];
};


export type MutationUpdateNewsletterTemplateArgs = {
  data: NewsletterTemplateInput;
  id: Scalars['String'];
};


export type MutationUpdateOpinionArgs = {
  data: UpdateOpinionInput;
  id: Scalars['String'];
};


export type MutationUpdatePlaidAccountArgs = {
  data: UpdatePlaidAccountInput;
};


export type MutationUpdatePositionArgs = {
  data: UpdatePositionInput;
  id: Scalars['String'];
};


export type MutationUpdatePreferredSupportContactArgs = {
  data: UpdatePreferredSupportContactInput;
  designerId: Scalars['String'];
};


export type MutationUpdateRevenueShareArgs = {
  data: UpdateRevenueShareInput;
  id: Scalars['String'];
};


export type MutationUpdateStrategyArgs = {
  data: UpdateStrategyInput;
  id: Scalars['String'];
};


export type MutationUpdateSubscriberArgs = {
  data: UpdateSubscriberInput;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  id: Scalars['String'];
};

export type NewsletterTemplate = {
  __typename?: 'NewsletterTemplate';
  body: Scalars['String'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  header: Scalars['String'];
  id: Scalars['ID'];
  seedId?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
};

export type NewsletterTemplateInput = {
  body: Scalars['String'];
  header: Scalars['String'];
};

export type Opinion = {
  __typename?: 'Opinion';
  accountPercentage: Scalars['Float'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  seedId?: Maybe<Scalars['String']>;
  stockData: StockData;
  stockDataId?: Maybe<Scalars['ID']>;
  strategy: Strategy;
  strategyId?: Maybe<Scalars['ID']>;
  updatedDate: Scalars['DateTime'];
};

export type OrderBy = {
  direction: Scalars['String'];
  field: Scalars['String'];
  parentRelationField?: InputMaybe<Scalars['String']>;
};

export type PaginatedAccountResponse = {
  __typename?: 'PaginatedAccountResponse';
  items: Array<Account>;
  totalRows: Scalars['Int'];
};

export type PaginatedDesignerNewsletterResponse = {
  __typename?: 'PaginatedDesignerNewsletterResponse';
  items: Array<DesignerNewsletter>;
  totalRows: Scalars['Int'];
};

export type PaginatedDesignerResponse = {
  __typename?: 'PaginatedDesignerResponse';
  items: Array<Designer>;
  totalRows: Scalars['Int'];
};

export type PaginatedNewsletterTemplateResponse = {
  __typename?: 'PaginatedNewsletterTemplateResponse';
  items: Array<NewsletterTemplate>;
  totalRows: Scalars['Int'];
};

export type PaginatedPlatformAdminResponse = {
  __typename?: 'PaginatedPlatformAdminResponse';
  items: Array<PlatformAdmin>;
  totalRows: Scalars['Int'];
};

export type PaginatedQuarterlyNewsletterResponse = {
  __typename?: 'PaginatedQuarterlyNewsletterResponse';
  items: Array<QuarterlyNewsletter>;
  totalRows: Scalars['Int'];
};

export type PaginatedStockDataResponse = {
  __typename?: 'PaginatedStockDataResponse';
  items: Array<StockData>;
  totalRows: Scalars['Int'];
};

export type PaginatedStrategyResponse = {
  __typename?: 'PaginatedStrategyResponse';
  items: Array<Strategy>;
  totalRows: Scalars['Int'];
};

export type PaginatedSubscriberResponse = {
  __typename?: 'PaginatedSubscriberResponse';
  items: Array<Subscriber>;
  totalRows: Scalars['Int'];
};

export type PaginatedSubscriptionResponse = {
  __typename?: 'PaginatedSubscriptionResponse';
  items: Array<Subscription>;
  totalRows: Scalars['Int'];
};

export type PaginatedUserResponse = {
  __typename?: 'PaginatedUserResponse';
  items: Array<User>;
  totalRows: Scalars['Int'];
};

export type PaginationInput = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Array<FilterBy>>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  billing_details?: Maybe<BillingDetails>;
  card: Card;
  id?: Maybe<Scalars['ID']>;
};

export type PlatformAdmin = {
  __typename?: 'PlatformAdmin';
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  seedId?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type Position = {
  __typename?: 'Position';
  account: Account;
  accountId?: Maybe<Scalars['ID']>;
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  institutionValue?: Maybe<Scalars['Float']>;
  seedId?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  shares: Scalars['Float'];
  stockData?: Maybe<StockData>;
  stockDataId?: Maybe<Scalars['ID']>;
  unsupported: Scalars['Boolean'];
  updatedDate: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
};

export enum PreferredSupportContact {
  Both = 'Both',
  Email = 'Email',
  Phone = 'Phone'
}

export type QuarterlyNewsletter = {
  __typename?: 'QuarterlyNewsletter';
  body: Scalars['String'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  header: Scalars['String'];
  id: Scalars['ID'];
  quarter: Scalars['Float'];
  seedId?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  year: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  accountAssessmentOne?: Maybe<AccountAssessment>;
  accountMany: Array<Account>;
  accountOne?: Maybe<Account>;
  accountPage: PaginatedAccountResponse;
  designerMany: Array<Designer>;
  designerNewsletter?: Maybe<DesignerNewsletter>;
  designerNewsletterPage: PaginatedDesignerNewsletterResponse;
  designerOne?: Maybe<Designer>;
  designerPage: PaginatedDesignerResponse;
  designerPaymentInfoOne?: Maybe<DesignerPaymentInfo>;
  designerShort?: Maybe<DesignerShort>;
  generateAllocateCashTrades: Array<Trades>;
  generateTradesToDeallocate: Array<Trades>;
  generateTradesToSync: Array<Trades>;
  getAccountOnboardingLink?: Maybe<Scalars['String']>;
  getAdminDesignerReport: DesignerDetails;
  getAdminReport: AdminReport;
  getCustodianAccounts: Array<Account>;
  getDesignerReport: DesignerReport;
  getPlaidLinkToken: Scalars['String'];
  getPlaidUpdateLinkToken: Scalars['String'];
  getStripeAccount?: Maybe<StripeAccountInfo>;
  getStripeDashboardLink?: Maybe<Scalars['String']>;
  getStripePaymentMethodLink?: Maybe<Scalars['String']>;
  getSubscriberAccountReport: SubscriberReport;
  newsletterTemplateMany?: Maybe<Array<NewsletterTemplate>>;
  newsletterTemplateOne?: Maybe<NewsletterTemplate>;
  newsletterTemplatePage: PaginatedNewsletterTemplateResponse;
  opinionOne?: Maybe<Opinion>;
  platformAdminMany: Array<PlatformAdmin>;
  platformAdminOne?: Maybe<PlatformAdmin>;
  platformAdminPage: PaginatedPlatformAdminResponse;
  positionMany: Array<Position>;
  positionOne?: Maybe<Position>;
  quarterlyNewsletter?: Maybe<QuarterlyNewsletter>;
  quarterlyNewsletterPage: PaginatedQuarterlyNewsletterResponse;
  revenueShareMany: Array<RevenueShare>;
  revenueShareOne?: Maybe<RevenueShare>;
  stockDataOne?: Maybe<StockData>;
  stockDataPage: PaginatedStockDataResponse;
  stockDataSearchBySymbol: Array<StockData>;
  strategyOne?: Maybe<Strategy>;
  strategyPage: PaginatedStrategyResponse;
  subscriberMany: Array<Subscriber>;
  subscriberOne?: Maybe<Subscriber>;
  subscriberPage: PaginatedSubscriberResponse;
  subscriberRelevantStrategy: Array<Strategy>;
  subscriptionOne?: Maybe<Subscription>;
  subscriptionPage: PaginatedSubscriptionResponse;
  swapRequestOne?: Maybe<SwapRequest>;
  userMany: Array<User>;
  userOne?: Maybe<User>;
  userPage: PaginatedUserResponse;
};


export type QueryAccountAssessmentOneArgs = {
  id: Scalars['String'];
};


export type QueryAccountManyArgs = {
  subscriberId?: InputMaybe<Scalars['String']>;
};


export type QueryAccountOneArgs = {
  id: Scalars['String'];
};


export type QueryAccountPageArgs = {
  pagination: PaginationInput;
};


export type QueryDesignerNewsletterArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryDesignerNewsletterPageArgs = {
  pagination: PaginationInput;
};


export type QueryDesignerOneArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryDesignerPageArgs = {
  pagination: PaginationInput;
};


export type QueryDesignerPaymentInfoOneArgs = {
  id: Scalars['String'];
};


export type QueryDesignerShortArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGenerateAllocateCashTradesArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type QueryGenerateTradesToDeallocateArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
};


export type QueryGenerateTradesToSyncArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
};


export type QueryGetAdminDesignerReportArgs = {
  designerId: Scalars['String'];
};


export type QueryGetCustodianAccountsArgs = {
  custodianId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDesignerReportArgs = {
  designerId: Scalars['String'];
  strategyId: Scalars['String'];
  timeRange: Scalars['String'];
};


export type QueryGetPlaidUpdateLinkTokenArgs = {
  custodianId: Scalars['String'];
};


export type QueryGetStripePaymentMethodLinkArgs = {
  cancelSubRoute?: InputMaybe<Scalars['String']>;
  successSubRoute?: InputMaybe<Scalars['String']>;
};


export type QueryGetSubscriberAccountReportArgs = {
  accountId: Scalars['String'];
  subscriberId: Scalars['String'];
  timeRange: Scalars['String'];
};


export type QueryNewsletterTemplateOneArgs = {
  id: Scalars['String'];
};


export type QueryNewsletterTemplatePageArgs = {
  pagination: PaginationInput;
};


export type QueryOpinionOneArgs = {
  id: Scalars['String'];
};


export type QueryPlatformAdminOneArgs = {
  id: Scalars['String'];
};


export type QueryPlatformAdminPageArgs = {
  pagination: PaginationInput;
};


export type QueryPositionManyArgs = {
  accountId: Scalars['String'];
};


export type QueryPositionOneArgs = {
  id: Scalars['String'];
};


export type QueryQuarterlyNewsletterPageArgs = {
  pagination: PaginationInput;
};


export type QueryRevenueShareOneArgs = {
  id: Scalars['String'];
};


export type QueryStockDataOneArgs = {
  id: Scalars['String'];
};


export type QueryStockDataPageArgs = {
  pagination: PaginationInput;
};


export type QueryStockDataSearchBySymbolArgs = {
  searchTerm: Scalars['String'];
};


export type QueryStrategyOneArgs = {
  id: Scalars['String'];
};


export type QueryStrategyPageArgs = {
  pagination: PaginationInput;
};


export type QuerySubscriberOneArgs = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QuerySubscriberPageArgs = {
  pagination: PaginationInput;
};


export type QuerySubscriberRelevantStrategyArgs = {
  accountId: Scalars['String'];
};


export type QuerySubscriptionOneArgs = {
  id: Scalars['String'];
};


export type QuerySubscriptionPageArgs = {
  pagination: PaginationInput;
};


export type QuerySwapRequestOneArgs = {
  id: Scalars['String'];
};


export type QueryUserOneArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryUserPageArgs = {
  pagination: PaginationInput;
};

export type RevenueShare = {
  __typename?: 'RevenueShare';
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer?: Maybe<Designer>;
  designerFee: Scalars['Float'];
  designerFeeAnnual: Scalars['Float'];
  designerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  priceId: Scalars['String'];
  priceIdAnnual: Scalars['String'];
  seedId?: Maybe<Scalars['String']>;
  serviceFee: Scalars['Float'];
  serviceFeeAnnual: Scalars['Float'];
  updatedDate: Scalars['DateTime'];
};

export enum RiskLevel {
  Aggressive = 'Aggressive',
  Conservative = 'Conservative',
  Moderate = 'Moderate'
}

export type StockData = {
  __typename?: 'StockData';
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  longName: Scalars['String'];
  price: Scalars['Float'];
  seedId?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
  symbol: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};

export type Strategy = {
  __typename?: 'Strategy';
  cashMin?: Maybe<Scalars['Float']>;
  cashPercentage: Scalars['Float'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  designer: Designer;
  designerId?: Maybe<Scalars['ID']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  opinions: Array<Opinion>;
  riskLevel: RiskLevel;
  seedId?: Maybe<Scalars['String']>;
  syncThreshold: Scalars['Float'];
  termDuration: TermDuration;
  updatedDate: Scalars['DateTime'];
};

export type StrategyDataPoint = {
  __typename?: 'StrategyDataPoint';
  date: Scalars['String'];
  performance: Scalars['Float'];
  quarter: Scalars['String'];
  totalAccounts: Scalars['Int'];
};

export type StripeAccountInfo = {
  __typename?: 'StripeAccountInfo';
  bankName?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  objectType?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transferTotal?: Maybe<Scalars['Int']>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  createdDate: Scalars['DateTime'];
  custodians: Array<Custodian>;
  customerPaymentMethods?: Maybe<PaymentMethod>;
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer?: Maybe<Designer>;
  designerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  plaidAccessToken?: Maybe<Scalars['String']>;
  seedId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  subscription?: Maybe<CombinedSubscription>;
  trialAvailable: Scalars['Boolean'];
  updatedDate: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type SubscriberReport = {
  __typename?: 'SubscriberReport';
  accounts: Array<Account>;
  netWorth: Scalars['Float'];
  numberOfAccounts: Scalars['Float'];
  values: Array<SubscriberReportValues>;
};

export type SubscriberReportValues = {
  __typename?: 'SubscriberReportValues';
  createdDate?: Maybe<Scalars['String']>;
  lifetimeDelta: Scalars['Float'];
  quarter?: Maybe<Scalars['String']>;
  totalValue: Scalars['Float'];
  year?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  cancelled: Scalars['Boolean'];
  cost: Scalars['Float'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer: Designer;
  designerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  renewalDate?: Maybe<Scalars['DateTime']>;
  renewalFrequency: SubscriptionRenewalFrequency;
  seedId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriber: Subscriber;
  subscriberId?: Maybe<Scalars['ID']>;
  swapRequest?: Maybe<SwapRequest>;
  trial: Scalars['Boolean'];
  updatedDate: Scalars['DateTime'];
};

export enum SubscriptionRenewalFrequency {
  Annually = 'Annually',
  Quarterly = 'Quarterly'
}

export type SupportTicket = {
  __typename?: 'SupportTicket';
  body: Scalars['String'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer: Designer;
  designerId: Scalars['ID'];
  id: Scalars['ID'];
  seedId?: Maybe<Scalars['String']>;
  subscriber: Subscriber;
  subscriberId: Scalars['ID'];
  title: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};

export type SupportTicketInput = {
  body: Scalars['String'];
  designer: GenericConnectInput;
  title: Scalars['String'];
};

export type SwapRequest = {
  __typename?: 'SwapRequest';
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer: Designer;
  designerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  seedId?: Maybe<Scalars['String']>;
  subscription: Subscription;
  subscriptionId?: Maybe<Scalars['ID']>;
  updatedDate: Scalars['DateTime'];
};

export enum TermDuration {
  Growth = 'Growth',
  Income = 'Income',
  ShortTerm = 'ShortTerm'
}

export type Trades = {
  __typename?: 'Trades';
  operation?: Maybe<TradesOperation>;
  shares?: Maybe<Scalars['Float']>;
  stockData?: Maybe<StockData>;
};

export enum TradesOperation {
  Buy = 'Buy',
  Sell = 'Sell'
}

export type UpdateAccountAssessmentInput = {
  account: GenericConnectInput;
  riskLevel: RiskLevel;
  termDuration: TermDuration;
};

export type UpdateAccountInput = {
  cashReserve?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  strategy?: InputMaybe<GenericConnectInput>;
};

export type UpdateDesignerInput = {
  bio?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicURL?: InputMaybe<Scalars['String']>;
};

export type UpdateOpinionInput = {
  accountPercentage?: InputMaybe<Scalars['Float']>;
  stockData?: InputMaybe<GenericConnectInput>;
};

export type UpdatePlaidAccountInput = {
  accountId: Scalars['String'];
  activate: Scalars['Boolean'];
};

export type UpdatePositionInput = {
  selected: Scalars['Boolean'];
};

export type UpdatePreferredSupportContactInput = {
  preferredSupportContact: PreferredSupportContact;
  supportEmail?: InputMaybe<Scalars['String']>;
  supportPhone?: InputMaybe<Scalars['String']>;
};

export type UpdateRevenueShareInput = {
  designerFee?: InputMaybe<Scalars['Float']>;
  designerFeeAnnual?: InputMaybe<Scalars['Float']>;
  serviceFee?: InputMaybe<Scalars['Float']>;
  serviceFeeAnnual?: InputMaybe<Scalars['Float']>;
};

export type UpdateStrategyInput = {
  cashPercentage?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  riskLevel?: InputMaybe<RiskLevel>;
  syncThreshold?: InputMaybe<Scalars['Float']>;
  termDuration?: InputMaybe<TermDuration>;
};

export type UpdateSubscriberInput = {
  designer?: InputMaybe<GenericConnectInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  smsConsent?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  acceptedTAC: Scalars['Boolean'];
  cognitoId: Scalars['String'];
  completedOnboarding: Scalars['Boolean'];
  createdDate: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  designer?: Maybe<Designer>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  role: UserRole;
  seedId?: Maybe<Scalars['String']>;
  smsConsent: Scalars['Boolean'];
  subscriber?: Maybe<Subscriber>;
  updatedDate: Scalars['DateTime'];
};

export enum UserRole {
  Designer = 'Designer',
  PlatformAdmin = 'PlatformAdmin',
  Subscriber = 'Subscriber'
}

export type CreateAccountAssessmentMutationVariables = Exact<{
  data: CreateAccountAssessmentInput;
}>;


export type CreateAccountAssessmentMutation = { __typename?: 'Mutation', createAccountAssessment: { __typename?: 'AccountAssessment', id: string, accountId?: string | null, riskLevel: RiskLevel, termDuration: TermDuration } };

export type UpdateAccountMutationVariables = Exact<{
  updateAccountId: Scalars['String'];
  data: UpdateAccountInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'Account', id: string, name: string } };

export type MigratePlaidAccountMutationVariables = Exact<{
  data: MigratePlaidAccountInput;
}>;


export type MigratePlaidAccountMutation = { __typename?: 'Mutation', migratePlaidAccount: Array<{ __typename?: 'Account', id: string, name: string, mask?: string | null, externalId: string, totalValue?: number | null, isActive?: boolean | null }> };

export type UpdatePlaidAccountMutationVariables = Exact<{
  data: UpdatePlaidAccountInput;
}>;


export type UpdatePlaidAccountMutation = { __typename?: 'Mutation', updatePlaidAccount: { __typename?: 'Account', id: string, name: string, mask?: string | null, externalId: string, totalValue?: number | null, isActive?: boolean | null } };

export type MarkAccountSyncedMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type MarkAccountSyncedMutation = { __typename?: 'Mutation', markedSynced: boolean };

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount: boolean };

export type UpdateLoginTagMutationVariables = Exact<{
  id: Scalars['String'];
  userTag: Scalars['String'];
}>;


export type UpdateLoginTagMutation = { __typename?: 'Mutation', updateCustodianTag: { __typename?: 'Custodian', id: string, name: string, userTag?: string | null, plaidInstitutionId: string } };

export type CreateDesignerMutationVariables = Exact<{
  data: CreateDesignerInput;
}>;


export type CreateDesignerMutation = { __typename?: 'Mutation', createDesigner: { __typename?: 'Designer', id: string, profilePicURL?: string | null, bio?: string | null, enabled: boolean, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null } | null } };

export type UpdateDesignerMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateDesignerInput;
}>;


export type UpdateDesignerMutation = { __typename?: 'Mutation', updateDesigner: { __typename?: 'Designer', id: string, profilePicURL?: string | null, bio?: string | null, enabled: boolean, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null } | null } };

export type UpdatePreferredSupportContactMutationVariables = Exact<{
  designerId: Scalars['String'];
  data: UpdatePreferredSupportContactInput;
}>;


export type UpdatePreferredSupportContactMutation = { __typename?: 'Mutation', updatePreferredSupportContact: { __typename?: 'Designer', id: string, supportEmail?: string | null, supportPhone?: string | null, preferredSupportContact: PreferredSupportContact } };

export type DeleteDesignerMutationVariables = Exact<{
  deleteDesignerId: Scalars['String'];
}>;


export type DeleteDesignerMutation = { __typename?: 'Mutation', deleteDesigner: boolean };

export type PublishDesignerNewsletterMutationVariables = Exact<{
  data: CreateDesignerNewsletterInput;
}>;


export type PublishDesignerNewsletterMutation = { __typename?: 'Mutation', publishDesignerNewsletter: { __typename?: 'DesignerNewsletter', id: string, header: string, body: string, quarter: number, year: number, designerId: string } };

export type CreateDesignerPaymentInfoMutationVariables = Exact<{
  data: CreateDesignerPaymentInfoInput;
}>;


export type CreateDesignerPaymentInfoMutation = { __typename?: 'Mutation', createDesignerPaymentInfo: { __typename?: 'DesignerPaymentInfo', id: string, designerId?: string | null, designer?: { __typename?: 'Designer', id: string, profilePicURL?: string | null, bio?: string | null, enabled: boolean, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null } | null } | null } };

export type CreateNewsletterTemplateMutationVariables = Exact<{
  data: NewsletterTemplateInput;
}>;


export type CreateNewsletterTemplateMutation = { __typename?: 'Mutation', createNewsletterTemplate: { __typename?: 'NewsletterTemplate', id: string, createdDate: any, updatedDate: any, header: string, body: string } };

export type UpdateNewsletterTemplateMutationVariables = Exact<{
  updateNewsletterTemplateId: Scalars['String'];
  data: NewsletterTemplateInput;
}>;


export type UpdateNewsletterTemplateMutation = { __typename?: 'Mutation', updateNewsletterTemplate: { __typename?: 'NewsletterTemplate', id: string, createdDate: any, header: string, body: string } };

export type CreateOpinionMutationVariables = Exact<{
  data: CreateOpinionInput;
}>;


export type CreateOpinionMutation = { __typename?: 'Mutation', createOpinion: { __typename?: 'Opinion', id: string, createdDate: any, strategyId?: string | null, stockDataId?: string | null, accountPercentage: number } };

export type UpdateOpinionMutationVariables = Exact<{
  updateOpinionId: Scalars['String'];
  data: UpdateOpinionInput;
}>;


export type UpdateOpinionMutation = { __typename?: 'Mutation', updateOpinion: { __typename?: 'Opinion', id: string, createdDate: any, strategyId?: string | null, stockDataId?: string | null, accountPercentage: number } };

export type DeleteOpinionMutationVariables = Exact<{
  deleteOpinionId: Scalars['String'];
}>;


export type DeleteOpinionMutation = { __typename?: 'Mutation', deleteOpinion: boolean };

export type UpdatePositionMutationVariables = Exact<{
  updatePositionId: Scalars['String'];
  data: UpdatePositionInput;
}>;


export type UpdatePositionMutation = { __typename?: 'Mutation', updatePosition: { __typename?: 'Position', id: string, accountId?: string | null, shares: number, stockData?: { __typename?: 'StockData', id: string, symbol: string, longName: string, price: number } | null } };

export type PublishQuarterlyNewsletterMutationVariables = Exact<{
  newsletterTemplateId: Scalars['String'];
}>;


export type PublishQuarterlyNewsletterMutation = { __typename?: 'Mutation', publishQuarterlyNewsletter: { __typename?: 'QuarterlyNewsletter', id: string, header: string, body: string, quarter: number, year: number } };

export type CreateRevenueShareMutationVariables = Exact<{
  data: CreateRevenueShareInput;
}>;


export type CreateRevenueShareMutation = { __typename?: 'Mutation', createRevenueShare: { __typename?: 'RevenueShare', id: string, designerId?: string | null, designerFee: number, serviceFee: number, designerFeeAnnual: number, serviceFeeAnnual: number, designer?: { __typename?: 'Designer', id: string, profilePicURL?: string | null, bio?: string | null, enabled: boolean, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null } | null } | null } };

export type UpdateRevenueShareMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateRevenueShareInput;
}>;


export type UpdateRevenueShareMutation = { __typename?: 'Mutation', updateRevenueShare: { __typename?: 'RevenueShare', id: string, designerId?: string | null, designerFee: number, serviceFee: number, designer?: { __typename?: 'Designer', id: string, profilePicURL?: string | null, bio?: string | null, enabled: boolean, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null } | null } | null } };

export type CreateStockDataMutationVariables = Exact<{
  data: CreateStockDataInput;
}>;


export type CreateStockDataMutation = { __typename?: 'Mutation', createStockData: { __typename?: 'StockData', id: string, createdDate: any, updatedDate: any, price: number, symbol: string, shortName: string, longName: string } };

export type CreateStrategyMutationVariables = Exact<{
  data: CreateStrategyInput;
}>;


export type CreateStrategyMutation = { __typename?: 'Mutation', createStrategy: { __typename?: 'Strategy', id: string, createdDate: any, designerId?: string | null, name: string, riskLevel: RiskLevel, termDuration: TermDuration, syncThreshold: number, opinions: Array<{ __typename?: 'Opinion', id: string, createdDate: any, strategyId?: string | null, stockDataId?: string | null, accountPercentage: number, stockData: { __typename?: 'StockData', id: string, createdDate: any, price: number, symbol: string, shortName: string, longName: string } }> } };

export type UpdateStrategyMutationVariables = Exact<{
  data: UpdateStrategyInput;
  updateStrategyId: Scalars['String'];
}>;


export type UpdateStrategyMutation = { __typename?: 'Mutation', updateStrategy: { __typename?: 'Strategy', id: string, createdDate: any, updatedDate: any, seedId?: string | null, designerId?: string | null, name: string, riskLevel: RiskLevel, termDuration: TermDuration, syncThreshold: number, cashPercentage: number, opinions: Array<{ __typename?: 'Opinion', id: string, createdDate: any, updatedDate: any, seedId?: string | null, strategyId?: string | null, stockDataId?: string | null, accountPercentage: number, stockData: { __typename?: 'StockData', id: string, createdDate: any, updatedDate: any, seedId?: string | null, price: number, symbol: string, shortName: string, longName: string } }> } };

export type CreateSubscriptionMutationVariables = Exact<{
  data: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription?: { __typename?: 'CombinedSubscription', id: string, designerId?: string | null, renewalDate?: any | null, createdDate: any, renewalFrequency: SubscriptionRenewalFrequency, cost: number, cancelled: boolean, trial: boolean, status?: string | null, swapRequest?: { __typename?: 'SwapRequest', id: string, designerId?: string | null } | null } | null };

export type BeginFreeTrialMutationVariables = Exact<{
  data: BeginFreeTrialInput;
}>;


export type BeginFreeTrialMutation = { __typename?: 'Mutation', beginFreeTrial?: { __typename?: 'Subscription', id: string, trial: boolean, renewalDate?: any | null, renewalFrequency: SubscriptionRenewalFrequency, cost: number, designer: { __typename?: 'Designer', user?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null } } | null };

export type CancelSubscriptionRenewalMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionRenewalMutation = { __typename?: 'Mutation', cancelCurrentSubscription: boolean };

export type CreateSupportTicketMutationVariables = Exact<{
  data: SupportTicketInput;
  subscriberId: Scalars['String'];
}>;


export type CreateSupportTicketMutation = { __typename?: 'Mutation', createSupportTicket?: { __typename?: 'SupportTicket', id: string } | null };

export type CreateSwapRequestMutationVariables = Exact<{
  data: CreateSwapRequestInput;
}>;


export type CreateSwapRequestMutation = { __typename?: 'Mutation', createSwapRequest: { __typename?: 'SwapRequest', id: string, createdDate: any, designerId?: string | null, subscriptionId?: string | null } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null, smsConsent: boolean } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type AcceptTacMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptTacMutation = { __typename?: 'Mutation', acceptTAC: boolean };

export type GetAccountPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type GetAccountPageQuery = { __typename?: 'Query', accountPage: { __typename?: 'PaginatedAccountResponse', totalRows: number, items: Array<{ __typename?: 'Account', id: string, createdDate: any, name: string, totalValue?: number | null, strategy?: { __typename?: 'Strategy', id: string, name: string } | null, subscriber: { __typename?: 'Subscriber', user?: { __typename?: 'User', firstName: string, lastName: string } | null } }> } };

export type GetAccountOneQueryVariables = Exact<{
  accountOneId: Scalars['String'];
}>;


export type GetAccountOneQuery = { __typename?: 'Query', accountOne?: { __typename?: 'Account', id: string, externalId: string, name: string, unallocatedFunds: number, cashReserve: number, totalValue?: number | null, mask?: string | null, withinThreshold: boolean, lastSynced?: any | null, strategy?: { __typename?: 'Strategy', id: string, name: string, description?: string | null, enabled: boolean, riskLevel: RiskLevel, termDuration: TermDuration, cashMin?: number | null, syncThreshold: number, cashPercentage: number, designer: { __typename?: 'Designer', id: string } } | null, positions?: Array<{ __typename?: 'Position', id: string, selected: boolean, shares: number, value?: number | null, unsupported: boolean, institutionValue?: number | null, description?: string | null, stockData?: { __typename?: 'StockData', id: string, createdDate: any, price: number, symbol: string, shortName: string, longName: string } | null }> | null, assessment?: { __typename?: 'AccountAssessment', riskLevel: RiskLevel, termDuration: TermDuration, id: string } | null, custodian: { __typename?: 'Custodian', id: string, name: string } } | null };

export type GetAccountManyQueryVariables = Exact<{
  subscriberId?: InputMaybe<Scalars['String']>;
}>;


export type GetAccountManyQuery = { __typename?: 'Query', accountMany: Array<{ __typename?: 'Account', id: string, createdDate: any, name: string, externalId: string, totalValue?: number | null, withinThreshold: boolean, mask?: string | null, lastSynced?: any | null, cashReserve: number, isActive?: boolean | null, strategy?: { __typename?: 'Strategy', id: string, name: string } | null, custodian: { __typename?: 'Custodian', id: string, name: string, userTag?: string | null, plaidInstitutionId: string, reAuthRequired: boolean, isActive: boolean } }> };

export type GetCustodianAccountsQueryVariables = Exact<{
  custodianId?: InputMaybe<Scalars['String']>;
}>;


export type GetCustodianAccountsQuery = { __typename?: 'Query', getCustodianAccounts: Array<{ __typename?: 'Account', id: string, externalId: string, name: string, mask?: string | null, isActive?: boolean | null, totalValue?: number | null }> };

export type GenerateTradeToSyncQueryVariables = Exact<{
  generateTradesToSyncId: Scalars['String'];
  amount?: InputMaybe<Scalars['Float']>;
}>;


export type GenerateTradeToSyncQuery = { __typename?: 'Query', generateTradesToSync: Array<{ __typename?: 'Trades', operation?: TradesOperation | null, shares?: number | null, stockData?: { __typename?: 'StockData', id: string, symbol: string, shortName: string, price: number } | null }> };

export type GenerateAllocateCashTradesQueryVariables = Exact<{
  generateTradesToSyncId: Scalars['String'];
  amount: Scalars['Float'];
}>;


export type GenerateAllocateCashTradesQuery = { __typename?: 'Query', generateAllocateCashTrades: Array<{ __typename?: 'Trades', operation?: TradesOperation | null, shares?: number | null, stockData?: { __typename?: 'StockData', id: string, symbol: string, shortName: string, price: number } | null }> };

export type GenerateDeallocateCashTradesQueryVariables = Exact<{
  generateTradesToSyncId: Scalars['String'];
  amount: Scalars['Float'];
}>;


export type GenerateDeallocateCashTradesQuery = { __typename?: 'Query', generateTradesToDeallocate: Array<{ __typename?: 'Trades', operation?: TradesOperation | null, shares?: number | null, stockData?: { __typename?: 'StockData', id: string, symbol: string, shortName: string, price: number } | null }> };

export type GetPlaidLinkTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlaidLinkTokenQuery = { __typename?: 'Query', getPlaidLinkToken: string };

export type GetPlaidUpdateLinkTokenQueryVariables = Exact<{
  custodianId: Scalars['String'];
}>;


export type GetPlaidUpdateLinkTokenQuery = { __typename?: 'Query', getPlaidUpdateLinkToken: string };

export type GetAdminDesignerReportQueryVariables = Exact<{
  designerId: Scalars['String'];
}>;


export type GetAdminDesignerReportQuery = { __typename?: 'Query', getAdminDesignerReport: { __typename?: 'DesignerDetails', noOfSubscribers: number, noOfStrategies: number, totalAmount: number, strategies: Array<{ __typename?: 'Strategy', name: string, riskLevel: RiskLevel, termDuration: TermDuration }> } };

export type GetAdminReportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminReportQuery = { __typename?: 'Query', getAdminReport: { __typename?: 'AdminReport', numberOfAccounts: number, averageValueOfAccounts: number, noOfActiveDesigners: number, designers: Array<{ __typename?: 'Designer', id: string, userId?: string | null, enabled: boolean, profilePicURL?: string | null, user?: { __typename?: 'User', firstName: string, lastName: string } | null }> } };

export type GetDesignersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDesignersQuery = { __typename?: 'Query', designerMany: Array<{ __typename?: 'Designer', id: string, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any } | null }> };

export type GetDesignerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type GetDesignerQuery = { __typename?: 'Query', designerOne?: { __typename?: 'Designer', id: string, profilePicURL?: string | null, bio?: string | null, enabled: boolean, supportPhone?: string | null, supportEmail?: string | null, preferredSupportContact: PreferredSupportContact, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any } | null, subscribers: Array<{ __typename?: 'Subscriber', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null }>, revenueShare?: { __typename?: 'RevenueShare', id: string, designerFee: number, serviceFee: number, designerFeeAnnual: number, serviceFeeAnnual: number } | null, paymentInfo?: { __typename?: 'DesignerPaymentInfo', id: string, stripeAccountId?: string | null, stripeOnboardingComplete: boolean } | null, strategies: Array<{ __typename?: 'Strategy', id: string, createdDate: any, name: string, description?: string | null, enabled: boolean, riskLevel: RiskLevel, termDuration: TermDuration, cashMin?: number | null }> } | null };

export type DesignerShortQueryVariables = Exact<{
  designerShortId?: InputMaybe<Scalars['String']>;
}>;


export type DesignerShortQuery = { __typename?: 'Query', designerShort?: { __typename?: 'DesignerShort', firstName?: string | null, lastName?: string | null, profilePicURL?: string | null, bio?: string | null } | null };

export type GetDesignerPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type GetDesignerPageQuery = { __typename?: 'Query', designerPage: { __typename?: 'PaginatedDesignerResponse', totalRows: number, items: Array<{ __typename?: 'Designer', id: string, enabled: boolean, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any } | null, subscribers: Array<{ __typename?: 'Subscriber', id: string }> }> } };

export type GetStripeDashboardLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeDashboardLinkQuery = { __typename?: 'Query', getStripeDashboardLink?: string | null };

export type GetStripeAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeAccountQuery = { __typename?: 'Query', getStripeAccount?: { __typename?: 'StripeAccountInfo', id?: string | null, objectType?: string | null, transferTotal?: number | null, last4?: string | null, status?: string | null, bankName?: string | null, routingNumber?: string | null, brand?: string | null, expMonth?: number | null, expYear?: number | null } | null };

export type GetStripeAccountLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeAccountLinkQuery = { __typename?: 'Query', getAccountOnboardingLink?: string | null };

export type DesignerNewsletterPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type DesignerNewsletterPageQuery = { __typename?: 'Query', designerNewsletterPage: { __typename?: 'PaginatedDesignerNewsletterResponse', totalRows: number, items: Array<{ __typename?: 'DesignerNewsletter', id: string, updatedDate: any, header: string, body: string, quarter: number, year: number, designerId: string }> } };

export type GetDesignerNewsletterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDesignerNewsletterQuery = { __typename?: 'Query', designerNewsletter?: { __typename?: 'DesignerNewsletter', id: string, updatedDate: any, header: string, body: string, quarter: number, year: number, designerId: string } | null };

export type GetDesignerReportQueryVariables = Exact<{
  timeRange: Scalars['String'];
  designerId: Scalars['String'];
  strategyId: Scalars['String'];
}>;


export type GetDesignerReportQuery = { __typename?: 'Query', getDesignerReport: { __typename?: 'DesignerReport', totalAccounts: number, totalSubscribers: number, strategyData: Array<{ __typename?: 'StrategyDataPoint', totalAccounts: number, performance: number, date: string, quarter: string }> } };

export type NewsletterTemplatePageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type NewsletterTemplatePageQuery = { __typename?: 'Query', newsletterTemplatePage: { __typename?: 'PaginatedNewsletterTemplateResponse', totalRows: number, items: Array<{ __typename?: 'NewsletterTemplate', id: string, updatedDate: any, header: string, body: string }> } };

export type GetNewsletterTemplateQueryVariables = Exact<{
  newsletterTemplateOneId: Scalars['String'];
}>;


export type GetNewsletterTemplateQuery = { __typename?: 'Query', newsletterTemplateOne?: { __typename?: 'NewsletterTemplate', id: string, createdDate: any, seedId?: string | null, header: string, body: string } | null };

export type GetPlatformAdminPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type GetPlatformAdminPageQuery = { __typename?: 'Query', platformAdminPage: { __typename?: 'PaginatedPlatformAdminResponse', totalRows: number, items: Array<{ __typename?: 'PlatformAdmin', id: string, user?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any } | null }> } };

export type GetPositionsQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetPositionsQuery = { __typename?: 'Query', positionMany: Array<{ __typename?: 'Position', id: string, selected: boolean, shares: number, value?: number | null, stockData?: { __typename?: 'StockData', id: string, symbol: string, shortName: string } | null }> };

export type QuarterlyNewsletterPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type QuarterlyNewsletterPageQuery = { __typename?: 'Query', quarterlyNewsletterPage: { __typename?: 'PaginatedQuarterlyNewsletterResponse', totalRows: number, items: Array<{ __typename?: 'QuarterlyNewsletter', id: string, updatedDate: any, header: string, body: string, quarter: number, year: number }> } };

export type GetQuarterlyNewsletterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuarterlyNewsletterQuery = { __typename?: 'Query', quarterlyNewsletter?: { __typename?: 'QuarterlyNewsletter', id: string, updatedDate: any, header: string, body: string, quarter: number, year: number } | null };

export type GetStockDataBySymbolQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;


export type GetStockDataBySymbolQuery = { __typename?: 'Query', stockDataSearchBySymbol: Array<{ __typename?: 'StockData', id: string, createdDate: any, price: number, symbol: string, shortName: string, longName: string }> };

export type GetStrategyPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type GetStrategyPageQuery = { __typename?: 'Query', strategyPage: { __typename?: 'PaginatedStrategyResponse', totalRows: number, items: Array<{ __typename?: 'Strategy', id: string, createdDate: any, designerId?: string | null, name: string, cashMin?: number | null, riskLevel: RiskLevel, termDuration: TermDuration, designer: { __typename?: 'Designer', user?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null } }> } };

export type GetStrategyQueryVariables = Exact<{
  strategyOneId: Scalars['String'];
}>;


export type GetStrategyQuery = { __typename?: 'Query', strategyOne?: { __typename?: 'Strategy', id: string, createdDate: any, designerId?: string | null, name: string, cashMin?: number | null, riskLevel: RiskLevel, termDuration: TermDuration, description?: string | null, enabled: boolean, syncThreshold: number, cashPercentage: number, designer: { __typename?: 'Designer', user?: { __typename?: 'User', firstName: string, lastName: string } | null }, opinions: Array<{ __typename?: 'Opinion', id: string, createdDate: any, strategyId?: string | null, stockDataId?: string | null, accountPercentage: number, stockData: { __typename?: 'StockData', id: string, createdDate: any, price: number, symbol: string, shortName: string, longName: string } }> } | null };

export type GetSubscriberStrategiesQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetSubscriberStrategiesQuery = { __typename?: 'Query', subscriberRelevantStrategy: Array<{ __typename?: 'Strategy', id: string, createdDate: any, designerId?: string | null, name: string, cashMin?: number | null, riskLevel: RiskLevel, termDuration: TermDuration }> };

export type GetSubscriberPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type GetSubscriberPageQuery = { __typename?: 'Query', subscriberPage: { __typename?: 'PaginatedSubscriberResponse', totalRows: number, items: Array<{ __typename?: 'Subscriber', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any } | null, designer?: { __typename?: 'Designer', user?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null } | null }> } };

export type GetSubscriberQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type GetSubscriberQuery = { __typename?: 'Query', subscriberOne?: { __typename?: 'Subscriber', id: string, trialAvailable: boolean, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any, smsConsent: boolean } | null, subscription?: { __typename?: 'CombinedSubscription', id: string, designerId?: string | null, renewalDate?: any | null, createdDate: any, renewalFrequency: SubscriptionRenewalFrequency, cost: number, cancelled: boolean, trial: boolean, status?: string | null, isExpired: boolean, swapRequest?: { __typename?: 'SwapRequest', id: string, designerId?: string | null } | null } | null, custodians: Array<{ __typename?: 'Custodian', id: string, name: string, userTag?: string | null, plaidInstitutionId: string, isActive: boolean, reAuthRequired: boolean }>, designer?: { __typename?: 'Designer', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, strategies: Array<{ __typename?: 'Strategy', id: string, name: string, description?: string | null, cashMin?: number | null, riskLevel: RiskLevel, termDuration: TermDuration, opinions: Array<{ __typename?: 'Opinion', accountPercentage: number, stockData: { __typename?: 'StockData', id: string, price: number, symbol: string, shortName: string, longName: string } }> }> } | null, customerPaymentMethods?: { __typename?: 'PaymentMethod', card: { __typename?: 'Card', brand?: string | null, country?: string | null, exp_month?: string | null, exp_year?: string | null, fingerprint?: string | null, last4?: string | null } } | null } | null };

export type GetSubscriberAccountReportQueryVariables = Exact<{
  timeRange: Scalars['String'];
  subscriberId: Scalars['String'];
  accountId: Scalars['String'];
}>;


export type GetSubscriberAccountReportQuery = { __typename?: 'Query', getSubscriberAccountReport: { __typename?: 'SubscriberReport', numberOfAccounts: number, netWorth: number, values: Array<{ __typename?: 'SubscriberReportValues', totalValue: number, lifetimeDelta: number, createdDate?: string | null, quarter?: string | null, year?: string | null }>, accounts: Array<{ __typename?: 'Account', id: string, name: string, unallocatedFunds: number, totalValue?: number | null }> } };

export type GetStripePaymentMethodLinkQueryVariables = Exact<{
  cancelSubRoute?: InputMaybe<Scalars['String']>;
  successSubRoute?: InputMaybe<Scalars['String']>;
}>;


export type GetStripePaymentMethodLinkQuery = { __typename?: 'Query', getStripePaymentMethodLink?: string | null };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', userMany: Array<{ __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any }> };

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type GetUserQuery = { __typename?: 'Query', userOne?: { __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any, acceptedTAC: boolean, smsConsent: boolean, completedOnboarding: boolean } | null };

export type GetUserPageQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type GetUserPageQuery = { __typename?: 'Query', userPage: { __typename?: 'PaginatedUserResponse', totalRows: number, items: Array<{ __typename?: 'User', id: string, cognitoId: string, role: UserRole, firstName: string, lastName: string, email: string, phoneNumber?: string | null, createdDate: any }> } };


export const CreateAccountAssessmentDocument = gql`
    mutation CreateAccountAssessment($data: CreateAccountAssessmentInput!) {
  createAccountAssessment(data: $data) {
    id
    accountId
    riskLevel
    termDuration
  }
}
    `;
export type CreateAccountAssessmentMutationFn = Apollo.MutationFunction<CreateAccountAssessmentMutation, CreateAccountAssessmentMutationVariables>;

/**
 * __useCreateAccountAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateAccountAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountAssessmentMutation, { data, loading, error }] = useCreateAccountAssessmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAccountAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountAssessmentMutation, CreateAccountAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountAssessmentMutation, CreateAccountAssessmentMutationVariables>(CreateAccountAssessmentDocument, options);
      }
export type CreateAccountAssessmentMutationHookResult = ReturnType<typeof useCreateAccountAssessmentMutation>;
export type CreateAccountAssessmentMutationResult = Apollo.MutationResult<CreateAccountAssessmentMutation>;
export type CreateAccountAssessmentMutationOptions = Apollo.BaseMutationOptions<CreateAccountAssessmentMutation, CreateAccountAssessmentMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($updateAccountId: String!, $data: UpdateAccountInput!) {
  updateAccount(id: $updateAccountId, data: $data) {
    id
    name
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      updateAccountId: // value for 'updateAccountId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const MigratePlaidAccountDocument = gql`
    mutation MigratePlaidAccount($data: MigratePlaidAccountInput!) {
  migratePlaidAccount(data: $data) {
    id
    name
    mask
    externalId
    totalValue
    isActive
  }
}
    `;
export type MigratePlaidAccountMutationFn = Apollo.MutationFunction<MigratePlaidAccountMutation, MigratePlaidAccountMutationVariables>;

/**
 * __useMigratePlaidAccountMutation__
 *
 * To run a mutation, you first call `useMigratePlaidAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigratePlaidAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migratePlaidAccountMutation, { data, loading, error }] = useMigratePlaidAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMigratePlaidAccountMutation(baseOptions?: Apollo.MutationHookOptions<MigratePlaidAccountMutation, MigratePlaidAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MigratePlaidAccountMutation, MigratePlaidAccountMutationVariables>(MigratePlaidAccountDocument, options);
      }
export type MigratePlaidAccountMutationHookResult = ReturnType<typeof useMigratePlaidAccountMutation>;
export type MigratePlaidAccountMutationResult = Apollo.MutationResult<MigratePlaidAccountMutation>;
export type MigratePlaidAccountMutationOptions = Apollo.BaseMutationOptions<MigratePlaidAccountMutation, MigratePlaidAccountMutationVariables>;
export const UpdatePlaidAccountDocument = gql`
    mutation UpdatePlaidAccount($data: UpdatePlaidAccountInput!) {
  updatePlaidAccount(data: $data) {
    id
    name
    mask
    externalId
    totalValue
    isActive
  }
}
    `;
export type UpdatePlaidAccountMutationFn = Apollo.MutationFunction<UpdatePlaidAccountMutation, UpdatePlaidAccountMutationVariables>;

/**
 * __useUpdatePlaidAccountMutation__
 *
 * To run a mutation, you first call `useUpdatePlaidAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaidAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaidAccountMutation, { data, loading, error }] = useUpdatePlaidAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePlaidAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaidAccountMutation, UpdatePlaidAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaidAccountMutation, UpdatePlaidAccountMutationVariables>(UpdatePlaidAccountDocument, options);
      }
export type UpdatePlaidAccountMutationHookResult = ReturnType<typeof useUpdatePlaidAccountMutation>;
export type UpdatePlaidAccountMutationResult = Apollo.MutationResult<UpdatePlaidAccountMutation>;
export type UpdatePlaidAccountMutationOptions = Apollo.BaseMutationOptions<UpdatePlaidAccountMutation, UpdatePlaidAccountMutationVariables>;
export const MarkAccountSyncedDocument = gql`
    mutation MarkAccountSynced($id: String!) {
  markedSynced(id: $id)
}
    `;
export type MarkAccountSyncedMutationFn = Apollo.MutationFunction<MarkAccountSyncedMutation, MarkAccountSyncedMutationVariables>;

/**
 * __useMarkAccountSyncedMutation__
 *
 * To run a mutation, you first call `useMarkAccountSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAccountSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAccountSyncedMutation, { data, loading, error }] = useMarkAccountSyncedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAccountSyncedMutation(baseOptions?: Apollo.MutationHookOptions<MarkAccountSyncedMutation, MarkAccountSyncedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAccountSyncedMutation, MarkAccountSyncedMutationVariables>(MarkAccountSyncedDocument, options);
      }
export type MarkAccountSyncedMutationHookResult = ReturnType<typeof useMarkAccountSyncedMutation>;
export type MarkAccountSyncedMutationResult = Apollo.MutationResult<MarkAccountSyncedMutation>;
export type MarkAccountSyncedMutationOptions = Apollo.BaseMutationOptions<MarkAccountSyncedMutation, MarkAccountSyncedMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($id: String!) {
  deleteAccount(id: $id)
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const UpdateLoginTagDocument = gql`
    mutation UpdateLoginTag($id: String!, $userTag: String!) {
  updateCustodianTag(custodianId: $id, userTag: $userTag) {
    id
    name
    userTag
    plaidInstitutionId
  }
}
    `;
export type UpdateLoginTagMutationFn = Apollo.MutationFunction<UpdateLoginTagMutation, UpdateLoginTagMutationVariables>;

/**
 * __useUpdateLoginTagMutation__
 *
 * To run a mutation, you first call `useUpdateLoginTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoginTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoginTagMutation, { data, loading, error }] = useUpdateLoginTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userTag: // value for 'userTag'
 *   },
 * });
 */
export function useUpdateLoginTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoginTagMutation, UpdateLoginTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoginTagMutation, UpdateLoginTagMutationVariables>(UpdateLoginTagDocument, options);
      }
export type UpdateLoginTagMutationHookResult = ReturnType<typeof useUpdateLoginTagMutation>;
export type UpdateLoginTagMutationResult = Apollo.MutationResult<UpdateLoginTagMutation>;
export type UpdateLoginTagMutationOptions = Apollo.BaseMutationOptions<UpdateLoginTagMutation, UpdateLoginTagMutationVariables>;
export const CreateDesignerDocument = gql`
    mutation CreateDesigner($data: CreateDesignerInput!) {
  createDesigner(data: $data) {
    id
    user {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
    }
    profilePicURL
    bio
    enabled
  }
}
    `;
export type CreateDesignerMutationFn = Apollo.MutationFunction<CreateDesignerMutation, CreateDesignerMutationVariables>;

/**
 * __useCreateDesignerMutation__
 *
 * To run a mutation, you first call `useCreateDesignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDesignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDesignerMutation, { data, loading, error }] = useCreateDesignerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDesignerMutation(baseOptions?: Apollo.MutationHookOptions<CreateDesignerMutation, CreateDesignerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDesignerMutation, CreateDesignerMutationVariables>(CreateDesignerDocument, options);
      }
export type CreateDesignerMutationHookResult = ReturnType<typeof useCreateDesignerMutation>;
export type CreateDesignerMutationResult = Apollo.MutationResult<CreateDesignerMutation>;
export type CreateDesignerMutationOptions = Apollo.BaseMutationOptions<CreateDesignerMutation, CreateDesignerMutationVariables>;
export const UpdateDesignerDocument = gql`
    mutation UpdateDesigner($id: String!, $data: UpdateDesignerInput!) {
  updateDesigner(id: $id, data: $data) {
    id
    user {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
    }
    profilePicURL
    bio
    enabled
  }
}
    `;
export type UpdateDesignerMutationFn = Apollo.MutationFunction<UpdateDesignerMutation, UpdateDesignerMutationVariables>;

/**
 * __useUpdateDesignerMutation__
 *
 * To run a mutation, you first call `useUpdateDesignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDesignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDesignerMutation, { data, loading, error }] = useUpdateDesignerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDesignerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDesignerMutation, UpdateDesignerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDesignerMutation, UpdateDesignerMutationVariables>(UpdateDesignerDocument, options);
      }
export type UpdateDesignerMutationHookResult = ReturnType<typeof useUpdateDesignerMutation>;
export type UpdateDesignerMutationResult = Apollo.MutationResult<UpdateDesignerMutation>;
export type UpdateDesignerMutationOptions = Apollo.BaseMutationOptions<UpdateDesignerMutation, UpdateDesignerMutationVariables>;
export const UpdatePreferredSupportContactDocument = gql`
    mutation UpdatePreferredSupportContact($designerId: String!, $data: UpdatePreferredSupportContactInput!) {
  updatePreferredSupportContact(designerId: $designerId, data: $data) {
    id
    supportEmail
    supportPhone
    preferredSupportContact
  }
}
    `;
export type UpdatePreferredSupportContactMutationFn = Apollo.MutationFunction<UpdatePreferredSupportContactMutation, UpdatePreferredSupportContactMutationVariables>;

/**
 * __useUpdatePreferredSupportContactMutation__
 *
 * To run a mutation, you first call `useUpdatePreferredSupportContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferredSupportContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferredSupportContactMutation, { data, loading, error }] = useUpdatePreferredSupportContactMutation({
 *   variables: {
 *      designerId: // value for 'designerId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePreferredSupportContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePreferredSupportContactMutation, UpdatePreferredSupportContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePreferredSupportContactMutation, UpdatePreferredSupportContactMutationVariables>(UpdatePreferredSupportContactDocument, options);
      }
export type UpdatePreferredSupportContactMutationHookResult = ReturnType<typeof useUpdatePreferredSupportContactMutation>;
export type UpdatePreferredSupportContactMutationResult = Apollo.MutationResult<UpdatePreferredSupportContactMutation>;
export type UpdatePreferredSupportContactMutationOptions = Apollo.BaseMutationOptions<UpdatePreferredSupportContactMutation, UpdatePreferredSupportContactMutationVariables>;
export const DeleteDesignerDocument = gql`
    mutation DeleteDesigner($deleteDesignerId: String!) {
  deleteDesigner(id: $deleteDesignerId)
}
    `;
export type DeleteDesignerMutationFn = Apollo.MutationFunction<DeleteDesignerMutation, DeleteDesignerMutationVariables>;

/**
 * __useDeleteDesignerMutation__
 *
 * To run a mutation, you first call `useDeleteDesignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDesignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDesignerMutation, { data, loading, error }] = useDeleteDesignerMutation({
 *   variables: {
 *      deleteDesignerId: // value for 'deleteDesignerId'
 *   },
 * });
 */
export function useDeleteDesignerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDesignerMutation, DeleteDesignerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDesignerMutation, DeleteDesignerMutationVariables>(DeleteDesignerDocument, options);
      }
export type DeleteDesignerMutationHookResult = ReturnType<typeof useDeleteDesignerMutation>;
export type DeleteDesignerMutationResult = Apollo.MutationResult<DeleteDesignerMutation>;
export type DeleteDesignerMutationOptions = Apollo.BaseMutationOptions<DeleteDesignerMutation, DeleteDesignerMutationVariables>;
export const PublishDesignerNewsletterDocument = gql`
    mutation PublishDesignerNewsletter($data: CreateDesignerNewsletterInput!) {
  publishDesignerNewsletter(data: $data) {
    id
    header
    body
    quarter
    year
    designerId
  }
}
    `;
export type PublishDesignerNewsletterMutationFn = Apollo.MutationFunction<PublishDesignerNewsletterMutation, PublishDesignerNewsletterMutationVariables>;

/**
 * __usePublishDesignerNewsletterMutation__
 *
 * To run a mutation, you first call `usePublishDesignerNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDesignerNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDesignerNewsletterMutation, { data, loading, error }] = usePublishDesignerNewsletterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePublishDesignerNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<PublishDesignerNewsletterMutation, PublishDesignerNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDesignerNewsletterMutation, PublishDesignerNewsletterMutationVariables>(PublishDesignerNewsletterDocument, options);
      }
export type PublishDesignerNewsletterMutationHookResult = ReturnType<typeof usePublishDesignerNewsletterMutation>;
export type PublishDesignerNewsletterMutationResult = Apollo.MutationResult<PublishDesignerNewsletterMutation>;
export type PublishDesignerNewsletterMutationOptions = Apollo.BaseMutationOptions<PublishDesignerNewsletterMutation, PublishDesignerNewsletterMutationVariables>;
export const CreateDesignerPaymentInfoDocument = gql`
    mutation CreateDesignerPaymentInfo($data: CreateDesignerPaymentInfoInput!) {
  createDesignerPaymentInfo(data: $data) {
    id
    designerId
    designer {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
      }
      profilePicURL
      bio
      enabled
    }
  }
}
    `;
export type CreateDesignerPaymentInfoMutationFn = Apollo.MutationFunction<CreateDesignerPaymentInfoMutation, CreateDesignerPaymentInfoMutationVariables>;

/**
 * __useCreateDesignerPaymentInfoMutation__
 *
 * To run a mutation, you first call `useCreateDesignerPaymentInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDesignerPaymentInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDesignerPaymentInfoMutation, { data, loading, error }] = useCreateDesignerPaymentInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDesignerPaymentInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateDesignerPaymentInfoMutation, CreateDesignerPaymentInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDesignerPaymentInfoMutation, CreateDesignerPaymentInfoMutationVariables>(CreateDesignerPaymentInfoDocument, options);
      }
export type CreateDesignerPaymentInfoMutationHookResult = ReturnType<typeof useCreateDesignerPaymentInfoMutation>;
export type CreateDesignerPaymentInfoMutationResult = Apollo.MutationResult<CreateDesignerPaymentInfoMutation>;
export type CreateDesignerPaymentInfoMutationOptions = Apollo.BaseMutationOptions<CreateDesignerPaymentInfoMutation, CreateDesignerPaymentInfoMutationVariables>;
export const CreateNewsletterTemplateDocument = gql`
    mutation CreateNewsletterTemplate($data: NewsletterTemplateInput!) {
  createNewsletterTemplate(data: $data) {
    id
    createdDate
    updatedDate
    header
    body
  }
}
    `;
export type CreateNewsletterTemplateMutationFn = Apollo.MutationFunction<CreateNewsletterTemplateMutation, CreateNewsletterTemplateMutationVariables>;

/**
 * __useCreateNewsletterTemplateMutation__
 *
 * To run a mutation, you first call `useCreateNewsletterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsletterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsletterTemplateMutation, { data, loading, error }] = useCreateNewsletterTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewsletterTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsletterTemplateMutation, CreateNewsletterTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewsletterTemplateMutation, CreateNewsletterTemplateMutationVariables>(CreateNewsletterTemplateDocument, options);
      }
export type CreateNewsletterTemplateMutationHookResult = ReturnType<typeof useCreateNewsletterTemplateMutation>;
export type CreateNewsletterTemplateMutationResult = Apollo.MutationResult<CreateNewsletterTemplateMutation>;
export type CreateNewsletterTemplateMutationOptions = Apollo.BaseMutationOptions<CreateNewsletterTemplateMutation, CreateNewsletterTemplateMutationVariables>;
export const UpdateNewsletterTemplateDocument = gql`
    mutation UpdateNewsletterTemplate($updateNewsletterTemplateId: String!, $data: NewsletterTemplateInput!) {
  updateNewsletterTemplate(id: $updateNewsletterTemplateId, data: $data) {
    id
    createdDate
    header
    body
  }
}
    `;
export type UpdateNewsletterTemplateMutationFn = Apollo.MutationFunction<UpdateNewsletterTemplateMutation, UpdateNewsletterTemplateMutationVariables>;

/**
 * __useUpdateNewsletterTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateNewsletterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsletterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsletterTemplateMutation, { data, loading, error }] = useUpdateNewsletterTemplateMutation({
 *   variables: {
 *      updateNewsletterTemplateId: // value for 'updateNewsletterTemplateId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNewsletterTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsletterTemplateMutation, UpdateNewsletterTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNewsletterTemplateMutation, UpdateNewsletterTemplateMutationVariables>(UpdateNewsletterTemplateDocument, options);
      }
export type UpdateNewsletterTemplateMutationHookResult = ReturnType<typeof useUpdateNewsletterTemplateMutation>;
export type UpdateNewsletterTemplateMutationResult = Apollo.MutationResult<UpdateNewsletterTemplateMutation>;
export type UpdateNewsletterTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateNewsletterTemplateMutation, UpdateNewsletterTemplateMutationVariables>;
export const CreateOpinionDocument = gql`
    mutation CreateOpinion($data: CreateOpinionInput!) {
  createOpinion(data: $data) {
    id
    createdDate
    strategyId
    stockDataId
    accountPercentage
  }
}
    `;
export type CreateOpinionMutationFn = Apollo.MutationFunction<CreateOpinionMutation, CreateOpinionMutationVariables>;

/**
 * __useCreateOpinionMutation__
 *
 * To run a mutation, you first call `useCreateOpinionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpinionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpinionMutation, { data, loading, error }] = useCreateOpinionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOpinionMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpinionMutation, CreateOpinionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpinionMutation, CreateOpinionMutationVariables>(CreateOpinionDocument, options);
      }
export type CreateOpinionMutationHookResult = ReturnType<typeof useCreateOpinionMutation>;
export type CreateOpinionMutationResult = Apollo.MutationResult<CreateOpinionMutation>;
export type CreateOpinionMutationOptions = Apollo.BaseMutationOptions<CreateOpinionMutation, CreateOpinionMutationVariables>;
export const UpdateOpinionDocument = gql`
    mutation UpdateOpinion($updateOpinionId: String!, $data: UpdateOpinionInput!) {
  updateOpinion(id: $updateOpinionId, data: $data) {
    id
    createdDate
    strategyId
    stockDataId
    accountPercentage
  }
}
    `;
export type UpdateOpinionMutationFn = Apollo.MutationFunction<UpdateOpinionMutation, UpdateOpinionMutationVariables>;

/**
 * __useUpdateOpinionMutation__
 *
 * To run a mutation, you first call `useUpdateOpinionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpinionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpinionMutation, { data, loading, error }] = useUpdateOpinionMutation({
 *   variables: {
 *      updateOpinionId: // value for 'updateOpinionId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOpinionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpinionMutation, UpdateOpinionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpinionMutation, UpdateOpinionMutationVariables>(UpdateOpinionDocument, options);
      }
export type UpdateOpinionMutationHookResult = ReturnType<typeof useUpdateOpinionMutation>;
export type UpdateOpinionMutationResult = Apollo.MutationResult<UpdateOpinionMutation>;
export type UpdateOpinionMutationOptions = Apollo.BaseMutationOptions<UpdateOpinionMutation, UpdateOpinionMutationVariables>;
export const DeleteOpinionDocument = gql`
    mutation DeleteOpinion($deleteOpinionId: String!) {
  deleteOpinion(id: $deleteOpinionId)
}
    `;
export type DeleteOpinionMutationFn = Apollo.MutationFunction<DeleteOpinionMutation, DeleteOpinionMutationVariables>;

/**
 * __useDeleteOpinionMutation__
 *
 * To run a mutation, you first call `useDeleteOpinionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpinionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpinionMutation, { data, loading, error }] = useDeleteOpinionMutation({
 *   variables: {
 *      deleteOpinionId: // value for 'deleteOpinionId'
 *   },
 * });
 */
export function useDeleteOpinionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOpinionMutation, DeleteOpinionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOpinionMutation, DeleteOpinionMutationVariables>(DeleteOpinionDocument, options);
      }
export type DeleteOpinionMutationHookResult = ReturnType<typeof useDeleteOpinionMutation>;
export type DeleteOpinionMutationResult = Apollo.MutationResult<DeleteOpinionMutation>;
export type DeleteOpinionMutationOptions = Apollo.BaseMutationOptions<DeleteOpinionMutation, DeleteOpinionMutationVariables>;
export const UpdatePositionDocument = gql`
    mutation UpdatePosition($updatePositionId: String!, $data: UpdatePositionInput!) {
  updatePosition(id: $updatePositionId, data: $data) {
    id
    accountId
    stockData {
      id
      symbol
      longName
      price
    }
    shares
  }
}
    `;
export type UpdatePositionMutationFn = Apollo.MutationFunction<UpdatePositionMutation, UpdatePositionMutationVariables>;

/**
 * __useUpdatePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionMutation, { data, loading, error }] = useUpdatePositionMutation({
 *   variables: {
 *      updatePositionId: // value for 'updatePositionId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePositionMutation, UpdatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePositionMutation, UpdatePositionMutationVariables>(UpdatePositionDocument, options);
      }
export type UpdatePositionMutationHookResult = ReturnType<typeof useUpdatePositionMutation>;
export type UpdatePositionMutationResult = Apollo.MutationResult<UpdatePositionMutation>;
export type UpdatePositionMutationOptions = Apollo.BaseMutationOptions<UpdatePositionMutation, UpdatePositionMutationVariables>;
export const PublishQuarterlyNewsletterDocument = gql`
    mutation PublishQuarterlyNewsletter($newsletterTemplateId: String!) {
  publishQuarterlyNewsletter(newsletterTemplateId: $newsletterTemplateId) {
    id
    header
    body
    quarter
    year
  }
}
    `;
export type PublishQuarterlyNewsletterMutationFn = Apollo.MutationFunction<PublishQuarterlyNewsletterMutation, PublishQuarterlyNewsletterMutationVariables>;

/**
 * __usePublishQuarterlyNewsletterMutation__
 *
 * To run a mutation, you first call `usePublishQuarterlyNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishQuarterlyNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishQuarterlyNewsletterMutation, { data, loading, error }] = usePublishQuarterlyNewsletterMutation({
 *   variables: {
 *      newsletterTemplateId: // value for 'newsletterTemplateId'
 *   },
 * });
 */
export function usePublishQuarterlyNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<PublishQuarterlyNewsletterMutation, PublishQuarterlyNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishQuarterlyNewsletterMutation, PublishQuarterlyNewsletterMutationVariables>(PublishQuarterlyNewsletterDocument, options);
      }
export type PublishQuarterlyNewsletterMutationHookResult = ReturnType<typeof usePublishQuarterlyNewsletterMutation>;
export type PublishQuarterlyNewsletterMutationResult = Apollo.MutationResult<PublishQuarterlyNewsletterMutation>;
export type PublishQuarterlyNewsletterMutationOptions = Apollo.BaseMutationOptions<PublishQuarterlyNewsletterMutation, PublishQuarterlyNewsletterMutationVariables>;
export const CreateRevenueShareDocument = gql`
    mutation CreateRevenueShare($data: CreateRevenueShareInput!) {
  createRevenueShare(data: $data) {
    id
    designerId
    designerFee
    serviceFee
    designerFeeAnnual
    serviceFeeAnnual
    designer {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
      }
      profilePicURL
      bio
      enabled
    }
  }
}
    `;
export type CreateRevenueShareMutationFn = Apollo.MutationFunction<CreateRevenueShareMutation, CreateRevenueShareMutationVariables>;

/**
 * __useCreateRevenueShareMutation__
 *
 * To run a mutation, you first call `useCreateRevenueShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevenueShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevenueShareMutation, { data, loading, error }] = useCreateRevenueShareMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRevenueShareMutation(baseOptions?: Apollo.MutationHookOptions<CreateRevenueShareMutation, CreateRevenueShareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRevenueShareMutation, CreateRevenueShareMutationVariables>(CreateRevenueShareDocument, options);
      }
export type CreateRevenueShareMutationHookResult = ReturnType<typeof useCreateRevenueShareMutation>;
export type CreateRevenueShareMutationResult = Apollo.MutationResult<CreateRevenueShareMutation>;
export type CreateRevenueShareMutationOptions = Apollo.BaseMutationOptions<CreateRevenueShareMutation, CreateRevenueShareMutationVariables>;
export const UpdateRevenueShareDocument = gql`
    mutation UpdateRevenueShare($id: String!, $data: UpdateRevenueShareInput!) {
  updateRevenueShare(id: $id, data: $data) {
    id
    designerId
    designerFee
    serviceFee
    designer {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
      }
      profilePicURL
      bio
      enabled
    }
  }
}
    `;
export type UpdateRevenueShareMutationFn = Apollo.MutationFunction<UpdateRevenueShareMutation, UpdateRevenueShareMutationVariables>;

/**
 * __useUpdateRevenueShareMutation__
 *
 * To run a mutation, you first call `useUpdateRevenueShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRevenueShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRevenueShareMutation, { data, loading, error }] = useUpdateRevenueShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRevenueShareMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRevenueShareMutation, UpdateRevenueShareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRevenueShareMutation, UpdateRevenueShareMutationVariables>(UpdateRevenueShareDocument, options);
      }
export type UpdateRevenueShareMutationHookResult = ReturnType<typeof useUpdateRevenueShareMutation>;
export type UpdateRevenueShareMutationResult = Apollo.MutationResult<UpdateRevenueShareMutation>;
export type UpdateRevenueShareMutationOptions = Apollo.BaseMutationOptions<UpdateRevenueShareMutation, UpdateRevenueShareMutationVariables>;
export const CreateStockDataDocument = gql`
    mutation CreateStockData($data: CreateStockDataInput!) {
  createStockData(data: $data) {
    id
    createdDate
    updatedDate
    price
    symbol
    shortName
    longName
  }
}
    `;
export type CreateStockDataMutationFn = Apollo.MutationFunction<CreateStockDataMutation, CreateStockDataMutationVariables>;

/**
 * __useCreateStockDataMutation__
 *
 * To run a mutation, you first call `useCreateStockDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockDataMutation, { data, loading, error }] = useCreateStockDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStockDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockDataMutation, CreateStockDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStockDataMutation, CreateStockDataMutationVariables>(CreateStockDataDocument, options);
      }
export type CreateStockDataMutationHookResult = ReturnType<typeof useCreateStockDataMutation>;
export type CreateStockDataMutationResult = Apollo.MutationResult<CreateStockDataMutation>;
export type CreateStockDataMutationOptions = Apollo.BaseMutationOptions<CreateStockDataMutation, CreateStockDataMutationVariables>;
export const CreateStrategyDocument = gql`
    mutation CreateStrategy($data: CreateStrategyInput!) {
  createStrategy(data: $data) {
    id
    createdDate
    designerId
    name
    riskLevel
    termDuration
    syncThreshold
    opinions {
      id
      createdDate
      strategyId
      stockDataId
      accountPercentage
      stockData {
        id
        createdDate
        price
        symbol
        shortName
        longName
      }
    }
  }
}
    `;
export type CreateStrategyMutationFn = Apollo.MutationFunction<CreateStrategyMutation, CreateStrategyMutationVariables>;

/**
 * __useCreateStrategyMutation__
 *
 * To run a mutation, you first call `useCreateStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStrategyMutation, { data, loading, error }] = useCreateStrategyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStrategyMutation(baseOptions?: Apollo.MutationHookOptions<CreateStrategyMutation, CreateStrategyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStrategyMutation, CreateStrategyMutationVariables>(CreateStrategyDocument, options);
      }
export type CreateStrategyMutationHookResult = ReturnType<typeof useCreateStrategyMutation>;
export type CreateStrategyMutationResult = Apollo.MutationResult<CreateStrategyMutation>;
export type CreateStrategyMutationOptions = Apollo.BaseMutationOptions<CreateStrategyMutation, CreateStrategyMutationVariables>;
export const UpdateStrategyDocument = gql`
    mutation UpdateStrategy($data: UpdateStrategyInput!, $updateStrategyId: String!) {
  updateStrategy(data: $data, id: $updateStrategyId) {
    id
    createdDate
    updatedDate
    seedId
    designerId
    name
    riskLevel
    termDuration
    syncThreshold
    cashPercentage
    opinions {
      id
      createdDate
      updatedDate
      seedId
      strategyId
      stockDataId
      accountPercentage
      stockData {
        id
        createdDate
        updatedDate
        seedId
        price
        symbol
        shortName
        longName
      }
    }
  }
}
    `;
export type UpdateStrategyMutationFn = Apollo.MutationFunction<UpdateStrategyMutation, UpdateStrategyMutationVariables>;

/**
 * __useUpdateStrategyMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyMutation, { data, loading, error }] = useUpdateStrategyMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updateStrategyId: // value for 'updateStrategyId'
 *   },
 * });
 */
export function useUpdateStrategyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyMutation, UpdateStrategyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyMutation, UpdateStrategyMutationVariables>(UpdateStrategyDocument, options);
      }
export type UpdateStrategyMutationHookResult = ReturnType<typeof useUpdateStrategyMutation>;
export type UpdateStrategyMutationResult = Apollo.MutationResult<UpdateStrategyMutation>;
export type UpdateStrategyMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyMutation, UpdateStrategyMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($data: CreateSubscriptionInput!) {
  createSubscription(data: $data) {
    id
    designerId
    renewalDate
    createdDate
    renewalFrequency
    cost
    cancelled
    trial
    swapRequest {
      id
      designerId
    }
    status
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const BeginFreeTrialDocument = gql`
    mutation BeginFreeTrial($data: BeginFreeTrialInput!) {
  beginFreeTrial(data: $data) {
    id
    trial
    renewalDate
    renewalFrequency
    designer {
      user {
        id
        firstName
        lastName
      }
    }
    cost
  }
}
    `;
export type BeginFreeTrialMutationFn = Apollo.MutationFunction<BeginFreeTrialMutation, BeginFreeTrialMutationVariables>;

/**
 * __useBeginFreeTrialMutation__
 *
 * To run a mutation, you first call `useBeginFreeTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginFreeTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginFreeTrialMutation, { data, loading, error }] = useBeginFreeTrialMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBeginFreeTrialMutation(baseOptions?: Apollo.MutationHookOptions<BeginFreeTrialMutation, BeginFreeTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BeginFreeTrialMutation, BeginFreeTrialMutationVariables>(BeginFreeTrialDocument, options);
      }
export type BeginFreeTrialMutationHookResult = ReturnType<typeof useBeginFreeTrialMutation>;
export type BeginFreeTrialMutationResult = Apollo.MutationResult<BeginFreeTrialMutation>;
export type BeginFreeTrialMutationOptions = Apollo.BaseMutationOptions<BeginFreeTrialMutation, BeginFreeTrialMutationVariables>;
export const CancelSubscriptionRenewalDocument = gql`
    mutation CancelSubscriptionRenewal {
  cancelCurrentSubscription
}
    `;
export type CancelSubscriptionRenewalMutationFn = Apollo.MutationFunction<CancelSubscriptionRenewalMutation, CancelSubscriptionRenewalMutationVariables>;

/**
 * __useCancelSubscriptionRenewalMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionRenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionRenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionRenewalMutation, { data, loading, error }] = useCancelSubscriptionRenewalMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionRenewalMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionRenewalMutation, CancelSubscriptionRenewalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionRenewalMutation, CancelSubscriptionRenewalMutationVariables>(CancelSubscriptionRenewalDocument, options);
      }
export type CancelSubscriptionRenewalMutationHookResult = ReturnType<typeof useCancelSubscriptionRenewalMutation>;
export type CancelSubscriptionRenewalMutationResult = Apollo.MutationResult<CancelSubscriptionRenewalMutation>;
export type CancelSubscriptionRenewalMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionRenewalMutation, CancelSubscriptionRenewalMutationVariables>;
export const CreateSupportTicketDocument = gql`
    mutation CreateSupportTicket($data: SupportTicketInput!, $subscriberId: String!) {
  createSupportTicket(data: $data, subscriberId: $subscriberId) {
    id
  }
}
    `;
export type CreateSupportTicketMutationFn = Apollo.MutationFunction<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>;

/**
 * __useCreateSupportTicketMutation__
 *
 * To run a mutation, you first call `useCreateSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportTicketMutation, { data, loading, error }] = useCreateSupportTicketMutation({
 *   variables: {
 *      data: // value for 'data'
 *      subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useCreateSupportTicketMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>(CreateSupportTicketDocument, options);
      }
export type CreateSupportTicketMutationHookResult = ReturnType<typeof useCreateSupportTicketMutation>;
export type CreateSupportTicketMutationResult = Apollo.MutationResult<CreateSupportTicketMutation>;
export type CreateSupportTicketMutationOptions = Apollo.BaseMutationOptions<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>;
export const CreateSwapRequestDocument = gql`
    mutation CreateSwapRequest($data: CreateSwapRequestInput!) {
  createSwapRequest(data: $data) {
    id
    createdDate
    designerId
    subscriptionId
  }
}
    `;
export type CreateSwapRequestMutationFn = Apollo.MutationFunction<CreateSwapRequestMutation, CreateSwapRequestMutationVariables>;

/**
 * __useCreateSwapRequestMutation__
 *
 * To run a mutation, you first call `useCreateSwapRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSwapRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSwapRequestMutation, { data, loading, error }] = useCreateSwapRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSwapRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateSwapRequestMutation, CreateSwapRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSwapRequestMutation, CreateSwapRequestMutationVariables>(CreateSwapRequestDocument, options);
      }
export type CreateSwapRequestMutationHookResult = ReturnType<typeof useCreateSwapRequestMutation>;
export type CreateSwapRequestMutationResult = Apollo.MutationResult<CreateSwapRequestMutation>;
export type CreateSwapRequestMutationOptions = Apollo.BaseMutationOptions<CreateSwapRequestMutation, CreateSwapRequestMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $data: UpdateUserInput!) {
  updateUser(id: $id, data: $data) {
    id
    cognitoId
    role
    firstName
    lastName
    email
    phoneNumber
    smsConsent
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const AcceptTacDocument = gql`
    mutation AcceptTAC {
  acceptTAC
}
    `;
export type AcceptTacMutationFn = Apollo.MutationFunction<AcceptTacMutation, AcceptTacMutationVariables>;

/**
 * __useAcceptTacMutation__
 *
 * To run a mutation, you first call `useAcceptTacMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTacMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTacMutation, { data, loading, error }] = useAcceptTacMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTacMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTacMutation, AcceptTacMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTacMutation, AcceptTacMutationVariables>(AcceptTacDocument, options);
      }
export type AcceptTacMutationHookResult = ReturnType<typeof useAcceptTacMutation>;
export type AcceptTacMutationResult = Apollo.MutationResult<AcceptTacMutation>;
export type AcceptTacMutationOptions = Apollo.BaseMutationOptions<AcceptTacMutation, AcceptTacMutationVariables>;
export const GetAccountPageDocument = gql`
    query GetAccountPage($pagination: PaginationInput!) {
  accountPage(pagination: $pagination) {
    items {
      id
      createdDate
      name
      strategy {
        id
        name
      }
      subscriber {
        user {
          firstName
          lastName
        }
      }
      totalValue
    }
    totalRows
  }
}
    `;

/**
 * __useGetAccountPageQuery__
 *
 * To run a query within a React component, call `useGetAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAccountPageQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPageQuery, GetAccountPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPageQuery, GetAccountPageQueryVariables>(GetAccountPageDocument, options);
      }
export function useGetAccountPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPageQuery, GetAccountPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPageQuery, GetAccountPageQueryVariables>(GetAccountPageDocument, options);
        }
export type GetAccountPageQueryHookResult = ReturnType<typeof useGetAccountPageQuery>;
export type GetAccountPageLazyQueryHookResult = ReturnType<typeof useGetAccountPageLazyQuery>;
export type GetAccountPageQueryResult = Apollo.QueryResult<GetAccountPageQuery, GetAccountPageQueryVariables>;
export const GetAccountOneDocument = gql`
    query GetAccountOne($accountOneId: String!) {
  accountOne(id: $accountOneId) {
    id
    externalId
    name
    unallocatedFunds
    cashReserve
    strategy {
      id
      designer {
        id
      }
      name
      description
      enabled
      riskLevel
      termDuration
      cashMin
      syncThreshold
      cashPercentage
    }
    positions {
      id
      selected
      stockData {
        id
        createdDate
        price
        symbol
        shortName
        longName
      }
      shares
      value
      unsupported
      institutionValue
      description
    }
    assessment {
      riskLevel
      termDuration
      id
    }
    custodian {
      id
      name
    }
    totalValue
    mask
    withinThreshold
    lastSynced
  }
}
    `;

/**
 * __useGetAccountOneQuery__
 *
 * To run a query within a React component, call `useGetAccountOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountOneQuery({
 *   variables: {
 *      accountOneId: // value for 'accountOneId'
 *   },
 * });
 */
export function useGetAccountOneQuery(baseOptions: Apollo.QueryHookOptions<GetAccountOneQuery, GetAccountOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountOneQuery, GetAccountOneQueryVariables>(GetAccountOneDocument, options);
      }
export function useGetAccountOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountOneQuery, GetAccountOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountOneQuery, GetAccountOneQueryVariables>(GetAccountOneDocument, options);
        }
export type GetAccountOneQueryHookResult = ReturnType<typeof useGetAccountOneQuery>;
export type GetAccountOneLazyQueryHookResult = ReturnType<typeof useGetAccountOneLazyQuery>;
export type GetAccountOneQueryResult = Apollo.QueryResult<GetAccountOneQuery, GetAccountOneQueryVariables>;
export const GetAccountManyDocument = gql`
    query GetAccountMany($subscriberId: String) {
  accountMany(subscriberId: $subscriberId) {
    id
    createdDate
    name
    externalId
    strategy {
      id
      name
    }
    totalValue
    custodian {
      id
      name
      userTag
      plaidInstitutionId
      reAuthRequired
      isActive
    }
    withinThreshold
    mask
    lastSynced
    cashReserve
    isActive
  }
}
    `;

/**
 * __useGetAccountManyQuery__
 *
 * To run a query within a React component, call `useGetAccountManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountManyQuery({
 *   variables: {
 *      subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useGetAccountManyQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountManyQuery, GetAccountManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountManyQuery, GetAccountManyQueryVariables>(GetAccountManyDocument, options);
      }
export function useGetAccountManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountManyQuery, GetAccountManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountManyQuery, GetAccountManyQueryVariables>(GetAccountManyDocument, options);
        }
export type GetAccountManyQueryHookResult = ReturnType<typeof useGetAccountManyQuery>;
export type GetAccountManyLazyQueryHookResult = ReturnType<typeof useGetAccountManyLazyQuery>;
export type GetAccountManyQueryResult = Apollo.QueryResult<GetAccountManyQuery, GetAccountManyQueryVariables>;
export const GetCustodianAccountsDocument = gql`
    query GetCustodianAccounts($custodianId: String) {
  getCustodianAccounts(custodianId: $custodianId) {
    id
    externalId
    name
    mask
    isActive
    totalValue
  }
}
    `;

/**
 * __useGetCustodianAccountsQuery__
 *
 * To run a query within a React component, call `useGetCustodianAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustodianAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustodianAccountsQuery({
 *   variables: {
 *      custodianId: // value for 'custodianId'
 *   },
 * });
 */
export function useGetCustodianAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustodianAccountsQuery, GetCustodianAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustodianAccountsQuery, GetCustodianAccountsQueryVariables>(GetCustodianAccountsDocument, options);
      }
export function useGetCustodianAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustodianAccountsQuery, GetCustodianAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustodianAccountsQuery, GetCustodianAccountsQueryVariables>(GetCustodianAccountsDocument, options);
        }
export type GetCustodianAccountsQueryHookResult = ReturnType<typeof useGetCustodianAccountsQuery>;
export type GetCustodianAccountsLazyQueryHookResult = ReturnType<typeof useGetCustodianAccountsLazyQuery>;
export type GetCustodianAccountsQueryResult = Apollo.QueryResult<GetCustodianAccountsQuery, GetCustodianAccountsQueryVariables>;
export const GenerateTradeToSyncDocument = gql`
    query GenerateTradeToSync($generateTradesToSyncId: String!, $amount: Float) {
  generateTradesToSync(id: $generateTradesToSyncId, amount: $amount) {
    operation
    shares
    stockData {
      id
      symbol
      shortName
      price
    }
  }
}
    `;

/**
 * __useGenerateTradeToSyncQuery__
 *
 * To run a query within a React component, call `useGenerateTradeToSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateTradeToSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateTradeToSyncQuery({
 *   variables: {
 *      generateTradesToSyncId: // value for 'generateTradesToSyncId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useGenerateTradeToSyncQuery(baseOptions: Apollo.QueryHookOptions<GenerateTradeToSyncQuery, GenerateTradeToSyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateTradeToSyncQuery, GenerateTradeToSyncQueryVariables>(GenerateTradeToSyncDocument, options);
      }
export function useGenerateTradeToSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateTradeToSyncQuery, GenerateTradeToSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateTradeToSyncQuery, GenerateTradeToSyncQueryVariables>(GenerateTradeToSyncDocument, options);
        }
export type GenerateTradeToSyncQueryHookResult = ReturnType<typeof useGenerateTradeToSyncQuery>;
export type GenerateTradeToSyncLazyQueryHookResult = ReturnType<typeof useGenerateTradeToSyncLazyQuery>;
export type GenerateTradeToSyncQueryResult = Apollo.QueryResult<GenerateTradeToSyncQuery, GenerateTradeToSyncQueryVariables>;
export const GenerateAllocateCashTradesDocument = gql`
    query GenerateAllocateCashTrades($generateTradesToSyncId: String!, $amount: Float!) {
  generateAllocateCashTrades(id: $generateTradesToSyncId, amount: $amount) {
    operation
    shares
    stockData {
      id
      symbol
      shortName
      price
    }
  }
}
    `;

/**
 * __useGenerateAllocateCashTradesQuery__
 *
 * To run a query within a React component, call `useGenerateAllocateCashTradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateAllocateCashTradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateAllocateCashTradesQuery({
 *   variables: {
 *      generateTradesToSyncId: // value for 'generateTradesToSyncId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useGenerateAllocateCashTradesQuery(baseOptions: Apollo.QueryHookOptions<GenerateAllocateCashTradesQuery, GenerateAllocateCashTradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateAllocateCashTradesQuery, GenerateAllocateCashTradesQueryVariables>(GenerateAllocateCashTradesDocument, options);
      }
export function useGenerateAllocateCashTradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateAllocateCashTradesQuery, GenerateAllocateCashTradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateAllocateCashTradesQuery, GenerateAllocateCashTradesQueryVariables>(GenerateAllocateCashTradesDocument, options);
        }
export type GenerateAllocateCashTradesQueryHookResult = ReturnType<typeof useGenerateAllocateCashTradesQuery>;
export type GenerateAllocateCashTradesLazyQueryHookResult = ReturnType<typeof useGenerateAllocateCashTradesLazyQuery>;
export type GenerateAllocateCashTradesQueryResult = Apollo.QueryResult<GenerateAllocateCashTradesQuery, GenerateAllocateCashTradesQueryVariables>;
export const GenerateDeallocateCashTradesDocument = gql`
    query GenerateDeallocateCashTrades($generateTradesToSyncId: String!, $amount: Float!) {
  generateTradesToDeallocate(id: $generateTradesToSyncId, amount: $amount) {
    operation
    shares
    stockData {
      id
      symbol
      shortName
      price
    }
  }
}
    `;

/**
 * __useGenerateDeallocateCashTradesQuery__
 *
 * To run a query within a React component, call `useGenerateDeallocateCashTradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateDeallocateCashTradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateDeallocateCashTradesQuery({
 *   variables: {
 *      generateTradesToSyncId: // value for 'generateTradesToSyncId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useGenerateDeallocateCashTradesQuery(baseOptions: Apollo.QueryHookOptions<GenerateDeallocateCashTradesQuery, GenerateDeallocateCashTradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateDeallocateCashTradesQuery, GenerateDeallocateCashTradesQueryVariables>(GenerateDeallocateCashTradesDocument, options);
      }
export function useGenerateDeallocateCashTradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateDeallocateCashTradesQuery, GenerateDeallocateCashTradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateDeallocateCashTradesQuery, GenerateDeallocateCashTradesQueryVariables>(GenerateDeallocateCashTradesDocument, options);
        }
export type GenerateDeallocateCashTradesQueryHookResult = ReturnType<typeof useGenerateDeallocateCashTradesQuery>;
export type GenerateDeallocateCashTradesLazyQueryHookResult = ReturnType<typeof useGenerateDeallocateCashTradesLazyQuery>;
export type GenerateDeallocateCashTradesQueryResult = Apollo.QueryResult<GenerateDeallocateCashTradesQuery, GenerateDeallocateCashTradesQueryVariables>;
export const GetPlaidLinkTokenDocument = gql`
    query GetPlaidLinkToken {
  getPlaidLinkToken
}
    `;

/**
 * __useGetPlaidLinkTokenQuery__
 *
 * To run a query within a React component, call `useGetPlaidLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaidLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaidLinkTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlaidLinkTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>(GetPlaidLinkTokenDocument, options);
      }
export function useGetPlaidLinkTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>(GetPlaidLinkTokenDocument, options);
        }
export type GetPlaidLinkTokenQueryHookResult = ReturnType<typeof useGetPlaidLinkTokenQuery>;
export type GetPlaidLinkTokenLazyQueryHookResult = ReturnType<typeof useGetPlaidLinkTokenLazyQuery>;
export type GetPlaidLinkTokenQueryResult = Apollo.QueryResult<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>;
export const GetPlaidUpdateLinkTokenDocument = gql`
    query GetPlaidUpdateLinkToken($custodianId: String!) {
  getPlaidUpdateLinkToken(custodianId: $custodianId)
}
    `;

/**
 * __useGetPlaidUpdateLinkTokenQuery__
 *
 * To run a query within a React component, call `useGetPlaidUpdateLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaidUpdateLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaidUpdateLinkTokenQuery({
 *   variables: {
 *      custodianId: // value for 'custodianId'
 *   },
 * });
 */
export function useGetPlaidUpdateLinkTokenQuery(baseOptions: Apollo.QueryHookOptions<GetPlaidUpdateLinkTokenQuery, GetPlaidUpdateLinkTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaidUpdateLinkTokenQuery, GetPlaidUpdateLinkTokenQueryVariables>(GetPlaidUpdateLinkTokenDocument, options);
      }
export function useGetPlaidUpdateLinkTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaidUpdateLinkTokenQuery, GetPlaidUpdateLinkTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaidUpdateLinkTokenQuery, GetPlaidUpdateLinkTokenQueryVariables>(GetPlaidUpdateLinkTokenDocument, options);
        }
export type GetPlaidUpdateLinkTokenQueryHookResult = ReturnType<typeof useGetPlaidUpdateLinkTokenQuery>;
export type GetPlaidUpdateLinkTokenLazyQueryHookResult = ReturnType<typeof useGetPlaidUpdateLinkTokenLazyQuery>;
export type GetPlaidUpdateLinkTokenQueryResult = Apollo.QueryResult<GetPlaidUpdateLinkTokenQuery, GetPlaidUpdateLinkTokenQueryVariables>;
export const GetAdminDesignerReportDocument = gql`
    query GetAdminDesignerReport($designerId: String!) {
  getAdminDesignerReport(designerId: $designerId) {
    noOfSubscribers
    noOfStrategies
    totalAmount
    strategies {
      name
      riskLevel
      termDuration
    }
  }
}
    `;

/**
 * __useGetAdminDesignerReportQuery__
 *
 * To run a query within a React component, call `useGetAdminDesignerReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDesignerReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDesignerReportQuery({
 *   variables: {
 *      designerId: // value for 'designerId'
 *   },
 * });
 */
export function useGetAdminDesignerReportQuery(baseOptions: Apollo.QueryHookOptions<GetAdminDesignerReportQuery, GetAdminDesignerReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminDesignerReportQuery, GetAdminDesignerReportQueryVariables>(GetAdminDesignerReportDocument, options);
      }
export function useGetAdminDesignerReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminDesignerReportQuery, GetAdminDesignerReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminDesignerReportQuery, GetAdminDesignerReportQueryVariables>(GetAdminDesignerReportDocument, options);
        }
export type GetAdminDesignerReportQueryHookResult = ReturnType<typeof useGetAdminDesignerReportQuery>;
export type GetAdminDesignerReportLazyQueryHookResult = ReturnType<typeof useGetAdminDesignerReportLazyQuery>;
export type GetAdminDesignerReportQueryResult = Apollo.QueryResult<GetAdminDesignerReportQuery, GetAdminDesignerReportQueryVariables>;
export const GetAdminReportDocument = gql`
    query GetAdminReport {
  getAdminReport {
    numberOfAccounts
    averageValueOfAccounts
    noOfActiveDesigners
    designers {
      id
      userId
      enabled
      profilePicURL
      user {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetAdminReportQuery__
 *
 * To run a query within a React component, call `useGetAdminReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminReportQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminReportQuery, GetAdminReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportQuery, GetAdminReportQueryVariables>(GetAdminReportDocument, options);
      }
export function useGetAdminReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportQuery, GetAdminReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportQuery, GetAdminReportQueryVariables>(GetAdminReportDocument, options);
        }
export type GetAdminReportQueryHookResult = ReturnType<typeof useGetAdminReportQuery>;
export type GetAdminReportLazyQueryHookResult = ReturnType<typeof useGetAdminReportLazyQuery>;
export type GetAdminReportQueryResult = Apollo.QueryResult<GetAdminReportQuery, GetAdminReportQueryVariables>;
export const GetDesignersDocument = gql`
    query GetDesigners {
  designerMany {
    id
    user {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
      createdDate
    }
  }
}
    `;

/**
 * __useGetDesignersQuery__
 *
 * To run a query within a React component, call `useGetDesignersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDesignersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDesignersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDesignersQuery(baseOptions?: Apollo.QueryHookOptions<GetDesignersQuery, GetDesignersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDesignersQuery, GetDesignersQueryVariables>(GetDesignersDocument, options);
      }
export function useGetDesignersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDesignersQuery, GetDesignersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDesignersQuery, GetDesignersQueryVariables>(GetDesignersDocument, options);
        }
export type GetDesignersQueryHookResult = ReturnType<typeof useGetDesignersQuery>;
export type GetDesignersLazyQueryHookResult = ReturnType<typeof useGetDesignersLazyQuery>;
export type GetDesignersQueryResult = Apollo.QueryResult<GetDesignersQuery, GetDesignersQueryVariables>;
export const GetDesignerDocument = gql`
    query GetDesigner($id: String) {
  designerOne(id: $id) {
    id
    user {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
      createdDate
    }
    profilePicURL
    bio
    enabled
    subscribers {
      id
      user {
        id
        firstName
        lastName
      }
    }
    revenueShare {
      id
      designerFee
      serviceFee
      designerFeeAnnual
      serviceFeeAnnual
    }
    paymentInfo {
      id
    }
    paymentInfo {
      id
      stripeAccountId
      stripeOnboardingComplete
    }
    strategies {
      id
      createdDate
      name
      description
      enabled
      riskLevel
      termDuration
      cashMin
    }
    supportPhone
    supportEmail
    preferredSupportContact
  }
}
    `;

/**
 * __useGetDesignerQuery__
 *
 * To run a query within a React component, call `useGetDesignerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDesignerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDesignerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDesignerQuery(baseOptions?: Apollo.QueryHookOptions<GetDesignerQuery, GetDesignerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDesignerQuery, GetDesignerQueryVariables>(GetDesignerDocument, options);
      }
export function useGetDesignerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDesignerQuery, GetDesignerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDesignerQuery, GetDesignerQueryVariables>(GetDesignerDocument, options);
        }
export type GetDesignerQueryHookResult = ReturnType<typeof useGetDesignerQuery>;
export type GetDesignerLazyQueryHookResult = ReturnType<typeof useGetDesignerLazyQuery>;
export type GetDesignerQueryResult = Apollo.QueryResult<GetDesignerQuery, GetDesignerQueryVariables>;
export const DesignerShortDocument = gql`
    query DesignerShort($designerShortId: String) {
  designerShort(id: $designerShortId) {
    firstName
    lastName
    profilePicURL
    bio
  }
}
    `;

/**
 * __useDesignerShortQuery__
 *
 * To run a query within a React component, call `useDesignerShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignerShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignerShortQuery({
 *   variables: {
 *      designerShortId: // value for 'designerShortId'
 *   },
 * });
 */
export function useDesignerShortQuery(baseOptions?: Apollo.QueryHookOptions<DesignerShortQuery, DesignerShortQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DesignerShortQuery, DesignerShortQueryVariables>(DesignerShortDocument, options);
      }
export function useDesignerShortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesignerShortQuery, DesignerShortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DesignerShortQuery, DesignerShortQueryVariables>(DesignerShortDocument, options);
        }
export type DesignerShortQueryHookResult = ReturnType<typeof useDesignerShortQuery>;
export type DesignerShortLazyQueryHookResult = ReturnType<typeof useDesignerShortLazyQuery>;
export type DesignerShortQueryResult = Apollo.QueryResult<DesignerShortQuery, DesignerShortQueryVariables>;
export const GetDesignerPageDocument = gql`
    query GetDesignerPage($pagination: PaginationInput!) {
  designerPage(pagination: $pagination) {
    items {
      id
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        createdDate
      }
      subscribers {
        id
      }
      enabled
    }
    totalRows
  }
}
    `;

/**
 * __useGetDesignerPageQuery__
 *
 * To run a query within a React component, call `useGetDesignerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDesignerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDesignerPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetDesignerPageQuery(baseOptions: Apollo.QueryHookOptions<GetDesignerPageQuery, GetDesignerPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDesignerPageQuery, GetDesignerPageQueryVariables>(GetDesignerPageDocument, options);
      }
export function useGetDesignerPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDesignerPageQuery, GetDesignerPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDesignerPageQuery, GetDesignerPageQueryVariables>(GetDesignerPageDocument, options);
        }
export type GetDesignerPageQueryHookResult = ReturnType<typeof useGetDesignerPageQuery>;
export type GetDesignerPageLazyQueryHookResult = ReturnType<typeof useGetDesignerPageLazyQuery>;
export type GetDesignerPageQueryResult = Apollo.QueryResult<GetDesignerPageQuery, GetDesignerPageQueryVariables>;
export const GetStripeDashboardLinkDocument = gql`
    query GetStripeDashboardLink {
  getStripeDashboardLink
}
    `;

/**
 * __useGetStripeDashboardLinkQuery__
 *
 * To run a query within a React component, call `useGetStripeDashboardLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeDashboardLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeDashboardLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeDashboardLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeDashboardLinkQuery, GetStripeDashboardLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeDashboardLinkQuery, GetStripeDashboardLinkQueryVariables>(GetStripeDashboardLinkDocument, options);
      }
export function useGetStripeDashboardLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeDashboardLinkQuery, GetStripeDashboardLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeDashboardLinkQuery, GetStripeDashboardLinkQueryVariables>(GetStripeDashboardLinkDocument, options);
        }
export type GetStripeDashboardLinkQueryHookResult = ReturnType<typeof useGetStripeDashboardLinkQuery>;
export type GetStripeDashboardLinkLazyQueryHookResult = ReturnType<typeof useGetStripeDashboardLinkLazyQuery>;
export type GetStripeDashboardLinkQueryResult = Apollo.QueryResult<GetStripeDashboardLinkQuery, GetStripeDashboardLinkQueryVariables>;
export const GetStripeAccountDocument = gql`
    query GetStripeAccount {
  getStripeAccount {
    id
    objectType
    transferTotal
    last4
    status
    bankName
    routingNumber
    brand
    expMonth
    expYear
  }
}
    `;

/**
 * __useGetStripeAccountQuery__
 *
 * To run a query within a React component, call `useGetStripeAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeAccountQuery, GetStripeAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeAccountQuery, GetStripeAccountQueryVariables>(GetStripeAccountDocument, options);
      }
export function useGetStripeAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeAccountQuery, GetStripeAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeAccountQuery, GetStripeAccountQueryVariables>(GetStripeAccountDocument, options);
        }
export type GetStripeAccountQueryHookResult = ReturnType<typeof useGetStripeAccountQuery>;
export type GetStripeAccountLazyQueryHookResult = ReturnType<typeof useGetStripeAccountLazyQuery>;
export type GetStripeAccountQueryResult = Apollo.QueryResult<GetStripeAccountQuery, GetStripeAccountQueryVariables>;
export const GetStripeAccountLinkDocument = gql`
    query GetStripeAccountLink {
  getAccountOnboardingLink
}
    `;

/**
 * __useGetStripeAccountLinkQuery__
 *
 * To run a query within a React component, call `useGetStripeAccountLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeAccountLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>(GetStripeAccountLinkDocument, options);
      }
export function useGetStripeAccountLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>(GetStripeAccountLinkDocument, options);
        }
export type GetStripeAccountLinkQueryHookResult = ReturnType<typeof useGetStripeAccountLinkQuery>;
export type GetStripeAccountLinkLazyQueryHookResult = ReturnType<typeof useGetStripeAccountLinkLazyQuery>;
export type GetStripeAccountLinkQueryResult = Apollo.QueryResult<GetStripeAccountLinkQuery, GetStripeAccountLinkQueryVariables>;
export const DesignerNewsletterPageDocument = gql`
    query DesignerNewsletterPage($pagination: PaginationInput!) {
  designerNewsletterPage(pagination: $pagination) {
    items {
      id
      updatedDate
      header
      body
      quarter
      year
      designerId
    }
    totalRows
  }
}
    `;

/**
 * __useDesignerNewsletterPageQuery__
 *
 * To run a query within a React component, call `useDesignerNewsletterPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignerNewsletterPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignerNewsletterPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDesignerNewsletterPageQuery(baseOptions: Apollo.QueryHookOptions<DesignerNewsletterPageQuery, DesignerNewsletterPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DesignerNewsletterPageQuery, DesignerNewsletterPageQueryVariables>(DesignerNewsletterPageDocument, options);
      }
export function useDesignerNewsletterPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesignerNewsletterPageQuery, DesignerNewsletterPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DesignerNewsletterPageQuery, DesignerNewsletterPageQueryVariables>(DesignerNewsletterPageDocument, options);
        }
export type DesignerNewsletterPageQueryHookResult = ReturnType<typeof useDesignerNewsletterPageQuery>;
export type DesignerNewsletterPageLazyQueryHookResult = ReturnType<typeof useDesignerNewsletterPageLazyQuery>;
export type DesignerNewsletterPageQueryResult = Apollo.QueryResult<DesignerNewsletterPageQuery, DesignerNewsletterPageQueryVariables>;
export const GetDesignerNewsletterDocument = gql`
    query GetDesignerNewsletter {
  designerNewsletter {
    id
    updatedDate
    header
    body
    quarter
    year
    designerId
  }
}
    `;

/**
 * __useGetDesignerNewsletterQuery__
 *
 * To run a query within a React component, call `useGetDesignerNewsletterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDesignerNewsletterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDesignerNewsletterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDesignerNewsletterQuery(baseOptions?: Apollo.QueryHookOptions<GetDesignerNewsletterQuery, GetDesignerNewsletterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDesignerNewsletterQuery, GetDesignerNewsletterQueryVariables>(GetDesignerNewsletterDocument, options);
      }
export function useGetDesignerNewsletterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDesignerNewsletterQuery, GetDesignerNewsletterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDesignerNewsletterQuery, GetDesignerNewsletterQueryVariables>(GetDesignerNewsletterDocument, options);
        }
export type GetDesignerNewsletterQueryHookResult = ReturnType<typeof useGetDesignerNewsletterQuery>;
export type GetDesignerNewsletterLazyQueryHookResult = ReturnType<typeof useGetDesignerNewsletterLazyQuery>;
export type GetDesignerNewsletterQueryResult = Apollo.QueryResult<GetDesignerNewsletterQuery, GetDesignerNewsletterQueryVariables>;
export const GetDesignerReportDocument = gql`
    query GetDesignerReport($timeRange: String!, $designerId: String!, $strategyId: String!) {
  getDesignerReport(
    timeRange: $timeRange
    designerId: $designerId
    strategyId: $strategyId
  ) {
    totalAccounts
    totalSubscribers
    strategyData {
      totalAccounts
      performance
      date
      quarter
    }
  }
}
    `;

/**
 * __useGetDesignerReportQuery__
 *
 * To run a query within a React component, call `useGetDesignerReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDesignerReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDesignerReportQuery({
 *   variables: {
 *      timeRange: // value for 'timeRange'
 *      designerId: // value for 'designerId'
 *      strategyId: // value for 'strategyId'
 *   },
 * });
 */
export function useGetDesignerReportQuery(baseOptions: Apollo.QueryHookOptions<GetDesignerReportQuery, GetDesignerReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDesignerReportQuery, GetDesignerReportQueryVariables>(GetDesignerReportDocument, options);
      }
export function useGetDesignerReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDesignerReportQuery, GetDesignerReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDesignerReportQuery, GetDesignerReportQueryVariables>(GetDesignerReportDocument, options);
        }
export type GetDesignerReportQueryHookResult = ReturnType<typeof useGetDesignerReportQuery>;
export type GetDesignerReportLazyQueryHookResult = ReturnType<typeof useGetDesignerReportLazyQuery>;
export type GetDesignerReportQueryResult = Apollo.QueryResult<GetDesignerReportQuery, GetDesignerReportQueryVariables>;
export const NewsletterTemplatePageDocument = gql`
    query NewsletterTemplatePage($pagination: PaginationInput!) {
  newsletterTemplatePage(pagination: $pagination) {
    items {
      id
      updatedDate
      header
      body
    }
    totalRows
  }
}
    `;

/**
 * __useNewsletterTemplatePageQuery__
 *
 * To run a query within a React component, call `useNewsletterTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterTemplatePageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useNewsletterTemplatePageQuery(baseOptions: Apollo.QueryHookOptions<NewsletterTemplatePageQuery, NewsletterTemplatePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsletterTemplatePageQuery, NewsletterTemplatePageQueryVariables>(NewsletterTemplatePageDocument, options);
      }
export function useNewsletterTemplatePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsletterTemplatePageQuery, NewsletterTemplatePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsletterTemplatePageQuery, NewsletterTemplatePageQueryVariables>(NewsletterTemplatePageDocument, options);
        }
export type NewsletterTemplatePageQueryHookResult = ReturnType<typeof useNewsletterTemplatePageQuery>;
export type NewsletterTemplatePageLazyQueryHookResult = ReturnType<typeof useNewsletterTemplatePageLazyQuery>;
export type NewsletterTemplatePageQueryResult = Apollo.QueryResult<NewsletterTemplatePageQuery, NewsletterTemplatePageQueryVariables>;
export const GetNewsletterTemplateDocument = gql`
    query GetNewsletterTemplate($newsletterTemplateOneId: String!) {
  newsletterTemplateOne(id: $newsletterTemplateOneId) {
    id
    createdDate
    seedId
    header
    body
  }
}
    `;

/**
 * __useGetNewsletterTemplateQuery__
 *
 * To run a query within a React component, call `useGetNewsletterTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterTemplateQuery({
 *   variables: {
 *      newsletterTemplateOneId: // value for 'newsletterTemplateOneId'
 *   },
 * });
 */
export function useGetNewsletterTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetNewsletterTemplateQuery, GetNewsletterTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsletterTemplateQuery, GetNewsletterTemplateQueryVariables>(GetNewsletterTemplateDocument, options);
      }
export function useGetNewsletterTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsletterTemplateQuery, GetNewsletterTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsletterTemplateQuery, GetNewsletterTemplateQueryVariables>(GetNewsletterTemplateDocument, options);
        }
export type GetNewsletterTemplateQueryHookResult = ReturnType<typeof useGetNewsletterTemplateQuery>;
export type GetNewsletterTemplateLazyQueryHookResult = ReturnType<typeof useGetNewsletterTemplateLazyQuery>;
export type GetNewsletterTemplateQueryResult = Apollo.QueryResult<GetNewsletterTemplateQuery, GetNewsletterTemplateQueryVariables>;
export const GetPlatformAdminPageDocument = gql`
    query GetPlatformAdminPage($pagination: PaginationInput!) {
  platformAdminPage(pagination: $pagination) {
    totalRows
    items {
      id
      user {
        id
        cognitoId
        role
        firstName
        lastName
        email
        phoneNumber
        createdDate
      }
    }
  }
}
    `;

/**
 * __useGetPlatformAdminPageQuery__
 *
 * To run a query within a React component, call `useGetPlatformAdminPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformAdminPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformAdminPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPlatformAdminPageQuery(baseOptions: Apollo.QueryHookOptions<GetPlatformAdminPageQuery, GetPlatformAdminPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlatformAdminPageQuery, GetPlatformAdminPageQueryVariables>(GetPlatformAdminPageDocument, options);
      }
export function useGetPlatformAdminPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformAdminPageQuery, GetPlatformAdminPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlatformAdminPageQuery, GetPlatformAdminPageQueryVariables>(GetPlatformAdminPageDocument, options);
        }
export type GetPlatformAdminPageQueryHookResult = ReturnType<typeof useGetPlatformAdminPageQuery>;
export type GetPlatformAdminPageLazyQueryHookResult = ReturnType<typeof useGetPlatformAdminPageLazyQuery>;
export type GetPlatformAdminPageQueryResult = Apollo.QueryResult<GetPlatformAdminPageQuery, GetPlatformAdminPageQueryVariables>;
export const GetPositionsDocument = gql`
    query GetPositions($accountId: String!) {
  positionMany(accountId: $accountId) {
    id
    selected
    stockData {
      id
      symbol
      shortName
    }
    shares
    value
  }
}
    `;

/**
 * __useGetPositionsQuery__
 *
 * To run a query within a React component, call `useGetPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetPositionsQuery(baseOptions: Apollo.QueryHookOptions<GetPositionsQuery, GetPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPositionsQuery, GetPositionsQueryVariables>(GetPositionsDocument, options);
      }
export function useGetPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPositionsQuery, GetPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPositionsQuery, GetPositionsQueryVariables>(GetPositionsDocument, options);
        }
export type GetPositionsQueryHookResult = ReturnType<typeof useGetPositionsQuery>;
export type GetPositionsLazyQueryHookResult = ReturnType<typeof useGetPositionsLazyQuery>;
export type GetPositionsQueryResult = Apollo.QueryResult<GetPositionsQuery, GetPositionsQueryVariables>;
export const QuarterlyNewsletterPageDocument = gql`
    query QuarterlyNewsletterPage($pagination: PaginationInput!) {
  quarterlyNewsletterPage(pagination: $pagination) {
    items {
      id
      updatedDate
      header
      body
      quarter
      year
    }
    totalRows
  }
}
    `;

/**
 * __useQuarterlyNewsletterPageQuery__
 *
 * To run a query within a React component, call `useQuarterlyNewsletterPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuarterlyNewsletterPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuarterlyNewsletterPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useQuarterlyNewsletterPageQuery(baseOptions: Apollo.QueryHookOptions<QuarterlyNewsletterPageQuery, QuarterlyNewsletterPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuarterlyNewsletterPageQuery, QuarterlyNewsletterPageQueryVariables>(QuarterlyNewsletterPageDocument, options);
      }
export function useQuarterlyNewsletterPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuarterlyNewsletterPageQuery, QuarterlyNewsletterPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuarterlyNewsletterPageQuery, QuarterlyNewsletterPageQueryVariables>(QuarterlyNewsletterPageDocument, options);
        }
export type QuarterlyNewsletterPageQueryHookResult = ReturnType<typeof useQuarterlyNewsletterPageQuery>;
export type QuarterlyNewsletterPageLazyQueryHookResult = ReturnType<typeof useQuarterlyNewsletterPageLazyQuery>;
export type QuarterlyNewsletterPageQueryResult = Apollo.QueryResult<QuarterlyNewsletterPageQuery, QuarterlyNewsletterPageQueryVariables>;
export const GetQuarterlyNewsletterDocument = gql`
    query GetQuarterlyNewsletter {
  quarterlyNewsletter {
    id
    updatedDate
    header
    body
    quarter
    year
  }
}
    `;

/**
 * __useGetQuarterlyNewsletterQuery__
 *
 * To run a query within a React component, call `useGetQuarterlyNewsletterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuarterlyNewsletterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuarterlyNewsletterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuarterlyNewsletterQuery(baseOptions?: Apollo.QueryHookOptions<GetQuarterlyNewsletterQuery, GetQuarterlyNewsletterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuarterlyNewsletterQuery, GetQuarterlyNewsletterQueryVariables>(GetQuarterlyNewsletterDocument, options);
      }
export function useGetQuarterlyNewsletterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuarterlyNewsletterQuery, GetQuarterlyNewsletterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuarterlyNewsletterQuery, GetQuarterlyNewsletterQueryVariables>(GetQuarterlyNewsletterDocument, options);
        }
export type GetQuarterlyNewsletterQueryHookResult = ReturnType<typeof useGetQuarterlyNewsletterQuery>;
export type GetQuarterlyNewsletterLazyQueryHookResult = ReturnType<typeof useGetQuarterlyNewsletterLazyQuery>;
export type GetQuarterlyNewsletterQueryResult = Apollo.QueryResult<GetQuarterlyNewsletterQuery, GetQuarterlyNewsletterQueryVariables>;
export const GetStockDataBySymbolDocument = gql`
    query GetStockDataBySymbol($searchTerm: String!) {
  stockDataSearchBySymbol(searchTerm: $searchTerm) {
    id
    createdDate
    price
    symbol
    shortName
    longName
  }
}
    `;

/**
 * __useGetStockDataBySymbolQuery__
 *
 * To run a query within a React component, call `useGetStockDataBySymbolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockDataBySymbolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockDataBySymbolQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetStockDataBySymbolQuery(baseOptions: Apollo.QueryHookOptions<GetStockDataBySymbolQuery, GetStockDataBySymbolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockDataBySymbolQuery, GetStockDataBySymbolQueryVariables>(GetStockDataBySymbolDocument, options);
      }
export function useGetStockDataBySymbolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockDataBySymbolQuery, GetStockDataBySymbolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockDataBySymbolQuery, GetStockDataBySymbolQueryVariables>(GetStockDataBySymbolDocument, options);
        }
export type GetStockDataBySymbolQueryHookResult = ReturnType<typeof useGetStockDataBySymbolQuery>;
export type GetStockDataBySymbolLazyQueryHookResult = ReturnType<typeof useGetStockDataBySymbolLazyQuery>;
export type GetStockDataBySymbolQueryResult = Apollo.QueryResult<GetStockDataBySymbolQuery, GetStockDataBySymbolQueryVariables>;
export const GetStrategyPageDocument = gql`
    query GetStrategyPage($pagination: PaginationInput!) {
  strategyPage(pagination: $pagination) {
    items {
      id
      createdDate
      designerId
      name
      cashMin
      riskLevel
      termDuration
      designer {
        user {
          id
          firstName
          lastName
        }
      }
    }
    totalRows
  }
}
    `;

/**
 * __useGetStrategyPageQuery__
 *
 * To run a query within a React component, call `useGetStrategyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrategyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrategyPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetStrategyPageQuery(baseOptions: Apollo.QueryHookOptions<GetStrategyPageQuery, GetStrategyPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStrategyPageQuery, GetStrategyPageQueryVariables>(GetStrategyPageDocument, options);
      }
export function useGetStrategyPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStrategyPageQuery, GetStrategyPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStrategyPageQuery, GetStrategyPageQueryVariables>(GetStrategyPageDocument, options);
        }
export type GetStrategyPageQueryHookResult = ReturnType<typeof useGetStrategyPageQuery>;
export type GetStrategyPageLazyQueryHookResult = ReturnType<typeof useGetStrategyPageLazyQuery>;
export type GetStrategyPageQueryResult = Apollo.QueryResult<GetStrategyPageQuery, GetStrategyPageQueryVariables>;
export const GetStrategyDocument = gql`
    query GetStrategy($strategyOneId: String!) {
  strategyOne(id: $strategyOneId) {
    id
    createdDate
    designerId
    name
    cashMin
    riskLevel
    termDuration
    description
    enabled
    syncThreshold
    cashPercentage
    designer {
      user {
        firstName
        lastName
      }
    }
    opinions {
      id
      createdDate
      strategyId
      stockDataId
      accountPercentage
      stockData {
        id
        createdDate
        price
        symbol
        shortName
        longName
      }
    }
  }
}
    `;

/**
 * __useGetStrategyQuery__
 *
 * To run a query within a React component, call `useGetStrategyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrategyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrategyQuery({
 *   variables: {
 *      strategyOneId: // value for 'strategyOneId'
 *   },
 * });
 */
export function useGetStrategyQuery(baseOptions: Apollo.QueryHookOptions<GetStrategyQuery, GetStrategyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStrategyQuery, GetStrategyQueryVariables>(GetStrategyDocument, options);
      }
export function useGetStrategyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStrategyQuery, GetStrategyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStrategyQuery, GetStrategyQueryVariables>(GetStrategyDocument, options);
        }
export type GetStrategyQueryHookResult = ReturnType<typeof useGetStrategyQuery>;
export type GetStrategyLazyQueryHookResult = ReturnType<typeof useGetStrategyLazyQuery>;
export type GetStrategyQueryResult = Apollo.QueryResult<GetStrategyQuery, GetStrategyQueryVariables>;
export const GetSubscriberStrategiesDocument = gql`
    query GetSubscriberStrategies($accountId: String!) {
  subscriberRelevantStrategy(accountId: $accountId) {
    id
    createdDate
    designerId
    name
    cashMin
    riskLevel
    termDuration
  }
}
    `;

/**
 * __useGetSubscriberStrategiesQuery__
 *
 * To run a query within a React component, call `useGetSubscriberStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberStrategiesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetSubscriberStrategiesQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriberStrategiesQuery, GetSubscriberStrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriberStrategiesQuery, GetSubscriberStrategiesQueryVariables>(GetSubscriberStrategiesDocument, options);
      }
export function useGetSubscriberStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriberStrategiesQuery, GetSubscriberStrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriberStrategiesQuery, GetSubscriberStrategiesQueryVariables>(GetSubscriberStrategiesDocument, options);
        }
export type GetSubscriberStrategiesQueryHookResult = ReturnType<typeof useGetSubscriberStrategiesQuery>;
export type GetSubscriberStrategiesLazyQueryHookResult = ReturnType<typeof useGetSubscriberStrategiesLazyQuery>;
export type GetSubscriberStrategiesQueryResult = Apollo.QueryResult<GetSubscriberStrategiesQuery, GetSubscriberStrategiesQueryVariables>;
export const GetSubscriberPageDocument = gql`
    query GetSubscriberPage($pagination: PaginationInput!) {
  subscriberPage(pagination: $pagination) {
    items {
      id
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        createdDate
      }
      designer {
        user {
          id
          firstName
          lastName
        }
      }
    }
    totalRows
  }
}
    `;

/**
 * __useGetSubscriberPageQuery__
 *
 * To run a query within a React component, call `useGetSubscriberPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetSubscriberPageQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriberPageQuery, GetSubscriberPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriberPageQuery, GetSubscriberPageQueryVariables>(GetSubscriberPageDocument, options);
      }
export function useGetSubscriberPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriberPageQuery, GetSubscriberPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriberPageQuery, GetSubscriberPageQueryVariables>(GetSubscriberPageDocument, options);
        }
export type GetSubscriberPageQueryHookResult = ReturnType<typeof useGetSubscriberPageQuery>;
export type GetSubscriberPageLazyQueryHookResult = ReturnType<typeof useGetSubscriberPageLazyQuery>;
export type GetSubscriberPageQueryResult = Apollo.QueryResult<GetSubscriberPageQuery, GetSubscriberPageQueryVariables>;
export const GetSubscriberDocument = gql`
    query GetSubscriber($id: String) {
  subscriberOne(id: $id) {
    id
    trialAvailable
    user {
      id
      firstName
      lastName
      email
      phoneNumber
      createdDate
      smsConsent
    }
    subscription {
      id
      designerId
      renewalDate
      createdDate
      renewalFrequency
      cost
      cancelled
      trial
      swapRequest {
        id
        designerId
      }
      status
      isExpired
    }
    custodians {
      id
      name
      userTag
      plaidInstitutionId
      isActive
      reAuthRequired
    }
    designer {
      id
      user {
        id
        firstName
        lastName
      }
      strategies {
        id
        name
        description
        cashMin
        riskLevel
        termDuration
        opinions {
          stockData {
            id
            price
            symbol
            shortName
            longName
          }
          accountPercentage
        }
      }
    }
    customerPaymentMethods {
      card {
        brand
        country
        exp_month
        exp_year
        fingerprint
        last4
      }
    }
  }
}
    `;

/**
 * __useGetSubscriberQuery__
 *
 * To run a query within a React component, call `useGetSubscriberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubscriberQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriberQuery, GetSubscriberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriberQuery, GetSubscriberQueryVariables>(GetSubscriberDocument, options);
      }
export function useGetSubscriberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriberQuery, GetSubscriberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriberQuery, GetSubscriberQueryVariables>(GetSubscriberDocument, options);
        }
export type GetSubscriberQueryHookResult = ReturnType<typeof useGetSubscriberQuery>;
export type GetSubscriberLazyQueryHookResult = ReturnType<typeof useGetSubscriberLazyQuery>;
export type GetSubscriberQueryResult = Apollo.QueryResult<GetSubscriberQuery, GetSubscriberQueryVariables>;
export const GetSubscriberAccountReportDocument = gql`
    query GetSubscriberAccountReport($timeRange: String!, $subscriberId: String!, $accountId: String!) {
  getSubscriberAccountReport(
    timeRange: $timeRange
    subscriberId: $subscriberId
    accountId: $accountId
  ) {
    numberOfAccounts
    netWorth
    values {
      totalValue
      lifetimeDelta
      createdDate
      quarter
      year
    }
    accounts {
      id
      name
      unallocatedFunds
      totalValue
    }
  }
}
    `;

/**
 * __useGetSubscriberAccountReportQuery__
 *
 * To run a query within a React component, call `useGetSubscriberAccountReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberAccountReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberAccountReportQuery({
 *   variables: {
 *      timeRange: // value for 'timeRange'
 *      subscriberId: // value for 'subscriberId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetSubscriberAccountReportQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriberAccountReportQuery, GetSubscriberAccountReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriberAccountReportQuery, GetSubscriberAccountReportQueryVariables>(GetSubscriberAccountReportDocument, options);
      }
export function useGetSubscriberAccountReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriberAccountReportQuery, GetSubscriberAccountReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriberAccountReportQuery, GetSubscriberAccountReportQueryVariables>(GetSubscriberAccountReportDocument, options);
        }
export type GetSubscriberAccountReportQueryHookResult = ReturnType<typeof useGetSubscriberAccountReportQuery>;
export type GetSubscriberAccountReportLazyQueryHookResult = ReturnType<typeof useGetSubscriberAccountReportLazyQuery>;
export type GetSubscriberAccountReportQueryResult = Apollo.QueryResult<GetSubscriberAccountReportQuery, GetSubscriberAccountReportQueryVariables>;
export const GetStripePaymentMethodLinkDocument = gql`
    query GetStripePaymentMethodLink($cancelSubRoute: String, $successSubRoute: String) {
  getStripePaymentMethodLink(
    cancelSubRoute: $cancelSubRoute
    successSubRoute: $successSubRoute
  )
}
    `;

/**
 * __useGetStripePaymentMethodLinkQuery__
 *
 * To run a query within a React component, call `useGetStripePaymentMethodLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripePaymentMethodLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripePaymentMethodLinkQuery({
 *   variables: {
 *      cancelSubRoute: // value for 'cancelSubRoute'
 *      successSubRoute: // value for 'successSubRoute'
 *   },
 * });
 */
export function useGetStripePaymentMethodLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetStripePaymentMethodLinkQuery, GetStripePaymentMethodLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripePaymentMethodLinkQuery, GetStripePaymentMethodLinkQueryVariables>(GetStripePaymentMethodLinkDocument, options);
      }
export function useGetStripePaymentMethodLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripePaymentMethodLinkQuery, GetStripePaymentMethodLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripePaymentMethodLinkQuery, GetStripePaymentMethodLinkQueryVariables>(GetStripePaymentMethodLinkDocument, options);
        }
export type GetStripePaymentMethodLinkQueryHookResult = ReturnType<typeof useGetStripePaymentMethodLinkQuery>;
export type GetStripePaymentMethodLinkLazyQueryHookResult = ReturnType<typeof useGetStripePaymentMethodLinkLazyQuery>;
export type GetStripePaymentMethodLinkQueryResult = Apollo.QueryResult<GetStripePaymentMethodLinkQuery, GetStripePaymentMethodLinkQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  userMany {
    id
    cognitoId
    role
    firstName
    lastName
    email
    phoneNumber
    createdDate
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: String) {
  userOne(id: $id) {
    id
    cognitoId
    role
    firstName
    lastName
    email
    phoneNumber
    createdDate
    acceptedTAC
    smsConsent
    completedOnboarding
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserPageDocument = gql`
    query GetUserPage($pagination: PaginationInput!) {
  userPage(pagination: $pagination) {
    items {
      id
      cognitoId
      role
      firstName
      lastName
      email
      phoneNumber
      createdDate
    }
    totalRows
  }
}
    `;

/**
 * __useGetUserPageQuery__
 *
 * To run a query within a React component, call `useGetUserPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUserPageQuery(baseOptions: Apollo.QueryHookOptions<GetUserPageQuery, GetUserPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPageQuery, GetUserPageQueryVariables>(GetUserPageDocument, options);
      }
export function useGetUserPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPageQuery, GetUserPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPageQuery, GetUserPageQueryVariables>(GetUserPageDocument, options);
        }
export type GetUserPageQueryHookResult = ReturnType<typeof useGetUserPageQuery>;
export type GetUserPageLazyQueryHookResult = ReturnType<typeof useGetUserPageLazyQuery>;
export type GetUserPageQueryResult = Apollo.QueryResult<GetUserPageQuery, GetUserPageQueryVariables>;